import React from 'react';
import GooglePayProcessor, { PaymentStatus } from './google-pay/google-pay-processor';
import GooglePayButtonOriginal from './google-pay/GooglePayButton';
import _toString from 'lodash/toString';
import { RotateLoader } from '@lib/components';
import { MobxComponent } from '../../../mobx/component';
import { inject, observer } from 'mobx-react';

interface Props {
  merchantID: string;
  merchantName: string;
  totalPriceLabel: string;
  totalPrice: number;
  currencyCode: string;
  countryCode: string;
  gatewayMerchantId: string;
  label: string;
  customerName: string;
  customerEmail: string;
  restaurantID: string;
  items: Array<T.Schema.Order.OrderDish>;
}

interface State {
  googlePayProcessing: boolean;
}

@inject('store')
@observer
export default class GooglePayButton extends MobxComponent<Props, State> {
  processor: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      googlePayProcessing: false,
    };
  }

  paymentRequestSuccess(paymentData: any) {
    this.setState({ googlePayProcessing: true });

    const token = paymentData.paymentMethodData.tokenizationData.token;
    const { store } = this.injected;
    const {
      merchantID,
      merchantName,
      totalPriceLabel,
      totalPrice,
      currencyCode,
      countryCode,
      gatewayMerchantId,
      label,
      customerName,
      customerEmail,
      restaurantID,
      items,
    } = this.props;

    const data = {
      merchantID,
      merchantName,
      currency: currencyCode,
      label,
      amount: totalPrice,
      countryCode,
      customerName,
      customerEmail,
      restaurantID,
      items,
    };

    console.log('initial google pay payment data:', data);

    const orderDataError = store.checkout.validateOrderData();
    if (orderDataError) {
      this.setState({ googlePayProcessing: false });
      return;
    }

    const processor = new GooglePayProcessor(data);
    processor
      .handlePaymentAuthorized(token)
      .then(async status => {
        switch (status) {
          case PaymentStatus.CANCEL:
            store.checkout.update({
              error: 'The Google Pay payment was cancelled.',
            });
            break;
          case PaymentStatus.SUCCESS:
            try {
              const order = await store.checkout.order_commence();
              if (!order) {
                store.checkout.update({ error: 'Failed to create the order' });
                this.setState({ googlePayProcessing: false });
                return;
              }
              this.setState({ googlePayProcessing: false });
              store.router.push(`/order/${order._id}`);
            } catch (e) {
              this.setState({ googlePayProcessing: false });
              return;
            }
            break;
          default:
            this.setState({ googlePayProcessing: false });
            store.checkout.update({
              error: 'Failed to process the payment using Google Pay',
            });
        }
      })
      .catch(e => {
        console.error('Cannot start Google Pay payment:', e);
        this.setState({ googlePayProcessing: false });
        store.checkout.update({
          error: 'Failed to process the payment using Google Pay',
        });
      });
  }

  // @ts-ignore
  onPaymentAuthorized(paymentData: google.payments.api.PaymentData) {
    return new Promise((resolve, reject) => {
      // handle the response
      resolve({ transactionState: 'SUCCESS' });
    });
  }

  render() {
    const { merchantID, merchantName, totalPriceLabel, totalPrice, currencyCode, countryCode, gatewayMerchantId } =
      this.props;

    return (
      <div>
        <GooglePayButtonOriginal
          style={{ width: '100%' }}
          environment="TEST"
          buttonSizeMode="fill"
          paymentRequest={{
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    gateway: 'checkoutltd',
                    gatewayMerchantId: gatewayMerchantId,
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: merchantID,
              merchantName: merchantName,
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPriceLabel: totalPriceLabel,
              totalPrice: _toString(totalPrice),
              currencyCode: currencyCode,
              countryCode: countryCode,
            },
          }}
          //@ts-ignore
          onPaymentAuthorized={this.onPaymentAuthorized} //Show recipt
          onLoadPaymentData={paymentRequest => {
            this.paymentRequestSuccess(paymentRequest);
          }}
        />
        {this.state.googlePayProcessing && <RotateLoader size={3} color="#111111" style={{ marginTop: '0.5rem' }} />}
      </div>
    );
  }
}
