import * as React from "react";
import { observer, inject } from "mobx-react";
import {
	Input,
	RotateLoader,
	Button,
	FormGroup,
	Checkbox,
} from "@lib/components";
import { cloneDeepSafe, FORMATS, logger, RestaurantUtils } from "@lib/common";
import { Untrusive } from "@lib/common";
import { FastField, Form, Formik, FormikActions, FormikProps } from "formik";
import { withTheme } from "styled-components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";
import moment from "moment-timezone";
import isEmail from "validator/lib/isEmail";

import _get from "lodash/get";
import _toString from "lodash/toString";
import _findIndex from "lodash/findIndex";
import _isEmpty from "lodash/isEmpty";
import { CustomBookingField } from "./custom-booking-field";
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { E164Number } from "libphonenumber-js";

interface Props extends WithTranslation { }
interface FormValues {
	name: string;
	phone: string;
	phone_e164_format: string;
	phone_local_format: string;
	email: string;
	zip: string;
	number_of_people: number;
	notes: string;
	custom_booking_field_list: T.Schema.CustomFieldValue[];
	terms: boolean;
}

@inject("store")
@observer
class BookingConfigClass extends MobxComponent<Props, {
	answerList: Array<T.Schema.CustomFieldValue>,
	error: string,
}> {
	enabledBookingFieldList: Array<T.Schema.Restaurant.Services.CustomCheckoutField>;
	timer: any = null;
	constructor(props: Props) {
		super(props);

		const service = this.injected.store.restaurant.settings.services.table_booking;
		const bookingFieldList = _get(service, 'custom_checkout_fields', [] as T.Schema.Restaurant.Services.CustomCheckoutField[]);
		this.enabledBookingFieldList = bookingFieldList.filter(field => field.enabled);
		const initialAnswerList = this.enabledBookingFieldList.map((field) => {
			const answer = field.type === "checkbox" ? "no" : "";
			return {
				id: field._id,
				question: {
					label: _toString(field.label),
					description: _toString(field.description),
				},
				answer,
				type: field.type
			}
		})

		this.state = {
			answerList: initialAnswerList,
			error: ""
		};
	}

	setError = (error: string) => this.setState({ error });

	submit = async (v: FormValues, form: FormikActions<FormValues>) => {
		try {
			const { store } = this.injected;
			const r = store.restaurant;
			const service = store.restaurant.settings.services.table_booking;
			const max_people = service.max_people || service.limit_number_of_people;
			const { min_people } = service;

			const bookingFieldList = _get(
				service,
				"custom_checkout_fields",
				[] as T.Schema.Restaurant.Services.CustomCheckoutField[]
			);

			let error = "";
			if (!v.name) {
				error = "required_name";
			}
			if (!v.email) {
				error = "required_email";
			}
			//   if (!v.zip) {
			//     error = "required_zip";
			//   }
			if (!isEmail(v.email, { require_tld: true })) {
				error = "invalid_email";
			}
			if (!v.phone) {
				error = "required_phone";
			}
			if (
				r.settings.business.validation &&
				r.settings.business.validation.phone_regex &&
				!new RegExp(r.settings.business.validation.phone_regex).test(v.phone)
			) {
				error = "invalid_phone";
			}
			if (max_people || min_people) {
				if (
					max_people &&
					min_people &&
					(v.number_of_people > max_people || v.number_of_people < min_people)
				) {
					error = "invalid_people";
				} else if (
					max_people &&
					!min_people &&
					v.number_of_people > max_people
				) {
					error = "invalid_people";
				} else if (
					!max_people &&
					min_people &&
					v.number_of_people < min_people
				) {
					error = "invalid_people";
				}
			}
			if (store.termsActive && !v.terms) {
				error = "accept_terms";
			}

			if (bookingFieldList.length > 0) {
				const requiredBookingFieldList = bookingFieldList
					.filter((field) => field.enabled && field.required)
					.map((field) => field._id);

				if (requiredBookingFieldList.length > 0) {
					for (const field of v.custom_booking_field_list) {
						if (
							requiredBookingFieldList.includes(field.id) &&
							_isEmpty(field.answer)
						) {
							error = "missing_custom_fields";
							break;
						}
					}
				}
			}

			if (error) {
				form.setSubmitting(false);
				this.setError(error);
				return;
			}

			const tz = store.restaurant.settings.region.timezone;
			const oc = store.order_config.s;
			const c = store.customer.s.item;

			this.setError("");
			Untrusive.start();
			form.setSubmitting(true);

			const timestamp = moment
				.tz(`${oc.date} ${oc.time}`, FORMATS.moment.datetime, tz)
				.valueOf();

			// remove booking fields with empty answer
			const filteredBookingFieldList = cloneDeepSafe(
				v.custom_booking_field_list
			).filter((field) => field.answer);

			const response = await store.api.booking_create({
				booking: {
					notes: v.notes,
					custom_booking_field_list: filteredBookingFieldList,
					customer: {
						_id: c ? c._id : "",
						name: v.name,
						phone: v.phone,
						phone_e164_format: v.phone_e164_format,
						phone_local_format: v.phone_local_format,
						email: v.email,
					},
					config: {
						date: oc.date,
						time: oc.time,
						timestamp: timestamp,
						number_of_people: v.number_of_people as number,
					},
				},
			});

			if (response.outcome) {
				form.setSubmitting(false);
				this.setError(response.message);
				return;
			}

			const { booking, customer } = response;

			// UPDATE CUSTOMER & ORDER PROFILE
			if (customer.type !== "guest") {
				store.customer.update({ item: customer });
			}
			store.booking.update({ item: booking });

			// CLOSE MODAL
			store.modal.update({ active: "", last: "" });

			// CLEAR CART AND ORDER SETUP
			store.order_config.setService("");

			Untrusive.stop();

			// TAKE TO ORDER
			store.router.push(`/booking/${booking._id}`);

			fbq("track", "Schedule");
		} catch (e) {
			logger.captureException(e);
			this.setError("something_went_wrong");
			form.setSubmitting(false);
			Untrusive.stop();
		}
	};

	getError = (form: FormikProps<FormValues>, field: keyof FormValues) => {
		if (form.submitCount > 0 && form.touched[field] && form.errors[field]) {
			return form.errors[field];
		}
		return null;
	};

	render() {
		const { store, t, theme } = this.injected;

		const service = store.restaurant.settings.services.table_booking;

		const max_people = service.max_people || service.limit_number_of_people;

		const { min_people } = service;

		const customer = store.customer.s.item;

		const answerList = this.state.answerList;

		const initialValues: FormValues = customer ? {
			name: customer.details.name || "",
			email: customer.details.email || "",
			phone: customer.details.phone || "",
			phone_e164_format: "",
			phone_local_format: "",
			number_of_people: 0,
			custom_booking_field_list: answerList,
			notes: "",
			zip: "",
			terms: false,
		} : {
			name: "",
			email: "",
			phone: "",
			phone_e164_format: "",
			phone_local_format: "",
			zip: "",
			number_of_people: 0,
			custom_booking_field_list: answerList,
			notes: "",
			terms: false,
		};


		let no_people_placeholder = t("store.modals.booking.form.number_of_people");
		if (max_people && min_people) {
			no_people_placeholder = t(
				"store.modals.booking.form.number_of_people_max_min",
				{ max_people, min_people }
			);
		} else if (max_people && !min_people) {
			no_people_placeholder = t(
				"store.modals.booking.form.number_of_people_max",
				{ max_people }
			);
		} else if (!max_people && min_people) {
			no_people_placeholder = t(
				"store.modals.booking.form.number_of_people_min",
				{ min_people }
			);
		}

		return (
			<div className="m-t-6 max400 center">
				<Formik initialValues={initialValues} onSubmit={this.submit}>
					{(form) => {
						const { isSubmitting, submitCount } = form;
						const showFormError = submitCount > 0 && this.state.error;
						return (
							<Form>
								<FastField
									name="name"
									render={({ field }: any) => (
										<FormGroup no_border={true} className="">
											<Input
												{...field}
												type="text"
												placeholder={t("store.modals.booking.form.name")}
												required={true}
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="email"
									render={({ field }: any) => (
										<FormGroup no_border={true} className="">
											<Input
												{...field}
												type="email"
												placeholder={t("store.modals.booking.form.email")}
												required={true}
											/>
										</FormGroup>
									)}
								/>

								<FastField
									name="phone_e164_format"
									render={({ field }: any) => (
										<FormGroup no_border={true} className="">
											
											<PhoneInput
												international={false}
												placeholder={t("store.modals.booking.form.phone")}
												onChange={(value) => {
													clearTimeout(this.timer)
													this.timer = setTimeout(() => {
														console.log('value', value)
														form.setFieldValue('phone', formatPhoneNumber(value as E164Number).replace(/[^0-9]+/g, ""))
														form.setFieldValue('phone_local_format', formatPhoneNumber(value as E164Number))
														form.setFieldValue('phone_e164_format', value)
	
													}, 1500);
												}}
												//@ts-ignore
												defaultCountry={ RestaurantUtils.settings.getCountryCodeFromLocation(store.restaurant) || 'AU'}
												countryOptionsOrder={['AU', 'US', 'GB', 'CA']}
												numberInputProps={{
													style: {
														background: theme.input.background,
														color: theme.input.text,
														border: `1px solid ${theme.input.border}`,
														fontSize: theme.input.fontSize,
														height: theme.input.height
													}
													
												}}
											/>
										</FormGroup>

										
									)}
								/>

								<FastField
									name="number_of_people"
									render={({ field }: any) => (
										<FormGroup no_border={true} className="">
											<Input
												type="number"
												max={max_people ? max_people : 100000}
												min={min_people ? min_people : 1}
												placeholder={t("store.modals.booking.form.number_of_guests")}
												required={true}
												{...field}
												value={field.value === 0 ? '' : field.value}
											/>
											
										</FormGroup>
									)}
								/>

								<FastField
									name="notes"
									render={({ field }: any) => (
										<FormGroup no_border={true} className="">
											<Input
												{...field}
												type="text"
												placeholder={t("store.modals.booking.form.notes")}
											/>
										</FormGroup>
									)}
								/>

								{this.enabledBookingFieldList &&
									this.enabledBookingFieldList.map((customBookingField, index) => (
										<CustomBookingField
											onValueChange={
												(value) => {
													answerList[index].answer = value;
													form.setFieldValue("custom_booking_field_list", answerList);
												}
											}
											key={customBookingField._id}
											field={customBookingField} />
									))}

								{store.termsActive && (
									<FastField
										name="terms"
										render={({ field }: any) => (
											<FormGroup
												no_border={true}
												contentClassName="flex-center"
											>
												<Checkbox
													id="accept-terms-checkout"
													name="terms"
													checked={field.value}
													onChange={(e) =>
														form.setFieldValue("terms", e.target.checked)
													}
													label={
														<p className="inline-block small">
															{t("store.modals.checkout.accept_terms")} -{" "}
															<a
																className="link"
																onClick={() => store.modal.show("terms")}
															>
																{t("store.modals.checkout.accept_terms_view")}
															</a>
														</p>
													}
												/>
											</FormGroup>
										)}
									/>
								)}

								{showFormError && (
									<FormGroup
										no_border={true}
										error={t(`store.modals.booking.errors.${this.state.error}`)}
									/>
								)}

								<Button
									full={true}
									color="primary"
									type="submit"
									disabled={isSubmitting}
								>
									{isSubmitting && (
										<RotateLoader
											size={2}
											color={this.injected.theme.colors.primary_text}
										/>
									)}
									{!isSubmitting && t("store.modals.booking.form.submit")}
								</Button>

								<p className="m-t-4 text-center lhp small">
									{t("store.modals.booking.form.info_1")}
								</p>
							</Form>
						);
					}}
				</Formik>
			</div>
		);
	}
}

// @ts-ignore
export const BookingConfig = withTheme(withTranslation()(BookingConfigClass));
