export function getCheckoutFormCommonFields(order: T.Schema.Order.OrderSchema, t: any) {
  return [
    {
      value: String(t('currency', { value: order.bill.total })),
      label: 'Total',
    },
    {
      value: order.customer.name,
      label: 'Name',
    },
    {
      value: order.customer.email,
      label: 'Email',
    },
    {
      value: order.customer.phone,
      label: 'Phone',
    },
  ];
}
