import { observable, action } from "mobx";
import { RootStore } from "../store";

export interface FiltersState {
	menu: string;
	category: string;
	categoryRestrictionModal: string;
}

export class FiltersStore {

	@observable s: FiltersState;
	store: RootStore;

	constructor(store: RootStore, initialState?: FiltersState) {
		this.store = store;
		this.s = {
			menu: "",
			category: "",
			categoryRestrictionModal: "",
			...(initialState || {}),
		};
	}

	@action update = (data: Partial<FiltersState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof FiltersState];
				if (value !== undefined) {
					this.s[key as keyof FiltersState] = value;
				}
			}
		}
	}

}
