import * as React from "react";
import { inject, observer } from "mobx-react";
import { Modal, ModalContent, CircleIcon, ButtonGroup, FormGroup, RotateLoader } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { AuthLoginForm } from "./login";
import { FaUser } from "react-icons/fa";
import { AuthRegisterForm } from "./register";
import { AuthSocial } from "./social";

interface Props extends WithTranslation { }
interface State {
	mounted: boolean;
}

@inject("store") @observer
class AuthModalClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			mounted: false,
		};
	}

	componentDidMount() {
		this.setState({ mounted: true });
	}

	close = () => {
		const { store } = this.injected;
		store.modal.back();
		store.router.push("/");
	}

	render() {
		const { mounted } = this.state;
		const { store, t } = this.injected;
		const r = store.restaurant;
		const form = store.customer.form;
		const isWebView = store.view.s.is_web_view;
		const path = store.router.s.path;

		const { isLoggedIn } = store.customer;
		const { logged_in_only, disable_signup } = r.settings.business;

		let active = logged_in_only && !isLoggedIn;

		if (!active) {
			active = path === "/login" || path === "/register" || store.modal.isVisible("auth");
		}

		if (active && isLoggedIn) {
			active = false;
		}

		return (
			<Modal
				width="xs"
				id="modal-auth"
				active={active}
				closeButton={!logged_in_only}
				persistChildren={true}
				close={this.close}>

				{!mounted && (
					<ModalContent paddinglr={20} paddingtb={100} className="round-sm">
						<RotateLoader size={3} />
					</ModalContent>
				)}

				{mounted && (
					<ModalContent>

						<div className="">
							<CircleIcon icon={<FaUser />} />
						</div>

						<FormGroup>
							{!disable_signup && (
								<div className="text-center m-b-6">
									<ButtonGroup
										size="xs"
										width={100}
										selected={form.current}
										round={true}
										options={[
											{ value: "login", name: t("store.modals.auth.login") },
											{ value: "register", name: t("store.modals.auth.register") },
										]}
										onSelect={(v) => store.customer.setCurrentForm(v.value as "login" | "register")}
									/>
								</div>
							)}
							{(disable_signup && logged_in_only) && (
								<div className="text-center m-b-6">
									<h3>Login To Order</h3>
								</div>
							)}
							{form.current === "login" && <AuthLoginForm />}
							{form.current === "register" && <AuthRegisterForm />}
						</FormGroup>

						{(!isWebView && !disable_signup) && <AuthSocial />}

						{!logged_in_only && !disable_signup && (
							<FormGroup contentClassName="text-center">
								<p className="small">{t("store.modals.auth.benefits")}</p>
							</FormGroup>
						)}

					</ModalContent>
				)}

			</Modal>
		);
	}

}

export const AuthModal = withTranslation()(AuthModalClass);
