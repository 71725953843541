import * as React from "react";
import { observer, inject } from "mobx-react";
import { Box } from "./components";
import { CircleIcon, RestaurantOpeningHours, RestaurantSpecialOpeningHours } from "@lib/components";
import { FaClock } from "react-icons/fa";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";
import { ServiceInfo } from "./services";

interface Props extends WithTranslation { }

@inject("store") @observer
class InfoOpeningHoursClass extends MobxComponent<Props, { active: boolean }> {

	constructor(props: Props) {
		super(props);
		this.state = {
			active: false,
		};
	}

	render() {
		const { t } = this.injected;
		const r = this.injected.store.restaurant;
		return (
			<Box className="col">
				<CircleIcon icon={<FaClock />} />
				<h3 className="text-center font-regular m-b-6">{t("store.sections.info.hours.title")}</h3>
				<RestaurantOpeningHours hours={r.location.opening_hours} />
				{(r.location.special_hours && r.location.special_hours.length > 0) && (
					<div className="m-t-6">
						<ServiceInfo
							title={t("store.sections.info.hours.title_special")}
							active={this.state.active}
							onClick={() => this.setState({ active: !this.state.active })}
							content={(
								<RestaurantSpecialOpeningHours
									hours={r.location.special_hours}
								/>
							)} />
					</div>
				)}
			</Box>
		);
	}

}

export const InfoOpeningHours = withTranslation()(InfoOpeningHoursClass);
