import * as React from "react";
import { observer, inject } from "mobx-react";
import {
	Modal,
	ModalContent,
	FormGroup,
	List,
	ListItemBig,
	RestaurantOpeningHours,
} from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

interface Props extends WithTranslation { }

@inject("store")
@observer
class CategoryRestrictionsModalClass extends MobxComponent<Props> {
	render() {
		const { store, t } = this.injected;
		const { modal, getCategory } = store;

		const category = getCategory(
			store.filters.s.categoryRestrictionModal || store.filters.s.category
		);
		if (!category) return null;
		const conditions = category.conditions;
		if (!conditions) return null;

		const { reason } = store.isCategoryAvailable(category); // reason = services, hours

		return (
			<Modal
				id="modal-category-restrictions"
				width={360}
				closeButton={true}
				active={modal.isVisible("category-restrictions")}
				close={() => modal.hide("category-restrictions")}
			>
				<ModalContent paddingtb={25} paddinglr={25}>
					{reason && (
						<FormGroup
							error={t(`store.sections.category.unavailable.${reason}`)}
						/>
					)}

					{conditions.services.length > 0 && (
						<FormGroup title={t("store.sections.menus.info.services.title")}>
							<List>
								{conditions.services.map((s, i) => (
									<ListItemBig key={i}>
										{t(`store.sections.menus.info.services.${s}`)}
									</ListItemBig>
								))}
							</List>
						</FormGroup>
					)}

					{conditions.hours.length > 0 && (
						<FormGroup title={t("store.sections.menus.info.hours.title")}>
							<RestaurantOpeningHours hours={conditions.hours} />
						</FormGroup>
					)}
				</ModalContent>
			</Modal>
		);
	}
}

export const CategoryRestrictionsModal = withTranslation()(
	CategoryRestrictionsModalClass
);
