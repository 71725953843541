import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { useStores } from '../../../mobx/useStores';
import { CheckoutFormModal } from './checkout-form';
import { InputField, PaymentMethod } from './flows';
import { getCheckoutFormCommonFields } from './utils';
import { useTranslation } from 'react-i18next';

function CabbagePayFormModalPlain() {
  const store = useStores();
  const { t } = useTranslation();
  const [publicToken, setPublicToken] = useState('');

  useEffect(() => {
    const onMessage = (event: any) => {
      try {
        const data = JSON.parse(event.data);
        if (Object.prototype.hasOwnProperty.call(data, 'public_token')) {
          const publicToken = data.public_token;
          setPublicToken(publicToken);
        }
      } catch (e) {
        // As events may come from different sources, for example, browser extensions,
        // We won't handle the error here. We just listen for public_token event to be
        // fired and store the token in component state. Event from CabbagePay may come
        // after events from other sources.
      }
    };

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    if (publicToken) {
      // Close CabbagePay iframe as we obtained the public_token.
      closeGrid();

      // Close this modal to show order details modal.
      store.order.update({ paymentFormModal: '' });

      async function moveTransaction() {
        try {
          await store.checkout.moveCabbagePayTransaction(publicToken, order!._id, store.restaurant._id);
        } catch (e) {
          //
        }
      }

      moveTransaction();
    }
  }, [publicToken]);

  const order = store.order.s.item;
  const cabbagepayData = order?.payment?.cabbagepay;
  if (!order || !cabbagepayData) return null;

  const cabbageScriptLoaded = typeof window !== 'undefined' && typeof window.cabbage;

  const closeGrid = () => {
    if (cabbageScriptLoaded) {
      window.cabbage!.closeGrid();
    }
  };

  const openGrid = async () => {
    const linkToken = cabbagepayData.linkToken;
    if (cabbageScriptLoaded && linkToken) {
      window.cabbage!.initializeGrid(linkToken);
      window.cabbage!.openGrid(linkToken);
    }
  };

  const paymentMethod: PaymentMethod = {
    name: order.payment.method,
  };

  const inputFields: InputField[] = [...getCheckoutFormCommonFields(order, t)];

  return (
    <CheckoutFormModal
      inputFields={inputFields}
      paymentMethod={paymentMethod}
      orderId={order._id}
      isFullScreen={isMobile}
      adhocAction={openGrid}
      preventClosingModal
    />
  );
}

export const CabbagePayFormModal = observer(CabbagePayFormModalPlain);
