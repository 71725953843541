import React, { FC, useEffect, useState, useRef } from 'react';

import { GoogleServiceLib } from '@lib/common';
import { config } from '../../../../../config';

export type Location = {
  lat: number,
  lng: number,
  address?: string,
  geocoderResult?: google.maps.GeocoderResult,
}

enum MapTypeId {
  Roadmap = 'roadmap',
  Satellite = 'satellite',
  Hybrid = 'hybrid',
  Terrain = 'terrain'
}

type Props = {
  defaultLocation: Location;
  zoom?: number;
  onChangeLocation?(location: Location): void;
  onChangeZoom?(zoom: number): void;
  handleLocationPermission: () => void;
  style?: any;
  className?: string;
  mapTypeId?: MapTypeId
}

function MyLocationControl(controlDiv: Element, requestCurrentLocation: () => void) {
  const controlUI = document.createElement("div");

  controlUI.style.backgroundColor = "#fff";
  controlUI.style.border = "2px solid #fff";
  controlUI.style.borderRadius = "3px";
  controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
  controlUI.style.cursor = "pointer";
  controlUI.style.marginTop = "10px";
  controlUI.style.marginRight = "10px";
  controlDiv.appendChild(controlUI);

  const controlIcon = document.createElement("span");
  controlIcon.style.display = "flex";
  controlIcon.style.alignItems = "center";
  controlIcon.style.justifyContent = "center";
  controlIcon.style.fontSize = "16px";
  controlIcon.style.padding = "5px";
  controlIcon.innerHTML = `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M256 176c-44.004 0-80.001 36-80.001 80 0 44.004 35.997 80 80.001 80 44.005 0 79.999-35.996 79.999-80 0-44-35.994-80-79.999-80zm190.938 58.667c-9.605-88.531-81.074-160-169.605-169.599V32h-42.666v33.067c-88.531 9.599-160 81.068-169.604 169.599H32v42.667h33.062c9.604 88.531 81.072 160 169.604 169.604V480h42.666v-33.062c88.531-9.604 160-81.073 169.605-169.604H480v-42.667h-33.062zM256 405.333c-82.137 0-149.334-67.198-149.334-149.333 0-82.136 67.197-149.333 149.334-149.333 82.135 0 149.332 67.198 149.332 149.333S338.135 405.333 256 405.333z"></path></svg>`;
  controlUI.appendChild(controlIcon);

  controlUI.addEventListener("click", () => {
    requestCurrentLocation();
  });
}

const MapPicker: FC<Props> = ({
  defaultLocation,
  style,
  className,
  mapTypeId,
  zoom = 10,
  onChangeZoom,
  onChangeLocation,
  handleLocationPermission,
}) => {
  const map = useRef<any>(null);
  const marker = useRef<any>(null);
  const MAP_VIEW_ID = 'google-map-view-' + Math.random().toString(36).substr(2, 9);

  const handleChangeLocation = async () => {
    if (onChangeLocation) {
      const position = marker.current.getPosition();
      const lat = position.lat();
      const lng = position.lng();
      const googleService = GoogleServiceLib(config.google_api_key);
      const geocodingResults = await googleService.geocode({ location: { lat, lng } });
      if (geocodingResults && geocodingResults.length > 0) {
        onChangeLocation({ lat, lng, geocoderResult: geocodingResults[0] });
      } else {
        onChangeLocation({ lat, lng });
      }
    }
  }

  const isValidLocation = (location: Location) => {
    return location && Math.abs(location.lat) <= 90 && Math.abs(location.lng) <= 180;
  }

  const handleChangeZoom = () => {
    onChangeZoom && onChangeZoom(map.current.getZoom());
  }

  function loadMap() {
    const Google = (window as any).google;
    const validLocation = isValidLocation(defaultLocation) ? defaultLocation : { lat: 0, lng: 0 };
    map.current = new Google.maps.Map(document.getElementById(MAP_VIEW_ID), {
      center: validLocation,
      zoom: zoom,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      ...(mapTypeId && { mapTypeId })
    });

    const myLocationDiv = document.createElement("div");
    MyLocationControl(myLocationDiv, handleLocationPermission);

    if (!marker.current) {
      marker.current = new Google.maps.Marker({
        position: validLocation,
        map: map.current,
        draggable: true
      });
      Google.maps.event.addListener(marker.current, 'dragend', handleChangeLocation);
    } else {
      marker.current.setPosition(validLocation);
    }

    map.current.controls[google.maps.ControlPosition.TOP_RIGHT].push(myLocationDiv);

    map.current.addListener('click', function (event: any) {
      const clickedLocation = event.latLng;
      marker.current.setPosition(clickedLocation);
      handleChangeLocation();
    });

    map.current.addListener('zoom_changed', handleChangeZoom);
  }

  useEffect(() => {
    loadMap();
  }, []);

  useEffect(() => {
    if (marker.current) {
      map.current.setCenter(defaultLocation);
      marker.current.setPosition(defaultLocation);
    }
  }, [defaultLocation]);

  useEffect(() => {
    if (map.current) {
      map.current.setZoom(zoom);
    }
  }, [zoom]);

  const componentStyle = Object.assign({ minHeight: '200px' }, style || {});

  return (
    <div id={MAP_VIEW_ID} style={componentStyle} className={className}></div>
  );
};

const DefaultZoom = 15;
const mapTypeId = MapTypeId.Roadmap;

type AddressPickerProps = {
  defaultLocation: Location;
  onAddressUpdated(location: Location): void;
  handleLocationPermission: () => void;
}

export const GoogleAddressPicker: FC<AddressPickerProps> = ({ onAddressUpdated, defaultLocation, handleLocationPermission }) => {
  const [zoom, setZoom] = useState(DefaultZoom);
  const handleChangeZoom = (newZoom: number) => setZoom(newZoom);

  return (
    <MapPicker
      zoom={zoom}
      mapTypeId={mapTypeId}
      onChangeZoom={handleChangeZoom}
      defaultLocation={defaultLocation}
      onChangeLocation={onAddressUpdated}
      handleLocationPermission={handleLocationPermission}
      style={{ margin: '-20px -20px 0 -20px', height: 'calc(100vh - 260px)' }}
    />
  );
}
