import * as React from "react";
import { observer, inject } from "mobx-react";
import { styled, TabSelect, withTheme } from "@lib/components";
import { action } from "mobx";
import { MobxComponent } from "../../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

const Tabs = styled("div")`
  flex-grow: 1;
`;

@inject("store") @observer
class MenusTabsClass extends MobxComponent<WithTranslation> {

	@action onCategoryChange = (selected: { label: string; value: string }) => {
		this.injected.store.filters.s.menu = this.injected.store.menu!._id;
		this.injected.store.filters.s.category = selected.value;
		this.injected.store.view.scrollToMenu();
	}

	render() {
		const theme = this.injected.theme;
		const { view, filters, restaurant, menu } = this.injected.store;

		if (!menu)
			return null;

		const hideAllCategory = restaurant.website.sections.menus.dish_hide_all_category;

		let categoryOptions = menu.categories.map((c) => ({
			value: c._id,
			label: c.display_name || c.name
		}));

		if (!hideAllCategory && categoryOptions.length) {
			const allOption = { label: "All", value: "" };
			categoryOptions.unshift(allOption);
		}

		return (
			<Tabs>
				<TabSelect
					id="menu-tab-select"
					background={theme.top_nav.background_color}
					screenWidth={view.s.screen_width}
					value={filters.s.category}
					values={categoryOptions}
					onChange={this.onCategoryChange} />
			</Tabs>
		);
	}
}

// @ts-ignore
export const MenusTabs = withTheme(withTranslation()(MenusTabsClass));
