import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CheckoutFormModal } from './checkout-form';
import { useStores } from '../../../mobx/useStores';
import { getCheckoutFormCommonFields } from './utils';
import { InputField, PaymentMethod } from './flows/types';
import { observer } from 'mobx-react';

function FiservFormModalPlain() {
  const store = useStores();
  const { t } = useTranslation();
  const restaurant = store.restaurant;

  const order = store.order.s.item;
  const isOrderUsingFiserv = order?.payment.method === 'fiserv';
  if (!order || !isOrderUsingFiserv) {
    return null;
  }

  const fiservConfig = restaurant.settings.payments.fiserv!;
  const paymentEndpoint = fiservConfig.connect_url;

  const saleTransactionParams = order.payment.fiserv!.saleTransactionParams!;
  const formFields = Object.entries(saleTransactionParams).map(([name, value]) => ({ name, value }))

  const inputFields: InputField[] = [
    ...getCheckoutFormCommonFields(order, t),
    ...formFields,
  ];

  const paymentMethod: PaymentMethod = {
    label: 'Fiserv',
    name: order.payment.method,
    urlEndpoint: paymentEndpoint,
    alias: order.payment.method_alias,
  };

  return (
    <CheckoutFormModal
      inputFields={inputFields}
      paymentMethod={paymentMethod}
      orderId={order._id}
      isFullScreen={isMobile}
      openNewWindow
    />
  );
}

export const FiservFormModal = observer(FiservFormModalPlain);
