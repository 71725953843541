const isProduction = process.env.NODE_ENV === "production";
const isStaging = process.env.STAGING === "true";
const isTest = !isProduction && !isStaging;

export const config = {
	environment: process.env.NODE_ENV || "development",
	isTest: isTest,
	isProduction: isProduction,
	isStaging: isStaging,
	api_url: isProduction
		? process.env.API_HOST || "https://api.cloudwaitress.com"
		: "http://localhost:3010",
		// : "https://api-dev.ap.ngrok.io",
	cdn_url: isProduction
		? process.env.STORES_CDN_URL || "https://stores-cdn.cloudwaitress.com"
		: "/",
	api_public_key:
		process.env.API_PUBLIC_KEY || "c2285f76-cc39-4ade-9269-023192e1798b",
	google_api_key:
		process.env.GOOGLE_API_KEY || "AIzaSyAc_6JJnBdHbKjXGeoKIznTE22-YaRRuSA",
	google_api_client_id:
		process.env.GOOGLE_API_CLIENT_ID || 
		"751036375129-4549h58nupttqo5u7j3k0nio1eicu2m6.apps.googleusercontent.com", 
	mapbox_api_key:
		process.env.MAPBOX_API_KEY ||
		"pk.eyJ1IjoicmVhbGZyZXNoIiwiYSI6ImNqb2hkeTdzbTBvczMzcXBqeGE0MXlwbjkifQ.L_3vQEe6bef12JJyt6268w",
	ably_order_updates_key: isProduction
		? process.env.ABLY_ORDER_UPDATES_KEY || "sUrayQ.ZmIILA:Q3autCYxiTdFRJY4"
		: "mPnq2Q.SVIylQ:3tVaHoKYGHW6dpst",
	webhook_url: isProduction
		? process.env.API_WEBHOOK_HOST || "https://api.cloudwaitress.com"
		: "https://6607a813.ngrok.io",
	google: {
		recaptcha_enterprise: {
			enabled: process.env.RECAPTCHA_ENTERPRISE_ENABLED || "true",
			site_key: process.env.RECAPTCHA_ENTERPRISE_SITE_KEY || "6LftLlchAAAAADuqwP9LNFzKN7Bfrh6WVtB9B-B6",
		}
	},
	open_replay: {
		global_enabled: isProduction ? process.env.OPEN_REPLAY_GLOBAL_ENABLED || false : false,
		project_key: isProduction ? process.env.OPEN_REPLAY_PROJECT_KEY || "MOLNCKSmeXxVVXEBfApI" : "iIxgYnLchO9U98cNIkev",
		resellers_enabled: isProduction ?  process.env.OPEN_REPLAY_RESELLERS_ENABLED || "cloudwaitress-test,cloudwaitressau,bytoau" : "cloudwaitress-test,cloudwaitressau,bytoau"
	},
	allow_send_fe_logs: process.env.FRONTEND_LOGGING_TO_SERVER_ENABLED?? true,
};
