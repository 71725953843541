import React from 'react';
import { Modal, ModalContent } from '@lib/components';
import { MobxComponent } from '../../mobx/component';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props extends WithTranslation { }

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Note = styled.div`
  color: #0d6efd;
  margin-top: 0.5rem;
`;

@inject("store") @observer
class CardPaymentAuthenticationModalClass extends MobxComponent<Props, {}> {
	handleModalClose = () => {
		const { store } = this.injected;
		store.order.update({ showCardPaymentAuthenticationModal: false });
	}

	render() {
		const { store, t } = this.injected;
		const showModal = store.order.s.showCardPaymentAuthenticationModal;
		const authenticationUrl = store.order.s.item?.payment.authentication_redirect_url;

		return (
			<Modal
				width={900}
				active={showModal}
				alignTop={true}
				id="card-payment-authentication-modal"
				close={this.handleModalClose}>

				<ModalContent>
					<h5>{t(`store.modals.card_payment_authentication.title`)}</h5>
					<Note>{t(`store.modals.card_payment_authentication.note`)}</Note>
				</ModalContent>

				<ModalContent style={{ height: 'calc(100vh - 250px)' }}>
					{authenticationUrl && (
						<Iframe key={authenticationUrl} src={authenticationUrl} title="Payment Authentication"></Iframe>
					)}
				</ModalContent>
			</Modal>
		);
	}
}

export const CardPaymentAuthenticationModal = withTranslation()(CardPaymentAuthenticationModalClass);
