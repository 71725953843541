import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import darken from "polished/lib/color/darken";
import { OrderHistoryItem } from "./item";
import { styled, RotateLoader, CS } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const List = styled("div")`
  
`;

const PageCtrl = styled("div")`
  display: flex;
  align-items: stretch;
  > div {
    border-right: 1px solid ${(props) => props.theme.box.border};
    &:last-child {
      border-right: none;
    }
  }
`;

const PageArrow = styled("div")`
  ${CS.flex.center};
  padding: 10px;
  flex-grow: 1;
  cursor: pointer;
  &:hover {
    background: ${(props) => darken(0.05, props.theme.box.background)};
  }
`;

const PageCount = styled("div")`
  ${CS.flex.center};
  padding: 10px;
  display: flex;
  width: 200px;
`;

interface Props extends WithTranslation { }
interface State {
	active_orders: number[];
}

@inject("store") @observer
class CustomerOrderHistoryClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			active_orders: [],
		};
	}

	toggleActive = (index: number) => {
		const active_orders = [...this.state.active_orders];
		const existing = active_orders.indexOf(index);
		if (existing !== -1) {
			active_orders.splice(existing, 1);
		}
		else {
			active_orders.push(index);
		}
		this.setState({ active_orders });
	}

	isActive = (index: number) => {
		return this.state.active_orders.indexOf(index) !== -1;
	}

	totalPages() {
		return Math.ceil((this.injected.store.order_history.s.count || 0) / 5);
	}

	changePage = (forward: boolean) => {
		const { store } = this.injected;
		const page = store.order_history.s.page;
		const totalPages = this.totalPages();
		if (forward) {
			if (page + 1 <= totalPages) {
				this.setState({ active_orders: [] });
				store.order_history.setPage(page + 1);
			}
		}
		else if (page - 1 >= 1) {
			this.setState({ active_orders: [] });
			store.order_history.setPage(page - 1);
		}
	}

	render() {
		if (!this.state) return;
		const { store, t } = this.injected;
		const {
			items,
			page,
			count,
			loading,
			error,
		} = store.order_history.s;
		return (
			<>
				{loading && (
					<div className="p-6">
						<RotateLoader size={3} />
					</div>
				)}

				{!!error && (
					<div>
						<p className="lhp text-center">
							{CoreUtils.string.cap_first(t("phrases.something_went_wrong"))}. <span className="primary-text underline cursor" onClick={() => store.order_history.get(1)}>{CoreUtils.string.cap_first(t("phrases.try_again"))}</span></p>
					</div>
				)}

				{(!loading && !error) && (
					<>
						<List>
							{items.map((o, i) => {
								const active = this.isActive(i);
								return (
									<OrderHistoryItem
										o={o}
										i={i}
										key={i}
										active={active}
										toggleActive={() => this.toggleActive(i)}
									/>
								);
							})}
						</List>
						<PageCtrl>
							<PageArrow onClick={() => this.changePage(false)}>
								<FaChevronLeft />
							</PageArrow>
							<PageCount>
								{page} / {this.totalPages()}
							</PageCount>
							<PageArrow onClick={() => this.changePage(true)}>
								<FaChevronRight />
							</PageArrow>
						</PageCtrl>
					</>
				)}
			</>
		);
	}

}

export const CustomerOrderHistory = withTranslation()(CustomerOrderHistoryClass);
