import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, Button } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

interface Props extends WithTranslation { }

@inject("store") @observer
class OrderExpiredWarningModalClass extends MobxComponent<Props> {

	render() {
		const { store, t } = this.injected;
		const { modal } = store;
		return (
			<Modal
				id="modal-order-expired-warning"
				width="sm"
				closeButton={true}
				active={modal.isVisible("order-expired-warning")}
				wrapperClick={(e) => e.stopPropagation()}
				close={() => modal.back()}>

				<ModalContent>
					<p className="error-text small m-b-1">{t("store.modals.warnings.order_expired.subtitle")}</p>
					<h4>{t("store.modals.warnings.order_expired.title")}</h4>
				</ModalContent>

				<ModalContent>
					<p className="lhp">
						{t("store.modals.warnings.order_expired.description")}
					</p>
				</ModalContent>

				<ModalContent className="text-right" paddingtb={15}>
					<Button onClick={(e) => {
						e.stopPropagation();
						modal.back();
					}}>
						<p>{t("store.modals.warnings.order_expired.button")}</p>
					</Button>
				</ModalContent>

			</Modal>
		);
	}

}

export const OrderExpiredWarning = withTranslation()(OrderExpiredWarningModalClass);
