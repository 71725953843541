import { genRecaptchaToken } from '../../../../libs/grecaptcha';
import { RootStore } from '../../../store';

const ACCEPT_HEADER =
  'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3';

export async function tokenizeBamboraNACard(store: RootStore) {
  const checkout = store.checkout.s;
  const restaurantId = store.restaurant._id;
  const { card_number: cardNumber, card_expiry: cardExpiry, card_cvv: cardCvv } = checkout;
  const validateToken = await genRecaptchaToken();
  const payload = {
    cardNumber,
    cardExpiry,
    cardCvv,
    restaurantId,
    validateToken,
  };

  const response = await store.api.tokenizeBamboraNaCard(payload);

  if (response.outcome === 1) {
    store.checkout.order_error(undefined, 'payment_fail');
    return;
  }

  return response.token;
}

export async function createBamboraNAPaymentRequest(store: RootStore, orderId: string) {
  const token = await tokenizeBamboraNACard(store);
  if (!token) {
    return;
  }

  const checkout = store.checkout.s;
  const { cardholder_name, billing_address_street, billing_address_city, postal_code } = checkout;
  const validateToken = await genRecaptchaToken();
  const payload: T.API.BamboraNaCreatePaymentRequest = {
    token,
    cardholderName: cardholder_name,
    billingAddressStreet: billing_address_street,
    billingAddressCity: billing_address_city,
    postalCode: postal_code,
    restaurantId: store.restaurant._id,
    orderId,
    browserData: {
      accept_header: ACCEPT_HEADER,
      language: navigator.language,
      color_depth: screen.colorDepth,
      screen_height: screen.height,
      screen_width: screen.width,
      time_zone: new Date().getTimezoneOffset(),
      user_agent: navigator.userAgent,
      java_enabled: false,
      javascript_enabled: true,
    },
    validateToken,
  };

  const response = await store.api.createBamboraPaymentRequest(payload);
  console.log("response", response);
  if (response.outcome === 1) {
    store.checkout.order_error(undefined, 'payment_fail');
    return;
  }
  const paymentFormModal = response.required3ds ? 'bambora_na' : '';

  const initial_order_id = localStorage.getItem("initial_order_id")
  store.router.push(`/order/${initial_order_id}`);
  store.order.update({ paymentFormModal: paymentFormModal });
}
export async function createBamboraNAPaymentRequest_v2(store: RootStore, draftOrder: any) {
  const token = await tokenizeBamboraNACard(store);
  if (!token) {
    return;
  }

  const checkout = store.checkout.s;
  const { cardholder_name, billing_address_street, billing_address_city, postal_code } = checkout;
  const validateToken = await genRecaptchaToken();
  const payload: T.API.BamboraNaCreatePaymentRequest = {
    token,
    cardholderName: cardholder_name,
    billingAddressStreet: billing_address_street,
    billingAddressCity: billing_address_city,
    postalCode: postal_code,
    restaurantId: store.restaurant._id,
    orderId: draftOrder.order._id,
    browserData: {
      accept_header: ACCEPT_HEADER,
      language: navigator.language,
      color_depth: screen.colorDepth,
      screen_height: screen.height,
      screen_width: screen.width,
      time_zone: new Date().getTimezoneOffset(),
      user_agent: navigator.userAgent,
      java_enabled: false,
      javascript_enabled: true,
    },
    validateToken,
  };

  const response = await store.api.createBamboraPaymentRequest(payload);
  console.log("response", response);
  if (response.outcome === 1) {
    store.checkout.order_error(undefined, 'payment_fail');
    return;
  }
  const paymentFormModal = response.required3ds ? 'bambora_na' : '';

  const initial_order_id = localStorage.getItem("initial_order_id")
  if (response.outcome === 0 && !response.required3ds) {
    await store.checkout.order_complete_v2(draftOrder);
  }
  store.router.push(`/order/${initial_order_id}`);
  store.order.update({ paymentFormModal: paymentFormModal });
}
