import React from 'react';
import styled from 'styled-components';
import { FaSearch } from "react-icons/fa"
import { MobxComponent } from '../../../mobx/component';
import { inject, observer } from 'mobx-react';

const SearchButton = styled('div')`
  padding: 8px;
  color: ${({ theme }) => theme.colors.primary_text};
  background: ${(props) => props.theme.colors.primary};
  border-radius: 3px;
  cursor: pointer;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 10px 12px;
  }
`;

@inject("store") @observer
export default class MenuSearchButton extends MobxComponent<{}, {}> {
	handleButtonClicked = (e: any) => {
		e.preventDefault();
		const { store } = this.injected;
		store.menuSearch.update({ showModal: true });
	}

	render() {
		const restaurant = this.injected.store.restaurant!;
		const hideMenuSearchButton = restaurant.website.sections.top_nav.hide_menu_search_button || false;
		let button;
		if (!hideMenuSearchButton) {
			button = (
				<SearchButton onClick={this.handleButtonClicked} style={{ marginLeft: 'auto' }}>
					<FaSearch />
				</SearchButton>
			)
		}

		return (
			<>
				{button}
			</>
		);
	}
}
