import * as React from "react";
import { styled, withTheme } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { inject, observer } from "mobx-react";
import { BlockError, BaseInputCSS } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }
interface State {
	error: string;
}

const InputDiv = styled("div")`
  ${BaseInputCSS};
`;

@inject("store") @observer
class BamboraApacFormClass extends MobxComponent<Props, State> {

	active: boolean = false;
	checkout: any;
	card: any;
	cvv: any;
	expiry: any;
	cardComplete: boolean = false;
	cvvComplete: boolean = false;
	expiryComplete: boolean = false;

	constructor(props: Props) {
		super(props);
		this.state = {
			error: "",
		};
	}

	componentDidMount() {
		if (this.active) return;
		this.active = true;
		console.log("BAMBORA MOUNT");
		try {

			const { store, theme } = this.injected;

			const { merchant_id } = store.restaurant.settings.payments.bambora_apac!;

			const style = {
				base: {
					fontSize: `${theme.font.size}px`,
					color: theme.input.text,
					paddingTop: "10px",
				},
				error: {
					color: theme.colors.error,
					":focus": {
						fontStyle: "italic",
					},
				},
			};

			const classes = {
				error: "my-error-class",
			};

			const options = {
				placeholder: "Card number",
				style: style,
				classes: classes,
			};

			this.checkout = customcheckout();
			this.card = this.checkout.create("card-number", options);
			this.cvv = this.checkout.create("cvv", { style, placeholder: "CVV" });
			this.expiry = this.checkout.create("expiry", { style, placeholder: "Expiry" });

			this.card.mount("#card_number");
			this.cvv.mount("#card_cvv");
			this.expiry.mount("#card_expiry");

			this.checkout.on("error", (event: { field: string, type: string, message: string }) => {
				if (this.active) {

					this.setState({ error: event.message });

					if (event.field === "card-number") {
						this.cardComplete = false;
					}
					if (event.field === "expiry") {
						this.expiryComplete = false;
					}
					if (event.field === "cvv") {
						this.cvvComplete = false;
					}

					this.injected.store.checkout.update({
						card_token: "",
						card_error: "invalid_cc_number",
					});

				}
			});

			this.checkout.on("complete", (event: { field: string, complete: boolean }) => {

				if (this.active) {

					if (event.complete) {
						if (event.field === "card-number") {
							this.cardComplete = true;
						}
						if (event.field === "expiry") {
							this.expiryComplete = true;
						}
						if (event.field === "cvv") {
							this.cvvComplete = true;
						}
						this.setState({ error: "" });
					}
					else if (!event.complete) {
						if (event.field === "card-number") {
							this.cardComplete = false;
						}
						if (event.field === "expiry") {
							this.expiryComplete = false;
						}
						if (event.field === "cvv") {
							this.cvvComplete = false;
						}
						this.injected.store.checkout.update({
							card_token: "",
							card_error: "invalid_cc_number",
						});
					}

					if (this.cardComplete && this.expiryComplete && this.cvvComplete) {
						this.checkout.createOneTimeToken(merchant_id, (result: { code: number | null, token: string, error?: { message: string } }) => {
							console.log(result);
							if (this.active) {
								if (result.error) {
									this.setState({ error: result.error.message });
									this.injected.store.checkout.update({
										card_token: "",
										card_error: "invalid_cc_number",
									});
								}
								else if (result.code === 200) {
									this.setState({ error: "" });
									this.injected.store.checkout.update({
										card_token: result.token,
										card_error: "",
									});
								}
							}
						});
					}

				}

			});

		}
		catch (e) {
			console.log(e);
		}

	}

	componentWillUnmount() {
		console.log("BAMBORA UNMOUNT");
		this.active = false;
		try {
			this.card.unmount();
			this.cvv.unmount();
			this.expiry.unmount();
		}
		catch (e) {
			console.log(e);
		}
	}

	render() {

		const { error } = this.state;

		return (
			<div className="p-t-5">

				<div className="m-b-2">
					<InputDiv
						id="card_number"
						className="col"
					/>
				</div>

				<div className="m-b-2 grid-2 xxs xs-gap">
					<InputDiv
						id="card_expiry"
						className="col"
					/>
					<InputDiv
						id="card_cvv"
						className="col"
					/>
				</div>

				{!!error && <BlockError text={error} className="m-t-2" />}
			</div>
		);

	}

}

// @ts-ignore
export const BamboraApacForm = withTheme(withTranslation()(BamboraApacFormClass));
