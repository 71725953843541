import { observable, action } from "mobx";
import { RootStore } from "../store";
import { logger, RestaurantUtils, cloneDeepSafe } from "@lib/common";
import * as React from "react";
import Big from "big.js";

export type OrderHistoryState = {
	items: T.Schema.Order.OrderSchema[];
	page: number;
	count: number;
	loading: boolean;
	error: string;
};

export class OrderHistoryStore {

	@observable s: OrderHistoryState;

	store: RootStore;

	constructor(store: RootStore, initialState?: OrderHistoryState) {
		this.store = store;
		this.s = initialState || {
			items: [],
			page: 1,
			count: 0,
			loading: false,
			error: "",
		};
	}

	// INTERACTIONS
	clickReceipt = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
		e.stopPropagation();
		const { store } = this;
		const o = store.order_history.s.items[i];
		if (o) {
			store.modal.close();
			store.router.push(`/order/${o._id}`);
		}
	}
	clickOrder = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
		e.stopPropagation();
		const { store } = this;
		const oc = store.order_config.s;
		if (!oc.confirmed) {
			store.modal.show("order-config");
		}
		else {
			const o = cloneDeepSafe(store.order_history.s.items[i]);
			if (o) {
				for (const d of o.dishes) {
					store.cart.push(RestaurantUtils.untrim.dish(d, this.store.restaurant));
				}
				store.modal.show("cart");
			}
		}
	}

	// DATA
	@action get = async (page?: number) => {
		const c = this.store.customer.s.item;
		if (!c) return;
		if (page) {
			this.s.page = page;
		}
		try {

			this.update({
				error: "",
				loading: true,
			});

			const data = await this.store.api.customer_orders({
				query: { "customer._id": c._id },
				limit: 5,
				page: this.s.page,
				sort: { created: -1 },
				search: "",
			});

			if (data.outcome === 0) {
				this.update({
					items: data.items,
					count: data.count,
					error: "",
					loading: false,
				});
			}
			else {
				this.update({
					items: [],
					count: 0,
					error: data.message,
					loading: false,
				});
			}

		}
		catch (e) {
			logger.captureException(e);
			this.update({
				items: [],
				count: 0,
				error: "true",
				loading: false,
			});
		}
	}
	@action setPage = (page: number) => {
		this.s.page = page;
		this.get();
	}
	@action update = (data: Partial<OrderHistoryState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof OrderHistoryState];
				if (value !== undefined && this.s) {
					// @ts-ignore
					this.s[key as keyof OrderHistoryState] = value;
				}
			}
		}
	}

	// CHECKS
	orderInvalidCheck = (o: T.Schema.Order.OrderSchema) => {

		for (const d of o.dishes) {
			const invalid = this.orderDishInvalid(d);
			if (invalid) {
				return invalid;
			}
		}

		return "";

	}
	orderDishInvalid = (dish: T.Schema.Order.OrderDish): string => {

		const { store } = this;

		const r = this.store.restaurant;

		const current = RestaurantUtils.menu.findDish(r, dish._id);

		if (!current) {
			return "missing_dish";
		}

		const menuCheck = this.store.menuAvailabilityCheck(
			current.menu,
			store.order_config.s,
			store.customer.s.item
		);
		const categoryCheck = this.store.categoryAvailabilityCheck(
			current.category,
			store.order_config.s
		);

		if (!menuCheck.available || !categoryCheck.available) {
			return "menu_unavailable";
		}

		if (current.dish.status) {
			return "dish_status";
		}

		const invalid = dish.type === "combo" ?
			this.orderDishComboInvalid(dish, current.dish) :
			this.orderDishStandardInvalid(dish, current.dish);
		return invalid;

	}
	orderDishStandardInvalid = (dish: T.Schema.Order.OrderDish, currentDish: T.Schema.Restaurant.Menu.RestaurantDish, forCombo: boolean = false) => {

		const r = this.store.restaurant;

		let actualPrice = Big(dish.price).div(dish.qty);
		if (!forCombo) {
			const optionPrices = RestaurantUtils.dish.optionSetTotal(dish.option_sets);
			actualPrice = actualPrice.minus(optionPrices.total);
		}

		if (!actualPrice.eq(currentDish.price)) {
			return "price_change";
		}

		// CHECK NOTHING NEW ADDED AS IF SOMETHING IS REQUIRED, THE DISH GETS MESSED UP
		// ON FLIP SIDE BECAUSE THE DISH OPTION SETS ARE TRIMMED, ITS HARD TO DETECT A CHANGE
		/*
		  if (dish.option_sets.length !== currentDish.option_sets.length) {
			console.log("OPTIONS_CHANGED", toJS(dish.option_sets), currentDish.option_sets);
			return "options_changed";
		  }
		*/

		if ((dish.ingredients || []).length !== currentDish.ingredients.length) {
			return "ingredients_changed";
		}

		for (const ing of dish.ingredients || []) {
			const found = currentDish.ingredients.find((i) => i._id === ing._id);
			if (!found) {
				return "missing_ingredient";
			}
		}

		for (const os of dish.option_sets || []) {
			const found = currentDish.option_sets.find((item) => item === os._id);
			if (!found) {
				return "missing_option_set_1";
			}
			const currentOptionSet = r.option_sets.find((item) => item._id === os._id);
			if (!currentOptionSet) {
				return "missing_option_set_2";
			}
			for (const o of os.options) {
				const existing = currentOptionSet.options.find((item) => item._id === o._id);
				if (!existing) {
					return "missing_option";
				}
				if (existing.price !== o.price) {
					return "option_price_change";
				}
			}
		}

		return "";

	}
	orderDishComboInvalid = (dish: T.Schema.Order.OrderDish, currentDish: T.Schema.Restaurant.Menu.RestaurantDish) => {

		const r = this.store.restaurant;

		if (dish.price_type !== currentDish.price_type) {
			return "price_change_combo_1";
		}

		const mergedDish = {
			...cloneDeepSafe(dish),
			price: currentDish.price,
		};

		const total = RestaurantUtils.dish.calculateTotal(mergedDish);

		if (!Big(total.price).eq(dish.price)) {
			return "price_change_combo_2";
		}

		if (dish.choices.length !== currentDish.choices.length) {
			return "choices_change";
		}

		for (const choice of dish.choices) {

			const currentChoice = currentDish.choices.find((c) => c._id === choice._id);

			if (!currentChoice) {
				return "missing_choice";
			}

			if (choice.selected) {

				if (currentChoice.dishes.indexOf(choice.selected._id) === -1) {
					return "missing_choice_dish_1";
				}

				const current = RestaurantUtils.menu.findDish(r, choice.selected._id);
				if (!current) {
					return "missing_choice_dish_2";
				}

				const invalid = this.orderDishStandardInvalid(choice.selected, current.dish, true);
				if (invalid) {
					return invalid;
				}

			}

		}

		return "";

	}

}
