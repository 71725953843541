import React from 'react';
import { useStores } from '../../../mobx/useStores';
import { observer } from 'mobx-react';
import { CheckoutFormModal } from './checkout-form';
import { InputField, PaymentMethod } from './flows/types';
import { getCheckoutFormCommonFields } from './utils';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function parseHtml(value: string): { actionUrl: string; hiddenValues: { [key: string]: string } } | null {
  const parser = new DOMParser();
  const doc = parser.parseFromString(value, 'text/html');

  const form = doc.getElementById('challengeform') as HTMLFormElement;
  if (!form) return null;

  const actionUrl = form.action;

  const hiddenInputs = form.querySelectorAll('input[type="hidden"]') as unknown as HTMLInputElement[];
  const hiddenValues: any = {};

  hiddenInputs.forEach((input: HTMLInputElement) => {
    hiddenValues[input.name] = input.value;
  });

  return { actionUrl, hiddenValues };
}

function BamboraNAFormModalPlain() {
  const store = useStores();
  const { t } = useTranslation();

  const order = store.order.s.item;
  const bamboraNaData = order?.payment?.bambora_na;

  if (!order || !bamboraNaData) return null;

  const htmlString = decodeURIComponent(bamboraNaData.threeDSecure?.contents!).replace(/\+/g, ' ');

  const parsedHtml = parseHtml(htmlString);
  if (!parsedHtml) return null;

  const { actionUrl, hiddenValues } = parsedHtml;

  const paymentMethod: PaymentMethod = {
    name: order.payment.method,
    label: 'Worldline',
    urlEndpoint: actionUrl,
  };

  const formFields = Object.entries(hiddenValues).map(([name, value]) => ({ name, value }));
  const inputFields: InputField[] = [...getCheckoutFormCommonFields(order, t), ...formFields];

  return (
    <CheckoutFormModal
      inputFields={inputFields}
      paymentMethod={paymentMethod}
      orderId={order._id}
      isFullScreen={isMobile}
      openNewWindow
    />
  );
}

export const BamboraNAFormModal = observer(BamboraNAFormModalPlain);
