import React, { useEffect, useState } from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useElements } from '@stripe/react-stripe-js';
import { useStores } from '../../../../mobx/useStores';

export function CheckoutForm() {
  const elements = useElements();
  const store = useStores();
  const layout = store.restaurant.settings.payments.stripe?.stripe_layout;
  const layoutType = layout === 'tabs' ? 'tabs' : 'accordion';
  const checkRadio = layout === "accordion_with_radio" ? true : false;

  const [stripeSubPaymentMethod, setStripeSubPaymentMethod] = useState('');
  useEffect(() => {
    if (elements) {
      window.stripeProcessingPaymentElements = elements;
      // @ts-ignore
      console.log(`REQUIRED_E3: ${ elements ? "Yes" : "No" }`);
    }
    // @ts-ignore
    store.checkout.s.subPayment = stripeSubPaymentMethod;
    return () => {
      // @ts-ignore
      console.log(`REQUIRED_E4: ${ window.stripeProcessingPaymentElements ? "Yes" : "No" }`);
      delete window.stripeProcessingPaymentElements;
    };
  }, [elements, stripeSubPaymentMethod]);

  return (
    <PaymentElement onChange={(e) => setStripeSubPaymentMethod(e.value.type)} 
    options={{
      layout: {
        type: layoutType === 'tabs' ? layoutType : 'accordion',
        defaultCollapsed: false,
        radios: layoutType === 'accordion' && checkRadio,
        spacedAccordionItems: layoutType === 'accordion' && !checkRadio
      },
      paymentMethodOrder: ['apple_pay', 'google_pay', 'card']
    }}/>
  );
}
