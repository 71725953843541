import * as React from "react";
import { styled, css } from "@lib/components";
import { DishTag, shadeAlternate } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { MobxComponent } from "../../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import Big from "big.js";
import classNames from "classnames";
import "lazysizes";
import { MenuItemText } from "../../../sections/item";

type DishProps = React.HTMLAttributes<HTMLDivElement> &
	WithTranslation & {
		dish: T.Schema.Restaurant.Menu.RestaurantDish;
		category: T.Schema.Restaurant.Menu.RestaurantCategory;
		tags: T.Schema.Restaurant.Menu.RestaurantDishTag[];
		index: number;
	};

const DishComponent = styled("div")<{
	dishStyle: "list" | "list-rounded" | "card";
}>`
	background: ${({ theme }) => theme.box.background};
	color: ${({ theme }) => theme.box.text};
	display: flex;
	flex-direction: ${({ dishStyle }) =>
		dishStyle === "card"
			? "column"
			: dishStyle === "list-rounded"
			? "row-reverse"
			: "row"};
	height: 100%;
	${({ dishStyle }) =>
		dishStyle === "list-rounded"
			? css`
					align-items: flex-start;
					position: relative;
					transition: box-shadow 0.2s ease-in-out;
					border-radius: 2px;
					min-height: 130px;

					&:hover {
						-webkit-box-shadow: 0px 22px 24px 0px
							rgba(0, 0, 0, 0.08);
						-moz-box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.08);
						box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.08);
					}
					&:focus {
						-webkit-box-shadow: 0px 22px 24px 0px
							rgba(0, 0, 0, 0.08);
						-moz-box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.08);
						box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.08);
					}
			  `
			: ``}
`;

const Image = styled("div")<{
	dishStyle: "list" | "list-rounded" | "card";
	url: string;
}>`
	position: relative;
	${({ dishStyle }) =>
		dishStyle === "list-rounded"
			? css`
					width: 96px;
					height: 96px;
					margin: 16px 16px 16px 0;
					border-radius: 3px;
					max-width: none;
					min-width: 96px;
					&:after {
						content: "";
						display: block;
						padding-bottom: 100%;
					}
			  `
			: ``}
	${({ dishStyle }) =>
		dishStyle === "list"
			? css`
					width: 100%;
					min-width: 110px;
					max-width: 110px;
			  `
			: ``}
	${({ dishStyle }) =>
		dishStyle === "card"
			? css`
					height: 200px;
					width: 100%;
			  `
			: ``}
`;

const Tags = styled("div")<{
	dishStyle: "list" | "list-rounded" | "card";
	url?: string;
}>`
	position: absolute;
	display: flex;
	align-items: center;

	${({ dishStyle }) =>
		dishStyle === "list-rounded"
			? css`
					justify-content: flex-start;
					width: auto;
					height: 30px;
					top: auto !important;
					transform: translateX(0);
					left: 75px;
					bottom: 10px;
			  `
			: `
		justify-content: flex-end;
		width: 90%;
		top: -15px;
		left: 50%;
		transform: translateX(-50%);
	`}
	> * {
		margin: 0 3px;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
`;

const InnerTags = styled("div")`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	> * {
		margin: 2px 6px 2px 0;
	}
`;

const Content = styled("div")<{
	dishStyle: "list" | "list-rounded" | "card";
	url?: string;
}>`
	position: relative;
	flex-grow: 1;
	display: flex;
	flex-direction: column;

	${({ dishStyle }) =>
		dishStyle === "list-rounded"
			? css`
					position: unset;
					padding: 16px;
					height: 100%;
					min-height: 130px;

					> p {
						margin-top: 3px !important;
					}
					> p.italic {
						display: none;
					}
					> p.error-text {
						display: block;
					}

					> p.lhp.small.m-t-1,
					> p:last-of-type > p.smaller.lhp:not(.italic) {
						height: 40px;
						white-space: pre-wrap;
						max-width: 220px;
						overflow: hidden;
						line-height: 1.4;
						color: #828585;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						font-weight: 400;
						font-size: 14px;
						padding: 0;
					}
			  `
			: css`
					position: relative;
					padding: 25px 20px 30px 20px;
					height: auto;
			  `};

	> * {
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const PriceWrapper = styled("div")<{
	dishStyle: "list" | "list-rounded" | "card";
	url?: string;
}>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	bottom: 0;
	right: 0;

	${({ dishStyle }) =>
		dishStyle === "list-rounded"
			? css`
					white-space: nowrap;
					overflow: hidden;
					max-width: 220px;
					color: #828585;
					position: relative;
					width: fit-content;
					left: 0;
					flex: 1 0 auto;
					align-items: flex-end;

					p {
						font-weight: 400;
						height: 25px;
						text-align: left;
						overflow: hidden;
						line-height: 1.4;
					}
					> div {
						padding: 0;
						background: transparent;
						margin-right: 15px;
					}

					> div:lastchild {
						margin-right: 0;
					}
			  `
			: css`
					position: absolute;
			  `};
`;

const Price = styled("div")<{
	dishStyle: "list" | "list-rounded" | "card";
	url?: string;
}>`
	background: ${({ theme }) => shadeAlternate(0.05, theme.box.background)};
	padding: 4px 5px;
	font-size: ${({ theme }) => theme.font.size}px;
	font-weight: 600;
	text-align: right;

	.small-title {
		margin-top: 2px;
		font-size: 12px;
		font-weight: 600;
		height: 20px;
	}
	&:first-child {
		border-top-left-radius: 5px;
	}
`;

interface State {
	pauseInfo: any;
}

@inject("store")
@observer
class DishClass extends MobxComponent<DishProps, State> {
	constructor(props: DishProps) {
		super(props);
		this.state = {
		  pauseInfo: {},
		};
	  }
	showCategoryNotAvailble = () => {
		const isMenuAvailable = this.injected.store.isMenuAvailable.available;
		return isMenuAvailable && !this.isCategoryAvailable();
	};

	isCategoryAvailable = () => {
		const { category } = this.props;
		const { isCategoryAvailable } = this.injected.store;

		return isCategoryAvailable(category).available;
	};

	setDish = (os_id?: string, o_id?: string) => {
		const { store } = this.injected;
		const { dish } = this.props;
		const { available } = store.isMenuAvailable;

		if (!available) {
			store.modal.toggle("menu-restrictions");
		} else if (!this.isCategoryAvailable()) {
			store.filters.update({
				categoryRestrictionModal: dish.category_id,
			});
			store.modal.toggle("category-restrictions");
		} else if (store.order_config.s.confirmed) {
			if (store.menuSearch.s.showModal) {
				store.menuSearch.update({
					chosen_menu_id: dish.menu_id,
					chosen_category_id: dish.category_id,
				});
			}
			store.dish.set(dish._id, os_id, o_id);
		} else {
			store.modal.toggle("order-config");
		}
	};

	getDishImage(dish: T.Schema.Restaurant.Menu.RestaurantDish) {
		let image;
		if (typeof dish.image === "string") {
			image = dish.image;
		} else {
			image = CoreUtils.image.uc(dish.image, {
				resize: "x400",
				format: "auto",
			});
		}

		return image;
	}

	componentDidUpdate(prevProps: Readonly<DishProps>, prevState: Readonly<State>, snapshot?: any): void {
		const { t, store } = this.injected;
		const { dish, } = this.props;
		const {pausedItems} = store.ably.s;
		if(Array.isArray(pausedItems?.dish_ids) && pausedItems?.dish_ids.includes(dish._id) && pausedItems.pause_start !== this.state.pauseInfo.pause_start){
			this.setState({ pauseInfo: pausedItems })
		}
		
	  }
	

	render() {
		const { t, store } = this.injected;
		const r = store.restaurant;
		const ws = r.website.sections;
		const isSelector = r.website.experimental_ui_1;
		const { dish, category, tags, className } = this.props;
		const showDescription = !ws.menus.dish_hide_description;
		const tagPositionInside =
			ws.menus.dish_tag_position === "inside_bottom";

		if (dish.status === "hidden") return null;
		//add ably pause items
		let isPaused = false;
		const { pausedItems } = store.ably.s;
		if(pausedItems !== null){
		  isPaused = store.isDishPaused({...this.props.dish, ...this.state.pauseInfo})
		} else {
		  isPaused = store.isDishPaused(this.props.dish)
		}
	
		const stock = store.restaurantStock.dishes[dish._id];
		const isNoStock = typeof stock === "number" && stock <= 0;
		const statusApplied = dish.status !== null || isNoStock || isPaused;
		const currentCategory = store.filters.s.category;

		const categoryNameClasess = classNames(
			"font-semi-bold",
			"smaller",
			"italic",
			"lhp",
			{
				"error-text": this.showCategoryNotAvailble(),
			}
		);

		let image = this.getDishImage(dish);

		let prices;
		if (dish.option_set_primary && dish.type !== "combo") {
			const os = r.option_sets.find(
				(option_set) => option_set._id === dish.option_set_primary
			);
			if (os) {
				prices = os.options.map((o) => {
					//
					let price;
					let oPrice;
					//
					if (typeof o.price === "string") {
						if (o.price.length > 0) {
							oPrice = parseFloat(o.price).toFixed(2);
						} else {
							oPrice = 0;
						}
					} else if (typeof o.price === "number") {
						oPrice = o.price.toFixed(2);
					} else {
						oPrice = 0;
					}
					//
					if (dish.price) {
						price = parseFloat(
							Big(dish.price)
								.plus(oPrice || 0)
								.toFixed(2)
						);
					} else {
						price = oPrice;
					}
					//
					return {
						p: price,
						n: o.name,
						s: o.status,
						oc: () => this.setDish(os._id, o._id),
					};
				});
			}
		}

		if (!prices) {
			prices = [
				{
					p: dish.price,
					n: "",
					s: dish.status,
					oc: () => this.setDish(),
				},
			];
		}

		return (
			<div className={className}>
				<DishComponent
					dishStyle={ws.menus.dish_style}
					className={statusApplied ? "" : "cursor"}
					onClick={() => {
						if (!statusApplied) {
							this.setDish();
						}
					}}
				>
					{image && (
						<Image
							className="lazyload"
							dishStyle={ws.menus.dish_style}
							url={image}
							data-bg={image}
						/>
					)}
					<Content dishStyle={ws.menus.dish_style}>
						{!tagPositionInside && (
							<Tags dishStyle={ws.menus.dish_style}>
								{tags.map((tag, i) => (
									<DishTag key={i} {...tag} />
								))}
							</Tags>
						)}
						<h2 className="font-regular small lhp" >{dish.display_name || dish.name}</h2>

						{dish.subtitle && (
							<p
								className="font-semi-bold smaller lhp"
								style={{
									marginTop: "-3px",
									marginBottom: "2px",
								}}
							>
								{dish.subtitle}
							</p>
						)}

						{currentCategory !== category._id && (
							<p className={categoryNameClasess}>
								{category.display_name || category.name}{" "}
								{this.showCategoryNotAvailble() &&
									`(${t(
										`restaurant.menus.dish.not_available`
									)})`}
							</p>
						)}

						{dish.description && showDescription && (
							<p className="lhp small m-t-1" style={{wordBreak: "break-all"}}>
								{dish.description}
							</p>
						)}

						{tagPositionInside && (
							<InnerTags className="m-t-1">
								{tags.map((tag, i) => (
									<DishTag key={i} {...tag} />
								))}
							</InnerTags>
						)}

						{statusApplied && (
							<p className="font-bold small italic underline error-text m-t-2">
								{t(
									`restaurant.menus.dish.${
										isNoStock || isPaused
											? "no_stock"
											: dish.status!.replace("-", "_")
									}`
								)}
							</p>
						)}

						<div
							className={
								prices && prices.length > 1 ? "m-b-5" : "m-b-2"
							}
						/>

						<PriceWrapper dishStyle={ws.menus.dish_style}>
							{prices &&
								prices.map((p, i) => (
									<Price
										dishStyle={ws.menus.dish_style}
										key={i}
										onClick={(e) => {
											e.stopPropagation();
											// Only let dish price be clickable if it's greater than 0.
											if (!statusApplied && !(p.s && ['not-available', 'no-stock', 'hidden'].includes(p.s))) {
												p.oc();
											}
										}}
									>
										<p>{t("currency", { value: p.p })}</p>
										{p.n && (
											<p className="small-title">{p.n}</p>
										)}
									</Price>
								))}
						</PriceWrapper>
					</Content>
				</DishComponent>
			</div>
		);
	}
}

export const Dish = withTranslation()(DishClass);
