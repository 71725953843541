import * as React from 'react';
import {
  styled,
  Button,
  Modal,
  ModalContent,
  CircleIcon,
  ModalDropContent,
} from '@lib/components';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../../mobx/component';
import { FaUser, FaPowerOff } from 'react-icons/fa';
import { CustomerAgeVerification } from './age-verification';
import { CustomerOrderHistory } from '../../components/order_history';
import { formatNumWithComma } from '../../../mobx/utils';
import LoyatyDeals from './loyaty';

interface Props extends WithTranslation {}
interface State {
  email: string;
  password: string;
  password_confirmation: string;
  loading: boolean;
  show_password: boolean;
  type: string;
  email_message: string;
  password_message: string;
}

interface ProfileImageProps {
  referrerPolicy: string;
}

const ProfilePic = styled.img.attrs((props: ProfileImageProps) => ({
  referrerPolicy: props.referrerPolicy,
}))<ProfileImageProps>`
  width: 48px;
  height: 48px;
  border-radius: 30px;
`;

const DetailRow = styled('div')`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed ${({ theme }) => theme.box.border};
  padding-bottom: 5px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

@inject('store')
@observer
class CustomerModalClass extends MobxComponent<Props, State> {
  logout = () => {
    const { store } = this.injected;
    if (store.cart.s.items.length > 0) {
      store.modal.show('logout-warning');
    } else {
      store.customer.logout();
    }
  };
  constructor(props: Props) {
    super(props);
    //
    this.state = {
      email: '',
      password: '',
      password_confirmation: '',
      loading: false,
      show_password: false,
      type: '',
      email_message: '',
      password_message: '',
    };
  }

  componentDidMount() {
    const { store, t } = this.injected;
    const c = store.customer.s.item;
    let email = '';
    // let type = "";
    let type: string = 'email';
    if (!c) {
      email = '';
      // type = ""
    } else {
      email = c.login || '';
      type = c.type || '';
    }
    // console.log({ type })
    //
    this.setState({
      email: email,
      type: type,
      password: '',
    });
  }

  handleChangePassword = (event: any) => {
    if (event.target.name === 'password') {
      if (
        this.state.password_confirmation === event.target.value &&
        this.state.password_confirmation.length > 0
      ) {
        this.setState({
          password: event.target.value,
          password_message: '',
          show_password: true,
        });
      } else {
        this.setState({
          password: event.target.value,
          password_message:
            'Password and Password Confirmation are not a match',
          show_password: false,
        });
      }
    } else if (event.target.name === 'password_confirmation') {
      if (
        this.state.password === event.target.value &&
        this.state.password.length > 0
      ) {
        this.setState({
          password_confirmation: event.target.value,
          password_message: '',
          show_password: true,
        });
      } else {
        this.setState({
          password_confirmation: event.target.value,
          password_message:
            'Password and Password Confirmation are not a match',
          show_password: false,
        });
      }
    }
  };

  changePassword = () => {
    const { store, t } = this.injected;
    const c = store.customer.s.item;
    //
    if (c && store.customer.s) {
      this.setState({
        loading: true,
      });
      const data = {
        email: c.details.email,
        token: store.customer.s.token,
        password: this.state.password,
      };
      (async () => {
        const res = await store.api.customer_logged_in_change_password(data);
        if (res.outcome) {
          // Something went wrong
          this.setState({
            password_message: 'An error has occurred.',
          });
        } else {
          // All went great
          this.setState(
            {
              loading: false,
              password_message: 'Success!',
            },
            () => {
              setTimeout(() => {
                this.setState({
                  password_message: '',
                });
              }, 3000);
            }
          );
        }
      })();
    }
  };

  changeEmail = () => {
    const { store, t } = this.injected;
    const c = store.customer.s.item;
    //
    if (c && store.customer.s) {
      const data = {
        token: store.customer.s.token,
        email: this.state.email,
      };
      (async () => {
        const res = await store.api.customer_logged_in_change_email(data);
        if (res.outcome) {
          // Something went wrong
          this.setState({
            email_message: 'An error has occurred.',
          });
        } else {
          // All went great
          this.setState(
            {
              loading: false,
              email_message: 'Success!',
            },
            () => {
              if (c) {
                c.details.email = data.email;
              }
              setTimeout(() => {
                this.setState({
                  email_message: '',
                });
              }, 3000);
            }
          );
        }
      })();
    }
  };

  render() {
    const { store, t } = this.injected;
    const c = store.customer.s.item;
    const walletlyIntegrational = store.restaurant.settings.loyalty_providers?.walletly;
    const walletly = c?.loyalty;
    const walletlyPoints = walletly?.walletly_points
      ? formatNumWithComma(walletly?.walletly_points)
      : '';
    const walletlyDeals = walletly?.walletly_deals;
    return (
      <Modal
        id="customer-modal"
        width={420}
        closeButton={true}
        active={store.modal.isVisible('customer')}
        close={() => store.modal.toggle('customer')}
      >
        {c && (
          <div>
            <ModalContent paddingtb={30}>
              <div className="text-center">
                {c.avatar && <ProfilePic src={c.avatar} referrerPolicy="no-referrer" />}
                {!c.avatar && <CircleIcon icon={<FaUser />} />}
                <p className="m-t-2 big">{store.customer.display_name}</p>
              </div>

              <div className="m-t-4">
                <DetailRow>
                  <p>{t('store.modals.customer.headings.sessions')}</p>
                  <p>{c.stats?.sessions}</p>
                </DetailRow>
                <DetailRow>
                  <p>{t('store.modals.customer.headings.orders')}</p>
                  <p>{c.stats?.orders}</p>
                </DetailRow>
                <DetailRow>
                  <p>{t('store.modals.customer.headings.spend')}</p>
                  <p>{t('currency', { value: c.stats?.sales })}</p>
                </DetailRow>
                {walletlyPoints && (
                  <DetailRow>
                    <p>{t('store.modals.customer.headings.loyalty_points')}</p>
                    <p>{walletlyPoints}</p>
                  </DetailRow>
                )}
              </div>
            </ModalContent>

            <ModalDropContent
              title={
                <h4>{t('store.modals.customer.headings.order_history')}</h4>
              }
              cPaddinglr={0}
              cPaddingtb={0}
            >
              <CustomerOrderHistory />
            </ModalDropContent>

            {(walletlyIntegrational && walletlyIntegrational.enabled && walletlyDeals && walletlyDeals.length > 0) && (
              <ModalDropContent
              title={
                <h4>{t('store.modals.customer.headings.loyalty')}</h4>
              }
              cPaddinglr={0}
              cPaddingtb={0}
            >
              {walletlyDeals.map((deal: { [key: string]: any; }, i: React.Key | undefined) => (
                <LoyatyDeals deal={deal} key={i}/>
              ))}
            </ModalDropContent>
            )}

            <CustomerAgeVerification />

            {this.state.type === 'email' ? (
              <ModalDropContent
                title={<h4>{'Change Email'}</h4>}
                cPaddinglr={0}
                cPaddingtb={0}
              >
                <div className="text-center">
                  <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    className="h-8 p-2 m-2 text-2xl width300"
                    value={this.state.email}
                    onChange={e => {
                      this.setState({ email: e.target.value });
                    }}
                  />
                  <p className="m-4">{this.state.email_message}</p>
                  <Button
                    size="md"
                    color="primary-inverse"
                    className="m-2"
                    onClick={this.changeEmail}
                  >
                    Change Email
                  </Button>
                </div>
              </ModalDropContent>
            ) : (
              <div></div>
            )}

            {this.state.type === 'email' ? (
              <ModalDropContent
                title={<h4>{'Change Password'}</h4>}
                cPaddinglr={0}
                cPaddingtb={0}
              >
                <div className="text-center">
                  <input
                    name="password"
                    type="password"
                    placeholder="New Password"
                    className="h-8 p-2 m-2 m-t-4 text-2xl width300"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                  />
                  <input
                    name="password_confirmation"
                    type="password"
                    placeholder="New Password Confirmation"
                    className="h-8 p-2 m-2 text-2xl width300"
                    value={this.state.password_confirmation}
                    onChange={this.handleChangePassword}
                  />
                  <p className="m-4">{this.state.password_message}</p>
                  <Button
                    size="md"
                    color="primary-inverse"
                    className="m-2"
                    onClick={this.changePassword}
                    disabled={!this.state.show_password}
                  >
                    Change Password
                  </Button>
                </div>
              </ModalDropContent>
            ) : (
              <div></div>
            )}
            <ModalContent>
              <p className="cursor" onClick={this.logout}>
                <FaPowerOff className="m-r-1" />
                {t('store.sections.nav_top.logout')}
              </p>
            </ModalContent>
          </div>
        )}
      </Modal>
    );
  }
}

export const CustomerModal = withTranslation()(CustomerModalClass);
