import { observable, action } from "mobx";
import { RootStore } from "../store";
import { logger } from "@lib/common";

export interface BookingState {
	item: T.Schema.Booking.BookingSchema | null;
	loading: boolean;
	error: string;
}

export class BookingStore {

	@observable s: BookingState;

	store: RootStore;

	constructor(store: RootStore, initialState?: BookingState) {
		this.store = store;
		this.s = observable({
			item: null,
			loading: true,
			error: "",
			...(initialState || {}),
		});
	}

	sync = () => {
		const { item } = this.s;
		const { booking_id } = this.store;
		if (booking_id !== null && (!item || item._id !== booking_id)) {
			this.get(booking_id);
		}
		else {
			this.update({ loading: false });
		}
	}

	@action async get(_id: string) {
		try {
			this.s.error = "";
			this.s.loading = true;
			const response = await this.store.api.booking_find({ _id });
			if (response.outcome) {
				this.s.error = response.message;
				this.s.loading = false;
			}
			else {
				this.s.error = "";
				this.s.loading = false;
				this.s.item = response.booking;
			}
		}
		catch (e) {
			logger.captureException(e);
			this.s.loading = false;
			this.s.error = "generic";
		}
	}

	@action update = (data: Partial<BookingState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof BookingState];
				if (value !== undefined) {
					// @ts-ignore
					this.s[key as keyof BookingState] = value;
				}
			}
		}
	}

	@action updateItem(data: Partial<T.Schema.Booking.BookingSchema>) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof T.Schema.Booking.BookingSchema];
				if (value !== undefined && this.s && this.s.item) {
					// @ts-ignore
					this.s.item[key as keyof T.Schema.Booking.BookingSchema] = value;
				}
			}
		}
	}

}
