import React from "react";
import { styled, Input } from "@lib/components";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import _isEmpty from "lodash/isEmpty";
import Payment from "payment";

interface Props { }
interface State {
	error: string;
}

const NumberInputNoSpinButton = styled(Input)`
  -moz-appearance:textfield;
  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

@inject("store") @observer
class RedDotFormClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			error: "",
		}
	}

	onChangeCvv = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { store } = this.injected;
		store.checkout.update({ card_cvv: e.target.value });

		this.validateCard();
	}

	onChangeExpiry = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { store } = this.injected;
		const next = e.target.value;
		const current = store.checkout.s.card_expiry;

		if (next.length > 7) {
			return;
		}
		if (current.length === 3 && next.length === 2) {
			store.checkout.update({ card_expiry: next });
		} else if (current.length === 2 && next.length === 3) {
			store.checkout.update({ card_expiry: current + "/" + next[2] });
		} else if (current.length === 1 && next.length === 2) {
			store.checkout.update({ card_expiry: next + "/" });
		} else {
			store.checkout.update({ card_expiry: next });
		}

		this.validateCard();
	}

	// onChangeBillingAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
	//   const { store } = this.injected;
	//   store.checkout.update({ address_field: e.target.value });

	//   this.validateCard();
	// }

	// onChangeZipCode = (e: React.ChangeEvent<HTMLInputElement>) => {
	//   const { store } = this.injected;
	//   store.checkout.update({ zip: e.target.value });

	//   this.validateCard();
	// }

	onChangeCardNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { store } = this.injected;
		store.checkout.update({ card_number: this.formatCreditCardNumber(e.target.value) });

		this.validateCard();
	}

	formatCreditCardNumber = (value: string) => {
		if (!value) {
			return value;
		}

		const issuer = Payment.fns.cardType(value);
		const clearValue = value.replace(/\D+/g, "");
		let nextValue;

		switch (issuer) {
			case "amex":
				nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
					4,
					10
				)} ${clearValue.slice(10, 15)}`;
				break;
			case "dinersclub":
				nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
					4,
					10
				)} ${clearValue.slice(10, 14)}`;
				break;
			default:
				nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
					4,
					8
				)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
				break;
		}

		return nextValue.trim();
	}

	validateCard = () => {
		const { store } = this.injected;
		const cardNumber = store.checkout.s.card_number;
		const cardExpiry = store.checkout.s.card_expiry;
		const cardCvv = store.checkout.s.card_cvv;
		// const billingAddress = store.checkout.s.address_field;
		// const zip = store.checkout.s.zip;

		if (_isEmpty(cardNumber) || !Payment.fns.validateCardNumber(cardNumber)) {
			this.setState({ error: "Missing card number or card number is invalid" });
			return false;
		}

		if (_isEmpty(cardExpiry) || !Payment.fns.validateCardExpiry(cardExpiry)) {
			this.setState({ error: "Missing card expiry or card expiry is invalid" })
			return false;
		}

		if (_isEmpty(cardCvv) || !Payment.fns.validateCardCVC(cardCvv)) {
			this.setState({ error: "Missing card CVV or card CVV is invalid" });
			return false;
		}

		// if (_isEmpty(billingAddress)) {
		//   this.setState({ error: "Missing billing address" });
		//   return false;
		// }

		// if (_isEmpty(zip)) {
		//   this.setState({ error: "Missing postal code" });
		//   return false;
		// }

		this.setState({ error: " " });

		return true;
	}

	render() {
		const { store } = this.injected;
		const { card_number, card_expiry, card_cvv } = store.checkout.s;

		return (
			<div>
				<div id="paymentForm" style={{ marginTop: "2rem" }}>
					<Input
						type="text"
						name="card_number"
						className="col"
						inputMode="numeric"
						placeholder={"Card Number"}
						onChange={this.onChangeCardNumber}
						value={card_number}
						style={{ marginBottom: "1rem" }}
						required={true}
					/>
					<div style={{
						display: "flex",
						justifyContent: "space-between",
						gap: ".25rem",
						marginBottom: "1rem",
					}}>
						<Input
							type="text"
							name="card_expiry"
							className="col"
							pattern="([0-9]{2})\/([0-9]{4})"
							inputMode="numeric"
							placeholder={"Expiry (MM/YYYY)"}
							onChange={this.onChangeExpiry}
							value={card_expiry}
							required={true}
						/>
						<NumberInputNoSpinButton
							type="text"
							name="card_cvv"
							className="col"
							placeholder={"CVV"}
							inputMode="numeric"
							onChange={this.onChangeCvv}
							value={card_cvv}
							required={true}
						/>
					</div>
					{/* <Input
            type="text"
            name="billing_address"
            className="col"
            inputMode="text"
            placeholder={"Billing Address"}
            onChange={this.onChangeBillingAddress}
            value={billing_address}
            style={{ marginBottom: "1rem" }}
            required={true}
          />
          <Input
            type="text"
            name="zip"
            className="col"
            inputMode="text"
            placeholder={"Postal Code"}
            onChange={this.onChangeZipCode}
            value={zip}
            required={true}
          /> */}
				</div>

				<p style={{ textAlign: "center", marginTop: "1em", color: "red" }}>
					{this.state.error}
				</p>
			</div>
		);
	}
}

export const RedDotForm = (RedDotFormClass);
