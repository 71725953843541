import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, withTheme } from '@lib/components';
import _get from 'lodash/get';
import { CustomCheckoutField } from './custom-checkout-field';
import { RestaurantUtils } from '@lib/common';
import { CheckoutState } from '../../../mobx/state/checkout';
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input';
import { CountryCode, E164Number, parsePhoneNumberFromString } from 'libphonenumber-js';
import { useStores } from '../../../mobx/useStores';

interface Props {
  theme: T.Core.BaseTheme.ThemeInterface;
}

const CheckoutCustomerComponent = observer(({ theme }: Props) => {
  const store = useStores();
  const { t } = useTranslation();
  const [userCountry, setUserCountry] = useState('');

  let timeoutId: number;

  useEffect(() => {

    fetch('https://ipinfo.io/json')
    .then(response => response.json())
    .then(data => {
      if(data && data.country) {
        setUserCountry(data.country)
      }
    })
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const updateCustomerInfo = (attributes: Partial<CheckoutState>) => {
    store.checkout.update({ ...attributes, error: '' });
  };

  const handlePhoneInputChange = (value: E164Number | undefined) => {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      updateCustomerInfo({
        phone_e164_format: value,
        phone: formatPhoneNumber(value as E164Number).replace(/[^0-9]+/g, ''),
        phone_local_format: formatPhoneNumber(value as E164Number),
      });
    }, 1000);
  };

  const constructInputPlaceholders = () => {
    const { reseller } = store;
    const { requiredCustomerInfo } = store.checkout;
    const service = store.order_config.s.service as T.Schema.Restaurant.Services.RestaurantServiceTypes;

    const prefix = 'store.modals.checkout.customer';
    const optionalText = ` (${t(`${prefix}.optional`)})`;

    let namePlaceholder = t(`${prefix}.name`);
    let emailPlaceholder = t(`${prefix}.email`);
    let phonePlaceholder = reseller._id === 'grabet' ? t(`${prefix}.phone_important`) : t(`${prefix}.phone`);
    let notePlaceholder = service === 'delivery' ? t(`${prefix}.delivery_notes`) : t(`${prefix}.notes`);

    const config = store.restaurant.settings.services[service]?.customer?.required_info || [];
    if (config.length > 0) {
      if (!(requiredCustomerInfo && config.includes('phone'))) phonePlaceholder += optionalText;
      if (!(requiredCustomerInfo && config.includes('name'))) namePlaceholder += optionalText;
      if (!(requiredCustomerInfo && config.includes('email'))) emailPlaceholder += optionalText;
    } else if (!requiredCustomerInfo) {
      phonePlaceholder += optionalText;
      namePlaceholder += optionalText;
      emailPlaceholder += optionalText;
    }

    notePlaceholder += optionalText;

    return { namePlaceholder, emailPlaceholder, phonePlaceholder, notePlaceholder };
  };

  const getCustomCheckoutFields = () => {
    const services = store.restaurant.settings.services;
    const service = store.order_config.s.service;
    const currentService = _get(services, service) as T.Schema.Restaurant.Services.RestaurantService;

    const checkoutFields = _get(
      currentService,
      'custom_checkout_fields',
      [] as T.Schema.Restaurant.Services.CustomCheckoutField[]
    );

    return checkoutFields.filter(field => field.enabled);
  };

  const checkoutFields = getCustomCheckoutFields();
  const { logged_in_only: loggedInOnly } = store.restaurant.settings.business;
  const { namePlaceholder, emailPlaceholder, phonePlaceholder, notePlaceholder } = constructInputPlaceholders();
  const requiredCustomerInfo = store.checkout.requiredCustomerInfo;
  const service = store.order_config.s.service;
  return (
    <>
      <div>
        <Input
          id="address_field_imp"
          type="text"
          name="address_field_imp"
          style={{
            height: '0 !important',
            visibility: 'hidden',
            position: 'absolute',
            width: 'auto',
          }}
        />

        {service === 'dine_in' && !requiredCustomerInfo ? null :
        <>
          <FormGroup no_border className="m-b-2">
            <Input
              type="text"
              name="name"
              placeholder={namePlaceholder}
              onChange={e => updateCustomerInfo({ name: e.target.value })}
              value={store.checkout.s.name}
              autoComplete="on"
              disabled={store.checkout.s.loading}
            />
          </FormGroup>

          <FormGroup no_border className="m-b-2">
            <Input
              type="email"
              name="email"
              placeholder={emailPlaceholder}
              onChange={e => updateCustomerInfo({ email: e.target.value })}
              value={store.checkout.s.email}
              disabled={loggedInOnly || store.checkout.s.loading}
              autoComplete="on"
            />
          </FormGroup>

          <FormGroup no_border className="m-b-2">
            <PhoneInput
              international={false}
              placeholder={phonePlaceholder}
              onChange={value => handlePhoneInputChange(value)}
              value={
                store.checkout.s.phone_e164_format ||
                parsePhoneNumberFromString(
                  store.checkout.s.phone,
                  userCountry as CountryCode || RestaurantUtils.settings.getCountryCodeFromLocation(store.restaurant) as CountryCode
                )?.number
              }
              // @ts-ignore
              defaultCountry={userCountry || RestaurantUtils.settings.getCountryCodeFromLocation(store.restaurant)}
              countries={
                store.order_config.s.service === 'delivery' && store.checkout.delivery_provider! === 'uber'
                  ? ['AU', 'NZ', 'US', 'GB', 'CA']
                  : []
              }
              countryOptionsOrder={
                (store.order_config.s.service === 'delivery' && store.checkout.delivery_provider! === 'uber'
                  ? undefined
                  : ['AU', 'NZ', 'US', 'GB', 'CA'] )
              }
              numberInputProps={{
                style: {
                  background: theme.input.background,
                  color: theme.input.text,
                  border: `1px solid ${theme.input.border}`,
                  fontSize: theme.input.fontSize,
                  height: theme.input.height,
                },
              }}
              disabled={store.checkout.s.loading}
              addInternationalOption={false}
            />
          </FormGroup>
        </>
        }
        {store.checkout.s.payment === 'gravity' && (
          <FormGroup no_border className="m-b-2">
            <Input
              type="text"
              name="zip"
              placeholder={'Billing Zip Code'}
              onChange={e => updateCustomerInfo({ zip: e.target.value })}
              value={store.checkout.s.zip}
              disabled={store.checkout.s.loading}
            />
          </FormGroup>
        )}

        <FormGroup no_border>
          <Input
            type="text"
            name="notes"
            placeholder={notePlaceholder}
            onChange={e => updateCustomerInfo({ notes: e.target.value })}
            value={store.checkout.s.notes}
            disabled={store.checkout.s.loading}
          />
        </FormGroup>
      </div>

      {checkoutFields && checkoutFields.map(field => <CustomCheckoutField key={field._id} field={field} />)}
    </>
  );
});

// @ts-ignore
export const CheckoutCustomer = withTheme(CheckoutCustomerComponent);
