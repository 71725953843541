import cloneDeep from "lodash/cloneDeep";
import { toJS, isObservable } from "mobx";

export function cloneDeepSafe<T>(value: T): T {
	if (isObservable(value)) {
		return toJS(value);
	}
	else {
		return cloneDeep(value);
	}
}

export function formatNumWithComma(num: number): string {
  if (!num) return ''
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || '';
}