import { get, isEmpty } from 'lodash';
import { RootStore } from '../../store';
import { CheckoutState } from '../checkout';
import { PromoParser, RequireCustomerInfoPaymentMethods, RequireCustomerInfoPaymentSubMethods, RestaurantUtils } from '@lib/common';
import isEmail from 'validator/lib/isEmail';
import { isValidPhoneNumber } from 'libphonenumber-js';

interface CheckoutData {
  restaurant: T.Schema.Restaurant.RestaurantSafeSchema;
  checkout: CheckoutState;
  store: RootStore;
}

type Validator = (data: CheckoutData) => string | undefined;

/**
 * Helper functions
 */

const isCardInvalid = (checkout: CheckoutState) => {
  return !(checkout.card_number && checkout.card_expiry && checkout.card_cvv);
};

const validatePhoneFormat = (
  restaurant: T.Schema.Restaurant.RestaurantSafeSchema,
  checkout: CheckoutState,
  service: T.Schema.Restaurant.Services.RestaurantServiceTypes
) => {
  const provider = RestaurantUtils.settings.getDefaultDeliveryProvider(restaurant);
  const lalamoveConfig = restaurant.settings.services.delivery.providers?.lalamove;
  const phoneRegex = restaurant.settings.business.validation?.phone_regex;

  if (
    phoneRegex &&
    (!new RegExp(phoneRegex).test(checkout.phone) || !new RegExp(phoneRegex).test(checkout.phone_local_format))
  ) {
    return 'invalid_phone';
  }

  if (service === 'delivery' && provider === 'lalamove') {
    if (!isValidPhoneNumber(checkout.phone, lalamoveConfig!.market)) return 'invalid_phone';
  }
};

const validateCustomerUsingConfig = (
  restaurant: T.Schema.Restaurant.RestaurantSafeSchema,
  checkout: CheckoutState,
  service: T.Schema.Restaurant.Services.RestaurantServiceTypes,
  config: string[]
) => {
  if (isEmpty(checkout.name) && (!config || config.includes('name'))) return 'required_name';
  if (isEmpty(checkout.email) && (!config || config.includes('email'))) return 'required_email';
  if (!isEmail(checkout.email) && (!config || config.includes('email'))) return 'invalid_email';
  if (isEmpty(checkout.phone) && (!config || config.includes('phone'))) return 'required_phone';
  if (validatePhoneFormat(restaurant, checkout, service)) return 'invalid_phone';
};

export const validateCustomerSimple = (
  restaurant: T.Schema.Restaurant.RestaurantSafeSchema,
  checkout: CheckoutState,
  service: T.Schema.Restaurant.Services.RestaurantServiceTypes
) => {
  if (isEmpty(checkout.name)) return 'required_name';
  if (isEmpty(checkout.email)) return 'required_email';
  if (!isEmail(checkout.email)) return 'invalid_email';
  if (isEmpty(checkout.phone)) return 'required_phone';
  if (validatePhoneFormat(restaurant, checkout, service)) return 'invalid_phone';
};

/**
 * List of validators
 */

const invalidStripeMinOrderTotal = ({ store, restaurant }: CheckoutData) => {
  const total = store.cart.total;
  const config = restaurant.settings.payments.stripe;
  return config?.min_order && total && total < config.min_order;
};

export const validateAddressField = () => {
  const addressField = document.getElementById('address_field_imp') as HTMLInputElement;
  if (addressField && addressField.value) return 'something_went_wrong';
};

export const validateUserAuthentication = ({ restaurant, store }: CheckoutData) => {
  const { logged_in_only } = restaurant.settings.business;
  if (logged_in_only && !store.customer.isLoggedIn) return 'something_went_wrong';
};

export const validateServiceAvailability = ({ store }: CheckoutData) => {
  const orderConfig = store.order_config.s;
  if (orderConfig.due === 'now' && !store.serviceOpen()) return 'closed';
};

export const isCustomerInfoRequired = ({ restaurant, checkout, store }: CheckoutData) => {
  const pm = checkout.payment;
  const psubmethod = checkout.subPayment;
  const service = store.order_config.s.service;
  const services = restaurant.settings.services!;
  const requiredForPm = RequireCustomerInfoPaymentMethods.includes(pm);
  const requiredForSubmethod = psubmethod ? RequireCustomerInfoPaymentSubMethods.includes(psubmethod) : false;
  const requiredDineIn = !restaurant.settings.services.dine_in.payments?.optional_customer_info || requiredForPm;

  const service_customer_setting = service && services[service] ? services[service].customer : undefined;
  const required_info_setting = service_customer_setting ? service_customer_setting.required_info : undefined;
  const requiredByService = !required_info_setting || (required_info_setting && required_info_setting.length || 0) > 0;

  if (service === 'dine_in') return requiredDineIn || (service_customer_setting && required_info_setting && required_info_setting.length > 0);

  return requiredByService || requiredForPm || requiredForSubmethod;
};

export const validateCustomerInformation = (data: CheckoutData) => {
  const { restaurant, store, checkout } = data;
  const service = store.order_config.s.service;

  console.log({service, confirmed: store.order_config.s.confirmed});
  if (!service) return; // nothing to validate

  const requiredInfo = isCustomerInfoRequired(data);
  const services = restaurant.settings.services!;

  if (requiredInfo) {
    const service_customer_setting = services[service].customer;
    const config = service_customer_setting ? service_customer_setting.required_info : undefined;
    if (config && config.length > 0) {
      return validateCustomerUsingConfig(restaurant, checkout, service, config);
    }
    return validateCustomerSimple(restaurant, checkout, service);
  }
};

export const validateTerms = ({ store, checkout }: CheckoutData) => {
  if (store.termsActive && !checkout.terms) return 'accept_terms';
};

export const validatePayment = (data: CheckoutData) => {
  const { checkout } = data;
  const method = checkout.payment;
  if (!method) return 'required_payment';

  switch (method) {
    case 'gravity':
      if (!checkout.zip) return 'required_zip';
      break;
    case 'cardconnect':
      if (checkout.card_error) return 'invalid_cc_number';
      if (!checkout.card_token) return 'retry_cc_number';
      break;
    case 'bambora_apac':
      if (checkout.card_error || !checkout.card_token) return 'invalid_cc_number';
      break;
    case 'elavon':
      if (isCardInvalid(checkout)) return 'invalid_cc_number';
      break;
    case 'stripe':
      if (invalidStripeMinOrderTotal(data)) return 'invalid_order_amount';
      break;
    case 'red_dot':
      if (isCardInvalid(checkout)) return 'invalid_cc_number';
      break;
    default:
      break;
  }
};

export const validateCustomCheckoutFields = ({ restaurant, checkout, store }: CheckoutData) => {
  const orderConfig = store.order_config.s;
  const checkoutFields = checkout.checkout_fields;
  const settingService = get(
    restaurant.settings.services,
    orderConfig.service
  ) as T.Schema.Restaurant.Services.RestaurantService;
  const requiredFieldIds = get(settingService, 'custom_checkout_fields', [])
    .filter((field: T.Schema.Restaurant.Services.CustomCheckoutField) => field.enabled && field.required)
    .map(field => field._id);

  for (const field of checkoutFields) {
    if (requiredFieldIds.includes(field.id) && isEmpty(field.answer)) {
      return 'missing_custom_checkout_fields';
    }
  }
};

export const validateDeliveryProviders = ({ store }: CheckoutData) => {
  const orderConfig = store.order_config.s;

  if (orderConfig.delivery_provider === 'postmates') {
    if (!orderConfig.postmates_quote_id || !orderConfig.postmates_quote_amount) {
      return 'delivery_unavailable';
    }
  }

  if (orderConfig.delivery_provider === 'lalamove') {
    if (!orderConfig.lalamove_service_type) {
      return 'required_delivery_provider';
    }

    if (!orderConfig.lalamove_quotationId) {
      return 'required_delivery_quotation';
    }
    
    if (!orderConfig.lalamove_quote_amount || !orderConfig.lalamove_quote_currency) {
      return 'delivery_unavailable';
    }
  }
};

export const validatePromotion = ({ store, checkout }: CheckoutData) => {
  const promo = store.cart.promo;
  if (promo) {
    const promoParse = new PromoParser(promo);
    const paymentMethods = promoParse.getPaymentMethods();
    if (paymentMethods && paymentMethods.length > 0 && !paymentMethods.includes(checkout.payment)) {
      return 'promo_by_payment_method_not_applicable';
    }
  }
};

export const validateCart = ({ store }: CheckoutData) => {
  if (store.cart.s.items.length === 0) return 'cart_empty';
}

export function doOrderValidation(data: CheckoutData) {
  const validators: Validator[] = [
    validateAddressField,
    validateUserAuthentication,
    validateServiceAvailability,
    // validateCustomerInformation,
    // validateTerms,
    validatePayment,
    // validateCustomCheckoutFields,
    validateDeliveryProviders,
    validatePromotion,
    validateCart
  ];

  for (const validator of validators) {
    const error = validator(data);
    if (error) {
      console.log({validator_name: validator.name, error: error}); // TODO: REMOVE AFTER STRIPE ISSUE
      return error;
    }
  }
}
