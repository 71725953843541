import * as React from "react";
import { MobxComponent } from "../../../../mobx/component";
import { Switch } from "@lib/components";
import { OptionListWrapper, OptionListItem } from "../components";
import { observer } from "mobx-react";

interface Props {
	ingredients: T.Schema.Restaurant.Menu.RestaurantDishIngredient[];
	update: (data: T.Schema.Restaurant.Menu.RestaurantDishIngredient[]) => void;
}
interface State { }

@observer
export class DishModalIngredients extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {};
	}

	onChangeActive = (i: number) => {
		const ingredients = [...this.props.ingredients];
		ingredients[i].active = !ingredients[i].active;
		this.props.update(ingredients);
	}

	render() {
		const { ingredients } = this.props;
		return (
			<OptionListWrapper>
				{ingredients.map((ing, i) => {
					return (
						<OptionListItem key={i} onClick={() => this.onChangeActive(i)}>
							<p className="p-r-1 small">{ing.name}</p>
							<Switch
								id={`${ing._id}-${i}`}
								name={`${ing._id}-${i}`}
								checked={ing.active}
								onChange={() => this.onChangeActive(i)} />
						</OptionListItem>
					);
				})}
			</OptionListWrapper>
		);
	}

}
