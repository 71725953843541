import * as React from "react";
import { observer, inject } from "mobx-react";
import { Box } from "./components";
import { CircleIcon, Button } from "@lib/components";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }

@inject("store") @observer
class InfoLocationClass extends MobxComponent<Props> {

	render() {
		const { t } = this.injected;
		const { isMappedData } = this.injected.store;
		const r = this.injected.store.restaurant;
		const modal = this.injected.store.modal;
		return (
			<Box className="col">
				<CircleIcon icon={<FaMapMarkerAlt />} />
				<div className="text-center">
					<h3 className="font-regular m-b-2">{t("store.sections.info.location.title")}</h3>
					<p className="lhp">{r.location.address}</p>
					{isMappedData && (
						<Button
							className="m-t-3"
							size="xs"
							color="primary-inverse"
							onClick={() => modal.toggle("map")}>
							{t("store.sections.info.location.view_map")}
						</Button>
					)}
				</div>
				{r.location.phone && (
					<div className="text-center m-t-5">
						<h3 className="font-regular m-b-2">{t("store.sections.info.location.phone")}</h3>
						<p className="lhp">{r.location.phone}</p>
					</div>
				)}
			</Box>
		);
	}

}

export const InfoLocation = withTranslation()(InfoLocationClass);
