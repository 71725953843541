import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { DropdownMenus } from "./dropdown-menu";
import { SliderMenus } from "./slider-menu";
import { NavigationMenus } from "../../menuTemplateV1/nav/menus";
import { NavigationMenus1 } from "../../menuTemplateV1/nav/menus_1";

interface Props {}

@inject("store")
@observer
export class MenuNavBar extends MobxComponent<Props> {
	render() {
		const { store } = this.injected;
		const restaurant = store.restaurant;

		const menuTemplate =
			restaurant.website.sections.menu_nav_bar?.menu_template || "v1";
		const legacyMenuSelectorOnlyFlag = restaurant.website.experimental_ui_1;

		if (menuTemplate === "v1") {
			return legacyMenuSelectorOnlyFlag ? (
				<NavigationMenus1 />
			) : (
				<NavigationMenus />
			);
		}

		if (menuTemplate === "v2") {
			let menuNavBarStyle =
				restaurant.website.sections.menu_nav_bar?.style || "slider";

			switch (menuNavBarStyle) {
				case "slider":
					return <SliderMenus />;
				case "dropdown":
				default:
					return <DropdownMenus />;
			}
		}
	}
}
