import React from 'react';
import { ModalContent } from '@lib/components';
import { QrFlowProps } from '../types';

export function QrFlow({ qrUrl, methodName }: QrFlowProps) {
  return (
    <ModalContent>
      <img alt={methodName} src={qrUrl} />
    </ModalContent>
  );
}
