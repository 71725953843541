import React from "react";
import { Button } from "@lib/components";
import NumberFormat from "react-number-format";
// import "./checkout.css"

interface Props {
	changeAuthorizedOpaqueData: Function;
	authorized_data: any;
	amount: number;
	onError: any;
}
interface State {
	errorMessage: string;
}

class AuthorizedForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			errorMessage: "Card not verified"
		}
	}
	componentDidMount = () => {
		window.authorized_data = {
			opaqueData: {
				dataDescriptor: "",
				dataValue: ""
			}
		};
	}
	sendPaymentDataToAnet = () => {
		//
		var authData: any = {};
		authData.clientKey = this.props.authorized_data.authorized_client_key;
		authData.apiLoginID = this.props.authorized_data.authorized_login_id;
		//
		var cardData: any = {};
		///@ts-ignore
		cardData.cardNumber = document.getElementById("cardNumber").value;
		///@ts-ignore
		cardData.month = document.getElementById("expMonth").value;
		///@ts-ignore
		cardData.year = document.getElementById("expYear").value;
		///@ts-ignore
		cardData.cardCode = document.getElementById("cardCode").value;
		//
		var secureData: any = {};
		secureData.authData = authData;
		secureData.cardData = cardData;
		//
		window.Accept.dispatchData(secureData, this.responseHandler);
		// For local dev:
		// this.responseHandler({
		// 	messages: {
		// 		resultCode: "Ok",
		// 	},
		// 	opaqueData: {
		// 		dataDescriptor: "",
		// 		dataValue: "",
		// 	},
		// })
	}
	responseHandler = (response: any) => {
		if (response.messages.resultCode === "Error") {
			var i = 0;
			while (i < response.messages.message.length) {
				console.log(
					response.messages.message[i].code + ": " +
					response.messages.message[i].text
				);
				i = i + 1;
			}
			this.setState({
				errorMessage: "Issue with card information. Please try again.",
			});
			this.props.onError("payment_fail");
			window.authorized_data = {
				opaqueData: {
					dataDescriptor: "",
					dataValue: "",
				},
			};
		} else {
			if (response.messages.resultCode === "Ok") {
				this.setState({
					errorMessage: "Card has been verified"
				})
				// this.props.onError("payment_fail");
				// this.props.onError("");
				// console.log({ response })
				window.authorized_data = {
					opaqueData: response.opaqueData
				};
			} else {
				this.setState({
					errorMessage: "Issue with card information. Please try again."
				})
				this.props.onError("payment_fail");
				window.authorized_data = {
					opaqueData: {
						dataDescriptor: "",
						dataValue: "",
					},
				};
			}
		}
	}
	render() {
		return (
			<div>
				<div id="paymentForm">
					<NumberFormat
						style={{
							marginTop: "15px",
							boxSizing: "border-box",
							width: "100%",
							padding: "1rem",
							outlineColor: "#d0d0ce",
							border: "3px solid #d0d0ce",
							fontFamily: "inherit",
							fontSize: "18px",
							paddingTop: "10px",
							paddingBottom: "10px",
						}}
						type="text"
						name="cardNumber"
						id="cardNumber"
						placeholder="Card Number"
					/>
					<input
						style={{
							marginTop: "5px",
							boxSizing: "border-box",
							width: "30%",
							padding: "1rem",
							outlineColor: "#d0d0ce",
							border: "3px solid #d0d0ce",
							fontFamily: "inherit",
							fontSize: "18px",
							paddingTop: "10px",
							paddingBottom: "10px",
						}}
						type="text"
						name="expMonth"
						id="expMonth"
						placeholder="Month"
					/>
					<input
						style={{
							marginTop: "5px",
							boxSizing: "border-box",
							width: "30%",
							outlineColor: "#d0d0ce",
							padding: "1rem",
							border: "3px solid #d0d0ce",
							fontFamily: "inherit",
							fontSize: "18px",
							paddingTop: "10px",
							paddingBottom: "10px",
						}}
						type="text"
						name="expYear"
						id="expYear"
						placeholder="Year"
					/>
					<input
						style={{
							marginBottom: "20px",
							marginTop: "5px",
							marginLeft: "10%",
							outlineColor: "#d0d0ce",
							boxSizing: "border-box",
							width: "30%",
							padding: "1rem",
							border: "3px solid #d0d0ce",
							fontFamily: "inherit",
							fontSize: "18px",
							paddingTop: "10px",
							paddingBottom: "10px",
						}}
						type="text"
						name="cardCode"
						id="cardCode"
						placeholder="CVV"
					/>
					<input type="hidden" name="dataValue" id="dataValue" />
					<input type="hidden" name="dataDescriptor" id="dataDescriptor" />
					{/* <button style={{marginTop: "20px"}} type="button" onClick={(e) => this.sendPaymentDataToAnet()}>Verify</button> */}
					<Button
						id="checkoutButton"
						full={true}
						color="primary"
						type="button"
						onClick={(e) => this.sendPaymentDataToAnet()}
						style={{
							marginTop: "30px",
							width: "30%",
							margin: "0 auto",
							display: "block",
						}}
					>
						Verify
					</Button>
				</div>
				<p style={{ textAlign: "center", marginTop: "10px" }}>
					{this.state.errorMessage}
				</p>
			</div>
			// <Box className="App" p={3}>
			//   </Box>
		);
	}
}

export default AuthorizedForm;
