import Big from 'big.js';
import axios from 'axios';
import _toString from 'lodash/toString';
import { config } from "../../../../config";

const APPLE_PAY_VERSION = 3;

export enum PaymentStatus {
	SUCCESS = 0,
	FAILURE,
	CANCEL
}

interface Options {
	merchantID: string;
	merchantName: string;
	merchantDomain: string;
	currency: string;
	label: string;
	amount: number;
	countryCode: string;
	customerName: string;
	customerEmail: string;
	orderID?: string;
	items: Array<T.Schema.Order.OrderDish>;
	restaurantID: string;
}

export default class ApplePayProcessor {
	merchantID: string;
	merchantName: string;
	merchantDomain: string;
	currency: string;
	label: string;
	amount: number;
	countryCode: string;
	customerName: string;
	customerEmail: string;
	orderID: string;
	items: Array<T.Schema.Order.OrderDish>;
	restaurantID: string;

	constructor(opts: Options) {
		this.merchantID = opts.merchantID;
		this.merchantName = opts.merchantName;
		this.merchantDomain = opts.merchantDomain;
		this.currency = opts.currency;
		this.label = opts.label;
		this.amount = opts.amount;
		this.countryCode = opts.countryCode;
		this.customerName = opts.customerName;
		this.customerEmail = opts.customerEmail;
		this.orderID = _toString(opts.orderID);
		this.items = opts.items;
		this.restaurantID = opts.restaurantID;
	}

	static isJsApiAvailable(): Promise<boolean | T.ErrorObject> {
		return new Promise((resolve, reject) => {
			try {
				// @ts-ignore
				const enabled = window.ApplePaySession && window.ApplePaySession.canMakePayments();
				resolve(enabled);
			} catch (err) {
				console.log({ err })
				reject(err);
			}
		});
	}

	/**
	 * Check if Apple Pay is available of the end device.
	 * Reference: https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
	 *
	 * @param {string} merchantID
	 * @returns {Promise<boolean>}
	 */
	static isAvailable(merchantID: string): Promise<boolean> {
		return ApplePayProcessor.isJsApiAvailable()
			.then(() => {
				// @ts-ignore
				return window.ApplePaySession.canMakePaymentsWithActiveCard(merchantID);
			}).catch((e) => {
				console.log({ e })
				return new Promise((resolve) => {
					resolve(false);
				});
			});
	}

	/**
	 * Build Apple Pay line items.
	 * Reference: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaylineitem
	 *
	 * @returns {Array<object>}
	 */
	buildLineItems(): Array<object> {
		return this.items.map((item) => {
			let amount;
			if (typeof item.discount === 'number' && item.discount > 0) {
				amount = Big(item.price).minus(item.discount).toFixed(2)
			} else {
				amount = Big(item.price).toFixed(2);
			}

			return {
				amount,
				label: item.name,
				type: 'final',
			}
		})
	}

	/**
	 * Initiate Apple Pay payment request payload.
	 *
	 * Reference: https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest
	 *
	 * @returns {object}
	 */
	getPaymentRequestPayload(): object {
		return {
			total: {
				label: this.label,
				amount: this.amount,
			},
			lineItems: this.buildLineItems(),
			currencyCode: this.currency,
			countryCode: this.countryCode,
			supportedNetworks: ['masterCard', 'visa', 'amex', 'discover', 'jcb'],
			merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],
		}
	}

	/**
	 * Validate and create Apple Pay session.
	 *
	 * @param {string} validationURL
	 */
	validateSession(validationURL: string) {
		const data = {
			url: validationURL,
			body: {
				initiative: "web",
				displayName: this.merchantName,
				merchantIdentifier: this.merchantID,
				initiativeContext: this.merchantDomain,
			},
			restaurant_id: this.restaurantID,
		}

		return this.post('/stores/applepay/session/validate', data, 'Failed to validate Apple Pay session.');
	}

	/**
	 * Apple Pay 'onvalidatemerchant' handler.
	 *
	 * @param {ApplePaySession} session
	 */
	handleValidateMerchant(session: ApplePaySession) {
		return (event: ApplePayJS.ApplePayValidateMerchantEvent) => {
			this.validateSession(event.validationURL)
				.then((response: any) => {
					session.completeMerchantValidation(response.data);
				}).catch((e) => {
					console.log({ e })
					session.abort();
				});
		}
	}

	/**
	 * Send Apple Pay token to server side to process the payment.
	 *
	 * @param {any} applePayToken
	 */
	payWithToken(applePayToken: any) {
		const payload = {
			token: applePayToken,
			amount: this.amount,
			currency: this.currency,
			customerName: this.customerName,
			customerEmail: this.customerEmail,
			orderID: this.orderID,
			restaurantID: this.restaurantID,
		}

		return this.post('/stores/applepay/pay', payload, 'Could not perform debit')
	}

	/**
	 * Apple Pay 'onpaymentauthorized' handler.
	 *
	 * @param {any} resolve
	 * @param {any} reject
	 * @param {ApplePaySession} session
	 */
	handlePaymentAuthorized(resolve: any, reject: any, session: ApplePaySession) {
		return (event: ApplePayJS.ApplePayPaymentAuthorizedEvent) => {
			this.payWithToken(event.payment.token)
				.then((response: any) => {
					if (response.outcome == 0) {
						// @ts-ignore
						session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
						resolve(PaymentStatus.SUCCESS);
					} else {
						// @ts-ignore
						session.completePayment(window.ApplePaySession.STATUS_FAILURE);
						resolve(PaymentStatus.FAILURE);
					}
				}).catch((e) => {
					console.log({ e })
					// @ts-ignore
					session.completePayment(window.ApplePaySession.STATUS_FAILURE);
					reject(e);
				});
		}
	}

	/**
	 * Apple Pay 'oncancel' handler.
	 *
	 * @param {any} resolve
	 */
	handleCancel(resolve: any) {
		return () => {
			resolve(PaymentStatus.CANCEL);
		}
	}

	/**
	 * Initiate new Apple Pay session and perform the payment process.
	 */
	performPayment() {
		return new Promise((resolve, reject) => {
			// @ts-ignore
			const session = new window.ApplePaySession(APPLE_PAY_VERSION, this.getPaymentRequestPayload());

			console.log({ session })

			session.onvalidatemerchant = this.handleValidateMerchant(session);
			session.onpaymentauthorized = this.handlePaymentAuthorized(resolve, reject, session);
			session.oncancel = this.handleCancel(resolve);

			session.begin();
		});
	}

	/**
	 * Make a post request to the API server.
	 *
	 * @param {string} path
	 * @param {object} data
	 * @param {string} message
	 */
	post(path: string, data: object, message: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: config.api_url + path,
				data: data,
				headers: { 'Content-Type': 'application/json' }
			}).then((response: any) => {
				if (response.data.outcome == 0) {
					resolve(response.data);
				} else {
					reject(new Error(message));
				}
			}).catch((error) => {
				console.log({ error })
				reject(error);
			});
		});
	}
}
