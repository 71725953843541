import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { Dish } from "../item";
import { Button } from "@lib/components";
import {
	Circle,
	CircleWrapper,
	Content,
	DishGrid,
	Wrapper,
} from "./components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";

interface Props extends WithTranslation {}

@inject("store")
@observer
class OneGridLayoutClass extends MobxComponent<Props> {

	render() {
		const { store, t } = this.injected;
		const { restaurant } = store;
		const { categories, tagsApplied } = store.dishes;
		const dishes = store.dishes.dishes;
		const ws = store.restaurant.website.sections;
		const menu = store.menu;

		const doesMenuHaveRestrictions = store.doesMenuHaveRestrictions;
		const menuAvailability = store.isMenuAvailable;
		const doesCategoryHaveRestrictions = store.doesFiltersCategoryHaveRestrictions;
		const categoryAvailability = store.isFilterCategoryAvailable;

		return (
			<Wrapper id="dish-grid" className="">
				<CircleWrapper>
					<Circle />
				</CircleWrapper>

				{!menu && (
					<Content>
						<div className="m-t-6 p-2 error-bg max400 text-center center round">
							<p className="">
								{t("store.sections.menus.info.no_menus")}
							</p>
						</div>
					</Content>
				)}

				{menu && (
					<Content>
						{menu.description && (
							<div
								className={cn(
									"text-center",
									doesMenuHaveRestrictions ? "m-b-4" : "m-b-8"
								)}
							>
								<p className="lhp">{menu.description}</p>
							</div>
						)}

						<div className="m-b-8 text-center">
							{doesMenuHaveRestrictions && (
								<Button
									size="xs"
									color="primary-inverse"
									className="m-1"
									onClick={() =>
										store.modal.toggle("menu-restrictions")
									}
								>
									{menuAvailability.available &&
										t(
											"store.sections.menus.info.restrictions"
										)}
									{!menuAvailability.available &&
										t(
											"store.sections.menus.info.not_available"
										)}
								</Button>
							)}

							{doesCategoryHaveRestrictions && (
								<Button
									size="xs"
									color="primary-inverse"
									className="m-1"
									onClick={() =>
										store.modal.toggle(
											"category-restrictions"
										)
									}
								>
									{categoryAvailability.available &&
										t(
											"store.sections.category.restrictions"
										)}
									{!categoryAvailability.available &&
										t(
											"store.sections.category.not_available"
										)}
								</Button>
							)}
						</div>

						<DishGrid
							dishStyle={ws.menus.dish_style}
							tagsApplied={tagsApplied}
						>
							{dishes.map((d, i) => {
								const tags = restaurant.dish_tags.filter(
									(tag) => d.tags.indexOf(tag._id) !== -1
								);
								const category = categories[i];
								return (
									<Dish
										key={d._id + i + d.name}
										index={i}
										dish={d}
										category={category}
										tags={tags}
										className="col"
									/>
								);
							})}
						</DishGrid>
					</Content>
				)}
			</Wrapper>
		);
	}
}

export const OneGridLayout = withTranslation()(OneGridLayoutClass);
