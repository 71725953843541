import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, Button } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

interface Props extends WithTranslation { }

@inject("store") @observer
class ResellerPortalBackModalClass extends MobxComponent<Props> {

	render() {
		const { store, t } = this.injected;
		const { modal } = store;
		return (
			<Modal
				id="modal-reseller-portal-back"
				width="sm"
				closeButton={true}
				active={modal.isVisible("reseller-portal-back")}
				close={() => modal.hide("reseller-portal-back")}>
				<ModalContent>
					<h4>{t("store.modals.portal_go_back.title")}</h4>
				</ModalContent>
				<ModalContent>
					<p className="lhp">{t("store.modals.portal_go_back.description")}</p>
				</ModalContent>
				<ModalContent className="text-right" paddingtb={15}>
					<Button className="m-r-2" onClick={() => modal.hide("order-restart")}>
						<p>{t("store.modals.portal_go_back.cancel")}</p>
					</Button>
					<Button color="primary" onClick={() => {
						store.loader.update({
							title: "",
							active: true,
							opacity: 0.75,
							message: "",
						});
						window.location.href = store.view.s.reseller_portal_url;
					}}>
						<p>{t("store.modals.portal_go_back.proceed")}</p>
					</Button>
				</ModalContent>
			</Modal>
		);
	}

}

export const ResellerPortalBackModal = withTranslation()(ResellerPortalBackModalClass);
