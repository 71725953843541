import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, FormGroup, List, ListItemBig, RestaurantOpeningHours } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

export type AvailabilityCheck = {
	available: boolean;
	reason: string;
};
interface Props extends WithTranslation {

}
interface State {
	menu: T.Schema.Restaurant.Menu.RestaurantMenu | undefined,
	menuAvailable: AvailabilityCheck
}

@inject("store") @observer
class MenuRestrictionsModalClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			menu: this.injected.store.menu,
			menuAvailable: this.injected.store.isMenuAvailable
		};
	}

	componentDidUpdate(): void {
		if (this.injected.store.menuSearch?.s.chosen_menu_id && this.injected.store.menuSearch?.s.chosen_menu_id !== this.state.menu?._id) {
			this.setState({
				menu: this.injected.store.menuSearch?.s.searchMenu,
				//@ts-ignore
				menuAvailable: this.injected.store.menuSearch?.s.searchMenuAvailable
			})
		} else if (!this.injected.store.menuSearch?.s.chosen_menu_id && (this.state.menu?._id !== this.injected.store.menu?._id)) {

			this.setState({
				menu: this.injected.store.menu,
				menuAvailable: this.injected.store.isMenuAvailable
			})

		}

	}
	render() {
		const { store, t } = this.injected;
		const { menuAvailable, menu } = this.state;
		const { modal } = store;

		if (!this.state.menu)
			return null;

		const { reason }: any = menuAvailable
		const conditions = menu && menu.conditions;
		const r = store.restaurant;
		const c = store.customer.s.item;
		const customerAgeVerified = (c && c.age_verification.status === "approved");

		return (
			<Modal
				id="modal-menu-restrictions"
				width={360}
				closeButton={true}
				active={modal.isVisible("menu-restrictions")}
				close={() => modal.hide("menu-restrictions")}>

				<ModalContent paddingtb={25} paddinglr={25}>

					{reason && <FormGroup error={t(`store.sections.menus.info.unavailable.${reason}`)} />}

					{menu && (r.settings.business.age_verification.enabled && menu.conditions.age_restricted) && (
						<FormGroup title={t("store.sections.menus.info.age_restricted.title")}>
							{customerAgeVerified && <p className="lhp">{t("store.sections.menus.info.age_restricted.approved")}</p>}
							{!customerAgeVerified && <p className="lhp">{t("store.sections.menus.info.age_restricted.rejected")}</p>}
						</FormGroup>
					)}

					{(conditions && (conditions.services.length > 0 || conditions.times.length === 1)) && (
						<FormGroup title={t("store.sections.menus.info.services.title")}>
							<List>
								{conditions.times.length === 1 && <ListItemBig>{t(`store.sections.menus.info.times.${conditions.times[0]}`)}</ListItemBig>}
								{conditions.services.map((s, i) => (
									<ListItemBig key={i}>{t(`store.sections.menus.info.services.${s}`)}</ListItemBig>
								))}
							</List>
						</FormGroup>
					)}

					{conditions && conditions.pre_order.enabled && (
						<FormGroup title={t("store.sections.menus.info.preorder.title")}>
							<List>
								<ListItemBig>{t("store.sections.menus.info.preorder.days_in_advance", { days: conditions.pre_order.days_in_advance })}</ListItemBig>
								{conditions.pre_order.cutoff_time && <ListItemBig>{t("store.sections.menus.info.preorder.cutoff_time", { time: conditions.pre_order.cutoff_time })}</ListItemBig>}
							</List>
						</FormGroup>
					)}

					{conditions && conditions.hours.length > 0 && (
						<FormGroup title={t("store.sections.menus.info.hours.title")}>
							<RestaurantOpeningHours hours={conditions.hours} />
						</FormGroup>
					)}

				</ModalContent>
			</Modal>
		);
	}

}

export const MenuRestrictionsModal = withTranslation()(MenuRestrictionsModalClass);
