import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

interface Props extends WithTranslation {}

@inject("store")
@observer
class PoliciesAndAgreementsModalClass extends MobxComponent<Props> {
	render() {
		const { modal, restaurant } = this.injected.store;

		const currentModalDisplay = modal.currentModalDisplay;

		if (!["terms", "privacy", "cookie"].includes(currentModalDisplay))
			return null;

		let htmlPoliciesAndAgreementsRender;

		switch (currentModalDisplay) {
			case "terms":
			case "privacy":
				htmlPoliciesAndAgreementsRender =
					restaurant.settings[
						currentModalDisplay as T.Schema.Restaurant.PoliciesAndAgreements
					] as string;
				break;

			case "cookie":
				htmlPoliciesAndAgreementsRender =
					restaurant.settings.cookie?.content;
				break;
		}

		return (
			!!htmlPoliciesAndAgreementsRender && (
				<Modal
					id={`modal-terms-and-conditions-${currentModalDisplay}`}
					width="md"
					closeButton={true}
					active={!!htmlPoliciesAndAgreementsRender}
					close={() => modal.close()}
				>
					<ModalContent paddingtb={25} paddinglr={30}>
						<div
							className="ql-content"
							dangerouslySetInnerHTML={{
								__html: htmlPoliciesAndAgreementsRender,
							}}
						/>
					</ModalContent>
				</Modal>
			)
		);
	}
}

export const PoliciesAndAgreementsModal = withTranslation()(
	PoliciesAndAgreementsModalClass
);
