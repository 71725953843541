import * as React from 'react';
import { styled, shadeAlternate } from '@lib/components';
import ApplePayLogo, { GooglePayLogo } from './apple-pay-logo';

export const PaymentButtonWrapper = styled('div')``;

const Btn = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  border: 1px solid ${({ theme }) => theme.box.border};
  color: ${({ theme }) => theme.box.text};
  transition: 0.23s all;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size}px;

  > .check-circle {
    transition: 0.23s all;
    border: 2px solid ${({ theme }) => shadeAlternate(0.1, theme.box.border)};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    &:after {
      content: ' ';
    }
  }

  &:hover,
  &.active {
    transform: scale(1.015);
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    > .check-circle {
      border: 1px solid ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.primary};
      width: 14px;
      height: 14px;
    }
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const PaymentButton = (props: { active: boolean; title: string; applepay: boolean; googlePay: boolean; onClick: () => void }) => (
  <Btn className={props.active ? 'active' : ''} onClick={props.onClick}>
    <span className="check-circle" />
    <span className="text" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <span style={{ marginLeft: '5px' }}>{props.googlePay && <GooglePayLogo />}</span>
      <span style={{ marginLeft: '5px' }}>{props.applepay && <ApplePayLogo />}</span>
      <span style={{ marginLeft: '10px' }}>{props.title}</span>
    </span>
  </Btn>
);

export const PaymentImageWrapper = styled('div')`
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  row-gap: 1rem;
  & > div {
    align-self: center;
    align-self: center;
  }
`;

export const PaymentImage = styled('div')`
  text-align: center;
  img {
    width: 100%;
    max-width: 45px;
  }
`;
