import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { RotateLoader, FormGroup, Input, Button, LinkTag } from "@lib/components";
import { FastField, Form, Formik, FormikActions } from "formik";
import { Untrusive } from "@lib/common";
import { logger, cloneDeepSafe, CoreUtils, DataCurrencies } from "@lib/common";
import { StripeCardForm } from "../checkout/methods/stripe";
import { withTheme } from "styled-components";

interface Props extends WithTranslation { }
interface State {
	mounted: boolean;
	stripeError: string;
	error: string;
	success: boolean;
}

type FormValues = T.API.StoresCustomPaymentRequest;

const initialValues: FormValues = {
	name: "",
	email: "",
	reference: "",
	amount: "",
	token: "",
};

@inject("store") @observer
class CustomPaymentModalClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			mounted: false,
			stripeError: "",
			error: "",
			success: false,
		};
	}

	componentDidMount() {
		this.setState({ mounted: true });
		const root = document.getElementById("root")!;
		root.style.overflowY = "auto";
	}

	componentWillUnmount() {
		const root = document.getElementById("root")!;
		root.style.overflowY = "hidden";
	}

	submit = async (v: FormValues, form: FormikActions<FormValues>) => {
		const stop = () => {
			Untrusive.stop();
			form.setSubmitting(false);
		};
		const error = (message: string, e?: any) => {
			stop();
			this.setState({ error: message });
			if (e) {
				logger.captureException(e);
			}
		};
		try {

			const { store } = this.injected;
			const method = store.restaurant.settings.payments.stripe;

			v = cloneDeepSafe(v);

			if (v.amount) {
				const precision = DataCurrencies[method!.currency].decimal_digits;
				v.amount = CoreUtils.currency.decimalToCents(v.amount, precision);
			}

			Untrusive.start();
			form.setSubmitting(true);
			this.setState({ error: "" });

			const stripe = window.stripe!;
			const stripeCard = window.stripeCard!;

			const { paymentMethod, error: errorPayment } = await stripe.createPaymentMethod({
				type: 'card',
				card: stripeCard,
				billing_details: {
					name: v.name,
					email: v.email,
				}
			});

			if (errorPayment) {
				console.log(errorPayment);
				return error("payment_fail");
			}

			v.paymentMethodId = paymentMethod!.id;

			const response = await store.api.custom_payment(v);

			if (response.outcome) {

				if (response.message !== "stripe_requires_action") {
					return error(response.message);
				}

				let intent;
				let errorAction;
				if (v.amount) {
					const result = await stripe.handleCardAction(response.intent_client_secret!);
					intent = result.paymentIntent;
					errorAction = result.error;
				}
				else {
					const result = await stripe.confirmCardSetup(response.intent_client_secret!, {});
					intent = result.setupIntent;
					errorAction = result.error;
				}

				if (errorAction) {
					console.log(errorAction);
					return error("payment_fail");
				}

				v.intentId = intent!.id;

				const secondResponse = await store.api.custom_payment(v);
				if (secondResponse.outcome) {
					return error(secondResponse.message);
				}

			}

			this.setState({ success: true });

		}
		catch (e) {
			error("generic", e);
		}
		finally {
			stop();
		}
	}

	render() {

		const { error, success } = this.state;
		const { mounted } = this.state;
		const { store, t } = this.injected;

		const r = store.restaurant;
		const { region } = r.settings;
		const { stripe } = r.settings.payments;
		const { images } = r.website.sections.top_nav;

		if (!stripe || !stripe.enabled || !stripe.custom_payment_email) {
			return (
				<div className="flex-center m-t-6" style={{ minHeight: "80vh" }}>
					<div className="width100 p-5 text-center" style={{ maxWidth: "500px" }}>
						<h3 className="">{t("store.modals.custom_payment.title", { name: r.name })}</h3>
						<p className="m-tb-3 lhp-lg">{t("store.modals.custom_payment.disabled")}</p>
						<p className="small"><LinkTag href="/">{t("store.modals.custom_payment.back")}</LinkTag></p>
					</div>
				</div>
			);
		}

		const differentCurrency = region.currency.code !== stripe.currency;

		const logo = !images.logo ? null : (
			<div className="m-b-4">
				<img
					style={{ maxHeight: "60px" }}
					alt="Business logo"
					src={CoreUtils.image.uc(images.logo, { resize: "x60" })!}
				/>
			</div>
		);

		return (
			<div className="flex-center m-t-6" style={{ minHeight: "80vh" }}>

				<div className="width100 p-5" style={{ maxWidth: "500px" }}>

					<>
						{!mounted && (
							<RotateLoader size={3} />
						)}

						{mounted && (
							<>

								<FormGroup contentClassName="text-center">
									{logo}
									<h3 className="">{t("store.modals.custom_payment.title", { name: r.name })}</h3>
									<p className="m-t-2 lhp">{t("store.modals.custom_payment.description")}</p>
									{differentCurrency && (
										<p className="font-semi-bold small m-t-2 italic">
											{t("store.modals.custom_payment.warning.currency", { actual: stripe.currency, default: region.currency.code })}
										</p>
									)}
								</FormGroup>

								{success && (
									<FormGroup contentClassName="text-center">
										<p className="big success-text lhp-lg">{t("store.modals.custom_payment.success")}</p>
									</FormGroup>
								)}

								{!success && (
									<Formik<FormValues>
										initialValues={initialValues}
										onSubmit={this.submit}>
										{(form) => {
											const { isSubmitting, submitCount } = form;
											const showFormError = (submitCount > 0 && this.state.error);
											return (
												<Form>

													<FormGroup>
														<FastField
															name="name"
															render={({ field }: any) => (
																<div className="m-b-2">
																	<Input
																		{...field}
																		type="text"
																		placeholder={t("store.modals.custom_payment.fields.name")}
																		required={true}
																	/>
																</div>
															)}
														/>

														<FastField
															name="email"
															render={({ field }: any) => (
																<div className="m-b-2">
																	<Input
																		{...field}
																		type="email"
																		placeholder={t("store.modals.custom_payment.fields.email")}
																		required={true}
																	/>
																</div>
															)}
														/>

														<FastField
															name="reference"
															render={({ field }: any) => (
																<div className="m-b-2">
																	<Input
																		{...field}
																		type="text"
																		placeholder={t("store.modals.custom_payment.fields.reference")}
																		required={true}
																	/>
																</div>
															)}
														/>

														<FastField
															name="amount"
															render={({ field }: any) => (
																<div className="m-b-2">
																	<Input
																		{...field}
																		type="number"
																		min="0"
																		step="0.01"
																		placeholder={t("store.modals.custom_payment.fields.amount")}
																	/>
																</div>
															)}
														/>

														<div>
															<StripeCardForm
																error={this.state.stripeError}
																onError={(stripeError) => this.setState({ stripeError })}
																onChange={() => {}}
															/>
														</div>

													</FormGroup>

													{showFormError && (
														<FormGroup
															no_border={true}
															error={t(`store.modals.custom_payment.errors.${error}`)}
														/>
													)}

													<Button full={true} color="primary" type="submit" disabled={isSubmitting}>
														{isSubmitting && <RotateLoader size={2} color={this.injected.theme.colors.primary_text} />}
														{!isSubmitting && t("store.modals.custom_payment.fields.submit")}
													</Button>

												</Form>
											);
										}}
									</Formik>
								)}

							</>
						)}

					</>

				</div>

			</div>
		);
	}

}

// @ts-ignore
export const CustomPaymentModal = withTheme(withTranslation()(CustomPaymentModalClass));
