/// <reference types='@lib/postmates/types' />

import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { PostmatesEstimation } from "./deliveries/postmates-estimation";
import { RestaurantUtils } from "@lib/common";
import { LalamoveEstimation } from "./deliveries/lalamove-estimation";
import { UberEstimation } from "./deliveries/uber-estimation";

interface Props extends WithTranslation { }
interface State { }
@inject("store") @observer
class DeliveryEstimationClass extends MobxComponent<Props, State> {
  provider: T.Schema.DeliveryProvider;

  constructor(props: Props) {
    super(props);
    const restaurant = this.injected.store.restaurant!;
    this.provider = RestaurantUtils.settings.getDefaultDeliveryProvider(restaurant);
  }

  componentDidMount() {
    this.injected.store.order_config.update({ delivery_provider: this.provider });
  }

  render() {
    if (this.provider === "postmates") {
      return <PostmatesEstimation />;
    }

    if (this.provider === "lalamove") {
      return <LalamoveEstimation />;
    }

    if (this.provider === 'uber') {
      return <UberEstimation />
    }


    return (<p className="small">Delivery estimation is not available.</p>)
  }
}

export const DeliveryEstimation = withTranslation()(DeliveryEstimationClass);
