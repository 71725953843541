import * as React from "react";
import { styled, shadeAlternate, DotsLoader, withTheme } from "@lib/components";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/component";
import { CategoryNavBar } from "../../category-nav-bar";
import { SliderMenuBar } from "./slider";

const Wrapper = styled("div")`
	position: relative;
`;

const Content = styled("div")`
	width: 100%;
	height: 100%;
	max-width: ${({ theme }) => theme.content_width.lg}px;
	margin: 0 auto;
`;

@inject("store")
@observer
class SliderMenuClass extends MobxComponent<{}, { mounted: boolean }> {
	state = {
		mounted: false,
	};

	componentDidMount() {
		this.setState({ mounted: true });
	}

	render() {
		const { theme } = this.injected;

		return (
			<Wrapper>
				{!this.state.mounted && (
					<DotsLoader
						size={10}
						style={{ height: "43px" }}
						className="flex-center"
						color={theme.top_nav.text_color}
					/>
				)}
				{this.state.mounted && (
					<div>
						<Content>
							<SliderMenuBar />
						</Content>
						<Content>
							<CategoryNavBar />
						</Content>
					</div>
				)}
			</Wrapper>
		);
	}
}

export const SliderMenus = withTheme(SliderMenuClass);
