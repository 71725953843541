import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js';
import { toString } from 'lodash';
import { CheckoutForm } from './stripe-digital-wallets/checkout-form';
import { observer } from 'mobx-react';
import { useStores } from '../../../mobx/useStores';
import { RotateLoader } from '@lib/components';

export const StripePaymentButton = observer(() => {
  const store = useStores();

  const getStripeInstance = () => {
    const value = store.checkout.getStripeConnectStatus();
    return value === 'CONNECT' ? window.stripeConnect : window.stripe;
  };

  const stripeInstance = getStripeInstance();

  const restaurantId = store.restaurant._id;
  const currency = toString(store.intl.s.currency.code).toLowerCase();
  const amount = Math.round((store.cart.total + Number.EPSILON) * 100) / 100;
  const amountSubunit = Math.round((store.cart.total + Number.EPSILON) * (currency === 'jpy' ? 1 : 100));
  const locale = store.restaurant.settings.region.locale.toLowerCase() as StripeElementLocale;

  return (
    <>
      {stripeInstance ? (
        <Elements stripe={stripeInstance} options={{ locale }}>
          <CheckoutForm amount={amount} amountSubunit={amountSubunit} currency={currency} restaurantId={restaurantId} />
        </Elements>
      ) : (
        <RotateLoader size={3} />
      )}
    </>
  );
});
