import { GoogleLogin } from "@react-oauth/google"
import React from "react"
import { RootStore } from "../../../mobx/store"

type CwCustomerGoogleLoginButtonTypes = {
    store: RootStore
}

export function CwLoginButton({ store }: CwCustomerGoogleLoginButtonTypes) {
    return (
        <GoogleLogin
            //@ts-ignore
            width={320}
            size="large"
            onSuccess={tokenResponse => {
                if (tokenResponse) {
                    store.api.customerLoginNewGoogle({
                        clientId: tokenResponse?.clientId || "",
                        credential: tokenResponse?.credential || ""
                    }).then(response => {
                        if (response.outcome == 0) {
                            store.customer.handleSocialLogin(response.customer)
                        } else {
                            store.customer.updateLogin({
                                submitting: false,
                                error: "error_generic",
                            });
                        }
                    })
                } else {
                    store.customer.updateLogin({
                        submitting: false,
                        error: "error_generic",
                    });
                };
            }}
            onError={() => {
                store.customer.updateLogin({
                    submitting: false,
                    error: "error_generic",
                });
            }}
        />
    )

}