import * as React from 'react';
import _flatten from 'lodash/flatten';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../../mobx/component';
import { CheckoutDetails } from './details';
import { CheckoutCustomer } from './customer';
import { CheckoutPayment } from './payment';
import { CheckoutButton } from './button';
import { FaExclamationCircle, FaChevronCircleLeft } from 'react-icons/fa';
import { Button, Checkbox, Modal, ModalTitle, ModalContent, styled } from '@lib/components';
import ApplePayButton from './apple-pay-button';
import ApplePayProcessor from './apple-pay-processor';
import { PaymentStatus } from './apple-pay-processor';
import _toString from 'lodash/toString';
import { toJS } from 'mobx';
import { RotateLoader } from '@lib/components';
import { DeliveryEstimation } from './delivery-estimation';
import GooglePayButton from './google-pay-button';
import { StripePaymentButton } from './stripe-payment-button';
import RazorPayButton from './razor-pay/razor-pay-button';
import { BancontactButton } from './bancontact/bancontact-button';
import { RestaurantUtils } from '@lib/common';
import { filterPaymentMethods } from './util';

interface Props extends WithTranslation {}
interface State {
  applePayProcessing: boolean;
}

const PlaceOrderWrapper = styled('div')<{ isMobileFullScreen?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    background-color: ${theme => theme.theme.box.background};
    position: ${props => (props.isMobileFullScreen ? 'fixed' : 'relative')};
    bottom: 0;
    left: 0;
    right: 0;
    & > div {
      padding: 0;
    }

    & button {
      border-radius: 0;
    }
  }
`;
const ValidateCheckOutWrapper = styled('div')<{ isMobileFullScreen?: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    & > div {
      background-color: ${theme => theme.theme.box.background};
      position: ${props => (props.isMobileFullScreen ? 'fixed' : 'relative')};
      bottom: 40px;
      left: 0;
      right: 0;
    }
  }
`;

@inject('store')
@observer
class CheckoutModalClass extends MobxComponent<Props, State> {
  checkoutErrorRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      applePayProcessing: false,
    };
    this.checkoutErrorRef = React.createRef();
  }

  scrollToErrorSection() {
    const { store } = this.injected;
    const element = this.checkoutErrorRef?.current;
    if (store.checkout.s.error && element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async componentDidMount() {
    const getCustomerOrder = localStorage.getItem("save_customer_ordered")
    const customerOrder = getCustomerOrder ? JSON.parse(getCustomerOrder) : null;
    if(!!customerOrder){
      await this.handleCheckingOrder()
    }
    setTimeout(() => this.scrollToErrorSection(), 0);
  }

  componentDidUpdate() {
    this.scrollToErrorSection();
  }

  handleCheckingOrder = async () => {
    const { store } = this.injected;
    const initial_order_id = store.order.getGeneratedOrderId();
    const orderChecking = await store.api.order_check({ _id: initial_order_id, isPostHookRequire: true});
    const { order, customer }: any = orderChecking;
    if(orderChecking && order && order._id === initial_order_id && order.status){
      if(order.status === "draft"){
        store.modal.isVisible('checkout');
      }else{
        await store.checkout.order_complete_v2({order: order, customer: customer});
      }
    }
  }

  getMerchantDetailsForApplePay() {
    const { store } = this.injected;
    const restaurant = store.restaurant;

    let merchantID = '';
    let merchantName = '';
    let merchantDomain = '';
    const paymentMethod = store.checkout.s.payment;

    if (paymentMethod === 'checkout_apple_pay') {
      const settings = restaurant.settings.payments.checkout_apple_pay!;
      merchantID = _toString(settings.apple_pay_merchant_id);
      merchantName = _toString(settings.apple_pay_merchant_name);
      merchantDomain = _toString(settings.apple_pay_merchant_domain);
    }

    return {
      merchantID,
      merchantName,
      merchantDomain,
    };
  }

  getMerchantDetailsForGooglePay() {
    const { store } = this.injected;
    const restaurant = store.restaurant;

    let merchantID = '';
    let merchantName = '';
    let gatewayMerchantId = '';

    let totalPriceLabel = 'Total';
    let currencyCode = _toString(store.intl.s.currency.code);
    let totalPrice = Math.round((store.cart.total + Number.EPSILON) * 100) / 100;
    const paymentMethod = store.checkout.s.payment;
    let countryCode = RestaurantUtils.settings.getCountryCodeFromLocation(restaurant);
    if (!countryCode) {
      store.checkout.update({
        error: 'Cannot get the country code from restaurant location.',
      });
    }

    if (paymentMethod === 'checkout_google_pay') {
      const settings = restaurant.settings.payments.checkout_google_pay!;
      merchantID = _toString(settings.google_pay_merchant_id);
      merchantName = _toString(settings.google_pay_merchant_name);
      gatewayMerchantId = _toString(settings.checkout_public_key);
    }

    let label = store.restaurant.name;
    let customerName = store.checkout.s.name;
    let customerEmail = store.checkout.s.email;
    let restaurantID = restaurant._id;
    let items = toJS(store.cart.s.items);

    return {
      merchantID,
      merchantName,
      totalPriceLabel,
      totalPrice,
      currencyCode,
      countryCode,
      gatewayMerchantId,
      label,
      customerName,
      customerEmail,
      restaurantID,
      items,
    };
  }

  handleApplePayButtonClicked = async (e: any) => {
    e.preventDefault();

    const { store } = this.injected;
    const restaurant = store.restaurant;

    const orderDataError = store.checkout.validateOrderData();
    if (orderDataError) {
      return;
    }

    this.setState({ applePayProcessing: true });

    const items = toJS(store.cart.s.items);

    store.checkout.update({ error: '' });

    const countryCode = RestaurantUtils.settings.getCountryCodeFromLocation(restaurant);
    if (!countryCode) {
      store.checkout.update({
        error: 'Cannot get the country code from restaurant location.',
      });
      return;
    }

    const { merchantID, merchantName, merchantDomain } = this.getMerchantDetailsForApplePay();

    const data = {
      merchantID,
      merchantName,
      merchantDomain,
      currency: _toString(store.intl.s.currency.code),
      label: store.restaurant.name,
      amount: Math.round((store.cart.total + Number.EPSILON) * 100) / 100,
      countryCode,
      customerName: store.checkout.s.name,
      customerEmail: store.checkout.s.email,
      restaurantID: restaurant._id,
      items,
    };

    console.log('initial apple pay payment data:', data);

    const processor = new ApplePayProcessor(data);

    try {
      const status = (await processor.performPayment()) as PaymentStatus;
      switch (status) {
        case PaymentStatus.CANCEL:
          store.checkout.update({
            error: 'The Apple Pay payment was cancelled.',
          });
          break;
        case PaymentStatus.SUCCESS:
          try {
            const order = await store.checkout.order_commence(e);
            if (!order) {
              store.checkout.update({ error: 'Failed to create the order' });
              return;
            }

            this.setState({ applePayProcessing: false });

            store.router.push(`/order/${order._id}`);
          } catch (e) {
            this.setState({ applePayProcessing: false });
            return;
          }
          break;
        default:
          this.setState({ applePayProcessing: false });
          store.checkout.update({
            error: 'Failed to process the payment using Apple Pay',
          });
      }
    } catch (e) {
      console.error('cannot start apple pay payment:', e);
      this.setState({ applePayProcessing: false });
      store.checkout.update({
        error: 'Failed to process the payment using Apple Pay',
      });
    }
  };

  getOrderPaymentButton() {
    const store = this.injected.store;
    const checkout = store.checkout.s;

    if (checkout.using_applepay) {
      let { merchantID } = this.getMerchantDetailsForApplePay();
      return (
        <div>
          <ApplePayButton merchantID={merchantID} onClick={this.handleApplePayButtonClicked} />
          {this.state.applePayProcessing && <RotateLoader size={3} color="#111111" style={{ marginTop: '0.5rem' }} />}
        </div>
      );
    }

    if (checkout.using_googlepay) {
      return (
        <div>
          <GooglePayButton {...this.getMerchantDetailsForGooglePay()} />
        </div>
      );
    }

    if (checkout.using_razor_pay) return <RazorPayButton />;
    if (checkout.using_bancontact) return <BancontactButton />;
    if (checkout.using_stripe_digital_wallet) return <StripePaymentButton />;

    const paymentMethods = filterPaymentMethods(store);
    if (paymentMethods.length > 0) return <CheckoutButton />;

    return <span>No payment methods available.</span>;
  }

  render() {
    const { store, t } = this.injected;
    const { isLoggedIn } = store.customer;
    const { termsActive } = store;
    const orderConfig = store.order_config;
    const isValidCustomer =
      store.checkout.s.name.trim() != '' && store.checkout.s.email.trim() != '' && store.checkout.s.phone.length > 5;
    const requiredCustomerInfo = store.checkout.requiredCustomerInfo;
    const isFullScreen = store.restaurant.website.sections?.mobiles?.mobile_fullscreen;
    const filteredPaymentMethods = filterPaymentMethods(store);
    const orderButton = this.getOrderPaymentButton();
    const stripeVersion = store.restaurant.settings.payments.stripe?.stripe_version;
    const handleSubmitForm = (store.checkout.s.payment === 'stripe' && stripeVersion === 'v2' || store.checkout.s.payment === 'bambora_na') ? store.checkout.order_commence_v2 : store.checkout.order_commence;
    return (
      <Modal
        id="checkout-modal"
        width={520}
        closeButton={true}
        active={store.modal.isVisible('checkout')}
        close={() => {
          store.modal.back();
          store.checkout.s.subPayment = undefined;
        }}
        isFullScreen={isFullScreen}
        preventClose={store.checkout.s.loading}
      >
        <form onSubmit={handleSubmitForm} className={store.checkout.s.loading && !store.checkout.s.paypal_show ? 'bg-disabled': ''}>
          <ModalTitle paddinglr={25} paddingtb={20} className="round-top">
            <div className="flex-line centered">
              <h4 className="cursor" style={{ padding: '10px 20px 10px 0' }} onClick={() => store.modal.back()}>
                <FaChevronCircleLeft />
              </h4>
              <div>
                <h4>{t('store.modals.checkout.title')}</h4>
                <p className="small m-t-1">{t('store.modals.checkout.subtitle')}</p>
              </div>
            </div>
          </ModalTitle>

          <ModalContent paddinglr={25} paddingtb={25}>
            <CheckoutDetails />
          </ModalContent>

          {orderConfig.s.service === 'dine_in' && requiredCustomerInfo && (
            <>
              <ModalTitle paddinglr={25} paddingtb={20} className="flex-l-r-center">
                <div>
                  <h4>{t('store.modals.checkout.customer.title')}</h4>
                  {!isLoggedIn && <p className="small m-t-1">{t('store.modals.checkout.customer.subtitle')}</p>}
                </div>
                {!isLoggedIn && (
                  <Button type="button" size="xs" color="primary-inverse" onClick={() => store.modal.show('auth')}>
                    {t('store.modals.checkout.customer.button')}
                  </Button>
                )}
              </ModalTitle>
            </>
          )}

          <ModalContent paddinglr={25} paddingtb={25}>
            <CheckoutCustomer />
          </ModalContent>

          {orderConfig.s.service === 'delivery' && store.checkout.delivery_provider === 'postmates' && (
            <>
              <ModalTitle paddinglr={25} paddingtb={20}>
                <h4>{t('store.modals.checkout.delivery_estimation.title')}</h4>
              </ModalTitle>

              <ModalContent paddinglr={25} paddingtb={25}>
                <DeliveryEstimation />
              </ModalContent>
            </>
          )}

          {orderConfig.s.service === 'delivery' &&
            store.checkout.delivery_provider === 'lalamove' &&
            isValidCustomer && (
              <>
                <ModalTitle paddinglr={25} paddingtb={20}>
                  <h4>{t('store.modals.checkout.delivery_estimation.title')}</h4>
                </ModalTitle>

                <ModalContent paddinglr={25} paddingtb={25}>
                  <DeliveryEstimation />
                </ModalContent>
              </>
            )}

          {orderConfig.s.service === 'delivery' &&
            store.checkout.delivery_provider === 'uber' &&
            store.restaurant.settings.services.delivery.providers.uber?.enabled &&
            isValidCustomer && (
              <>
                <ModalTitle paddinglr={25} paddingtb={20}>
                  <h4>{t('store.modals.checkout.delivery_estimation.title')}</h4>
                </ModalTitle>

                <ModalContent paddinglr={25} paddingtb={25}>
                  <DeliveryEstimation />
                </ModalContent>
              </>
            )}

          {orderConfig.s.service === 'delivery' && store.checkout.delivery_provider === 'uber' ? (
            filteredPaymentMethods.length > 0 &&
            !orderConfig.s.uber_error &&
            !orderConfig.s.uber_delivery_error &&
            orderConfig.s.uber_quotation_id && (
              <>
                <ModalTitle paddinglr={25} paddingtb={20}>
                  <h4>{t('store.modals.checkout.payment.title')}</h4>
                </ModalTitle>
                <ModalContent paddinglr={25} paddingtb={25}>
                  <CheckoutPayment />
                </ModalContent>
              </>
            )
          ) : (
            <>
              <ModalTitle paddinglr={25} paddingtb={20}>
                <h4>{t('store.modals.checkout.payment.title')}</h4>
              </ModalTitle>

              {filteredPaymentMethods.length > 0 && (
                <ModalContent paddinglr={25} paddingtb={25}>
                  <CheckoutPayment />
                </ModalContent>
              )}
            </>
          )}

          {termsActive && filteredPaymentMethods.length > 0 && (
            <ModalContent className="m-b-4" paddinglr={25} paddingtb={15}>
              <div className="flex-center">
                <Checkbox
                  id="accept-terms-checkout"
                  name="accept_terms"
                  checked={store.checkout.s.terms}
                  onChange={e => store.checkout.update({ terms: e.target.checked, error: '' })}
                  label={
                    <p className="inline-block small">
                      {t('store.modals.checkout.accept_terms')} -{' '}
                      <a className="link" onClick={() => store.modal.show('terms')}>
                        {t('store.modals.checkout.accept_terms_view')}
                      </a>
                    </p>
                  }
                />
              </div>
            </ModalContent>
          )}

          {store.checkout.s.error && (
            <ValidateCheckOutWrapper isMobileFullScreen={isFullScreen}>
              <ModalContent className="error-bg text-center" paddinglr={25} paddingtb={12}>
                <p className="small lhp flex-line centered" ref={this.checkoutErrorRef}>
                  <FaExclamationCircle className="m-r-2" />
                  {store.checkout.s.error.indexOf(' ') !== -1
                    ? store.checkout.s.error
                    : t(`store.modals.checkout.errors.${store.checkout.s.error}`)}
                  {store.checkout.s.error_details ? ' ' + store.checkout.s.error_details : ''}
                </p>
              </ModalContent>
            </ValidateCheckOutWrapper>
          )}

          {orderConfig.s.service === 'delivery' &&
          store.checkout.delivery_provider === 'uber' &&
          store.restaurant.settings.services.delivery.providers.uber?.enabled &&
          (new Date().getTime() >= new Date(orderConfig.s.uber_quote_expire!).getTime() ||
            !isValidCustomer ||
            orderConfig.s.uber_error != '' ||
            orderConfig.s.uber_quotation_id == '') ? null : (
            <PlaceOrderWrapper isMobileFullScreen={isFullScreen}>
              <ModalContent paddinglr={25} paddingtb={25}>
                {orderButton}
              </ModalContent>
            </PlaceOrderWrapper>
          )}
        </form>
      </Modal>
    );
  }
}

export const CheckoutModal = withTranslation()(CheckoutModalClass);
