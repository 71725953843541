require('./init');
import 'react-hot-loader';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { RootStore } from '../common/mobx/store';
import App from '../common/ui/index';
import { Untrusive } from '@lib/common';
import lighten from 'polished/lib/color/lighten';
import * as Sentry from '@sentry/browser';
import { config as commonConfig } from '../config';
import { StripeElementLocale } from '@stripe/stripe-js';

const translation = window.__INITIAL_TRANSLATION__;
const preloadedTheme = window.__INITIAL_THEME__;
const preloadedState = window.__INITIAL_STATE__;
const store = new RootStore(preloadedState);
const state = store.getRootState();

if (translation.lng) {
  store.intl.i18n.addResourceBundle(translation.lng, translation.ns, translation.resources, true, true);
}

Sentry.configureScope(scope => {
  scope.setExtra('restaurant_id', store.restaurant._id);
  scope.setExtra('restaurant_name', store.restaurant.name);
  scope.setExtra('host', window.location.host);
});

Untrusive.init({
  bgColor: lighten(0.2, preloadedTheme.colors.primary),
  barColor: preloadedTheme.colors.primary,
});

function setStripeConnect(commonConfig: any, store: any) {
  if (!window.Stripe) return;
  if (commonConfig.isProduction) {
    if (store.restaurant.reseller_id === 'customcontactsolutions') {
      window.stripeConnect = window.Stripe('pk_live_B0sWpyaw7zFJVZggtliCM7YO'); // CCS Live
    } else if (store.restaurant.reseller_id === 'pushordering') {
      // window.stripeConnect = window.Stripe(
      //   'pk_test_51JqnKnGkpaHMg1BlTHqnS5mkoaDAXUcxtE2u1eIgz1ANuERZnqsVMcNeBBeEzSJjxt8F7MPKmYdAkWDy4ZHQ2veU00KB0wTuog'
      // ); // PushOrdering Test
      window.stripeConnect = window.Stripe(
        'pk_live_51JqnKnGkpaHMg1BlXFSoo06rN4dnNSKqUXK1jIVHHZtD6oSCxjNVmpZLmqBz1CYudqNwKQz31iLGvcgr1G5OVHbp00OFtGcAkS'
      ); // PushOrdering Live
    } else {
      // window.stripeConnect = window.Stripe(
      //   'pk_test_51G30loIQTqOKzvBfUmlY1bVBNKbfZ5Mfsht3crd4c69f34yNjZEanxADknSVVdfYcIQs7Qp1ooKb0hUOHppmdlwJ00Wf0fhNRk'
      // ); // Cloudwaitress Test
      window.stripeConnect = window.Stripe('pk_live_C7H3tzoX2UhBoekwzZ3Zbgim00W76eCnus'); // Ordering Dashboard Live
    }
  } else {
    if (store.restaurant.reseller_id === 'customcontactsolutions') {
      window.stripeConnect = window.Stripe('pk_test_Hsr0UkiqdNA4bF2BSdvuTMqF'); // CCS Test Mode
    } else if (store.restaurant.reseller_id === 'pushordering') {
      window.stripeConnect = window.Stripe(
        'pk_test_51JqnKnGkpaHMg1BlTHqnS5mkoaDAXUcxtE2u1eIgz1ANuERZnqsVMcNeBBeEzSJjxt8F7MPKmYdAkWDy4ZHQ2veU00KB0wTuog'
      ); // PushOrdering Test Mode
    } else {
      window.stripeConnect = window.Stripe(
        'pk_test_51IOE6YLyrXrMLb7s50twEbI55RwU5O89CjUUoWkbM7JIcsExN9qusakFxovFd9gLtdOW8ajJVjEK5U5DKrKaTDIt00yibYR7pS'
      ); // Cloudwaitress Test
      // window.stripeConnect = window.Stripe('pk_test_zrLvcGrIykPDFImfX4q0SVZh00Yy5yC6nB'); // Ordering Dashboard Test Mode
    }
  }
}

function loadStripe() {
  const stripe = store.restaurant.settings.payments.stripe;
  if (stripe && stripe.enabled) {
    const locale = state.restaurant.settings.region.locale as StripeElementLocale;
    if (window.Stripe && !window.stripe) {
      window.stripe = window.Stripe(stripe.publishable_key);
      setStripeConnect(commonConfig, store);
      window.stripeElements = window.stripe!.elements({ locale });
      window.stripeConnectElements = window.stripeConnect!.elements({ locale });
    } else {
      const stripeTimer = setInterval(() => {
        if (window.Stripe && !window.stripe) {
          window.stripe = window.Stripe(stripe.publishable_key);
          setStripeConnect(commonConfig, store);
          window.stripeElements = window.stripe!.elements({ locale });
          window.stripeConnectElements = window.stripeConnect!.elements({ locale });
          clearInterval(stripeTimer);
        }
      }, 100);
    }
  }
}

loadStripe();

function render() {
  const root = document.getElementById('root');
  const { logged_in_only, service_required } = store.restaurant.settings.business;
  // IF LOGGED IN ONLY, USE RENDER TO RERENDER EVERYTHING TO UPDATE LOGIN STATE
  try {
    if (logged_in_only || service_required) {
      ReactDOM.render(<App store={store} theme={preloadedTheme} />, root);
    } else {
      ReactDOM.hydrate(<App store={store} theme={preloadedTheme} />, root);
    }
  } catch (e) {
    console.log({ e });
  }
}

render();

store.restoreSavedSessions();
