import React from 'react';
import ApplePayProcessor from './apple-pay-processor';
import { styled } from '@lib/components';

const ApplePayButtonStatus = { UNKNOWN: 0, AVAILABLE: 1, NOT_AVAILABLE: 2 };

interface Props {
	onClick: (e: any) => void;
	merchantID: string;
}

interface State {
	buttonStatus: number;
}

const ApplePayButtonBlack = styled.div`
  display: block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: black;
  -apple-pay-button-type: check-out;
`;

export default class ApplePayButton extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			buttonStatus: ApplePayButtonStatus.UNKNOWN,
		}
	}

	componentDidMount() {
		ApplePayProcessor.isAvailable(this.props.merchantID)
			.then((isAvailable) => {
				this.setState({
					buttonStatus: isAvailable ? ApplePayButtonStatus.AVAILABLE : ApplePayButtonStatus.NOT_AVAILABLE,
				});
			}).catch((e) => {
				this.setState({
					buttonStatus: ApplePayButtonStatus.NOT_AVAILABLE,
				});
			});
	}

	render() {
		const getButton = () => {
			switch (this.state.buttonStatus) {
				case ApplePayButtonStatus.UNKNOWN:
					return (
						<div className="text-muted text-center">Loading Apple Pay...</div>
					)
				case ApplePayButtonStatus.AVAILABLE:
					return (
						<ApplePayButtonBlack className="apple-pay-button" onClick={this.props.onClick}></ApplePayButtonBlack>
					)
				case ApplePayButtonStatus.NOT_AVAILABLE:
					return (
						<div className="text-muted text-center" id="apple-pay-activation">Apple Pay inactive or not available on your device.</div>
					)
				default:
					return (
						<div>Apple Pay is not available.</div>
					)
			}
		}

		return (
			<div key={this.state.buttonStatus}>
				{getButton()}
			</div>
		);
	}
}
