import React, { useEffect, useState } from 'react';
import { Button, RotateLoader } from '@lib/components';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js';
import { config } from '../../../../../config';
import { observer } from 'mobx-react';
import { useStores } from '../../../../mobx/useStores';
import { RestaurantUtils } from '@lib/common';
import { genRecaptchaToken } from '../../../../libs/grecaptcha';

export const BancontactButton = observer(() => {
  const store = useStores();
  const [processing, setProcessing] = useState(false);
  const [handlingRedirect, setHandlingRedirect] = useState(false);

  const getStripeInstance = () => {
    const value = store.checkout.getStripeConnectStatus();
    return value === 'CONNECT' ? window.stripeConnect : window.stripe;
  };

  const stripeInstance = getStripeInstance();

  useEffect(() => {
    processPaymentResult();
  }, []);

  const processPaymentResult = async () => {
    const url = new URL(window.location.href);
    const redirectStatus = url.searchParams.get('redirect_status');
    const clientSecret = url.searchParams.get('payment_intent_client_secret');
    if (!stripeInstance || !clientSecret || !redirectStatus) {
      return;
    }

    if (redirectStatus === 'failed') {
      store.checkout.update({ error: 'Failed to process the payment using Bancontact' });
      return;
    }

    setHandlingRedirect(true);

    const { paymentIntent, error } = await stripeInstance.retrievePaymentIntent(clientSecret);
    if (error) {
      store.checkout.update({ error: 'Failed to process the payment using Bancontact' });
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      setProcessing(true);
      store.checkout.update({
        stripe_bancontact_payment_intent_id: paymentIntent.id,
        stripe_bancontact_payment_intent_client_secret: clientSecret,
      });

      const order = await store.checkout.order_commence();
      if (!order) {
        store.checkout.update({ error: 'Failed to create the order' });
        return;
      }
    }

    setProcessing(false);
  };

  const handlePayment = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setProcessing(true);

    if (store.checkout.validateOrderData()) {
      setProcessing(false);
      return;
    }

    if (!stripeInstance || store.checkout.s.error) {
      setProcessing(false);
      return;
    }

    const validateToken = await genRecaptchaToken();
    const response = await store.api.createStripeBancontactPaymentIntent({
      restaurantId: store.restaurant._id,
      amount: store.cart.total,
      currency: store.intl.s.currency.code.toLowerCase(),
      paymentMethodTypes: ['bancontact'],
      validateToken,
      paymentIntentId: store.checkout.s.stripe_bancontact_payment_intent_id,
    });

    if (response.outcome !== 0) {
      store.checkout.update({ error: 'Failed to process the payment using Bancontact' });
      setProcessing(false);
      return;
    }

    const clientSecret = response.paymentIntentClientSecret!;
    store.checkout.update({
      stripe_bancontact_payment_intent_id: response.paymentIntentId,
      stripe_bancontact_payment_intent_client_secret: clientSecret,
    });

    const returnUrl = RestaurantUtils.store.url({
      isTest: config.isTest,
      storeHost: store.reseller.store_host,
      subdomain: store.restaurant.subdomain,
      domain: store.restaurant.domain,
    });

    const { name, phone, email } = store.checkout.s;
    await stripeInstance.confirmBancontactPayment(clientSecret, {
      payment_method: {
        billing_details: {
          name,
          phone,
          email,
        },
      },
      return_url: returnUrl,
    });

    setProcessing(false);
  };

  const locale = store.restaurant.settings.region.locale.toLowerCase() as StripeElementLocale;

  return (
    <>
      {stripeInstance ? (
        <Elements stripe={stripeInstance} options={{ locale }}>
          {!processing && !handlingRedirect && (
            <Button id="bancontact-checkout-button" full color="primary" type="button" onClick={handlePayment}>
              <div>Checkout with Bancontact</div>
            </Button>
          )}
          {(processing || handlingRedirect) && <RotateLoader size={3} />}
        </Elements>
      ) : (
        <RotateLoader size={3} />
      )}
    </>
  );
});
