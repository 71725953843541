import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Untrusive, GN } from "@lib/common";
import { logger } from "@lib/common";
import { Input, Button, FormGroup, RotateLoader, Modal, ModalContent } from "@lib/components"

interface Props extends WithTranslation { }
interface State {
	password: string;
	password_confirm: string;
	mounted: boolean;
	submitting: boolean;
}

function generateState() {
	return {
		password: "",
		password_confirm: "",
		mounted: false,
		submitting: false,
	};
}

@inject("store") @observer
class ResetPasswordModalClass extends MobxComponent<Props, State> {

	state = generateState();

	componentDidMount() {
		this.setState({ mounted: true });
	}

	close = () => {
		const { store } = this.injected;
		store.modal.back();
		store.router.push("/");
	}

	submit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const { store, t } = this.injected;
		try {

			const { password, password_confirm } = this.state;
			const { token } = store.router.s.query;

			if (password !== password_confirm) {
				GN.add({
					type: "error",
					message: t(`store.modals.auth.error_password_no_match`),
					duration: 5000,
				});
				return;
			}

			if (password.length < 6) {
				GN.add({
					type: "error",
					message: t(`store.modals.auth.error_password_length`),
					duration: 5000,
				});
				return;
			}

			Untrusive.start();
			this.setState({ submitting: true });
			const result = await store.api.customer_reset_password_complete({ password, token });
			Untrusive.stop();

			if (result.outcome) {
				this.setState({ submitting: false });
				GN.add({
					type: "error",
					message: t(`store.modals.auth.error_${result.message}`),
					duration: 6000,
				});
			}
			else {
				this.setState({
					...generateState(),
					mounted: true,
				});
				GN.add({
					type: "success",
					message: t("store.modals.auth.password_reset.complete_success"),
					duration: 7000,
				});
				store.router.push("/");
				store.modal.show("auth");
			}

		}
		catch (e) {
			logger.captureException(e);
			Untrusive.stop();
			this.setState({ submitting: false });
			GN.add({
				type: "error",
				message: t(`store.modals.auth.error_generic`),
				duration: 5000,
			});
		}
	}

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		const update = { [name]: value };
		// @ts-ignore
		this.setState(update);
	}

	render() {
		const { mounted, password, password_confirm, submitting } = this.state;
		const { store, t } = this.injected;
		const { token } = store.router.s.query;
		const path = store.router.s.path;
		const active = path === "/reset-password" || store.modal.isVisible("reset_password");
		return (
			<Modal
				width="xs"
				id="modal-reset-password"
				active={active}
				close={this.close}>

				{!mounted && (
					<ModalContent paddinglr={20} paddingtb={100} className="round-sm">
						<RotateLoader size={3} />
					</ModalContent>
				)}

				{mounted && (
					<>
						<ModalContent paddingtb={30}>
							{!token && (
								<div className="text-center p-tb-5 p-lr-2">
									<h4 className="m-b-2">{t("store.modals.auth.password_reset.invalid_title")}</h4>
									<p className="m-b-2 lhp">{t("store.modals.auth.password_reset.invalid_description")}</p>
									<p>
										<a className="link" onClick={() => store.router.push("/login")}>
											{t("store.modals.auth.password_reset.invalid_link")}
										</a>
									</p>
								</div>
							)}
							{!!token && (
								<form onSubmit={this.submit}>

									<FormGroup>
										<h3 className="m-b-2 text-center">{t("store.modals.auth.password_reset.title")}</h3>
										<p className="text-center">{t("store.modals.auth.password_reset.description")}</p>
									</FormGroup>

									<FormGroup no_border={true} className="m-b-3">
										<Input
											type="password"
											name="password"
											placeholder={t("store.modals.auth.password_placeholder")}
											required={true}
											value={password}
											onChange={this.onChange}
										/>
									</FormGroup>

									<FormGroup className="m-b-4">
										<Input
											type="password"
											name="password_confirm"
											placeholder={t("store.modals.auth.confirm_password_placeholder")}
											required={true}
											value={password_confirm}
											onChange={this.onChange}
										/>
									</FormGroup>

									<FormGroup no_border={true}>
										<Button full={true} color="primary" disabled={submitting}>
											{!submitting && t("store.modals.auth.password_reset.complete_button")}
											{submitting && <RotateLoader color="white" size={2} />}
										</Button>
									</FormGroup>

								</form>
							)}
						</ModalContent>
					</>
				)}

			</Modal>
		);
	}

}

export const ResetPasswordModal = withTranslation()(ResetPasswordModalClass);
