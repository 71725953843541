import * as React from "react";
import { styled } from "@lib/components";

export const Box = styled("div")`
  
  background: ${({ theme }) => theme.box.background};
  color: ${({ theme }) => theme.box.text};
  padding: 45px 30px;
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    max-width: 500px;
    margin: 0 auto;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 420px;
    margin: 0 auto;
  }
`;
