import * as React from "react";
import { observer, inject } from "mobx-react";
import { FaShoppingBag, FaMotorcycle, FaUtensils, FaCalendarAlt } from "react-icons/fa";
import { ChoicesWrapper, Choice, ChoiceIcon } from "../components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { BlockInfo } from "@lib/components";
import { logger, OrderUtils } from "@lib/common";

interface Props extends WithTranslation { }

@inject("store") @observer
class OrderConfigServicesClass extends MobxComponent<Props> {

	setDelivery = async () => {
		const { store } = this.injected;
		const r = store.restaurant;
		const oc = store.order_config;

		try {

			oc.setService("delivery");
			oc.updateD({ delivery_error: "" });

			const customer = store.customer.s.item;

			if (customer) {
				const d = customer.delivery;
				if (d && d.destination) {
					const blocked = OrderUtils.checkBlockedAddresses(d.destination, r.settings.services.delivery.block_addresses);
					if (!blocked && r.location.map_data.type === "google_maps" && d.lat && d.lng) {
						oc.updateD({ delivery_loading: true });
						const { valid, distance, driving_time, zoneMatch } = await oc.validate_delivery_details({
							lat: d.lat,
							lng: d.lng,
						});
						if (valid) {
							oc.setDestination({
								destination: d.destination,
								destination_misc: d.destination_misc || "",
								lat: d.lat,
								lng: d.lng,
								distance: distance,
								driving_time: driving_time,
								zone: zoneMatch ? zoneMatch.name : "",
							});
						}
					}
					else if (!blocked && r.location.map_data.type === "custom") {
						oc.setDestination({
							...d,
							destination_misc: d.destination_misc || "",
						});
					}
				}
			}

		}
		catch (e) {
			logger.captureException(e);
		}
		finally {
			oc.updateD({ delivery_loading: false });
		}
	}

	render() {
		const { t } = this.injected;
		const { order_config, restaurant, servicesEnabledCount } = this.injected.store;
		const s = restaurant.settings.services;
		const service = order_config.s.service;
		let notes = "";
		if (service !== "") {
			notes = s[service].notes || "";
		}
		const wrapperClass = (
			servicesEnabledCount === 1 ? "grid-2 xs-gap max200" :
				servicesEnabledCount === 2 ? "grid-2 xs xs-gap max250" :
					servicesEnabledCount === 3 ? "grid-3 xs xs-gap max400" :
						servicesEnabledCount === 4 ? "grid-4-2 xs-2 md-4 xs-gap max500" :
							""
		);
		return (
			<div className="m-b-6">
				<h2 className="font-regular text-center m-b-6 lhp">
					{t("store.modals.order.config.services.title")}
				</h2>
				<ChoicesWrapper className={wrapperClass}>
					<Choice
						className="col"
						hidden={!s.pickup.enabled}
						active={service === "pickup"}
						onClick={() => order_config.setService("pickup")}
					>
						<ChoiceIcon>
							<FaShoppingBag />
						</ChoiceIcon>
						<p>
							{s.pickup.display_name ||
								t("constants.services.pickup")}
						</p>
					</Choice>
					<Choice
						className="col"
						hidden={!s.delivery.enabled}
						active={service === "delivery"}
						onClick={this.setDelivery}
					>
						<ChoiceIcon>
							<FaMotorcycle />
						</ChoiceIcon>
						<p>
							{s.delivery.display_name ||
								t("constants.services.delivery")}
						</p>
					</Choice>
					<Choice
						className="col"
						hidden={!s.dine_in.enabled}
						active={service === "dine_in"}
						onClick={() => order_config.setService("dine_in")}
					>
						<ChoiceIcon>
							<FaUtensils />
						</ChoiceIcon>
						<p>
							{s.dine_in.display_name ||
								t("constants.services.dine_in")}
						</p>
					</Choice>
					<Choice
						className="col"
						hidden={!s.table_booking.enabled}
						active={service === "table_booking"}
						onClick={() => order_config.setService("table_booking")}
					>
						<ChoiceIcon>
							<FaCalendarAlt />
						</ChoiceIcon>
						<p>
							{s.table_booking.display_name ||
								t(
									"store.modals.order.config.services.table_booking"
								)}
						</p>
					</Choice>
				</ChoicesWrapper>
				{notes && (
					<div className="flex-center max500 center m-t-8">
						<BlockInfo className="">{notes}</BlockInfo>
					</div>
				)}
			</div>
		);
	}

}

export const OrderConfigServices = withTranslation()(OrderConfigServicesClass);
