import axios from "axios";
import { config } from "../../../../../config";
import _toString from "lodash/toString";

export enum PaymentStatus {
	SUCCESS = 0,
	FAILURE,
	CANCEL,
}

interface Options {
	merchantID: string;
	merchantName: string;
	currency: string;
	label: string;
	amount: number;
	countryCode: string;
	customerName: string;
	customerEmail: string;
	orderID?: string;
	items: Array<T.Schema.Order.OrderDish>;
	restaurantID: string;
}

export default class GooglePayProcessor {
	merchantID: string;
	merchantName: string;
	currency: string;
	label: string;
	amount: number;
	countryCode: string;
	customerName: string;
	customerEmail: string;
	orderID: string;
	items: Array<T.Schema.Order.OrderDish>;
	restaurantID: string;

	constructor(opts: Options) {
		this.merchantID = opts.merchantID;
		this.merchantName = opts.merchantName;
		this.currency = opts.currency;
		this.label = opts.label;
		this.amount = opts.amount;
		this.countryCode = opts.countryCode;
		this.customerName = opts.customerName;
		this.customerEmail = opts.customerEmail;
		this.orderID = _toString(opts.orderID);
		this.items = opts.items;
		this.restaurantID = opts.restaurantID;
	}

	payWithToken(googlePayToken: string) {
		const payload = {
			token: googlePayToken,
			amount: this.amount,
			currency: this.currency,
			customerName: this.customerName,
			customerEmail: this.customerEmail,
			orderID: this.orderID,
			restaurantID: this.restaurantID,
		};
		return this.post(
			"/stores/googlepay/pay",
			payload,
			"Could not perform debit"
		);
	}

	handlePaymentAuthorized(googlePayToken: string) {
		return new Promise((resolve, reject) => {
			this.payWithToken(googlePayToken)
				.then((response: any) => {
					if (response.outcome == 0) {
						// @ts-ignore
						resolve(PaymentStatus.SUCCESS);
					} else {
						// @ts-ignore
						resolve(PaymentStatus.FAILURE);
					}
				})
				.catch((e) => {
					// @ts-ignore
					reject(e);
				});
		});
	}

	post(path: string, data: object, message: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: "post",
				url: config.api_url + path,
				data: data,
				headers: { "Content-Type": "application/json" },
			})
				.then((response: any) => {
					if (response.data.outcome == 0) {
						resolve(response.data);
					} else {
						reject(new Error(message));
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
}
