import * as React from "react";
import { styled, css } from "@lib/components";
import { shadeAlternate } from "@lib/components";
import { HTMLAttributes } from "react";

export const ChoicesWrapper = styled("div")`
  margin: 0 auto;
`;

interface ChoiceProps extends HTMLAttributes<HTMLDivElement> {
	active: boolean;
	disabled?: boolean;
	hidden?: boolean;
}

export const ChoiceComponent = styled("div") <ChoiceProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  ${({ active, theme }) => !active ? "" : css`
    background: ${shadeAlternate(0.1, theme.box.background)};
  `}
  &:hover {
    background: ${({ theme }) => shadeAlternate(0.1, theme.box.background)};
  }
  ${({ disabled, theme }) => !disabled ? "" : css`
    cursor: not-allowed;
    &:hover {
      background: transparent;
    }
  `}
  ${({ hidden }) => !hidden ? "" : css`
    display: none;
  `}
`;

export const Choice = (props: ChoiceProps) => {
	if (props.hidden) {
		return null;
	}
	return <ChoiceComponent {...props} />;
};

export const ChoiceIcon = styled("div")`
  margin-bottom: 10px;
  font-size: 26px;
  svg {
    min-width: 36px;
  }
`;
