import * as React from "react";
import { styled, shadeAlternate, DotsLoader, withTheme } from "@lib/components";
import { observer, inject } from "mobx-react";
import { MenusDropdown } from "./dropdown";
import { CategoryNavBar } from "../../category-nav-bar";
import { MobxComponent } from "../../../../../mobx/component";

const Wrapper = styled("div")`
  border-top: 1px dashed ${({ theme }) => shadeAlternate(0.15, theme.top_nav.background_color)};
`;

const Content = styled("div")`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.content_width.lg}px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
`;

@inject("store") @observer
class DropdownMenusClass extends MobxComponent<{}, { mounted: boolean }> {

	constructor(props: {}) {
		super(props);
		this.state = {
			mounted: false,
		};
	}

	componentDidMount() {
		this.setState({ mounted: true });
	}

	render() {
		if (!this.state) return null;
		const { theme } = this.injected;
		return (
			<Wrapper>
				{!this.state.mounted && (
					<DotsLoader
						size={10}
						style={{ height: "43px" }}
						className="flex-center"
						color={theme.top_nav.text_color}
					/>
				)}
				{this.state.mounted && (
					<div>
						<MenusDropdown className="hide-above-flex-sm" />
						<Content>
							<MenusDropdown className="hide-below-flex-sm" />
							<CategoryNavBar />
						</Content>
					</div>
				)}
			</Wrapper>
		);
	}

}

export const DropdownMenus = withTheme(DropdownMenusClass);
