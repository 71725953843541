import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../mobx/component";
import CookieConsent from "react-cookie-consent";

interface Props {}
interface State {}

@inject("store")
@observer
export class CookieConsentBanner extends MobxComponent<Props, State> {
	render() {
		const { store } = this.injected;

		const restaurant = store.restaurant;
		const { cookie } = restaurant.settings;
		if (!cookie?.content) return null;

		const {domain, subdomain} = restaurant;
		const { primary, primary_text } = restaurant.website.colors;

		const {
			banner_color,
			text_color,
			banner_text,
			link_text,
			accept_button_text,
		} = cookie;

		return (
			<CookieConsent
				location="bottom"
				buttonText={accept_button_text}
				cookieName={`${subdomain}-${domain}-cookie-consent`}
				style={{
					background: `${banner_color}`,
					color: `${text_color}`,
					lineHeight: 1.4,
					zIndex: 19, // z-index of cookie modal is 20
				}}
				buttonStyle={{
					background: `${primary}`,
					color: `${primary_text}`,
					padding: "12px",
					borderRadius: "3px",
				}}
			>
				{banner_text}
				{link_text && (
					<a
						className="underline"
						onClick={() => store.modal.toggle("cookie")}
						style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}
					>
						{link_text}
					</a>
				)}
			</CookieConsent>
		);
	}
}
