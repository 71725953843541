import styled from 'styled-components';

export const HelpText = styled.div`
  font-size: 14px;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 1rem;
`;

export const MoreInfoText = styled.div`
  font-size: 14px;
  color: #0064c8;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  & > a {
    margin-left: 3px;
  }
`;
