import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { ImageWrapper, Image } from "./components";
import {
	Button,
	DishTag,
	HitBox,
	Modal,
	ModalContent,
	ModalDropContent,
	styled,
} from "@lib/components";
import { CoreUtils } from "@lib/common";
import { DishModalIngredients } from "./sections/ingredients";
import { DishModalOptionSet } from "./sections/option-sets";
import { DishModalChoice } from "./sections/choice";
import { DishModalNotes } from "./sections/notes";
import { FaExclamationCircle } from "react-icons/fa";
import _cloneDeep from "lodash/cloneDeep";
import { ErrorBoundary } from "react-error-boundary";
import { OptionSetErrorFallback } from "./sections/option-set-error";

interface Props extends WithTranslation {}
interface State {}

const ButtonWrapper = styled('div') <{ isMobileFullScreen?: boolean }>`
	@media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
		background-color: ${(theme) => theme.theme.box.background};
		position: ${props => props.isMobileFullScreen ? 'fixed' : 'relative'};
		bottom: 0;
		left: 0;
		right: 0;
		& > div {
			padding: 0;
		}

		& button {
			border-radius: 0;
		}
	}
`;

@inject("store")
@observer
class DishModalClass extends MobxComponent<Props, State> {
	dishErrorRef: React.RefObject<any>;

	constructor(props: Props) {
		super(props);
		this.dishErrorRef = React.createRef();
	}

	scrollToErrorSection() {
		const { store } = this.injected;
		const element = this.dishErrorRef?.current;
		if (store.dish.s.error && element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	}

	componentDidMount() {
		setTimeout(() => this.scrollToErrorSection(), 0);
	}

	componentDidUpdate() {
		this.scrollToErrorSection();
	}

	image = () => {
		const { store } = this.injected;
		const dish = store.dish.s.dish!;
		let image: any = null;
		if (dish.image) {
			if (typeof dish.image === "string") {
				image = dish.image;
			} else {
				image = CoreUtils.image.uc(dish.image, {
					resize: "x400",
					format: "auto",
				});
			}
		}
		if (!image) return null;
		return (
			<ImageWrapper>
				<Image url={image} />
			</ImageWrapper>
		);
	};

	content = () => {
		const { t, store } = this.injected;
		const dish = store.dish.s.dish!;
		const category = store.dish.s.category!;
		const price = store.dish.price;
		const tags = store.dish.tags;

		return (
			<ModalContent paddingtb={dish.image ? 25 : 50} paddinglr={25}>
				<div className="flex-l-r-center">
					<h4 className="lhp">{dish.display_name || dish.name}</h4>
					<h4 className="lhp">{t("currency", { value: price })}</h4>
				</div>

				{dish.subtitle && (
					<p
						className="font-semi-bold lhp"
						style={{ marginTop: "-3px", marginBottom: "3px" }}
					>
						{dish.subtitle}
					</p>
				)}

				<p className="font-semi-bold small italic lhp">
					{category.display_name || category.name}
				</p>

				{dish.description && (
					<p className="lhp m-t-1">{dish.description}</p>
				)}

				{tags.length > 0 && (
					<div className="flex-line  flex-wrap">
						{tags.map((tag, i) => (
							<div key={i} className="m-r-1 m-t-2">
								<DishTag {...tag} />
							</div>
						))}
					</div>
				)}
			</ModalContent>
		);
	};

	controls = () => {
		const { t, store } = this.injected;
		const dish = store.dish.s.dish!;
		const isEdit = store.dish.s.edit !== -1;
		const isMobileFullScreen = store.restaurant.website.sections &&
			store.restaurant.website.sections.mobiles
			? store.restaurant.website.sections.mobiles.mobile_fullscreen
			: false;
		return (
			<ButtonWrapper
				isMobileFullScreen={isMobileFullScreen}>
				<div>
					<ModalContent
						paddingtb={15}
						paddinglr={15}
						className="flex-l-r-center"
					>
						<HitBox
						
							up={() => 
								dish.qty < 100 ?
									store.dish.update({ qty: dish.qty + 1 })
									: null
								}
							down={() =>
								dish.qty > 1
									? store.dish.update({ qty: dish.qty - 1 })
									: null
							}
							value={dish.qty}
							editable
							onChange={(e: string) => {
								const parsed = parseInt(e, 10);
								let val = 1;
								if (!isNaN(parsed)) { 
									if (parsed < 0) val = parsed * -1
									else if(parsed > 100) val = 100
									else val = parsed
								}
								store.dish.update({ qty: val })
							}}
						/>
						<Button
							color="primary-inverse"
							className="no-select"
							onClick={store.dish.toCart}
						>
							{isEdit
								? t("store.modals.dish.edit")
								: t("store.modals.dish.add")}
						</Button>
					</ModalContent>
				</div>
			</ButtonWrapper>
		);
	};

	render() {
		const { store, t } = this.injected;
		const { dish, category, errors, error } = store.dish.s;
		const singleError = errors.length === 1 ? errors[0] || [] : [];
		const isFullScreen =
			store.restaurant.website.sections?.mobiles?.mobile_fullscreen;

		const isModalActive = () => {
			const hasDishInfo = !!dish && !!category;

			if (
				store.restaurant.website.sections.menu_nav_bar
					?.menu_template === "v2"
			) {
				return (
					hasDishInfo &&
					store.order_config.s.confirmed &&
					store.isMenuAvailable.available &&
					store.isCategoryAvailable(category!).available
				);
			}

			return hasDishInfo;
		};

		return (
			<Modal
				id="modal-dish"
				width={520}
				closeButton={true}
				active={isModalActive()}
				isFullScreen={isFullScreen}
				preventClose={store.modal.s.active === "order-expired-warning"}
				close={() => store.dish.set()}
			>
				{!!dish && !!category && (
					<div>
						{this.image()}

						{this.content()}

						{dish.type === "combo" &&
							dish.choices.map((choice, i) => (
								<DishModalChoice
									key={i}
									dish={dish}
									errors={
										errors.length >= i + 1
											? errors[i] || []
											: []
									}
									choice={choice}
									onSelect={(dish_id) => {
										store.dish.setChoice(
											i,
											dish_id,
											dish.option_set_blacklist || []
										);
									}}
									updateDish={(data) => {
										store.dish.updateChoice(i, data);
									}}
								/>
							))}

						{["standard", "point"].includes(dish.type) &&
							dish.option_sets.length > 0 &&
							dish.option_sets.map((os, i) => (
								<ErrorBoundary
									key={i}
									FallbackComponent={OptionSetErrorFallback}
									onReset={() => store.dish.set(dish._id)}
									resetKeys={[dish.option_sets]}
								>
									<DishModalOptionSet
										key={i}
										totalOptionSets={
											dish.option_sets.length
										}
										dish_id={dish._id}
										dish_type={dish.type}
										error={
											typeof singleError !== "boolean"
												? singleError[i]
												: false
										}
										option_set={os}
										point_consumption_id={store.dish.constructIdForDishPointItem(
											dish._id
										)}
										option_sets={dish.option_sets}
										total_points={
											store.dish.dishTotalPoints
										}
										update={(option_set) => {
											if (
												!option_set.conditions
													.multi_select
											) {
												let options = _cloneDeep(
													option_set.options
												);
												const lastOption = options
													.filter(
														(option) =>
															option.quantity ===
															1
													)
													.splice(-1)
													.pop();
												if (lastOption) {
													option_set.options =
														options.map(
															(option) => {
																option.quantity =
																	option._id ===
																	lastOption._id
																		? 1
																		: 0;
																return option;
															}
														);
												}
											}

											const option_sets = [
												...dish.option_sets,
											];
											option_sets[i] = option_set;
											store.dish.update({ option_sets });
										}}
									/>
								</ErrorBoundary>
							))}

						{["standard", "point"].includes(dish.type) &&
							(dish.ingredients || []).length > 0 && (
								<ModalDropContent
									title={
										<div>
											<p className="font-semi-bold">
												{t(
													"store.modals.dish.ingredients.title"
												)}
											</p>
											<p className="small m-t-1">
												{t(
													"store.modals.dish.ingredients.uncheck_remove"
												)}
											</p>
										</div>
									}
									paddingtb={15}
									paddinglr={25}
									cPaddingtb={15}
									cPaddinglr={25}
								>
									<DishModalIngredients
										ingredients={dish.ingredients}
										update={(ingredients) => {
											store.dish.update({ ingredients });
										}}
									/>
								</ModalDropContent>
							)}

						<DishModalNotes />

						{error && (
							<ModalContent
								className="error-bg"
								paddinglr={25}
								paddingtb={12}
							>
								<p
									className="small lhp"
									ref={this.dishErrorRef}
								>
									<FaExclamationCircle className="m-r-2" />
									{t("store.modals.dish.error")}
								</p>
							</ModalContent>
						)}

						{this.controls()}
					</div>
				)}
			</Modal>
		);
	}
}

export const DishModal = withTranslation()(DishModalClass);
