import * as React from "react";
import {
	Input,
	Checkbox,
	SelectAdv,
	shadeAlternate,
	styled,
} from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import _find from "lodash/find";
import { IconPickerItem, IconList } from "react-fa-icon-picker";

interface CustomBookingFieldProps {
	field: T.Schema.Restaurant.Services.CustomCheckoutField;
	onValueChange: (value: any) => void;
}
interface CustomBookingFieldState {
	checkboxState: boolean;
	inputText: string;
	dropdownValue: string;
	dropdownSelection: string;
}
interface DropdownItem {
	label: string;
	value: string;
}
const BookingFieldWrapper = styled.div`
  margin: auto;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 4px;
  background: ${({ theme }) => shadeAlternate(0.04, theme.box.background)};
  color: ${({ theme }) => theme.box.text};
  .icon {
    font-size: 30px;
  }
`;
export class CustomBookingField extends MobxComponent<
	CustomBookingFieldProps,
	CustomBookingFieldState
> {
	dropdownOptions: Array<DropdownItem>;
	constructor(props: CustomBookingFieldProps) {
		super(props);
		const field = this.props.field;
		let options = [{ value: "", label: "" }];

		if (field.dropdown_options && field.dropdown_options.length > 0) {
			options = field.dropdown_options.map((field: string, index: number) => ({
				value: index.toString(),
				label: field.toString(),
			}));
		}

		this.dropdownOptions = options;

		this.state = {
			checkboxState: false,
			inputText: "",
			dropdownValue: "",
			dropdownSelection: "",
		};
	}

	findDropdownLabel = (value: string) => {
		const item = _find(this.dropdownOptions, (item) => item.value === value);
		return item ? item.label : "";
	};

	handleCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ checkboxState: e.target.checked }, this.updateFields);
	};

	handleDropdownChanged = (value: string) => {
		this.setState(
			{
				dropdownValue: value,
				dropdownSelection: this.findDropdownLabel(value),
			},
			this.updateFields
		);
	};

	handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ inputText: e.target.value }, this.updateFields);
	};

	computeFieldAnswer(field: T.Schema.Restaurant.Services.CustomCheckoutField) {
		switch (field.type) {
			case "input":
				return this.state.inputText;
			case "dropdown":
				return this.state.dropdownSelection;
			case "checkbox":
				return this.state.checkboxState ? "yes" : "no";
			default:
				return "";
		}
	}

	updateFields = () => {
		const field = this.props.field;
		this.props.onValueChange(this.computeFieldAnswer(field));
	};

	render() {
		const { field } = this.props;

		return (
			<BookingFieldWrapper>
				<div className="flex-left m-t-2 p-4 cursor">
					<div className="m-r-4">
						<IconPickerItem icon={field.icon as IconList} size={20} />
					</div>
					<div className="m-r-3">
						<p className="font-semi-bold lhp">
							{field.label}
							{field.required && (
								<span
									style={{
										color: "red",
										fontSize: "1.2rem",
										fontWeight: "bold",
									}}
								>
									{" "}
									*
								</span>
							)}
						</p>
						<p className="small lhp">{field.description}</p>
					</div>

					{field.type === "checkbox" && (
						<div className="flex-margin-left flex-wrap">
							<Checkbox
								id={`mockup-checkbox-${field._id}`}
								name="mockup-checkbox"
								checked={this.state.checkboxState}
								onChange={this.handleCheckboxChanged}
							/>
						</div>
					)}
				</div>

				{field.type === "dropdown" && (
					<div className="p-t-0 p-b-3 p-l-3 p-r-3">
						<SelectAdv
							type="single"
							options={this.dropdownOptions}
							value={this.state.dropdownValue}
							onChange={this.handleDropdownChanged}
						/>
					</div>
				)}

				{field.type === "input" && (
					<div className="p-t-0 p-b-3 p-l-3 p-r-3">
						<Input
							type="text"
							value={this.state.inputText}
							onChange={this.handleInputChanged}
						/>
					</div>
				)}
			</BookingFieldWrapper>
		);
	}
}
