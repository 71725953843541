import React from 'react';
import { DeepLinkFlowProps } from '../types';
import { ModalContent, Button } from '@lib/components';
import { useTranslation } from 'react-i18next';

export function DeepLinkFlow({ deepLink, methodName }: DeepLinkFlowProps) {
  const { t } = useTranslation();
  return (
    <ModalContent>
      <Button
        full
        color="primary"
        type="button"
        onClick={() => window.open(deepLink, '_blank')}
      >
        <span>{t('store.modals.checkout.payment.open_app', { app: methodName })}</span>
      </Button>
    </ModalContent>
  )
}
