import { flatten } from 'lodash';
import { RootStore } from '../../../../mobx/store';
import { PaymentMethodAliases } from '@lib/common';

type PaymentMethodTypes = T.Schema.Restaurant.Payments.RestaurantPaymentTypes;

export interface PaymentMethod extends T.Schema.Restaurant.Payments.RestaurantPayment {
  key: string;
  aliasKey?: string;
}

export const getPaymentMethodList = (store: RootStore): PaymentMethod[] => {
  const paymentMethods = store.checkout.paymentMethods.filter((paymentMethod) => paymentMethod.key !== 'stripe_digital_wallet');
  return flatten(
    paymentMethods.map(method => {
      if (!Object.prototype.hasOwnProperty.call(PaymentMethodAliases, method.key)) return [method];
      // Append aliases of a given payment method.
      // They will be displayed right after the original payment method.
      const aliases = PaymentMethodAliases[method.key as PaymentMethodTypes] || [];
      return [method, ...aliases.map(alias => ({ ...method, aliasKey: alias.key, label: alias.label }))];
    })
  );
};

export const filterPaymentMethods = (store: RootStore) => {
  let list = [...getPaymentMethodList(store)];
  if (
    store.order_config.s.service == 'delivery' &&
    store.checkout.delivery_provider === 'uber' &&
    store.restaurant.settings.services.delivery.providers.uber &&
    store.restaurant.settings.services.delivery.providers.uber.enabled
  ) {
    list = [...list].filter(({ key, ...rest }) =>
      // @ts-ignore
      ['stripe_digital_wallet'].includes(key) ? true : ['stripe'].includes(key) && rest.connect_account_id && rest.connect_account_id.length > 0
    );
  }

  return list;
};
