import * as React from "react";
import { styled, withTheme, BlockError, Input, RotateLoader } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import qs from "qs";
import { logger } from "@lib/common";
import { genRecaptchaToken } from "../../../../libs/grecaptcha";

interface Props extends WithTranslation { }
interface State { isLoading: boolean; }

const NumberInputNoSpinButton = styled(Input)`
  -moz-appearance:textfield;
  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

@inject("store") @observer
class CardConnectFormClass extends MobxComponent<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			isLoading: false,
		};
	}
	listenerActive: boolean = false;

	async componentDidMount() {
		if (this.listenerActive) {
			window.removeEventListener("message", this.listenForToken);
		}
		this.listenerActive = true;
		window.addEventListener("message", this.listenForToken);

		this.injected.store.checkout.initCardConnect();
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.listenForToken);
	}

	listenForToken = (event: MessageEvent) => {
		try {
			if (event.data && typeof event.data === "string") {

				const data = JSON.parse(event.data) as {
					entry: string;
					errorCode: string;
					errorMessage: string;
					token: string;
					validationError: string;
				};

				if (data && data.entry) {

					if (data.errorMessage) {
						this.injected.store.checkout.update({
							card_token: "",
							card_error: "invalid_cc_number",
						});
					}
					else if (data.token) {
						this.injected.store.checkout.update({
							card_token: data.token,
							card_error: "",
						});
					}

				}

			}
		}
		catch (e) {
			if (e.message.indexOf("Unexpected token") === -1) {
				logger.captureException(e);
			}
		}
	}

	onChangeExpiry = (e: React.ChangeEvent<HTMLInputElement>) => {

		const { store } = this.injected;

		const next = e.target.value;

		const current = store.checkout.s.card_expiry;

		if (next.length > 5) {
			return;
		}
		if (current.length === 3 && next.length === 2) {
			store.checkout.update({ card_expiry: next });
		}
		else if (current.length === 2 && next.length === 3) {
			store.checkout.update({ card_expiry: current + "/" + next[2] });
		}
		else if (current.length === 1 && next.length === 2) {
			store.checkout.update({ card_expiry: next + "/" });
		}
		else {
			store.checkout.update({ card_expiry: next });
		}

	}

	onChangeCvv = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { store } = this.injected;
		store.checkout.update({ card_cvv: e.target.value });
	}

	render() {
		const { store, theme } = this.injected;

		const { site, port } = store.restaurant.settings.payments.cardconnect!;

		const frameQuery = qs.stringify({
			placeholder: "Card Number",
			invalidinputevent: true,
			tokenizewheninactive: true,
			inactivityto: 500,
			enhancedresponse: true,
			tokenpropname: "token",
			formatinput: true,
			unique: true, // unique token for same card
			css: `
        body { margin: 0 } 
        input { 
          width: 100%; 
          box-sizing: border-box; 
          height: 38px;
          line-height: normal;
          padding: 0 10px;
          border-radius: 3px;
          outline: 0;
          background: ${theme.input.background};
          color: ${theme.input.text};
          border: 1px solid ${theme.input.border};
          font-size: ${theme.input.fontSize}px;
        }
        input:focus {
          border: 1px solid ${theme.colors.primary};
        }
        .error { color: ${theme.colors.error}; }
      `.replace(/\s+/g, " "),
		});

		const { card_expiry, card_cvv, error, loading } = store.checkout.s;
		return (
			<>
				{loading &&
					<div className="p-t-5 tally">
						<RotateLoader size={3} />
					</div>
				}
				{!loading && !error &&
					<div className="p-t-5">
						<div className="m-b-2">
							<iframe
								id="tokenFrame"
								name="tokenFrame"
								src={`https://${site}.cardconnect.com:${port}/itoke/ajax-tokenizer.html?${frameQuery}`}
								style={{
									width: "100%",
									height: "38px",
								}}
								frameBorder="0"
								scrolling="no"
							/>
						</div>

						<div className="m-b-2 grid-2 xxs xs-gap">
							<Input
								type="string"
								name="card_expiry"
								className="col"
								pattern="([0-9]{2})\/([0-9]{2})"
								inputMode="numeric"
								placeholder={"Expiry (MM/YY)"}
								onChange={this.onChangeExpiry}
								value={card_expiry}
								required={true}
							/>
							<NumberInputNoSpinButton
								type="number"
								name="card_cvv"
								className="col"
								placeholder={"CVV"}
								inputMode="numeric"
								onChange={this.onChangeCvv}
								value={card_cvv}
								required={true}
							/>
						</div>

						{!!(0) && <BlockError text="" className="m-t-2" />}
					</div>
				}

			</>
		);

	}

}

// @ts-ignore
export const CardConnectForm = withTheme(withTranslation()(CardConnectFormClass));
