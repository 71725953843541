import * as React from "react";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { FaStickyNote } from "react-icons/fa";
import { styled, shadeAlternate, Input } from "@lib/components";

interface Props extends WithTranslation { }
interface State { }

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.span`
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  left: 25px;
  color: ${({ theme }) => shadeAlternate(0.3, theme.box.text)};
  line-height: 0;
  font-size: 14px;
`;

export const DishNotesInput = styled(Input)`
  padding: 23px 25px 23px 50px;
  border-radius: 0;
  font-size: 16px;
  border-top: none;
  &:focus {
    border-top: none;
    border-left: none !important;
    border-right: none !important;
  }
`;

@inject("store") @observer
class DishModalNotesClass extends MobxComponent<Props, State> {

	render() {
		const { t, store } = this.injected;
		return (
			<Wrapper>
				<Icon>
					<FaStickyNote />
				</Icon>
				<DishNotesInput
					onChange={(e) => store.dish.update({ notes: e.target.value })}
					value={store.dish.s.dish!.notes}
					placeholder={t("store.modals.dish.notes")}
				/>
			</Wrapper>
		);
	}

}

export const DishModalNotes = withTranslation()(DishModalNotesClass);
