import * as React from "react";
import { inject, observer } from "mobx-react";
import {
	Modal,
	ModalContent,
	DetailRowList,
	DetailRowListItem,
	OrderNumber,
	OrderTimeline,
	RotateLoader,
	ConnectionIndicatorCircle
} from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import * as Ably from "ably";
import { config } from "../../../../config";
import { action, observable } from "mobx";
import Push, { PushNotificationParams } from "push.js";
import { logger } from "@lib/common";
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';

interface Props extends WithTranslation { }

@inject("store") @observer
class BookingReceiptModalClass extends MobxComponent<Props, {}> {

	ably?: Ably.Realtime;
	channel?: Ably.Types.RealtimeChannelCallbacks;
	pushNotificationsInitialized = false;

	@observable ablyState: Ably.Types.ConnectionState = "initialized";
	@observable tab: number = 0;

	componentDidMount() {
		this.injected.store.booking.sync();
		this.ablyInit();
		if (this.injected.store.booking_id) {
			this.initPushNotification();
		}
	}

	componentDidUpdate() {
		this.injected.store.booking.sync();
		this.ablyInit();
		if (this.injected.store.booking_id) {
			this.initPushNotification();
		}
	}

	ablyInit = () => {
		const { store } = this.injected;
		const item = store.booking.s.item;

		if (!this.ably) {
			this.ably = new Ably.Realtime(config.ably_order_updates_key);
			this.ably.connection.on(this.ablyHandleConnectionState);
		}

		if (item) {
			if (this.channel) {
				this.channel.unsubscribe();
			}
			this.channel = this.ably.channels.get(`public:booking-updates:${item._id}`);
			this.channel.subscribe(this.ablyHandleSubscription);
		}
		else if (this.channel) {
			this.channel.unsubscribe();
			this.channel = undefined;
		}
	}

	ablyHandleSubscription = (message: Ably.Types.Message) => {
		const { store, t } = this.injected;
		const item = store.booking.s.item;
		if (!item) return;
		if (message.name === "status") {
			const data = message.data as {
				status: T.Schema.Booking.BookingStatuses;
				updated: number;
			};
			store.booking.updateItem(data);
			this.sendPushNotification(t("booking.receipt.status_update.title", { number: item.number }), {
				body: t("booking.receipt.status_update.body", { status: t(`order.status.${data.status}`) }),
				timeout: 30000,
				requireInteraction: true,
			});
		}
	}

	@action ablyHandleConnectionState = (stateChange: Ably.Types.ConnectionStateChange) => {
		this.ablyState = stateChange.current;
	}

	@action setTab = (tab: number) => this.tab = tab;

	initPushNotification = () => {
		try {
			if (!Push.Permission.has() && !this.pushNotificationsInitialized) {
				Push.Permission.request(() => { }, () => { });
				this.pushNotificationsInitialized = true;
			}
		}
		catch (e) {
			logger.captureException(e);
		}
	}

	sendPushNotification = async (title: string, opts: PushNotificationParams) => {
		try {
			if (Push.Permission.has()) {
				const n = await Push.create(title, {
					vibrate: true,
					icon: "/store-notification-icon.png",
					link: window.location.href,
					onClick: () => {
						// @ts-ignore
						n.close();
						window.focus();
					},
					...opts,
				});
			}
		}
		catch (e) {
			logger.captureException(e);
		}
	}

	render() {
		const { store, t } = this.injected;
		const { loading, error, item } = store.booking.s;
		const { booking_id } = store;
		const r = store.restaurant;

		let bookingReceiptFields: DetailRowListItem[] = [];
		if (item)
			bookingReceiptFields = [
				{
					l: t(`booking.receipt.details.datetime`),
					v: t("datetimeFromTimestamp", { value: item.config.timestamp }),
				},
				{
					l: t(`booking.receipt.details.number_of_people`),
					v: t("number", { value: item.config.number_of_people }),
				},
				{
					l: t(`booking.receipt.details.placed`),
					v: t("datetimeFromTimestamp", { value: item.created }),
				},
				{
					l: t(`booking.receipt.details.updated`),
					h: !!item.updated,
					v: item.updated ? t("datetimeFromTimestamp", { value: item.updated }) : "",
				},
				{
					l: t(`booking.receipt.details.notes`),
					v: item.notes,
				},
				{
					l: t(`booking.receipt.details.name`),
					v: item.customer.name,
				},
				{
					l: t(`booking.receipt.details.email`),
					v: item.customer.email,
				},
				{
					l: t(`booking.receipt.details.phone`),
					v: item.customer.phone,
				},
				{
					l: t(`booking.receipt.details.store_address`),
					v: r.location.address,
				},
			];

		let customBookingFields = _get(item, 'custom_booking_field_list');

		if (customBookingFields) {
			let customBookingReceiptFields =
				customBookingFields.map((field: T.Schema.CustomFieldValue): DetailRowListItem => {
					let answer = field.answer;
					if (field.type === "checkbox") {
						answer = (answer === "yes") ? '✅' : '❌'
					}
					return {
						l: field.question.label,
						v: answer,
					}
				});

			const idx = _findIndex(bookingReceiptFields, (item: DetailRowListItem) => item.l === t("booking.receipt.details.notes"))

			if (idx === -1) {
				bookingReceiptFields = [...bookingReceiptFields, ...customBookingReceiptFields];
			} else {
				bookingReceiptFields.splice(idx + 1, 0, ...customBookingReceiptFields);
			}
		}

		return (
			<Modal
				id="modal-booking-receipt"
				width={420}
				closeButton={true}
				active={!!booking_id}
				close={() => store.router.push("/")}>

				{loading && (
					<ModalContent paddinglr={20} paddingtb={100} className="round-sm">
						<RotateLoader size={3} />
					</ModalContent>
				)}

				{(!loading && error) && (
					<ModalContent paddinglr={20} paddingtb={50} className="round-sm text-center">
						<p className="error-text">
							{t(`store.modals.receipt.errors.${error}`)}
						</p>
					</ModalContent>
				)}

				{(!loading && !error && item) && (
					<div>

						<ModalContent paddinglr={20} paddingtb={25} className="flex-l-r-center no-border">
							<OrderNumber>
								#{item.number}
							</OrderNumber>
							<div className="text-right m-t-1">
								<p className="m-b-1">{t(`booking.receipt.title`)}</p>
								<p className="smaller">
									{(
										this.ablyState === "connected" ? t(`store.modals.receipt.connection.connected`) :
											this.ablyState === "connecting" ? t(`store.modals.receipt.connection.connecting`) :
												t(`store.modals.receipt.connection.disconnected`)
									)}
									<ConnectionIndicatorCircle className="m-l-1" status={this.ablyState} />
								</p>
							</div>
						</ModalContent>

						<OrderTimeline
							status={item.status}
							isDelivery={false}
							isBooking={true}
						/>

						<ModalContent paddinglr={20} paddingtb={25}>
							<DetailRowList
								items={bookingReceiptFields}
							/>
						</ModalContent>

					</div>
				)}

			</Modal>
		);
	}

}

export const BookingReceiptModal = withTranslation()(BookingReceiptModalClass);
