import { observable, action } from "mobx";
import { RootStore } from "../store";

export interface LoaderState {
	active: boolean;
	opacity: number;
	title: string | null;
	message: string | null;
}

export class LoaderStore {

	@observable s: LoaderState;
	store: RootStore;

	constructor(store: RootStore, initialState?: LoaderState) {
		this.store = store;
		this.s = {
			active: false,
			opacity: 0,
			message: "",
			title: "",
			...(initialState || {}),
		};
	}

	@action update(data: Partial<LoaderState>) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof LoaderState];
				if (value !== undefined && this.s) {
					// @ts-ignore
					this.s[key as keyof LoaderState] = value;
				}
			}
		}
	}

}
