import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { CheckoutFormModal } from './checkout-form';
import { getCheckoutFormCommonFields } from './utils';
import { MobxComponent } from '../../../mobx/component';
import { InputField, PaymentMethod } from './flows/types';
import { RestaurantUtils } from '@lib/common';
import { withTranslation, WithTranslation } from 'react-i18next';

const PRODUCTION_PAYMENT_ENDPOINT =
  'https://checkout.payway.com.kh/api/payment-gateway/v1/payments/purchase';
const TESTING_PAYMENT_ENDPOINT =
  'https://checkout-sandbox.payway.com.kh/api/payment-gateway/v1/payments/purchase';

interface Props extends WithTranslation { };

@inject('store') @observer
class PayWayFormModalClass extends MobxComponent<Props, {}> {
  render() {
    const { t, store } = this.injected;
    const restaurant = store.restaurant;

    const order = store.order.s.item;
    const isOrderUsingPayway = order?.payment.method === 'payway';

    if (
      !order ||
      !isOrderUsingPayway ||
      !RestaurantUtils.settings.paymentActivePayWay(
        restaurant.settings.payments
      )
    ) {
      return null;
    }

    const payway = restaurant.settings.payments.payway!;
    const { payway_transaction_params: transactionPayload } = order.payment;
    if (!transactionPayload) {
      return null;
    }
    const formFields = Object.keys(transactionPayload).map(key => ({
      name: key,
      value: transactionPayload[key],
    }));

    const paymentEndpoint = payway?.testing
      ? TESTING_PAYMENT_ENDPOINT
      : PRODUCTION_PAYMENT_ENDPOINT;
    const inputFields: InputField[] = [
      ...getCheckoutFormCommonFields(order, t),
      ...formFields,

      {
        name: 'view_type',
        value: 'hosted_view',
      },
    ];

    const paymentMethod: PaymentMethod = {
      label: 'ABA Pay',
      name: order.payment.method,
      urlEndpoint: paymentEndpoint,
    };

    return (
      <CheckoutFormModal
        inputFields={inputFields}
        paymentMethod={paymentMethod}
        orderId={order._id}
        formTarget="aba_webservice"
        openNewWindow
      />
    );
  }
}

export const PayWayFormModal = withTranslation()(PayWayFormModalClass);
