import * as React from "react";
import { RootStore } from "./store";
import { WithTranslation } from "react-i18next";

export interface InjectedProps extends WithTranslation {
	theme: T.DefaultTheme;
	store: RootStore;
}

export class MobxComponent<Props = {}, State = {}> extends React.Component<Props, State> {

	get injected() {
		return this.props as InjectedProps & Props;
	}

}
