import { styled, shadeAlternate } from "@lib/components";

export const CircleWrapper = styled("div")`
  position: absolute;
  bottom: -100px;
  left: 0;
  right: 0;
`;

export const Circle = styled("div")`
  position: relative;
  height: 200px;
  width: 100%;
  overflow: hidden;
  :before {
    content: '';
    position: absolute;
    height: 200px;
    width: 100%;
    border-radius: 50%;
    bottom: 0;
    background: ${({ theme }) => shadeAlternate(0.03, theme.colors.background)};
  }
`;
