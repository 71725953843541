/// <reference types='@lib/lalamove/types' />

import * as React from "react";
import styled, { withTheme } from "styled-components";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";

import { MobxComponent } from "../../../../mobx/component";
import { RotateLoader, SelectAdv, Table, TableBody, TableCell, TableRow } from "@lib/components";
import { Quotation } from "@lib/lalamove/types/resources/Quotations";
import Lalamove from "@lib/lalamove";
import { QuotationCreateParams } from "@lib/lalamove/types/resources/Quotations";
import _ from 'lodash';
import { TimerContainer, Timer, TimerButton } from "./uber-estimation";
interface Props extends WithTranslation {
  //
}

interface State {
  quote: Quotation | null;
  error: string;
  service: string;
  loading: boolean;
  minutes: number;
  seconds: number;
  expiration_time: number;
}

const SelectWrapper = styled.div`
	width: 100%;
	padding-bottom: 0.5rem;
	border-bottom: 1px dashed #d6d6d6;
`;

const ErrorWrapper = styled.div`
	margin-top: 0.5rem;
`;

const TableWrapper = styled.div`
	tr {
		border-bottom: 1px dashed #d6d6d6 !important;
	}
`;

@inject("store") @observer
class LalamoveEstimationClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: "",
      quote: null,
      service: "",
      loading: false,
      minutes: 5,
      seconds: 0,
      expiration_time: 0
    };
  }

  interval: any = null

  componentDidMount() {
    const { store, t } = this.injected;
    if (!this.state.service) {
      store.checkout.update({
        error: t("store.modals.checkout.errors.required_delivery_provider")
      })
    }
  }

  componentWillUnmount(): void {
    const { store } = this.injected
    store.order_config.update({
      lalamove_quotationId: '',
      lalamove_service_type: ''
    })
    clearInterval(this.interval)
    this.setState({
      service: '',
      quote: null
    })
  }

  transformServiceTypes = () => {
    const { store } = this.injected;
    const restaurant = store.restaurant!;
    const market = store.restaurant.settings.services.delivery.providers.lalamove!.market;
    const allServiceTypes = Lalamove.serviceTypesByMarket(market);
    const serviceTypes = restaurant.settings.services.delivery.providers.lalamove?.service_types || [];
    return allServiceTypes.filter(item => serviceTypes.includes(item.value));
  }

  constructQuotationParams = (serviceType: any): QuotationCreateParams => {
    const { store } = this.injected;
    const restaurant = store.restaurant!;
    const orderConfig = store.order_config.s;
    const checkout = store.checkout.s;
    const location = store.restaurant.location;
    const lalamoveConfig = store.restaurant.settings.services.delivery.providers.lalamove!;
    const locale = Lalamove.localeByMarket(lalamoveConfig.market);

    return {
      data : {
        serviceType,
        specialRequests: [],
        scheduleAt: store.order_config.isoDueTime,
        stops: [
          {
            coordinates: {
              lat: location.map_data.type !== "custom" ? location.map_data.lat.toString() : '',
              lng: location.map_data.type !== "custom" ? location.map_data.lng.toString() : '',
            },
            address: location.address
          },
          {
            coordinates: {
              lat: orderConfig.lat.toString(),
              lng: orderConfig.lng.toString()
            },
            address: orderConfig.destination
          }
        ],
        language: locale,
      }
    };
  }

  createQuotation = async (serviceType: any) => {
    const { store } = this.injected;
    const lalamoveConfig = store.restaurant.settings.services.delivery.providers.lalamove!;

    this.setState({ loading: true })
    const params = this.constructQuotationParams(serviceType);
    if(typeof params.data.scheduleAt != 'string') {
      delete params.data.scheduleAt;
    }

    const response: T.API.CreateLalamoveQuoteResponse = await store.api.create_lalamove_quotation({
      restaurantId: store.restaurant._id,
      params,
    });

    if (response.outcome === 1) {
      this.setState({ error: response.message, quote: null, loading: false });
      store.checkout.update({ error: 'Something went wrong calculating delivery estimation.' })
    } else if (response.quote) {
      store.order_config.update({
        delivery_provider: "lalamove",
        lalamove_quote_amount: response.quote.data.priceBreakdown.total,
        lalamove_quote_currency: response.quote.data.priceBreakdown.currency,
        lalamove_quote_payload: response.quote,
        lalamove_quotationId: response.quote.data.quotationId,
        lalamove_market: lalamoveConfig.market,
        lalamove_service_type: serviceType,
        lalamove_share_link: ''
      });
      const expirationTime = new Date();
      expirationTime.setMinutes(expirationTime.getMinutes() + 5)
      this.setState({ 
        quote: response.quote,
        error: '',
        loading: false,
        expiration_time: expirationTime.getTime()});
      store.checkout.update({ error: '' });
      this.timer()
    }
  }
  initTimer() {
	
		clearInterval(this.interval);
		const { store } = this.injected;
		const orderConfig = store.order_config.s;
		if(orderConfig.lalamove_quotationId) {
			this.setState({
				loading: false,
			}, () => {
				this.timer()
				
			});
		} else {
			this.setState({ error: 'Invalid quotation.', loading: false, quote: null });
		}
	}

  timer() {
		this.interval = setInterval(() => {
		const now = new Date().getTime();
		const distance = this.state.expiration_time - now;
		const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		const seconds = Math.floor((distance % (1000 * 60)) / 1000);
		

    if(distance < 0) {
			this.setState({
				minutes: 0,
				seconds: 0
			});
			clearInterval(this.interval);
      const { store } = this.injected;
			store.order_config.update({
				lalamove_error: 'Delivery estimation expired',
				lalamove_quotationId: ''
			});
      store.checkout.update({error: 'Delivery estimation expired'})
		} else {
			this.setState({
				minutes,
				seconds
			});
		}
		}, 1000);
	}

  render() {
    const { t, theme } = this.injected;
    const serviceTypes = this.transformServiceTypes();
    const { quote, error, service, loading, minutes, seconds } = this.state;
    const isLessThanTenSeconds = minutes == 0 && seconds < 10;
    return (
      <>
        {quote &&
        <TimerContainer>
						<Timer>
							<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.9998 24.6666C19.8665 24.6666 24.6665 19.8666 24.6665 14C24.6665 8.13329 19.8665 3.33329 13.9998 3.33329C8.13317 3.33329 3.33317 8.13329 3.33317 14C3.33317 19.8666 8.13317 24.6666 13.9998 24.6666ZM13.9998 0.666626C21.3332 0.666626 27.3332 6.66663 27.3332 14C27.3332 21.3333 21.3332 27.3333 13.9998 27.3333C6.6665 27.3333 0.666504 21.3333 0.666504 14C0.666504 6.66663 6.6665 0.666626 13.9998 0.666626ZM20.6665 13.3333V15.3333H12.6665V7.33329H14.6665V13.3333H20.6665Z"
								fill={isLessThanTenSeconds ? '#EA4335': 'black'}/>
							</svg>
							<div>
								<span className={isLessThanTenSeconds ? 'danger': ''}>00:{_.padStart(minutes.toString(), 2, '0')}:{_.padStart(seconds.toString(), 2, '0')}</span>
								<p className={isLessThanTenSeconds ? 'danger': ''}>
									{minutes == 0 && seconds == 0 ? t("store.modals.checkout.delivery_estimation.details.lalamove_estimation_expired"):
									 t("store.modals.checkout.delivery_estimation.details.lalamove_price")}
								</p>
							</div>

						</Timer>
            { (minutes == 0 && seconds == 0) &&<TimerButton onClick={(e) =>  {
							e.preventDefault();
              this.createQuotation(service);
						}}>
							{ t("store.modals.checkout.delivery_estimation.details.lalamove_new_estimation") }
						</TimerButton> }
        </TimerContainer> }
        <SelectWrapper>
          <SelectAdv
            type="single"
            value={service}
            options={serviceTypes}
            placeholder="Deliver via... (Required field)"
            onChange={(option: string) => {
              this.setState({ service: option })
              this.createQuotation(option);
            }}
          />
        </SelectWrapper>

        <TableWrapper>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="small">Delivery Fee</TableCell>
                {!loading && (
                  <TableCell className="small">
                    {quote?.data.priceBreakdown.total && quote?.data.priceBreakdown.currency ? `${quote?.data.priceBreakdown.total} ${quote?.data.priceBreakdown.currency}` : 0}
                  </TableCell>
                )}
                {loading && (
                  <TableCell>
                    <RotateLoader size={1} color={theme.colors.primary} />
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableWrapper>

        {error && (
          <ErrorWrapper className="error-text" style={{ fontSize: '0.85rem' }}>
            {t("store.modals.checkout.delivery_estimation.quotation_error")}
          </ErrorWrapper>
        )}
      </>
    )
  }
}

// @ts-ignore
export const LalamoveEstimation = withTheme(withTranslation()(LalamoveEstimationClass));
