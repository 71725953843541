import { Appearance } from "@stripe/stripe-js";

export const getAppearance = (themes: any, store: any) => {
    let appearance : Appearance;
    switch (themes) {
      case 'stripe':
        appearance = {
          theme: 'stripe',
          variables: {
            colorPrimary: `${store.restaurant.website.colors.text}`,
            accessibleColorOnColorPrimary: `${store.restaurant.website.colors.primary}`,
          },
          rules: {
            '.Tab:hover': {
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            },
            '.TabIcon--selected': {
              fill: `${store.restaurant.website.colors.primary}`
            },
            '.Input:focus': {
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              borderColor: `${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            }
          }
        };
        break;
      case 'night':
        appearance = {
          theme: 'night',
          labels: 'floating',
          variables: {
            colorPrimary: `${store.restaurant.website.colors.text}`,
            accessibleColorOnColorPrimary: `${store.restaurant.website.colors.primary}`,
          },
          rules: {
            '.Tab:hover': {
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            },
            '.TabIcon--selected': {
              fill: `${store.restaurant.website.colors.primary}`
            },
            '.Input:focus': {
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              borderColor: `${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            }
          }
        };
        break;
      case 'flat':
        appearance = {
          theme: 'flat',
          variables: {
            colorPrimary: `${store.restaurant.website.colors.text}`,
            accessibleColorOnColorPrimary: `${store.restaurant.website.colors.primary}`,
          },
          rules: {
            '.Tab:hover': {
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            },
            '.TabIcon--selected': {
              fill: `${store.restaurant.website.colors.primary}`
            },
            '.Input:focus': {
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              borderColor: `${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            }
          }
        };
        break;
      case 'bubblegum':
        appearance = {
          theme: 'stripe',
          variables: {
            fontWeightNormal: '500',
            borderRadius: '2px',
            colorPrimary: '#f360a6',
            tabIconSelectedColor: '#fff',
            gridRowSpacing: '16px',
            accessibleColorOnColorPrimary: `${store.restaurant.website.colors.primary}`,
          },
          rules: {
            '.Tab, .Input, .Block, .CheckboxInput, .CodeInput': {
              boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)'
            },
            '.Block': {
              borderColor: 'transparent'
            },
            '.BlockDivider': {
              backgroundColor: '#ebebeb'
            },
            '.Tab, .Tab:hover, .Tab:focus': {
              border: '0'
            },
            '.Tab--selected, .Tab--selected:hover': {
              backgroundColor: '#f360a6',
              color: '#fff',
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            },
            '.TabIcon--selected': {
              fill: `${store.restaurant.website.colors.primary}`
            },
            '.Input:focus': {
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              borderColor: `${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            }
          }
        };
        break;
      case 'ninety_five':
        appearance = {
          theme: 'flat',
          variables: {
            fontLineHeight: '1.5',
            borderRadius: '0',
            colorBackground: '#fff',
            focusBoxShadow: 'none',
            focusOutline: '-webkit-focus-ring-color auto 1px',
            tabIconSelectedColor: 'var(--colorText)'
          },
          rules: {
            '.Input, .CheckboxInput, .CodeInput': {
              transition: 'none',
              boxShadow: 'inset -1px -1px #ffffff, inset 1px 1px #0a0a0a, inset -2px -2px #dfdfdf, inset 2px 2px #808080'
            },
            '.Input': {
              padding: '12px'
            },
            '.Input--invalid': {
              color: '#DF1B41'
            },
            '.Tab, .Block, .PickerItem--selected': {
              backgroundColor: '#dfdfdf',
              boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf'
            },
            '.Tab': {
              transition: 'none'
            },
            '.Tab:hover': {
              backgroundColor: '#eee'
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
              backgroundColor: '#ccc',
              color: `${store.restaurant.website.colors.primary}`,
              border: `1px solid ${store.restaurant.website.colors.primary}`,
            },
            '.TabIcon--selected': {
              fill: `${store.restaurant.website.colors.primary}`
            },
            '.Tab:focus, .Tab--selected:focus': {
              boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf',
              outline: 'none'
            },
            '.Tab:focus-visible': {
              outline: 'var(--focusOutline)'
            },
            '.PickerItem': {
              backgroundColor: '#dfdfdf',
              boxShadow: 'inset -1px -1px #0a0a0a, inset 1px 1px #ffffff, inset -2px -2px #808080, inset 2px 2px #dfdfdf',
              transition: 'none'
            },
            '.PickerItem:hover': {
              backgroundColor: '#eee'
            },
            '.PickerItem--highlight': {
              outline: '1px solid blue'
            },
            '.PickerItem--selected:hover': {
              backgroundColor: '#dfdfdf'
            },
            '.Input:focus': {
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              borderColor: `${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            }
          }
        };
        break;
      case 'dark_blue':
        appearance = {
          theme: 'night',
          variables: {
            fontWeightNormal: '500',
            borderRadius: '8px',
            colorBackground: '#0A2540',
            colorPrimary: '#EFC078',
            accessibleColorOnColorPrimary: '#1A1B25',
            colorText: 'white',
            colorTextSecondary: 'white',
            colorTextPlaceholder: '#ABB2BF',
            tabIconColor: 'white',
            logoColor: 'dark',
          },
          rules: {
            '.Input': {
              backgroundColor: '#212D63',
              border: '1px solid var(--colorPrimary)'
            },
            '.Input:focus': {
              border: `1px solid ${store.restaurant.website.colors.primary}`,
              borderColor: `${store.restaurant.website.colors.primary}`,
              outline: 'none',
              boxShadow: 'none'
            }
          }
        };
        break;
      default:
        appearance = {
          theme: 'stripe'
        };
    }
  
    return appearance;
  };