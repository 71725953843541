import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Dish } from '../item';
import { Button } from '@lib/components';
import { DishGrid, DishGridWrapper } from './components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../../../mobx/component';
import { CategoryName } from '../../menuTemplateV1/menus/indexExperimental';

interface Props extends WithTranslation {
  category: T.Schema.Restaurant.Menu.RestaurantCategory;
}

@inject('store')
@observer
class CategorySectionClass extends MobxComponent<Props> {
  observer: IntersectionObserver | null;

  constructor(props: Props) {
    super(props);
    this.observer = null;
  }

  componentDidMount() {
    const categoryId = this.props.category._id;

    const changeHighlightedCategory = () => {
      const { store } = this.injected;
      store.filters.s.category = categoryId;
      store.view.scrollToHighlightedOnCategoryBar();
    };

    const observerCallback: IntersectionObserverCallback = function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          changeHighlightedCategory();
        }
      });
    };

    const observerOptions = {
      rootMargin: '-49% 0px -50% 0px',
      threshold: 0,
    };

    this.observer = new IntersectionObserver(observerCallback, observerOptions);

    const currentCategory = document.getElementById(categoryId);
    if (currentCategory) {
      this.observer.observe(currentCategory);
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this.observer?.disconnect();
  }

  render() {
    const { store, t } = this.injected;
    const { restaurant } = store;
    const { tagsApplied } = store.dishes;
    const menus = store.restaurant.website.sections.menus;
    const doesCategoryHaveRestrictions = store.doesCategoryHaveRestrictions;

    const { category } = this.props;
    const categoryAvailability = store.isCategoryAvailable(category);
    const showCategoryDescription = !menus.dish_hide_category_description;

    return (
      <DishGridWrapper id={category._id}>
        <div className="m-b-6">
          <CategoryName>{category.display_name || category.name}</CategoryName>
          {showCategoryDescription && (
            <CategoryName className="lhp italic">{category.description}</CategoryName>
          )}
          {doesCategoryHaveRestrictions(category) && (
            <Button
              size="xxs"
              className="m-tb-1"
              color="primary-inverse"
              onClick={() => store.modal.toggle('category-restrictions')}
            >
              {categoryAvailability.available &&
                t('store.sections.category.restrictions')}
              {!categoryAvailability.available &&
                t('store.sections.category.not_available')}
            </Button>
          )}
        </div>

        <DishGrid dishStyle={menus.dish_style} tagsApplied={tagsApplied}>
          {category.dishes.map((dish, k) => {
            const tags = restaurant.dish_tags.filter(
              tag => dish.tags.indexOf(tag._id) !== -1
            );
            return (
              <Dish
                key={dish._id}
                index={k}
                dish={dish}
                category={category}
                tags={tags}
                className="col"
              />
            );
          })}
        </DishGrid>
      </DishGridWrapper>
    );
  }
}

export const CategorySection = withTranslation()(CategorySectionClass);
