import { observable, action } from "mobx";
import { RootStore } from "../store";
import { logger } from "@lib/common";
import { nanoid } from 'nanoid';
import { config } from "../../../config";
export interface OrderState {
	item: T.Schema.Order.OrderSchema | null;
	loading: boolean;
	error: string;
	showCardPaymentAuthenticationModal: boolean;
	paymentFormModal: string;
	initial_order_id: string;
}

export class OrderStore {

	@observable s: OrderState;

	store: RootStore;

	constructor(store: RootStore, initialState?: OrderState) {
		this.store = store;
		this.s = {
			item: null,
			loading: true,
			error: "",
			showCardPaymentAuthenticationModal: false,
			paymentFormModal: "",
			initial_order_id: "",
			...(initialState || {}),
		};
	}

	sync = () => {
		const { item } = this.s;
		const { order_id } = this.store;
		if (order_id !== null && (!item || item._id !== order_id)) {
			this.get(order_id);
		}
		else {
			this.update({ loading: false });
		}
	}

	@action async get(_id: string) {
		try {
			if (!this.s.error) {
				this.s.loading = true;
				const response = await this.store.api.order_find({ _id });
				if (response.outcome) {
					this.s.error = response.message;
					this.s.loading = false;
				}
				else {
					this.s.error = "";
					this.s.loading = false;
					this.s.item = response.order;
				}
			}
		}
		catch (e) {
			logger.captureException(e);
			this.s.loading = false;
			this.s.error = "generic";
		}
	}

	@action update = (data: Partial<OrderState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof OrderState];
				if (value !== undefined) {
					// @ts-ignore
					this.s[key as keyof OrderState] = value;
				}
			}
		}
	}

	@action updateItem(data: Partial<T.Schema.Order.OrderSchema>) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof T.Schema.Order.OrderSchema];
				if (value !== undefined && this.s && this.s.item) {
					// @ts-ignore
					this.s.item[key as keyof T.Schema.Order.OrderSchema] = value;
				}
			}
		}
	}

	@action generateOrderId(forced = false) { // only if the order._id is not set. It must be cleared after each successful order creation or restart. Otherwise, risk creating duplicate orders.
		const initial_order_id = nanoid();
		if (forced) {
			this.s.initial_order_id = initial_order_id;
			localStorage.setItem('initial_order_id',initial_order_id);
			this.restartOpenReplay(false);
		}
		
	 	// TODO: Use React implementation after upgrade to NodeJS 14+
		// Check OpenReplay
		//@ts-ignore
		if (this.isEnabledOpenReplayReseller() && OpenReplay !== undefined){
			//@ts-ignore
			if(forced && OpenReplay.isActive()) { //its forced then stop recording
				console.log('stopping record openreplay');
				setTimeout(() =>  {
					//@ts-ignore
					OpenReplay.stop();
				}, 2000); //give 2 seconds allowance to stop
		
				
			}
		}
	 }

	@action getGeneratedOrderId(){
		return localStorage.getItem('initial_order_id') || this.s.initial_order_id;
	}

	@action restartOpenReplay(restart = true) {
		//@ts-ignore
		if(this.isEnabledOpenReplayReseller() && OpenReplay !== undefined) {
			//@ts-ignore
			OpenReplay.setUserID(this.getGeneratedOrderId());
			//@ts-ignore
			OpenReplay.setMetadata("reseller_id", this.store.reseller._id);
			//@ts-ignore
			OpenReplay.setMetadata("restaurant_id", this.store.restaurant._id);
			//@ts-ignore
			OpenReplay.setMetadata("restaurant_name", this.store.restaurant.name);
			if(restart) {
				//@ts-ignore
				OpenReplay.start();
				console.log('restarting record openreplay');
			}
		}
	}

	@action isEnabledOpenReplayReseller() {
		let open_replay_resellers;
		if (this.store.reseller && 
			config.open_replay.resellers_enabled) {
			open_replay_resellers = config.open_replay.resellers_enabled.split(",");
		}
		return config.open_replay.global_enabled &&
		config.open_replay.global_enabled == "true" &&
		open_replay_resellers &&
		open_replay_resellers.indexOf(this.store.reseller._id) > -1;
	}

	@action reset = () => {
		this.update ({
			item: null,
			loading: true,
			error: ""
		})
	}

}
