import * as React from "react";
import { styled, withTheme } from "@lib/components";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/component";
import { action } from "mobx";
import { TabSelect } from "../../../../components/tab-select";

const Tabs = styled("div")`
	flex-grow: 1;
`;

@inject("store")
@observer
class SliderMenuBarClass extends MobxComponent<{}, {}> {
	@action onMenuChange = (id: string) => {
		this.setState({ active: false });
		this.injected.store.filters.s.menu = id;
		this.injected.store.filters.s.category = "";
		this.injected.store.view.scrollToMenu();
	};

	render() {
		const { theme, store } = this.injected;
		const menus = store.menus;

		if (menus.length < 2) return null;

		const menuOptions = menus.map((m) => ({
			value: m._id,
			label: m.display_name || m.name,
		}));

		const highlightSelectedStyle =
			store.restaurant.website.sections.menu_nav_bar
				?.slider_highlight_selected_style;

		return (
			<Tabs>
				<TabSelect
					id="menu-tab-select"
					background={theme.top_nav.background_color}
					screenWidth={store.view.s.screen_width}
					value={store.filters.s.menu}
					values={menuOptions}
					onChange={(v) => this.onMenuChange(v.value)}
					highlightSelectedStyle={highlightSelectedStyle}
				/>
			</Tabs>
		);
	}
}

export const SliderMenuBar = withTheme(SliderMenuBarClass);
