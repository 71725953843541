import * as React from "react";
import { inject, observer } from "mobx-react";
import { CheckoutFormModal } from "./checkout-form";
import { MobxComponent } from "../../../mobx/component";
import { InputField, PaymentMethod } from './flows/types';

interface Props { }
interface State { }

const TESTING_PAYMENT_ENDPOINT = "https://sandbox.sgepay.com/SgepayPGWApi/SgepayPGWApi";
const PRODUCTION_PAYMENT_ENDPOINT = "https://www.sgepay.com/SgepayPGWApi/SgepayPGWApi";

@inject("store")
@observer
export class SgepayFormModal extends MobxComponent<Props, State> {
	render() {
		const { store } = this.injected;
		const restaurant = store.restaurant;
		const sgepay = restaurant.settings.payments.sgepay;

		const order = store.order.s.item;

		const isOrderUsingSgepay = order?.payment.method === "sgepay";

		if (
			!order ||
			!isOrderUsingSgepay ||
			!sgepay ||
			!sgepay.Mid ||
			!sgepay.RequestId
		)
			return null;

		const paymentEndpoint = sgepay?.testing ? TESTING_PAYMENT_ENDPOINT : PRODUCTION_PAYMENT_ENDPOINT;
		const inputFields: InputField[] = [
			{
				name: "Mid",
				value: sgepay.Mid,
			},
			{
				name: "RequestId",
				value: sgepay.RequestId,
			},
			{
				name: "BillNo",
				value: order._id,
				label: "Order ID",
			},
			{
				name: "Email",
				value: order.customer.email,
				label: "Email",
			},
			{
				name: "Amount",
				value: order.bill.total.toFixed(2),
				label: `Amount (${restaurant.settings.region.currency.code!})`,
			},
			{
				name: "CustomerName",
				value: order.customer.name,
				label: "Customer Name",
			},
			{
				name: "WebUrl",
				value: "",
			}
		];

		const paymentMethod: PaymentMethod = {
			name: order.payment.method,
			urlEndpoint: paymentEndpoint,
			label: "SGEPAY"
		};

		return (
			<CheckoutFormModal
				inputFields={inputFields}
				paymentMethod={paymentMethod}
				orderId={order._id}
			/>
		);
	}
}
