import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { RotateLoader } from '@lib/components';
import { observer } from 'mobx-react';
import { RestaurantUtils } from '@lib/common';
import { useStores } from '../../../../mobx/useStores';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { genRecaptchaToken } from '../../../../libs/grecaptcha';

const InfoText = styled.div`
  padding: 14px 14px;
  text-align: center;
  font-size: 16px;
`;

interface Props {
  currency: string;
  amount: number;
  amountSubunit: number;
  restaurantId: string;
}

export const CheckoutForm = observer((props: Props) => {
  const store = useStores();
  const { t } = useTranslation();

  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const [canMakePayment, setCanMakePayment] = useState(true);

  useEffect(() => {
    setPaymentRequest(null);
    createPaymentRequest();
  }, [stripe, store.checkout.s.error, store.checkout.orderValidated]);

  const createPaymentIntent = async () => {
    const { amount, currency, restaurantId } = props;
    const validateToken = await genRecaptchaToken();
    const payload: T.API.StripeLatest.CreateStripeWalletPaymentIntentRequest = {
      amount,
      currency,
      restaurantId,
      validateToken,
      serviceType: store.order_config.s.service,
      orderId: store.order.getGeneratedOrderId(),
    };

    if (store.order_config.s.delivery_provider === 'uber') {
      payload.serviceProvider = 'uber';
      payload.collectedFees = store.checkout.computeStripeUberCollectedFees();
    }

    const response = await store.api.createStripeWalletPaymentIntent(payload);
    if (response.outcome === 0) {
      store.checkout.update({
        stripe_digital_wallet_payment_intent_id: response.paymentIntentId,
        stripe_digital_wallet_payment_client_secret: response.paymentIntentClientSecret!,
      });

      return response.paymentIntentClientSecret;
    }

    return null;
  };

  const createPaymentRequest = async () => {
    if (!stripe || store.checkout.validateOrderData()) {
      return;
    }

    const { amountSubunit: amount, currency } = props;
    const country = RestaurantUtils.settings.getCountryCodeFromLocation(store.restaurant!);

    const pr = stripe.paymentRequest({
      country,
      currency,
      total: {
        label: store.restaurant.name,
        amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    const result = await pr.canMakePayment();
    if (result) {
      setPaymentRequest(pr);
      processPayment(pr);
    } else {
      setCanMakePayment(false);
    }
  };

  const processPayment = (pr: PaymentRequest) => {
    if (!pr) return;

    pr.on('paymentmethod', async ev => {
      const clientSecret = await createPaymentIntent();
      if (!clientSecret) {
        return;
      }

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { paymentIntent, error: confirmError } = await stripe!.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        ev.complete('fail');

        await handleErrorPayment(confirmError);
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');

        // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
        // handle the flow. If using an API version older than "2019-02-11"
        // instead check for: `paymentIntent.status === "requires_source_action"`.
        if (paymentIntent && paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe!.confirmCardPayment(clientSecret);
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            await handleErrorPayment(error);
          } else {
            // The payment has succeeded.
            await handleSuccessPayment();
          }
        } else {
          // The payment has succeeded.
          await handleSuccessPayment();
        }
      }
    });
  };

  const handleSuccessPayment = async () => {
    setPaymentRequest(null);

    const order = await createOrderWithRetry();
    if (!order) {
      store.checkout.update({
        error: 'The order cannot be created at this time. Any payment made will be reversed.',
        loading: false,
      });
      store.checkout.handleFailedOrderCreation(
        store.checkout.s.stripe_digital_wallet_payment_intent_id,
        store.restaurant._id
      );
    } else {
      store.router.push(`/order/${order._id}`);
    }
  };

  const handleErrorPayment = async (error: any) => {
    if (error && error.code) {
      let message = t('store.modals.checkout.errors.payment_fail') + `[cause: ${error.code}`;
      if (error.decline_code) {
        message += ' | ' + error.decline_code;
      }
      message += `]`;
      store.checkout.update({ error: message });
    }
  };

  const createOrderWithRetry = async (): Promise<T.Schema.Order.OrderSchema | undefined> => {
    let order;
    let currentTry = 0;
    const maxRetries = 1;
    while (!order && currentTry <= maxRetries) {
      order = await store.checkout.order_commence();
      if (currentTry > 0) await new Promise(r => setTimeout(r, 500));
      currentTry++;
    }
    return order;
  };

  if (!canMakePayment) {
    return (
      <InfoText>
        Unfortunately, your current device doesn't support Apple Pay/Google Pay. You can try using a compatible device
        or set up your digital wallet to proceed with the transaction.
      </InfoText>
    );
  }

  if (paymentRequest) {
    return (
      <PaymentRequestButtonElement
        options={{ paymentRequest, style: { paymentRequestButton: { type: 'check-out' } } }}
      />
    );
  }

  return <RotateLoader size={3} style={{ marginTop: '0.5rem' }} />;
});
