import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { Button } from "@lib/components";
import { Circle, CircleWrapper, Content, Wrapper } from "./components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";
import { CategorySection } from "./category";

interface Props extends WithTranslation {}

@inject("store")
@observer
class DividedIntoCategoriesLayoutClass extends MobxComponent<Props> {
	render() {
		const { store, t } = this.injected;
		const menu = store.menu;
		const doesMenuHaveRestrictions = store.doesMenuHaveRestrictions;
		const menuAvailability = store.isMenuAvailable;

		return (
			<Wrapper id="dish-grid">
				<CircleWrapper>
					<Circle />
				</CircleWrapper>

				{!menu && (
					<Content>
						<div className="m-t-6 p-2 error-bg max400 text-center center round">
							<p>{t("store.sections.menus.info.no_menus")}</p>
						</div>
					</Content>
				)}

				{menu && (
					<Content>
						{menu.description && (
							<div
								className={cn(
									"text-center",
									doesMenuHaveRestrictions ? "m-b-4" : "m-b-8"
								)}
							>
								<p className="lhp">{menu.description}</p>
							</div>
						)}

						{doesMenuHaveRestrictions && (
							<div className="m-b-8 text-center">
								<Button
									size="xs"
									color="primary-inverse"
									className="m-b-1"
									onClick={() =>
										store.modal.toggle("menu-restrictions")
									}
								>
									{menuAvailability.available &&
										t(
											"store.sections.menus.info.restrictions"
										)}
									{!menuAvailability.available &&
										t(
											"store.sections.menus.info.not_available"
										)}
								</Button>
							</div>
						)}

						<div>
							{menu.categories.map((category) => {
								return (
									<CategorySection
										key={category._id}
										category={category}
									/>
								);
							})}
						</div>
					</Content>
				)}
			</Wrapper>
		);
	}
}

export const DividedIntoCategoriesLayout = withTranslation()(DividedIntoCategoriesLayoutClass);
