import * as React from "react";
import cn from "classnames";
import { MobxComponent } from "../../../../mobx/component";
import { DishModalIngredients } from "./ingredients";
import { ModalDropContent, ModalSelectSection, ModalContent } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { DishModalOptionSet } from "./option-sets";
import Big from "big.js";
import { ErrorBoundary } from "react-error-boundary";
import { OptionSetErrorFallback } from "./option-set-error";

interface Props extends WithTranslation {
	dish: T.Schema.Order.OrderDish;
	choice: T.Schema.Restaurant.Menu.RestaurantDishChoice;
	onSelect: (_id: string) => void;
	updateDish: (dish: Partial<T.Schema.Order.OrderDish>) => void;
	errors: boolean | boolean[];
}

interface State { }

@inject("store") @observer
export class DishModalChoiceClass extends MobxComponent<Props, State> {
	isCategoryAvailable = (category: T.Schema.Restaurant.Menu.RestaurantCategory) => {
		const { isCategoryAvailable } = this.injected.store;
		return isCategoryAvailable(category).available;
	}

	render() {
		const { t, store } = this.injected;
		const { choice, updateDish, errors, onSelect } = this.props;
		const isDifferencePricing = this.props.dish.price_type === "difference";
		const dish = choice.selected;

		const options = [];
		for (const menu of store.menus) {
			for (const category of menu.categories) {
				for (const d of category.dishes) {
					if (choice.dishes.indexOf(d._id) !== -1) {
						const o = {
							label: d.display_name || d.name,
							sublabel: "",
							value: d._id,
							disabled: false,
						};

						const stock = store.restaurantStock.dishes[d._id];
						const isNoStock = typeof stock === "number" && stock <= 0;

						if (!this.isCategoryAvailable(category)) {
							o.sublabel = `${t("restaurant.menus.dish.not_available")}`;
							o.disabled = true;
						}
						else if (d.status === "no-stock" || isNoStock) {
							o.sublabel = `${t("restaurant.menus.dish.no_stock")}`;
							o.disabled = true;
						}
						else if (d.status === "not-available") {
							o.sublabel = `${t("restaurant.menus.dish.not_available")}`;
							o.disabled = true;
						}
						else if (isDifferencePricing && d.price !== choice.lpo) {
							o.sublabel = `+${t("currency", { value: Big(d.price).minus(choice.lpo).toString() })}`;
						}

						options.push(o);
					}
				}
			}
		}

		const title = dish ? (
			<div className="flex-l-r-center flex-grow">
				<div>
					<p className="font-semi-bold">{dish.display_name || dish.name}</p>
					<p className="m-t-1 small">{choice.name}</p>
				</div>
				{(isDifferencePricing && dish.price !== choice.lpo) && <p className="p-r-5">+{t("currency", { value: Big(dish.price).minus(choice.lpo).toString() })}</p>}
			</div>
		) : (
			<p className={cn("font-semi-bold", { "error-text": errors === true })}>{choice.name}</p>
		);

		return (
			<ModalSelectSection
				id={choice._id}
				title={title}
				selectTitle={t("store.modals.dish.combo.choose_dish")}
				selected={choice.selected ? choice.selected._id : ""}
				options={options}
				onSelect={(o) => onSelect(o.value)}
				level={2}
				paddingtb={15}
				paddinglr={25}>
				{dish && (
					<ModalContent paddinglr={0} paddingtb={0}>
						{(dish.option_sets.length > 0) && dish.option_sets.map((os, i) => (
							<ErrorBoundary
								key={i}
								FallbackComponent={OptionSetErrorFallback}
								onReset={() => store.dish.set(this.props.dish._id)}
								resetKeys={[dish.option_sets]}
							>
								<DishModalOptionSet
									key={i}
									totalOptionSets={dish.option_sets.length}
									dish_id={dish._id}
									dish_type={dish.type}
									choice_index={i}
									option_set={os}
									option_sets={dish.option_sets}
									point_consumption_id={store.dish.constructIdForDishPointItem(this.props.dish._id, choice._id, dish._id)}
									error={typeof errors !== "boolean" ? errors[i] : false}
									total_points={store.dish.dishTotalPoints}
									update={(option_set) => {
										const option_sets = [...dish.option_sets];
										option_sets[i] = option_set;
										updateDish({ option_sets });
									}}
								/>
							</ErrorBoundary>
						))}

						{((dish.ingredients || []).length > 0) && (
							<ModalDropContent
								title={
									<div>
										<p className="font-semi-bold">{t("store.modals.dish.ingredients.title")}</p>
										<p className="small m-t-1">{t("store.modals.dish.ingredients.uncheck_remove")}</p>
									</div>
								}
								paddingtb={15}
								paddinglr={25}
								cPaddingtb={15}
								cPaddinglr={25}>
								<DishModalIngredients
									ingredients={dish.ingredients}
									update={(ingredients) => {
										updateDish({ ingredients });
									}}
								/>
							</ModalDropContent>
						)}
					</ModalContent>
				)}
			</ModalSelectSection>
		);
	}
}

export const DishModalChoice = withTranslation()(DishModalChoiceClass);
