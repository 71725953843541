import axios from 'axios';
import _toString from 'lodash/toString';
import { config } from "../../../../../config";
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../mobx/component';
interface Options {
	restaurantID: string,
	amount: number;
	currency: string;
	receipt: string;
	items?: Array<T.Schema.Order.OrderDish>;
}

const ensureScriptIsLoaded = (url: any) => {
	const promisesMap: any = {};
	const loadedScripts: any = {};
	if (loadedScripts[url]) return;
	if (promisesMap[url]) return promisesMap[url];

	const promise = new Promise((resolve, reject) => {
		const scriptElement = document.createElement('script')
		const installTimeoutId = window.setTimeout(() => {
			promisesMap[url] = null
			reject(new Error(`Failed to load ${url} in 20s`))
		}, 20000)
		scriptElement.onload = () => {
			if (installTimeoutId) window.clearTimeout(installTimeoutId);
			loadedScripts[url] = true;
			promisesMap[url] = null;
			//@ts-ignore
			resolve();
		}

		scriptElement.onerror = err => {
			if (installTimeoutId) window.clearTimeout(installTimeoutId)
			promisesMap[url] = null
			reject(err)
		}
		scriptElement.src = url
		document.body.appendChild(scriptElement)
	})
	promisesMap[url] = promise
	return promise
}
export default class RazorPayProcessor {

	async createOrder(opts: Options) {
		let result;
		const { restaurantID, amount, currency, receipt, items } = opts;
		const data = {
			restaurantID,
			amount,
			currency,
			receipt,
			notes: null
		}
		if (items) {
		}
		await this.post('/stores/razor-pay/create-order', data, 'Failed to create order to Razor Pay.').then((resp) => {
			result = resp;
		});
		return result;
	}

	handlePayment(order: any, store: any) {
		return new Promise(async (resolve) => {
			try {
				const url = 'https://checkout.razorpay.com/v1/checkout.js';
				await ensureScriptIsLoaded(url);

				const restaurant = store.restaurant;
				const razorPay = restaurant.settings.payments.razor_pay!;
				const publicKey = _toString(razorPay.razor_pay_public_key);
				var options = {
					key: publicKey, // Enter the Key ID generated from the Dashboard
					amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
					currency: order.currency,
					name: restaurant.name,
					description: "",
					order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
					handler: (response: any) => {
						resolve({ data: response });
					},
					prefill: {
						name: store.checkout.s.name,
						email: store.checkout.s.email,
						contact: store.checkout.s.phone
					},
					notes: order.notes,
					theme: {
						"color": "#3399cc"
					}
				};
				//@ts-ignore
				var rzp1 = new Razorpay(options);
				rzp1.on('payment.failed', (response: any) => {

				});
				rzp1.open();
			}
			catch (err) {
				resolve({ paymentErr: err });
			}
		})
	}

	async paymentProcess(opts: Options, store: any) {
		let result: any = {};
		try {
			const resp: any = await this.createOrder(opts);
			if (resp.data) {
				const order = resp.data;
				result = await this.handlePayment(order, store);
			} else {
				result = resp;
			}
		} catch (e) {
			result.paymentErr = e.message;
		}
		return result;
	}

	post(path: string, data: object, message: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: config.api_url + path,
				data: data,
				headers: { 'Content-Type': 'application/json' }
			}).then((response: any) => {
				if (response.data.outcome == 0) {
					resolve(response.data);
				} else {
					reject(new Error(message));
				}
			}).catch((error) => {
				reject(error);
			});
		});
	}
}
