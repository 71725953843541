import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Input, styled, shadeAlternate, ModalTitle, Button } from "@lib/components";
import Big from "big.js";

interface Props extends WithTranslation { }
interface State {
	tipping: {
		enabled: boolean;
		option_1: number;
		option_2: number;
		option_3: number;
		default: 0 | 1 | 2 | 3;
	};
	exceededTip : boolean;
}

export const TipInputWrapper = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  // align-items: stretch;
  line-height: 0;
  border-bottom: 1px solid ${({ theme }) => theme.box.border};
`;

export const TipInput = styled(Input)`
  background: ${({ theme }) => theme.box.background};
  padding: 0 25px 0 8px;
  height: 46px;
  border: none !important;
  border-radius: 0;
  min-width: 100px;
  &:focus {
    border: none !important;
  }
`;

export const Buttons = styled(Button)`
  border-radius: 0;
  width: 60px;
  height: auto;
  font-size: 14px;
  border-top: none;
  border-left: 1px solid ${({ theme }) => theme.box.border} !important;
  border-right: none;
  border-bottom: none;
  background: ${({ theme }) => theme.box.background};
  color: ${({ theme }) => shadeAlternate(0.1, theme.box.text)};
  &:hover {
    box-shadow: none;
  }
`;

@inject("store") @observer
class CartTippingClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props)
		//
		const { store } = this.injected;
		let tipping = store.restaurant.settings.business.tipping
		const total = store.cart.totalCart;
		if (tipping === undefined || tipping === null) {
			tipping = {
				enabled: false,
				option_1: 10,
				option_2: 15,
				option_3: 20,
				default: 0
			}
		} else if (store.restaurant.settings.business.tipping) {
			tipping = {
				enabled: store.restaurant.settings.business.tipping.enabled || false,
				option_1: store.restaurant.settings.business.tipping.option_1 || 10,
				option_2: store.restaurant.settings.business.tipping.option_2 || 15,
				option_3: store.restaurant.settings.business.tipping.option_3 || 20,
				default: store.restaurant.settings.business.tipping.default || 0
			}
		}
		//
		if (tipping.default !== 0) {
			if (tipping.default === 1) {
				this.setTipPercent(tipping.option_1 / 100)
			} else if (tipping.default === 2) {
				this.setTipPercent(tipping.option_2 / 100)
			} else if (tipping.default === 3) {
				this.setTipPercent(tipping.option_3 / 100)
			}
		}
		this.state = {
			tipping: tipping,
			exceededTip: false,
		}
	}

	getTipAmount = (p: number) => {
		const { store } = this.injected;
		const total = store.cart.totalCart;
		const { precision } = store.intl.s.currency;
		return Big(total).times(p).toFixed(precision);
	}

	setTipPercent = (p: number) => {
		const { store } = this.injected;
		const tip = this.getTipAmount(p);
		store.cart.update({ tip });
	}

	render() {
		const { t, store } = this.injected;
		const { symbol } = store.intl.s.currency;
		const total = store.cart.totalCart;
		const totalTip = store.cart.s.tip;
		//
		const color1 = this.getTipAmount(this.state.tipping.option_1 / 100) === totalTip ? "rgb(220,220,220)" : "rgb(255,255,255)"
		const color2 = this.getTipAmount(this.state.tipping.option_2 / 100) === totalTip ? "rgb(220,220,220)" : "rgb(255,255,255)"
		const color3 = this.getTipAmount(this.state.tipping.option_3 / 100) === totalTip ? "rgb(220,220,220)" : "rgb(255,255,255)"
		//
		const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
			const value = e.target.value;

			if (Number(value) > total) this.setState({ exceededTip: true})

			if (Number(value) >= 0 && Number(value) <= total) {
				this.setState({ exceededTip: false})
					store.cart.update({ tip: value });
			}
		};

		return (
			<>
				<ModalTitle paddinglr={25} paddingtb={15}>
					<h4 className="font16">{t("store.modals.cart.tip.title")}</h4>
				</ModalTitle>
				<TipInputWrapper>
					<div className="p-l-5 flex-center font16 line-height-0">
						{symbol}
					</div>
					<TipInput
						min="0"
						type="number"
						step="0.01"
						name="promo"
						onKeyPress={(event) => {
							let regex = new RegExp("^[-+Ee]");
							var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
							if (regex.test(key)) {
								event.preventDefault();
								return false;
							}
						}}
						value={store.cart.s.tip}
						placeholder={t("store.modals.cart.tip.placeholder")}
						onChange={handleChange}
					/>
					<Buttons style={ color1 == "rgb(220,220,220)" ? { backgroundColor: "rgb(212,53,56)", color: "rgb(255,255,255)"} : {}} onClick={() => this.setTipPercent(this.state.tipping ? this.state.tipping.option_1 / 100 : 0.10)}>{this.state.tipping ? this.state.tipping.option_1 : 10}%</Buttons>
					<Buttons style={ color2 == "rgb(220,220,220)" ? { backgroundColor: "rgb(212,53,56)", color: "rgb(255,255,255)"} : {}} onClick={() => this.setTipPercent(this.state.tipping ? this.state.tipping.option_2 / 100 : 0.15)}>{this.state.tipping ? this.state.tipping.option_2 : 15}%</Buttons>
					<Buttons style={ color3 == "rgb(220,220,220)" ? { backgroundColor: "rgb(212,53,56)", color: "rgb(255,255,255)"} : {}} onClick={() => this.setTipPercent(this.state.tipping ? this.state.tipping.option_3 / 100 : 0.20)}>{this.state.tipping ? this.state.tipping.option_3 : 20}%</Buttons>
				</TipInputWrapper>
			</>
		);
	}

}

export const CartTipping = withTranslation()(CartTippingClass);
