import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, styled, withTheme, LinkTag } from "@lib/components";
import { logger } from "@lib/common";
import { MobxComponent } from "../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }

const MapEmbed = styled("div")`
  height: 360px;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

@inject("store") @observer
class MapModalClass extends MobxComponent<Props> {

	mb?: mapboxgl.Map;

	componentDidUpdate() {
		const { modal } = this.injected.store;
		if (modal.isVisible("map")) {
			this.draw();
		}
	}

	draw = () => {
		if (typeof window === "undefined") return;
		setTimeout(() => {
			try {
				const { theme, t } = this.injected;
				const { restaurant } = this.injected.store;
				const { location } = restaurant;
				const { map_data } = location;
				const delivery = restaurant.settings.services.delivery;
				if (map_data.type !== "custom") {

					const el = document.getElementById("map-embed");

					if (!el) return;

					const { zones } = delivery;

					this.mb = new mapboxgl.Map({
						container: el,
						style: "mapbox://styles/mapbox/streets-v11",
						center: [map_data.lng, map_data.lat],
						zoom: restaurant.website.map_zoom || 12,
					});

					new mapboxgl.Marker({ color: theme.colors.primary })
						.setLngLat([map_data.lng, map_data.lat])
						.addTo(this.mb);

					if (zones.length > 0) {
						this.mb.on("load", () => {
							if (this.mb) {
								for (const zone of delivery.zones) {

									this.mb.addLayer({
										id: zone._id,
										type: "fill",
										layout: {},
										paint: {
											"fill-color": "#292929",
											"fill-opacity": 0.2,
										},
										source: {
											type: "geojson",
											data: {
												type: "Feature",
												properties: {},
												geometry: {
													type: "Polygon",
													coordinates: [zone.polygon.map((p) => [p.lng, p.lat])],
												},
											},
										},
									});

									this.mb.on("click", zone._id, (e) => {
										if (this.mb) {
											new mapboxgl.Popup()
												.setLngLat(e.lngLat)
												.setHTML(`<div style="color: #333;"><p class="font-bold m-b-1">${zone.name}</p><p>${t("store.modals.map.zone_fee")}: ${t("currency", { value: zone.fee || 0 })}</p></div>`)
												.addTo(this.mb);
										}
									});

									this.mb.on("mouseenter", zone._id, () => {
										if (this.mb) {
											this.mb.getCanvas().style.cursor = "pointer";
										}
									});

									this.mb.on("mouseleave", zone._id, () => {
										if (this.mb) {
											this.mb.getCanvas().style.cursor = "";
										}
									});

								}
							}
						});
					}

				}
			}
			catch (e) {
				logger.captureException(e);
			}
		}, 500);
	}

	render() {
		const { t } = this.injected;
		const { restaurant } = this.injected.store;
		const { modal } = this.injected.store;
		const r = this.injected.store.restaurant;
		const md = r.location.map_data;
		return (
			<Modal
				id="modal-map-embed"
				width="md"
				closeButton={true}
				active={modal.isVisible("map")}
				close={() => modal.hide("map")}>
				<ModalContent paddingtb={0} paddinglr={0}>
					<MapEmbed id="map-embed" />
				</ModalContent>
				<ModalContent paddingtb={25} paddinglr={25} className="text-center">
					<p>{restaurant.location.address}</p>
					{md.type !== "custom" && (
						<LinkTag className="inline-block m-t-2" target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${md.lat},${md.lng}`}>
							{t("store.modals.map.view_in_google")}
						</LinkTag>
					)}
				</ModalContent>
			</Modal>
		);
	}

}

// @ts-ignore
export const MapModal = withTheme(withTranslation()(MapModalClass));
