import { observable, action, computed, toJS } from 'mobx';
import { RootStore } from '../store';
import autobind from 'autobind-decorator';
import { Social } from '../../libs/social';
import { logger, CoreUtils } from '@lib/common';
import * as React from 'react';
import { GN } from '@lib/common';

export type CustomerState = {
  item: T.Schema.Customer.CustomerSchema | null;
  decoded: T.Core.Auth.JWTCustomerDecoded | null;
  token: string;
};

interface LoginState {
  login: string;
  password: string;
  error: string;
  submitting: boolean;
}
interface RegisterState {
  step: 0 | 1;
  login: string;
  password: string;
  accept_terms: boolean;
  confirmation_code: string;
  error: string;
  submitting: boolean;
  resent: boolean;
}
export interface CustomerFormState {
  current: 'login' | 'register';
  register: RegisterState;
  login: LoginState;
  fb: {
    loading: boolean;
    error: string;
  };
  google: {
    loading: boolean;
    error: string;
  };
}

let timer_auth_logout: number | NodeJS.Timer | null = null;
let timer_auth_refresh: number | NodeJS.Timer | null = null;

export const generateLoginState = () => ({
  login: '',
  password: '',
  error: '',
  submitting: false,
});

export const generateRegisterState = () => ({
  step: 0 as 0,
  login: '',
  password: '',
  accept_terms: false,
  confirmation_code: '',
  error: '',
  submitting: false,
  resent: false,
});

@autobind
export class CustomerStore {
  @observable s: CustomerState;
  @observable form: CustomerFormState;

  store: RootStore;

  constructor(
    store: RootStore,
    initialState?: CustomerState,
    initialFormState?: CustomerFormState
  ) {
    this.store = store;

    this.s = initialState || {
      item: null,
      decoded: null,
      token: '',
    };

    this.form = {
      current: 'login',
      register: generateRegisterState(),
      login: generateLoginState(),
      fb: {
        loading: true,
        error: '',
      },
      google: {
        loading: true,
        error: '',
      },
      ...(initialFormState || {}),
    };

    this.initNormal();
    // ALLOW TIME FOR SOCIAL LIBRARIES TO LOAD ASYNC
    setTimeout(() => this.initSocial(), 1000);
  }

  async initNormal() {
    try {
      if (typeof window === 'undefined' || this.s.item) return;

      const token = localStorage.getItem('customer-at');

      if (!token) return;

      const response = await this.store.api.customer_refresh({ token });

      if (response.outcome) {
        return; // LOGIN SESSION EXPIRED
      }

      this.handleEmailLogin(response);

    } catch (e) {
      logger.captureException(e);
    }
  }

  initSocial() {
    // CALL IN COMPONENT TO ENSURE IT BUTTON IS RENDERED FIRST
    const isWebView = this.store.view.s.is_web_view;
    const { disable_signup } = this.store.restaurant.settings.business;
    if (
      disable_signup ||
      isWebView ||
      typeof window === 'undefined'
    )
      return;

    // already logged in
    // TODO: Improve criteria
    if (this.s.item) {
      this.postAuthActions(this.s.item);
      return;
    }
    // not logged in
    this.facebookInit();
    // this.googleInit();
  }

  async facebookInit() {
    try {
      this.setStatusFacebook(true, false);

      const r = this.store.restaurant;
      if (!r.settings.accounts.facebook.app_id) return;

      // WAIT FOR FACEBOOK TO LOAD
      while (!window.__fbInitialized || typeof FB === 'undefined') {
        await CoreUtils.fns.wait(1000);
      }

      const res = await Social.fb.getLoginStatus();

      await this.facebookHandleLogin(res);
    } catch (e) {
      this.facebookError(e);
    }
  }
  async facebookButtonClick() {
    try {
      this.setStatusFacebook(true, false);
      const res = await Social.fb.login();
      await this.facebookHandleLogin(res);
    } catch (e) {
      this.facebookError(e);
    }
  }
  async facebookHandleLogin(res: fb.StatusResponse) {
    if (res.status !== 'connected') {
      this.setStatusFacebook(false, false);
      return;
    }

    const { accessToken, userID } = res.authResponse;

    const data = await this.store.api.customerLoginFacebook({
      uid: userID,
      token: accessToken,
    });
    if (data.outcome) {
      this.setStatusFacebook(false, true);
      return;
    }

    this.handleSocialLogin(data.customer);
    this.setStatusFacebook(false, false);
  }
  facebookError = (e: string | T.ObjectString) => {
    if (
      typeof e === 'string' ||
      e.error !== 'idpiframe_initialization_failed'
    ) {
      logger.captureException(e);
    }
    this.setStatusFacebook(false, true);
  };

  async register_commence(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const { termsActive } = this.store;
      const { login, password, accept_terms } = this.form.register;

      // CHECK INPUT FOR ERRORS
      if (termsActive && !accept_terms) {
        this.updateRegister({ error: 'error_accept_terms' });
        return;
      } else if (password.length < 6) {
        this.updateRegister({ error: 'error_password_length' });
        return;
      }

      // START LOAD
      this.updateRegister({ submitting: true, error: '' });
      const response = await this.store.api.customer_register_commence({
        login,
      });
      if (response.outcome) {
        this.updateRegister({ submitting: false, error: response.message });
        return;
      }

      this.updateRegister({ submitting: false, step: 1 });
    } catch (e) {
      logger.captureException(e);
      this.updateRegister({ submitting: false, error: 'error_generic' });
    }
  }
  async register_complete(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      const { login, password, confirmation_code } = this.form.register;

      // CHECK INPUT FOR ERRORS
      if (
        !confirmation_code ||
        confirmation_code.length < 4 ||
        confirmation_code.length > 7
      ) {
        this.updateRegister({ error: 'error_confirmation_code' });
        return;
      }

      // START LOAD
      this.updateRegister({ submitting: true, error: '' });
      const response = await this.store.api.customer_register_complete({
        login,
        password,
        confirmation_code,
      });
      if (response.outcome) {
        this.updateRegister({ submitting: false, error: response.message });
        return;
      }

      await this.login_email(login, password);

      fbq('track', 'CompleteRegistration');
    } catch (e) {
      logger.captureException(e);
      this.updateRegister({ submitting: false, error: 'error_generic' });
    }
  }
  async register_resend() {
    try {
      const { login } = this.form.register;
      this.updateRegister({ submitting: true, error: '' });
      const response = await this.store.api.customer_register_commence({
        login,
      });
      if (response.outcome) {
        this.updateRegister({ submitting: false, error: response.message });
        return;
      }
      this.updateRegister({ submitting: false, resent: true });
    } catch (e) {
      logger.captureException(e);
      this.updateRegister({ submitting: false, error: 'error_generic' });
    }
  }
  async register_restart() {
    this.updateRegister({
      step: 0,
      login: '',
      password: '',
      accept_terms: false,
      confirmation_code: '',
      error: '',
      submitting: false,
      resent: false,
    });
  }

  @action async login_email(login: string, password: string) {
    try {
      this.updateLogin({ submitting: true, error: '' });
      const response = await this.store.api.customer_login({ login, password });
      if (response.outcome) {
        this.updateLogin({ submitting: false, error: response.message });
        return;
      }
      this.handleEmailLogin(response);
    } catch (e) {
      logger.captureException(e);
      this.updateLogin({ submitting: false, error: 'error_generic' });
    }
  }
  @action async login_email_refresh() {
    try {
      const { token } = this.s;
      if (token) return;
      const response = await this.store.api.customer_refresh({ token });
      if (response.outcome) {
        this.logout();
        return;
      }
      this.handleEmailLogin(response);
    } catch (e) {
      logger.captureException(e);
      // this.updateLogin({ submitting: false, error: "error_generic" });
    }
  }

  @action handleSocialLogin(customer: T.Schema.Customer.CustomerSchema) {
    this.s.item = customer;
    this.logoutEmail();
    this.handleLogin(customer);
  }

  @action handleEmailLogin(args: {
    customer: T.Schema.Customer.CustomerSchema;
    decoded: T.Core.Auth.JWTCustomerDecoded;
    token: string;
  }) {
    // SET DATA
    const { customer, decoded, token } = args;
    this.s.decoded = decoded;
    this.s.token = token;
    this.s.item = customer;

    // CLEAR LOGIN FIELD
    this.updateLogin(generateLoginState());
    this.updateRegister(generateRegisterState());

    // SET TIMERS & TOKEN
    localStorage.setItem('customer-at', token);
    this.login_timers(decoded.exp);

    this.handleLogin(customer);
  }

  handleLogin = async (c: T.Schema.Customer.CustomerSchema) => {
    const { intl } = this.store;

    this.store.checkout.update({
      name: c.details.name,
      email: c.details.email,
      phone: c.details.phone,
    });

    this.store.modal.back('auth');

    const path = this.store.router.s.path;
    if (['/login', '/register'].indexOf(path) !== -1) {
      this.store.router.push('/');
    }

    GN.add({
      type: 'success',
      message: `<p>${intl.i18n.t('store.modals.auth.logged_in')}</p>`,
      duration: 2000,
    });

    this.postAuthActions(c);

    this.store.cart.check_promo();

    this.store.order_history.update({ page: 1 });
    this.store.order_history.get(1);
  };

  postAuthActions = async (c: T.Schema.Customer.CustomerSchema) => {
    // load walletly points
    const walletly = this.store.restaurant.settings.loyalty_providers?.walletly;
    const restaurantId = this.store.restaurant._id
    if (walletly?.enabled) {
      await Promise.all([this.getWalletlyPoints(
        c.details.email,
        walletly?.brand_id || ''
      ),
      this.getWalletlyUserDeals(c.details.email, walletly?.brand_id, walletly.api_key, restaurantId)]);
    }
  }

  @action async getWalletlyPoints(email: string, brandId: string) {
    const apiKey = this.store.restaurant.settings.loyalty_providers?.walletly?.api_key;
    
    if (!apiKey) return;
    
    const response = await this.store.api.get_walletly_points({
      email,
      brandId,
      apiKey,
    });

    if (response.outcome) return;

    if (this.s.item) {
      this.s.item.loyalty = {
        ...this.s.item.loyalty,
        walletly_points: response.data.currentPoints,
      };
    }
  }

  @action async getWalletlyUserDeals(email: string, brandId: string, apiKey: string, restaurantId: string) {
    const response = await this.store.api.get_walletly_user_deals({
      email,
      brandId,
      apiKey,
      restaurantId
    });

    if (response.outcome) return;
    const { data, free_item_promos, conventional_discount_promos } = response;

    if (this.s.item) {
      this.s.item.loyalty = {
        ...this.s.item.loyalty,
        walletly_deals: data,
      };
    }
    if (free_item_promos) {
      this.store.restaurant.free_item_promos = [...this.store.restaurant.free_item_promos || [], ...free_item_promos];
    }
    if (conventional_discount_promos) {
      this.store.restaurant.conventional_discount_promos = [...this.store.restaurant.conventional_discount_promos || [], ...conventional_discount_promos];
    }
  }

  @action async walletlyApplyDeals(dealId: string) {
    
    const response = await this.store.api.walletly_apply_deals({
      dealId
    });

    if (response.outcome) return;

    if (this.s.item) {
      this.s.item.loyalty = {
        ...this.s.item.loyalty,
        walletly_deals: response.data,
      };
    }
  }

  login_timers(expiry: number) {
    if (expiry <= 0) {
      // PREVENT INFINITE REDIRECT LOOP
      this.logout();
      return;
    }

    expiry = expiry * 1000;

    const logout_time = Math.round(expiry - 120000 - Date.now()); // 2 min's before expiry logout
    const refresh_time = Math.round(expiry - 60000 * 60 * 24 - Date.now()); // 1 day before expiry refresh

    if (timer_auth_logout) clearTimeout(timer_auth_logout as number);
    if (timer_auth_refresh) clearTimeout(timer_auth_refresh as number);

    timer_auth_logout = setTimeout(
      () => this.logout(),
      Math.max(logout_time, 0)
    );
    timer_auth_refresh = setTimeout(
      this.login_email_refresh,
      Math.max(refresh_time, 0)
    );
  }

  @action async logout() {
    if (!this.s) return;

    const { intl } = this.store;

    // UNPOPULATED CHECKOUT
    this.store.checkout.update({
      name: '',
      email: '',
      phone: '',
      zip: '',
    });

    this.store.order_history.update({ items: [], count: 0 });

    try {
      // LOGOUT
      this.logoutEmail();
      this.logoutSocial();
      // CLEAR CART
      this.store.cart.clear();
    } catch (e) {
      logger.captureException('logout error', e);
    } finally {
      this.s.item = null;
      this.s.decoded = null;
      this.s.token = '';
      GN.add({
        type: 'success',
        message: `<p>${intl.i18n.t('store.modals.auth.logged_out')}</p>`,
        duration: 3000,
      });
    }
  }

  @action logoutEmail() {
    localStorage.setItem('customer-at', '');
  }
  @action logoutSocial() {
    const r = this.store.restaurant;
    const customer_type = this.s.item?.type;
    // LOGOUT FACEBOOK
    if (r.settings.accounts.facebook.app_id && customer_type === "facebook")
      Social.fb.logout().catch(logger.captureException);

    // LOGOUT GOOGLE
    if (r.settings.accounts.google.api_client_id && customer_type === "google")
      Social.google.logout().catch(logger.captureException);
  }

  // CUSTOMER
  @computed get display_name() {
    const c = this.s.item;
    if (!c) return '';
    return c.details.name || c.details.email || c.login || c._id;
  }

  @computed get walletly_points() {
    const c = this.s.item;
    if (!c) return -1;
    return c.loyalty?.walletly_points || -1;
  }

  @computed get isLoggedIn() {
    return !!this.s.item;
  }

  // UTILITY
  @action setStatusFacebook(loading: boolean, error: boolean) {
    this.form.fb.loading = loading;
    this.form.fb.error = error ? 'store.modals.auth.error_social' : '';
  }
  @action setStatusGoogle(loading: boolean, error: boolean) {
    this.form.google.loading = loading;
    this.form.google.error = error ? 'store.modals.auth.error_social' : '';
  }

  // UPDATING
  @action set(item: CustomerState) {
    this.s = item;
  }
  @action setCurrentForm(current: 'login' | 'register') {
    this.form.current = current;
  }
  @action update(data: Partial<CustomerState>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof CustomerState];
        if (value !== undefined && this.s) {
          // @ts-ignore
          this.s[key as keyof CustomerState] = value;
        }
      }
    }
  }
  @action updateRegister(data: Partial<RegisterState>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof RegisterState];
        if (value !== undefined) {
          // @ts-ignore
          this.form.register[key as keyof RegisterState] = value;
        }
      }
    }
  }
  @action updateLogin(data: Partial<LoginState>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof LoginState];
        if (value !== undefined) {
          // @ts-ignore
          this.form.login[key as keyof LoginState] = value;
        }
      }
    }
  }
  @action updateItem(data: Partial<T.Schema.Customer.CustomerSchema>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Schema.Customer.CustomerSchema];
        if (value !== undefined && this.s && this.s.item) {
          // @ts-ignore
          this.s.item[key as keyof T.Schema.Customer.CustomerSchema] = value;
        }
      }
    }
  }
}
