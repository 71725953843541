import { GN } from '@lib/common';
import { Button, styled } from '@lib/components';
import { toJS } from 'mobx';
import { MobXProviderContext, observer } from 'mobx-react';
import React from 'react';

type Props = {
  deal: { [key: string]: any };
};

const ImageWrapper = styled.img`
  width: 100%;
  height: 60%;
  padding: 1rem;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 20px 10px 20px;
`;
const ComponentWrapper = styled.div`
  border-bottom: 1px solid #d6d6d6;
`;
const Point = styled.h3`
  align: flex-start;
  color: ${({ theme }) => theme.colors.primary_text};
`;

function LoyatyDeals({ deal }: Props) {
  const { store } = React.useContext(MobXProviderContext);
  const hasCartItems = store.cart.s.items.length > 0;
  const havePromo = store.cart.s.promos[0];
  const promoList: any[] = [
    ...(store.restaurant.free_item_promos || []),
    ...(store.restaurant.conventional_discount_promos || []),
  ];

  const isSelectedService = store.order_config.s.service;
  const onSubmit = async () => {
    if (!isSelectedService) {
      store.modal.show('order-config');
      return;
    }

    if (!hasCartItems) {
      GN.add({
        type: 'error',
        message: `${store.intl.i18n.t(
          'store.modals.customer.loyalty_errors.invalid_cart_items'
        )}`,
        duration: 3000,
      });
      return;
    }
    if (havePromo) {
      GN.add({
        type: 'error',
        message: `${store.intl.i18n.t(
          'store.modals.customer.loyalty_errors.already_exists'
        )}`,
        duration: 3000,
      });
      return;
    }

    const found = promoList.find(
      (promo: any) => promo.condition.deal === deal.id
    );

    if (!found) {
      GN.add({
        type: 'error',
        message: `${store.intl.i18n.t(
          'store.modals.customer.loyalty_errors.not_found'
        )}`,
        duration: 3000,
      });
      return;
    }
    store.cart.updateFormPromo({ error: '', code: found.code });
    // store.cart.promoAdd(found.code);
    await store.cart.promoCodeApply();
    store.modal.show('cart');
  };

  return (
    <ComponentWrapper>
      <ImageWrapper src={deal.image} />
      <ActionWrapper>
        <Point>
          <Button
            style={{ border: 'none', cursor: 'text', pointerEvents: 'none' }}
            className=""
            size="xs"
            color="primary-inverse"
          >
            {deal.conditionValue}
          </Button>
        </Point>
        <Button
          className=""
          size="xs"
          color="primary-inverse"
          onClick={onSubmit}
          disabled={!deal || deal.valid === false}
        >
          {'Redeem'}
        </Button>
      </ActionWrapper>
    </ComponentWrapper>
  );
}

export default observer(LoyatyDeals);
