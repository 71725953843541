import React from "react";

interface Props {
	// checkout_public_key: string
	updateAthMovilStatus: Function;
	amount: number;
	reference: string;
	ath_movil_data: any;
	cart: any;
	changeLoading: Function;
}
interface State { }

class ATHMovilForm extends React.Component<Props, State> {
	componentDidMount = () => {
		//
		window.ath_reference = "";
		window.ath_update_function = this.props.updateAthMovilStatus;
		window.ath_update_function(false);
		window.changeLoading = this.props.changeLoading;
		let newScript = document.createElement("script");
		let total_tax = 0;
		this.props.cart.taxes.forEach((t: any) => {
			total_tax += t.amount;
		});
		let data = `ATHM_Checkout = {
                //
                env: '${this.props.ath_movil_data.env}',
                publicToken: '${this.props.ath_movil_data.public_token}',
                //
                timeout: 600,
                //
                theme: '${this.props.ath_movil_data.theme}',
                lang: '${this.props.ath_movil_data.lang_code}',
                //
                total: "${this.props.amount}",
                tax: ${total_tax},
                subtotal: ${this.props.amount - total_tax},
                //
                // metadata1: 'metadata1 test',
                // metadata2: 'metadata2 test',
                //
                items: [
                    {
                        "name": "${this.props.reference}",
                        "description": "Restaurant Order",
                        "quantity": "1",
                        "price": "${this.props.amount}",
                        "tax": "0.00",
                    },
                ],
                onCompletedPayment: function (response)
					{
						console.log("On Completed")
						// console.log({response})
						window.ath_reference = response.referenceNumber
						window.ath_update_function(true)
						//console.log("HERE111")
						window.changeLoading(false)
						document.getElementById("checkoutButton").click()
						// console.log({response})
					},
				onCancelledPayment: function (response)
					{
						console.log("On Cancelled")
						console.log({response})
					},
				onExpiredPayment: function (response)
					{
						console.log("On Expired")
						console.log({response})
					}
            }`;
		let inlineScript = document.createTextNode(data);
		newScript.appendChild(inlineScript);
		///@ts-ignore
		document.getElementById("ATHMOVIL_AREA").appendChild(newScript);
		//
		let script = document.createElement("script");
		script.setAttribute("id", "athmovillib");
		script.src = "https://www.athmovil.com/api/js/v2/athmovilV2.js";
		///@ts-ignore
		document.getElementById("ATHMOVIL_AREA").appendChild(script);
	};

	render() {
		return (
			<div
				style={{
					backgroundColor: "white",
					paddingTop: "20px",
					width: "100%",
					height: "1px",
				}}
			>
				<div id="ATHMOVIL_AREA"></div>
				<div
					id="ATHMovil_Checkout_Button"
					style={{ margin: "auto", width: "100%", display: "none" }}
				></div>
			</div>
		);
	}
}

export default ATHMovilForm;
