/// <reference types='@lib/postmates/types' />

import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Table, TableRow, TableCell, TableBody, RotateLoader } from "@lib/components";
import { Quote, QuoteCreateParams } from "../../../../../../../../lib/postmates/types/resources/Quotes";
import { MobxComponent } from "../../../../mobx/component";
import { withTheme } from "styled-components";

interface Props extends WithTranslation { }
interface State {
	quote: Quote | null;
	error: string;
	loading: boolean;
}

@inject("store") @observer
class PostmatesEstimationClass extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props)
		this.state = {
			quote: null,
			error: '',
			loading: false,
		}
	}
	async componentDidMount() {
		const { store } = this.injected;
		const restaurant = store.restaurant!;
		const location = restaurant.location;
		const orderConfig = store.order_config.s;
		const postmates = restaurant.settings.services.delivery.providers.postmates!;

		this.setState({ loading: true })
		let params = {
			v1_customer_id: postmates.customer_id,
			dropoff_address: orderConfig.destination,
			pickup_address: location.address,
			dropoff_latitude: orderConfig.lat,
			dropoff_longitude: orderConfig.lng,
			dropoff_phone_number: store.checkout.s.phone,
			pickup_latitude: location.map_data.type !== "custom" ? location.map_data.lat : undefined,
			pickup_longitude: location.map_data.type !== "custom" ? location.map_data.lng : undefined,
			pickup_phone_number: location.phone || postmates.pickup_phone_number,
		} as QuoteCreateParams

		const timestamp = orderConfig.timestamp;
		if (orderConfig.due === "later" && timestamp && timestamp > 0) {
			params.dropoff_deadline_dt = (new Date(timestamp)).toISOString(); // RFC 3339
		}

		const response = await store.api.create_postmates_quote({
			restaurant_id: restaurant._id,
			params,
		})

		if (response.outcome === 1) {
			this.setState({ error: response.message, loading: false });
		} else if (response.quote) {
			store.order_config.update({
				delivery_provider: "postmates",
				postmates_quote_id: response.quote.id,
				postmates_quote_amount: response.quote.fee
			});
			this.setState({ quote: response.quote, loading: false });
		}
	}

	render() {
		const { t, theme } = this.injected;
		const quote = this.state.quote;
		const error = this.state.error;

		return (
			<>
				{this.state.loading && <RotateLoader size={3} color={theme.colors.primary} />}

				{quote && (
					<Table>
						<TableBody>
							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.provider")}</TableCell>
								<TableCell className="small">Postmates</TableCell>
							</TableRow>

							<TableRow>
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.estimated_fee")}</TableCell>
								<TableCell className="small">{t("currency", { value: quote.fee / 100 })}</TableCell>
							</TableRow>

							<TableRow className="no-border">
								<TableCell className="small">{t("store.modals.checkout.delivery_estimation.details.estimated_delivery_time")}</TableCell>
								<TableCell className="small">{quote.duration} minutes</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				)}

				{error && <div className="error-text" style={{ fontSize: '0.85rem' }}>{error}</div>}
			</>
		);
	}
}

// @ts-ignore
export const PostmatesEstimation = withTheme(withTranslation()(PostmatesEstimationClass));
