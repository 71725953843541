import * as React from "react";
import { observer, inject } from "mobx-react";
import { InfoServices } from "./services";
import { InfoOpeningHours } from "./opening_hours";
import { InfoLocation } from "./location";
import { styled } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";

interface Props extends WithTranslation { }

const Wrapper = styled("div")`
  position: relative;
  padding: 0 20px 60px 20px;
`;

const Content = styled("div")`
  max-width: ${({ theme }) => theme.content_width.lg}px;
  width: 100%;
  margin: 0 auto;
`;

@inject("store") @observer
class InfoClass extends MobxComponent<Props> {

	render() {
		const hideInfoBoxes = this.injected.store.restaurant.website.sections.footer.hide_info_boxes === true;
		if (hideInfoBoxes)
			return null;

		return (
			<Wrapper>
				<Content className="grid-3 xl lg-gap">
					<InfoServices />
					<InfoOpeningHours />
					<InfoLocation />
				</Content>
			</Wrapper>
		);
	}

}

export const Info = withTranslation()(InfoClass);
