import * as React from "react";
import { styled, CS } from "@lib/components";
import Measure, { ContentRect } from "react-measure";
import { observer, inject } from "mobx-react";
import { NavAlert } from "./alert";
import { NavigationTop } from "./top";
import { NavigationMain } from "./main";
import { MobxComponent } from "../../../../mobx/component";
import { MenuNavBar } from "../menu-nav-bar";

interface Props { }

const Wrapper = styled("menu")`
  position: fixed;
  z-index: ${({ theme }) => theme.top_nav.zindex};
  display: block;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.top_nav.background_color};
  color: ${({ theme }) => theme.top_nav.text_color};
  transition: 0.14s all;
  ${CS.shadow.light.three};
`;

@inject("store") @observer
export class Navigation extends MobxComponent<Props, { marginRight: number }> {

	lastScroll: number = 0;
	topHiddenAt: number = 0;

	constructor(props: Props) {
		super(props);
		this.state = {
			marginRight: 0,
		};
	}

	componentDidMount() {
		const root = document.getElementById("scroll-root")!;
		this.setState({ marginRight: window.innerWidth - root.clientWidth });
	}

	onComponentResize = (contentRect: ContentRect) => {
		if (contentRect.bounds) {
			this.injected.store.view.update({
				top_nav_height: contentRect.bounds.height,
			});
		}
	}

	render() {

		if (!this.state) return;
		const { store } = this.injected;
		const { scroll_top, screen_width, top_alert_height } = store.view.s;

		let hideTop = false;
		let hideTopHeight = top_alert_height;

		if (scroll_top > 0) {
			hideTop = true;
			if (screen_width < 500) {
				if (scroll_top > this.lastScroll) {
					hideTopHeight += 38;
					this.topHiddenAt = scroll_top;
				}
				else if ((this.topHiddenAt !== 0) && scroll_top > (this.topHiddenAt - 100)) {
					hideTopHeight += 38;
				}
				this.lastScroll = scroll_top;
			}
		}

		const { isLoggedIn } = store.customer;
		const { servicesAllDisabled, orderingDisabled } = store
		const oc = store.order_config.s;
		const { logged_in_only, service_required } = store.restaurant.settings.business;

		let blur = false;
		if (logged_in_only && !isLoggedIn || service_required && !oc.confirmed) blur = true;
		if (service_required && (servicesAllDisabled || orderingDisabled)) blur = false;

		return (
			<Measure bounds={true} onResize={this.onComponentResize}>
				{({ measureRef }) => (
					<Wrapper
						id="top-navigation"
						ref={measureRef as any}
						style={{
							marginRight: `${this.state.marginRight}px`,
							transform: `translateY(${hideTop ? `-${hideTopHeight}` : "0"}px)`,
							...(!blur ? null : {
								filter: "blur(5px)",
								transform: "scale(1.01)",
							}),
						}}>
						<NavAlert />
						<NavigationTop />
						<NavigationMain />
						<MenuNavBar />
					</Wrapper>
				)}
			</Measure>
		);
	}

}
