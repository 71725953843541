import * as React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { CheckoutFormModal } from './checkout-form';
import { useStores } from '../../../mobx/useStores';
import { getCheckoutFormCommonFields } from './utils';
import { InputField, PaymentMethod } from './flows/types';
import { observer } from 'mobx-react';
import { CoreUtils } from '@lib/common';

function VivaWalletFormModalPlain() {
  const store = useStores();
  const { t } = useTranslation();
  const restaurant = store.restaurant;

  const order = store.order.s.item;
  const isOrderUsingVivaWallet = order?.payment.method === 'viva_wallet';
  if (!order || !isOrderUsingVivaWallet) {
    return null;
  }

  const orderCode = order.payment.viva_wallet!.paymentReference!;
  const { env } = restaurant.settings.payments.viva_wallet!;
  const {
    website: {
      colors: { primary: brandColor },
    },
  } = restaurant;
  let paymentEndpoint = `https://www.vivapayments.com/web/checkout?ref=${orderCode}&color=${CoreUtils.colors.rgbToHex(
    brandColor
  )}`;
  if (env === 'sandbox') {
    paymentEndpoint = `https://demo.vivapayments.com/web/checkout?ref=${orderCode}&color=${CoreUtils.colors.rgbToHex(
      brandColor
    )}`;
  }

  const inputFields: InputField[] = [...getCheckoutFormCommonFields(order, t)];

  const paymentMethod: PaymentMethod = {
    label: 'Viva Wallet',
    name: order.payment.method,
    urlEndpoint: paymentEndpoint,
  };

  return (
    <CheckoutFormModal
      inputFields={inputFields}
      paymentMethod={paymentMethod}
      orderId={order._id}
      isFullScreen={isMobile}
      openNewWindow
    />
  );
}

export const VivaWalletFormModal = observer(VivaWalletFormModalPlain);
