import * as React from "react";
import { inject, observer } from "mobx-react";
import { Modal, ModalTitle } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { CustomerOrderHistory } from "../../components/order_history";

interface Props extends WithTranslation { }
interface State { }

@inject("store") @observer
class OrderHistoryModalClass extends MobxComponent<Props, State> {

	render() {
		const { store, t } = this.injected;
		const c = store.customer.s.item;
		return (
			<Modal
				id="modal-order-history"
				width={420}
				closeButton={true}
				active={store.modal.isVisible("order-history")}
				close={() => store.modal.toggle("order-history")}>
				{c && (
					<>

						<ModalTitle paddinglr={25} paddingtb={20} className="round-top">
							<div>
								<h4 className="lhp">{t("store.modals.order.reorder.title")}</h4>
								<p className="lhp small">{t("store.modals.order.reorder.description")}</p>
							</div>
						</ModalTitle>

						<CustomerOrderHistory />

					</>
				)}
			</Modal>
		);
	}

}

export const OrderHistoryModal = withTranslation()(OrderHistoryModalClass);
