import { observable, action, computed } from "mobx";
import { RootStore } from "../store";
import throttle from "lodash/throttle";
import { logger, CoreUtils } from "@lib/common";

export interface ViewState {
	breakpoint: T.UIBreakpoint;
	screen_width: number;
	scroll_top: number;
	top_alert_height: number;
	top_nav_height: number;
	menu_nav_stuck: boolean;
	reseller_portal_url: string;
	is_web_view: boolean;
	platform: string;
	app_id: string | null;
	domain: string;
}

export class ViewStore {
	@observable s: ViewState;
	store: RootStore;

	constructor(store: RootStore, initialState?: ViewState) {
		this.store = store;
		this.s = {
			breakpoint: "md",
			screen_width: 560,
			scroll_top: 0,
			top_alert_height: 0,
			top_nav_height: 153,
			menu_nav_stuck: false,
			reseller_portal_url: "",
			is_web_view: false,
			platform: "web",
			app_id: "",
			domain: "",
			...(initialState || {}),
		};
		if (typeof window !== "undefined") {
			this.windowResize();
			this.windowScroll();
			window.addEventListener("resize", throttle(this.windowResize, 100));
			const root = document.getElementById("scroll-root");
			if (root) {
				root.addEventListener(
					"scroll",
					throttle(this.windowScroll, 200)
				);
			}
		}
	}

	scrollToElement = (elementId: string, offset: number = 0) => {
		if (typeof window === "undefined") return;
		const root = document.getElementById("scroll-root")!;
		const el = document.getElementById(elementId);

		const topNavigationElement = document.getElementById("top-navigation");
		const topNavigationElementHeight =
			topNavigationElement?.offsetHeight || this.s.top_nav_height;

		const isAlertBarExits =
			this.store.restaurant.website.sections.alert?.text;
		if (isAlertBarExits) {
			offset += this.s.top_alert_height;
		}

		if (el) {
			const position = el.getBoundingClientRect();

			const scrollTo = position.top - topNavigationElementHeight;

			root.scrollBy({
				left: 0,
				top: scrollTo + offset,
				behavior: "smooth",
			});
		}
	};

	scrollToMenu = () => {
		this.scrollToElement("dish-grid", 10);
	};

	scrollToCategory = (categoryId?: string) => {
		const elementId = categoryId || this.store.menu?.categories[0]._id;

		if (elementId) {
			this.scrollToElement(elementId, -30);
		}
	};

	scrollToHighlightedOnCategoryBar = () => {
		const selectedCategoryId = `TabSelectOption-${this.store.filters.s.category}`;

		const el = document.getElementById(selectedCategoryId);

		el?.scrollIntoView({ block: "start" });
	};

	@computed get itemLayoutStyle() {
		const website = this.store.restaurant.website;

		const itemLayoutStyle = website.sections.item_layout?.style;

		if (website.sections.menu_nav_bar?.menu_template !== "v2") {
			return;
		}

		return itemLayoutStyle || "divided-into-categories";
	}

	@action windowResize = () => {
		const width = window.innerWidth;
		const breakpoint = CoreUtils.ui.breakpoint(width);
		this.s.screen_width = width;
		this.s.breakpoint = breakpoint;
	};

	@action windowScroll = () => {
		try {
			const root = document.getElementById("scroll-root");
			if (root) {
				this.s.scroll_top = root.scrollTop || 0;
			}
		} catch (e) {
			logger.captureException(e, "SCROLL FUNCTION ERROR");
		}
	};

	@action update(data: Partial<ViewState>) {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof ViewState];
				if (value !== undefined && this.s) {
					// @ts-ignore
					this.s[key as keyof ViewState] = value;
				}
			}
		}
	}
}
