import React, { useEffect, useState } from 'react';
import kebabCase from 'lodash/kebabCase';
import { Collapse } from 'react-collapse';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';

import { DefaultFlowProps } from '../types';
import { HelpText, MoreInfoText } from '../styled';
import { Button, ModalContent, FormGroup, Input, RotateLoader } from '@lib/components';
import styled from 'styled-components';

const ButtonFormGroup = styled(FormGroup)`
  width: 100%;

  & > div {
    width: inherit;
    .payment-redirection-actions {
      display: flex;
      justify-content: center;
      gap: 10px;
      width: inherit;
    }
  }
`;
const TextContent = styled.p`
  font-size: 13px;
  font-weight: 600;
`;

export function DefaultFlow(props: DefaultFlowProps) {
  const { t } = useTranslation();
  const [formFieldsVisible, showFormFields] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  let timer: any = null;
  const {
    paymentMethod,
    inputFields,
    formTarget,
    openNewWindow,
    orderId,
    methodName,
    closePaymentForm,
    adhocAction,
    autoRedirect,
    handleCloseReceiptModal,
  } = props;

  useEffect(() => {
    if (!autoRedirect) return;

    const timeoutId = setTimeout(() => {
      const form = document.getElementById(`${kebabCase(methodName)}-checkout-form`) as HTMLFormElement;
      if (form) {
        form.submit();
        closePaymentForm();
      }
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, []);

  const target = formTarget || `checkout-popup-${orderId}`;
  const windowFeatures = !openNewWindow ? 'resizable,scrollbars,status,height=800,width=600' : '';

  const toggleFormFields = () => {
    showFormFields(prevState => !prevState);
  };

  return (
    <ModalContent>
      {
        methodName === 'Worldline' ? 
        <HelpText>
        For your security, you will be redirected to complete a 3D Secure (3DS) challenge. This step ensures the safety of your transaction. Please follow the instructions on the next screen.
      </HelpText> : 
      <HelpText>
        You will be redirected to the <strong>{methodName}</strong> hosted payment page and will complete your payment
        there. After completing the payment, close the window and come back here!
      </HelpText>
      }
      

      <MoreInfoText>
        <FaInfoCircle />
        <a href="#" onClick={toggleFormFields}>
          {formFieldsVisible ? 'hide' : 'more'} information
        </a>
      </MoreInfoText>

      <form
        onSubmit={(e: React.SyntheticEvent) => {
          if (adhocAction && typeof adhocAction === 'function') {
            e.preventDefault();
            adhocAction();
            setTimeout(() => closePaymentForm(), 0);
            return;
          }
          setCheckLoading(true);
          const openNewWindow = window.open(`${paymentMethod.urlEndpoint}`, target, windowFeatures);
            if(openNewWindow){
                timer = setInterval(() => {
                  if(openNewWindow.closed && typeof handleCloseReceiptModal === 'function'){
                    console.log("window closed");
                    handleCloseReceiptModal();

                    if(timer) clearInterval(timer)
                  }
                })
            }
        }}
        action={paymentMethod.urlEndpoint}
        target={target}
        method="POST"
        id={`${kebabCase(methodName)}-checkout-form`}
      >
        <Collapse isOpened={formFieldsVisible}>
          {(inputFields || []).map((inputField, index) => {
            return (
              <FormGroup
                key={index}
                title={inputField.label || ''}
                no_border
                small_title
                className={inputField.label ? 'm-b-2' : 'm-0 p-0'}
              >
                <Input
                  type={inputField.label ? 'text' : 'hidden'}
                  name={inputField.name}
                  value={inputField.value}
                  readOnly
                />
              </FormGroup>
            );
          })}
        </Collapse>

        {autoRedirect && <HelpText>Redirecting in few seconds...</HelpText>}

        {!autoRedirect && (
          <ButtonFormGroup className="m-t-6 m-b-2" no_border={true} contentClassName="payment-redirection-actions">
          <Button id={`${methodName}-checkout-button`} color="primary" type="submit" disabled={checkLoading} full={true}>
          {!checkLoading ? (
            methodName === 'Worldline' 
              ? t('store.modals.checkout.payment.continue') 
              : t('store.modals.checkout.payment.continue_to_payment', { method: methodName })
          ) : (
            <RotateLoader size={2} color="white" />
          )}
          </Button>
          
        </ButtonFormGroup>
        )}
      </form>
    </ModalContent>
  );
}
