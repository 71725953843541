import * as React from "react";
import { inject, observer } from "mobx-react";
import { CheckoutFormModal } from "./checkout-form";
import { MobxComponent } from "../../../mobx/component";
import { InputField, PaymentMethod } from './flows/types';

interface Props {}
interface State {}

@inject("store")
@observer
export class GkashFormModal extends MobxComponent<Props, State> {
	render() {
		const { store } = this.injected;

		const order = store.order.s.item;

		const gkashPaymentRequestData =
			order?.payment?.gkashPaymentData?.request;

		if (!order || !gkashPaymentRequestData) return null;

		const inputFields: InputField[] = [
			{
				name: "version",
				value: gkashPaymentRequestData.gkashVersion,
			},
			{
				name: "CID",
				value: gkashPaymentRequestData.merchantId,
			},
			{
				name: "v_cartid",
				value: order._id,
				label: "Order ID",
			},
			{
				name: "v_amount",
				value: order.bill.total.toFixed(2),
				label: "Amount",
			},
			{
				name: "v_currency",
				value: order.bill.currency,
			},
			{
				name: "v_firstname",
				value: order.customer.name,
				label: "Name",
			},
			{
				name: "v_billemail",
				value: order.customer.email,
				label: "Email",
			},
			{
				name: "v_billphone",
				value: order.customer.phone,
				label: "Phone Number",
			},
			{
				name: "signature",
				value: gkashPaymentRequestData.requestSignature,
			},
			{
				name: "callbackurl",
				value: gkashPaymentRequestData.callbackUrl,
			},
		];

		const paymentMethod: PaymentMethod = {
			name: order.payment.method,
			urlEndpoint: gkashPaymentRequestData.paymentEndpoint,
		};

		return (
			<CheckoutFormModal
				inputFields={inputFields}
				paymentMethod={paymentMethod}
				orderId={order._id}
			/>
		);
	}
}
