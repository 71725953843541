import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Input, FormGroup, RotateLoader, Button } from "@lib/components";
import { Untrusive, GN } from "@lib/common";
import { logger } from "@lib/common";
import { generateLoginState } from "../../../mobx/state/customer";
import { CwLoginButton } from "../../components/google_login/CwGoogleLoginButton";


interface Props extends WithTranslation { }
interface State {
	resetPassword: boolean;
}

@inject("store") @observer
class AuthLoginFormClass extends MobxComponent<Props, State> {

	state = {
		resetPassword: false,
	};

	render() {
		const { t, store } = this.injected;
		const { login, password, error, submitting } = store.customer.form.login;
		const { resetPassword } = this.state;

		return (
			<div>
				<form onSubmit={async (e) => {
					e.preventDefault();
					if (!resetPassword) {
						store.customer.login_email(login, password);
						return;
					}

					try {
						Untrusive.start();
						store.customer.updateLogin({ submitting: true });
						const result = await store.api.customer_reset_password_init({ email: login });
						Untrusive.stop();
						if (result.outcome) {
							store.customer.updateLogin({
								submitting: false,
								error: "error_" + result.message,
							});
						}
						else {
							store.customer.updateLogin(generateLoginState());
							this.setState({ resetPassword: false });
							GN.add({
								type: "success",
								message: t("store.modals.auth.password_reset.init_success"),
								duration: 7000,
							});
						}
					}
					catch (e) {
						logger.captureException(e);
						Untrusive.stop();
						store.customer.updateLogin({
							submitting: false,
							error: "error_generic",
						});
					}

				}}>

					<FormGroup no_border={true} className="m-b-3">
						<Input
							name="email"
							type="text"
							placeholder={t("store.modals.auth.login_placeholder")}
							required={true}
							value={login}
							onChange={(e) => store.customer.updateLogin({ login: e.target.value })}
						/>
					</FormGroup>

					{!resetPassword && (
						<FormGroup no_border={true} className="m-b-3">
							<Input
								name="password"
								type="password"
								placeholder={t("store.modals.auth.password_placeholder")}
								required={true}
								value={password}
								onChange={(e) => store.customer.updateLogin({ password: e.target.value })}
							/>
						</FormGroup>
					)}

					{error && <FormGroup no_border={true} className="m-b-2" error={t(`store.modals.auth.${error}`)} />}

					<FormGroup no_border={true} style={{ marginBottom: 7 }}>
						<Button full={true} color="primary" disabled={submitting}>
							{!submitting && (
								<>
									{!resetPassword ? t("store.modals.auth.login") : t("store.modals.auth.password_reset.init_button")}
								</>
							)}
							{submitting && <RotateLoader color="white" size={2} />}
						</Button>
					</FormGroup>
					<FormGroup no_border={true}>
						{!resetPassword && (
							<p className="text-center small">
								{t("store.modals.auth.password_reset.forgot_password_text")}
								<a className="link" onClick={() => this.setState({ resetPassword: true })}>
									{t("store.modals.auth.password_reset.forgot_password_link")}
								</a>
							</p>
						)}
						{resetPassword && (
							<p className="text-center small">
								{t("store.modals.auth.password_reset.forgot_password_text")}
								<a className="link" onClick={() => this.setState({ resetPassword: false })}>
									{t("store.modals.auth.password_reset.forgot_password_link")}
								</a>
							</p>
						)}
					</FormGroup>
				</form>
			</div>
		);
	}

}

export const AuthLoginForm = withTranslation()(AuthLoginFormClass);
