import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Table, TableRow, TableCell, TableBody } from "@lib/components";
interface Props extends WithTranslation { }
interface State { }

@inject("store") @observer
class CheckoutDetailsClass extends MobxComponent<Props, State> {

	render() {
		const { store, t } = this.injected;
		const oc = store.order_config.s;
		const l = store.restaurant.location;
		return (
			<Table className="">
				<TableBody>

					{(oc.service !== "dine_in") && (
						<TableRow>
							<TableCell className="small">{t("store.modals.checkout.details.service")}</TableCell>
							<TableCell className="small">{t(`constants.services.${oc.service}`)}</TableCell>
						</TableRow>
					)}

					{oc.service === "dine_in" && (
						<TableRow>
							<TableCell className="small">{t("store.modals.checkout.details.table")}</TableCell>
							<TableCell className="small">{oc.table}</TableCell>
						</TableRow>
					)}

					<TableRow>
						<TableCell>{t("store.modals.checkout.details.due")}</TableCell>
						<TableCell className="small">
							{oc.due === "now" && t("store.modals.checkout.details.due_now_asap")}
							{oc.due === "later" && t("datetimeFromDateTime", { value: `${oc.date} ${oc.time}` })}
						</TableCell>
					</TableRow>

					<TableRow>
						<TableCell className="small">{t("store.modals.checkout.details.total")}</TableCell>
						<TableCell className="small">{t("currency", { value: store.cart.total })}</TableCell>
					</TableRow>

					{oc.service === "delivery" && (
						<TableRow>
							<TableCell className="small">{t("store.modals.checkout.details.address")}</TableCell>
							<TableCell className="small">{oc.destination_misc ? `${oc.destination_misc} - ` : ""}{oc.destination}</TableCell>
						</TableRow>
					)}

					{(oc.service === "dine_in") ? (
						<TableRow className="no-border" />) : (
							<TableRow className="no-border">
								<TableCell className="small">{t("store.modals.checkout.details.store_location")}</TableCell>
								<TableCell className="small">{l.address}</TableCell>
							</TableRow>
						)}

				</TableBody>
			</Table>
		);
	}

}

export const CheckoutDetails = withTranslation()(CheckoutDetailsClass);
