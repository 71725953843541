import { config } from '../../config';

export async function genRecaptchaToken() {
  let token = '';
  if (config.google.recaptcha_enterprise.enabled === 'true') {
    const grecaptcha = window.grecaptcha;
    if (grecaptcha) {
      token = await new Promise(resolve => {
        grecaptcha.enterprise.ready(async () => {
          try {
            const token = await grecaptcha.enterprise.execute(config.google.recaptcha_enterprise.site_key, {
              action: 'init_payment',
            });
            resolve(token);
          } finally {
            resolve('');
          }
        });
      });
    }
  }
  return token;
}
