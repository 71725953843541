import * as React from "react";
import { styled, Button } from "@lib/components";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { FaShoppingBag } from "react-icons/fa";
import { MainActionsRenderer } from "../../../components/main_actions_renderer";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }

const Wrapper = styled("menu")`
  position: fixed;
  z-index: ${({ theme }) => theme.top_nav.zindex};
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.top_nav.background_color};
  color: ${({ theme }) => theme.top_nav.text_color};
  box-shadow: 0 -6px 28px rgba(0,0,0,0.19);
  transition: 0.14s all;
`;

const Context = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  font-weight: 600;
`;

@inject("store") @observer
class NavigationBottomClass extends MobxComponent<Props, { marginRight: number }> {

	constructor(props: Props) {
		super(props);
		this.state = {
			marginRight: 0,
		};
	}

	componentDidMount() {
		const root = document.getElementById("scroll-root")!;
		this.setState({ marginRight: window.innerWidth - root.clientWidth });
	}

	render() {

		if (!this.state) return;

		const { store, t } = this.injected;
		const { orderingDisabled } = store;
		const r = this.injected.store.restaurant;

		if (!r.website.bottom_cart_button) {
			return null;
		}

		return (

			<Wrapper
				id="bottom-navigation"
				className="hide-above-sm"
				style={{
					marginRight: `${this.state.marginRight}px`,
				}}>
				<Context>

					<MainActionsRenderer
						renderClosed={() => (
							<Button color="primary-disabled" className="no-round flex-grow not-allowed">
								{orderingDisabled ? t("store.global.buttons.order.not_accepting") : t("store.global.buttons.order.closed")}
							</Button>
						)}
						renderPaused={() => (
							<Button color="primary-disabled" className="no-round flex-grow not-allowed">
								{t("store.global.buttons.order.not_accepting")}
							</Button>
						)}
						renderPartiallyOpen={(displayText, order) => (
							<Button color="primary" className="no-round flex-grow" onClick={order}>
								{displayText}
							</Button>
						)}
						renderOpen={(order) => (
							<Button color="primary" className="no-round flex-grow" onClick={order}>
								{t("store.global.buttons.order.book_order")}
							</Button>
						)}
						renderCart={(total, cart, restart) => (!orderingDisabled &&
							<>
								<Button color="primary-inverse" className="no-round min125 no-border" onClick={restart}>
									{t("store.global.buttons.order.restart")}
								</Button>
								<Button color="primary" className="no-round flex-grow" onClick={cart}>
									<FaShoppingBag className="m-r-1" />
									{total}
								</Button>
							</>
						)}
					/>

				</Context>
			</Wrapper>
		);
	}

}

export const NavigationBottom = withTranslation()(NavigationBottomClass);
