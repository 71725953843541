import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { logger } from "@lib/common";

interface Props extends WithTranslation {
	renderClosed: () => React.ReactNode;
	renderPaused: () => React.ReactNode;
	renderPartiallyOpen: (text: string, order: () => void) => React.ReactNode;
	renderOpen: (order: () => void) => React.ReactNode;
	renderCart: (total: string, cart: () => void, restart: () => void) => React.ReactNode;
}

@inject("store") @observer
class MainActionsRendererClass extends MobxComponent<Props> {

	order = () => {
		const { store } = this.injected;
		logger.info("ORDER BUTTON");
		if (!store.order_config.s.confirmed) {
			store.modal.toggle("order-config");
		}
	}

	cart = () => {
		const { store } = this.injected;
		if (store.order_config.s.confirmed) {
			store.modal.toggle("cart");
		}
	}

	restart = () => {
		const { store } = this.injected;
		if (store.order_config.s.confirmed) {
			store.modal.show("order-restart");
		}
	}

	render() {
		const { t, store } = this.injected;
		const { renderClosed, renderPaused, renderPartiallyOpen, renderOpen, renderCart } = this.props;
		const { servicesAllDisabled, orderingDisabled, serviceOrderingEnabled, serviceBookingEnabled, servicePaused } = store;
		const confirmed = store.order_config.s.confirmed;
		return (
			<>
				{(servicesAllDisabled || orderingDisabled) &&
					renderClosed()
				}

				{
					servicePaused && !confirmed &&
					renderPaused()
				}

				{(!confirmed && !servicesAllDisabled && !orderingDisabled && !servicePaused && (!serviceOrderingEnabled || !serviceBookingEnabled)) &&
					renderPartiallyOpen((serviceOrderingEnabled && !serviceBookingEnabled) ? t("store.global.buttons.order.order_online") : t("store.global.buttons.order.book_table"), this.order)
				}

				{(!confirmed && !servicesAllDisabled && !orderingDisabled && !servicePaused && serviceOrderingEnabled && serviceBookingEnabled) && (
					renderOpen(this.order)
				)}

				{confirmed && (
					renderCart(t("currency", { value: store.cart.totalCart }), this.cart, this.restart)
				)}
			</>
		);
	}

}

export const MainActionsRenderer = withTranslation()(MainActionsRendererClass);
