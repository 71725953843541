import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, Button } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

interface Props extends WithTranslation { }

@inject("store") @observer
class OrderExpiryWarningModalClass extends MobxComponent<Props> {

	close = () => {
		const { store } = this.injected;
		setTimeout(() => store.modal.back(), 200);
	}

	render() {
		const { store, t } = this.injected;
		const { modal } = store;
		return (
			<Modal
				id="modal-order-expiry-warning"
				width="sm"
				closeButton={true}
				active={modal.isVisible("order-expiry-warning")}
				close={this.close}>

				<ModalContent>
					<p className="error-text small m-b-1">{t("store.modals.warnings.order_expiring.subtitle")}</p>
					<h4>{t("store.modals.warnings.order_expiring.title")}</h4>
				</ModalContent>

				<ModalContent>
					<p className="lhp">{t("store.modals.warnings.order_expiring.description", { time: store.order_config.expiresAt })}</p>
				</ModalContent>

				<ModalContent className="text-right" paddingtb={15}>
					<Button onClick={this.close}>
						<p>{t("store.modals.warnings.order_expiring.button")}</p>
					</Button>
				</ModalContent>

			</Modal>
		);
	}

}

export const OrderExpiryWarning = withTranslation()(OrderExpiryWarningModalClass);
