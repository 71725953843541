import React from "react";
import axios from "axios";
import { OrderHistoryStore } from "../../../../mobx/state/order-history";

interface Props {
	// checkout_public_key: string
	amount: number;
	full_name: string;
	reference: string;
	api_url: string;
	restaurant_id: string;
	zip: string;
}
interface State {
	hosted: any;
}

class GravityForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			hosted: () => { },
		};
	}
	componentDidMount = () => {
		var realThis = this;
		// window.$(document).ready(function () {
		axios({
			baseURL: this.props.api_url + "/",
			url: "/stores/gravity-token",
			method: "POST",
			// headers: { "Authorization-Server": this.props.public_key },
			data: {
				restaurant_id: realThis.props.restaurant_id,
				amount: realThis.props.amount,
				full_name: realThis.props.full_name,
				zip: realThis.props.zip,
			},
		})
			.then(function (data: any) {
				console.log({ data });
				console.log("TOKEN:" + data.data.transactionToken);
				var iframe = document.createElement("iframe");
				iframe.id = "cip-hosted-urlpage";
				iframe.style.display = "none";
				window.$("#iframeDiv").append(iframe);
				///@ts-ignore
				var transactionToken = data.data.transactionToken;
				// window.gravityTransactionToken = data.data.transactionToken
				// console.log(data.data.transactionToken)
				// if (data.data.transactionToken) {
				// console.log("Got a token")
				// resolve(data.transactionToken)
				// console.log(window.emergepayFormFields)
				///@ts-ignore
				// var hosted = window.emergepayFormFields.init({
				var hosted = window.emergepayUrlPage.init({
					// (optional) Callback function that gets called after a successful transaction
					onTransactionSuccess: function (approvalData: any) {
						console.log("Approval Data", approvalData);
						window.gravityUniqueToken = approvalData.uniqueTransId;
						window.gravityExternalToken = approvalData.externalTransactionId;
						window.$(".iframeContainer").hide();
					},
					// (optional) Callback function that gets called after a failure occurs during the transaction (such as a declined card)
					onTransactionFailure: function (failureData: any) {
						console.log("Failure Data", failureData);
					},
					// (required) Used to set up each field
					// transactionToken: data.data.transactionToken,
					// (required) The type of transaction to run
					// transactionType: "CreditSale",
					// transactionType: "CreditSaveCard",
					// (optional) Configure which fields to use and the id's of the elements to append each field to
					// fieldSetUp: {
					// 	// These fields are valid for credit card transactions
					// 	cardNumber: {
					// 		appendToSelector: "cardNumberContainer",
					// 		useField: true,
					// 		// optional, see styles section above for more information
					// 		styles: {
					// 			width: "80%"
					// 		}
					// 	},
					// 	cardExpirationDate: {
					// 		appendToSelector: "expirationDateContainer",
					// 		useField: true,
					// 		// optional, see styles section above for more information
					// 		styles: {
					// 			width: "80%"
					// 		}
					// 	},
					// 	cardSecurityCode: {
					// 		appendToSelector: "securityCodeContainer",
					// 		useField: true,
					// 		// optional, see styles section above for more information
					// 		styles: {
					// 			width: "80%"
					// 		}
					// 	},
					// 	// These fields are valid for ACH transactions
					// 	accountNumber: {
					// 		appendToSelector: "accountNumberContainer",
					// 		useField: false,
					// 		// optional, see styles section above for more information
					// 		styles: {}
					// 	},
					// 	routingNumber: {
					// 		appendToSelector: "routingNumberContainer",
					// 		useField: false,
					// 		// optional, see styles section above for more information
					// 		styles: {}
					// 	},
					// 	accountHolderName: {
					// 		appendToSelector: "accountHolderNameContainer",
					// 		useField: false,
					// 		// optional, see styles section above for more information
					// 		styles: {}
					// 	},
					// 	// These fields are valid for all transaction types
					// 	totalAmount: {
					// 		appendToSelector: "amountContainer",
					// 		useField: false,
					// 		// optional, see styles section above for more information
					// 		styles: {}
					// 	},
					// 	externalTranId: {
					// 		useField: false,
					// 		// optional, see styles section above for more information
					// 		styles: {}
					// 	}
					// },
					// // (optional) If there is a validation error for a field, the styles set in this object will be applied to the field
					// fieldErrorStyles: {
					// 	"border": "none",
					// 	"background-color": "red",
					// 	// "box-shadow": "0px 0px 4px 1px red"
					// },
					// // (optional) This callback function will be called when there is a validation error for a field.
					// onFieldError: function (data: any) {
					// 	console.log("ON FIELD ERROR")
					// 	console.log(data);
					// },
					// // (optional) Callback function that gets called after a successful transaction
					// onTransactionSuccess: function (approvalData: any) {
					// 	console.log('approvalData', approvalData.uniqueTransId);
					// 	///@ts-ignore
					// 	window.gravityToken = approvalData.uniqueTransId
					// 	///@ts-ignore
					// 	// location = 'https://www.chargeitpro.com';
					// },
					// // (optional) Callback function that gets called after a failure occurs during the transaction (such as a declined card)
					// onTransactionFailure: function (failureData: any) {
					// 	console.log('failureData', failureData);
					// }
				});
				iframe.src = hosted.getUrl(transactionToken);
				iframe.style.display = "block";
				iframe.style.height = "400px";
				iframe.style.margin = "auto";
				window.$(".iframeContainer").show();
				// window.$('#payBtn').on("click", function (event: any) {
				// event.preventDefault();
				// console.log("HERE!!!!!")
				// hosted.process()
				// console.log({ event })
				// console.log("Called")
				//run the transaction if all fields are valid
				// window.processGravity = function () {
				// 	hosted.process()
				// };
				// });
				// realThis.setState({
				//     hosted: hosted
				// })
				// }
				// else {
				// 	console.log("else worked")
				// 	// reject('Error getting transaction token')
				// };
			})
			.catch(function (err: any) {
				console.log("Error");
				console.log({ err });
				console.log(err.transactionToken);
				// reject(err);
			});
		// })
	};
	// buttonClicked = (event: any) => {
	// 	event.preventDefault();
	// 	console.log("Called")
	// 	///@ts-ignore
	// 	// console.log(window.hosted.process)
	// 	//run the transaction if all fields are valid
	// 	///@ts-ignore
	// 	this.state.hosted.process();
	// };
	render() {
		return (
			<div
				style={{
					backgroundColor: "white",
					textAlign: "center",
					marginTop: "10px",
					justifyContent: "space-between",
				}}
			>
				<div className="iframeContainer" style={{ height: "400px" }}>
					<div className="headerRow"></div>
					<div id="iframeDiv" style={{ height: "380px" }}></div>
				</div>
				{/* <p style={{ paddingTop: "12px", textAlign: "left" }}>Credit Card:</p>
				<div id="cardNumberContainer"
					style={{ height: "50px", paddingTop: "8px" }}></div>
				<p style={{ paddingTop: "12px", textAlign: "left" }}>Expiry Date:</p>
				<div id="expirationDateContainer"
					style={{ height: "50px", paddingTop: "8px" }}></div>
				<p style={{ paddingTop: "12px", textAlign: "left" }}>CVV:</p>
				<div id="securityCodeContainer"
					style={{ height: "50px", paddingTop: "8px" }}></div>
				<div id="accountNumberContainer"></div>
				<div id="routingNumberContainer"></div>
				<div id="accountHolderNameContainer"></div>
				<div id="amountContainer"></div>
				<button id="payBtn">Pay</button> */}
			</div>
		);
	}
}

export default GravityForm;
