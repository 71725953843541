import * as React from 'react';
import { inject, observer } from 'mobx-react';
import {
  Modal,
  ModalContent,
  DetailRowList,
  RotateLoader,
  OrderItems,
  OrderTotals,
  OrderTimeline,
  OrderNumber,
  LinkTag,
  LinkTagSuccess,
  LinkTagError,
  ConnectionIndicatorCircle,
  ModalTabs,
  DetailRowListItem,
} from '@lib/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../../mobx/component';
import * as Ably from 'ably';
import { config } from '../../../../config';
import { action, observable, toJS } from 'mobx';
import { PaymentMethods, hasPaymentMethodAlias, getPaymentMethodName, FORMATS, logger, OrderUtils } from '@lib/common';
import Push, { PushNotificationParams } from 'push.js';
import { SessionStorage } from '../../../libs/session-storage';
import _get from 'lodash/get';
import _trim from 'lodash/trim';
import _findIndex from 'lodash/findIndex';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import _isArray from 'lodash/isArray';
import { remove } from 'lodash';

interface Props extends WithTranslation {}
interface State {
  latestAblyMsgId: string;
  flashOrder: boolean;
  orderDeliveryETA: number;
  orderDeliveryPickupETA: number;
}

const TimeCustom = styled.span`
  font-size: 24px;
`;

const DeliveryStatus = styled.div`
  padding: 4px 16px;
  border-radius: 99px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  display: inline-block;
`;

const DeliveryStatusWarning = styled(DeliveryStatus)`
  background: rgba(255, 153, 0, 0.2);
  color: #d78100;
`;
const DeliveryStatusInfo = styled(DeliveryStatus)`
  background: rgba(66, 133, 244, 0.2);
  color: #4285f4;
`;
const DeliveryStatusSuccess = styled(DeliveryStatus)`
  background: rgba(81, 163, 81, 0.1);
  color: #51a351;
`;
const DeliveryStatusDanger = styled(DeliveryStatus)`
  background: rgba(213, 53, 56, 0.15)
  color: #D53538;
`;

const ButtonTrackYourOrder = styled.a`
  background: #ea4335;
  border-radius: 4px;
  padding: 16px 0px;
  display: flex;
  width: calc(100% + 100px);
  margin-left: -100px;
  color: white;
  justify-content: center;

  & span {
    margin-right: 10px;
  }
`;

const Eta = styled.div`
  background: ${props => props.theme.box.border};
  border-radius: 99px;
  padding: 4px 16px;
  & span {
    margin-left: 14px;
    margin-right: 14px;
  }
`;

@inject('store')
@observer
class ReceiptModalClass extends MobxComponent<Props, State> {
  ably?: Ably.Realtime;
  channel?: Ably.Types.RealtimeChannelCallbacks;
  pushNotificationsInitialized = false;
  lastOrderID: string = '';
  currentPaymentIntent: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      latestAblyMsgId: '',
      flashOrder: false,
      orderDeliveryETA: new Date().getTime(),
      orderDeliveryPickupETA: new Date().getTime(),
    };
  }

  @observable ablyState: Ably.Types.ConnectionState = 'initialized';
  @observable tab: number = 0;

  componentDidMount() {
    this.injected.store.order.sync();
    this.ablyInit();
    if (this.injected.store.order_id) {
      this.initPushNotification();
      this.lastOrderID = this.injected.store.order_id;
      SessionStorage.remove(this.injected.store.order_id);
      SessionStorage.remove('bambora_apac');
      SessionStorage.remove('pesapal');
    }
  }

  componentDidUpdate(prevProps: Props) {
    this.injected.store.order.sync();
    this.ablyInit();
    if (this.injected.store.order_id) {
      this.initPushNotification();

      if (this.lastOrderID !== this.injected.store.order_id) {
        this.setTab(0);
      }

      this.lastOrderID = this.injected.store.order_id;
    } else {
      this.lastOrderID = '';
    }
  }

  ablyInit = () => {
    const { store } = this.injected;
    const order = store.order.s.item;

    if (!this.ably) {
      this.ably = new Ably.Realtime(config.ably_order_updates_key);
      this.ably.connection.on(this.ablyHandleConnectionState);
    }

    if (order) {
      if (this.channel) {
        this.channel.unsubscribe();
      }
      this.channel = this.ably.channels.get(`public:order-updates:${order._id}`);
      this.channel.subscribe(this.ablyHandleSubscription);
    } else if (this.channel) {
      this.channel.unsubscribe();
      this.channel = undefined;
    }
  };

  ablyHandleSubscription = (message: Ably.Types.Message) => {
    const { store, t } = this.injected;
    const o = store.order.s.item;
    if (!o) return;
    this.setState({
      latestAblyMsgId: message.id,
      flashOrder: true,
    });
    if (message.name === 'status') {
      const data = message.data as {
        status: T.Schema.Order.OrderStatuses;
        updated: number;
      };
      const orderConfig = store.order.s.item?.config;
      if (orderConfig && data.status === 'cancelled') orderConfig.lalamove_share_link = undefined;
      store.order.updateItem({ config: orderConfig, ...data });
      this.sendPushNotification(t('order.receipt.status_update.title', { number: o.number }), {
        body: t('order.receipt.status_update.body', {
          status: t(`order.status.${data.status}`),
        }),
        timeout: 30000,
        requireInteraction: true,
      });
    }
    if (message.name === 'delivery') {
      const data = message.data;
      const orderConfig = store.order.s.item?.config;
      if (orderConfig?.delivery_provider === 'lalamove') {
        orderConfig.lalamove_share_link = data.lalamove_share_link;
        orderConfig.lalamove_order_status = data.lalamove_order_status;
      } else if (orderConfig?.delivery_provider === 'uber') {
        orderConfig.uber_share_link = data.uber_share_link;
        orderConfig.uber_order_status = data.uber_order_status;
        orderConfig.uber_order_eta = data.uber_order_eta;
        if (data.uber_order_eta) {
          this.setState({
            orderDeliveryETA: new Date(data.uber_order_eta).getTime(),
          });
        }

        if (data.uber_order_pickup_eta) {
          this.setState({
            orderDeliveryPickupETA: new Date(data.uber_order_pickup_eta).getTime(),
          });
        }
      }
      store.order.updateItem({ config: orderConfig });
      if (data.postmates_tracking_url) {
        this.sendPushNotification(t('order.receipt.details.delivery_tracking'), {
          body: t('order.receipt.details.delivery_tracking_link'),
          link: data.postmates_tracking_url,
          timeout: 30000,
          requireInteraction: true,
        });
      }

      if (data.lalamove_share_link) {
        this.sendPushNotification(t('order.receipt.details.delivery_tracking'), {
          body: t('order.receipt.details.delivery_tracking_link'),
          link: data.lalamove_share_link,
          timeout: 30000,
          requireInteraction: true,
        });
      }

      if (data.uber_share_link) {
        this.sendPushNotification(t('order.receipt.details.delivery_tracking'), {
          body: t('order.receipt.details.delivery_tracking_link'),
          link: data.uber_share_link,
          timeout: 30000,
          requireInteraction: true,
        });
      }
    }
    if (message.name === 'ready_time') {
      const data = message.data as {
        status: T.Schema.Order.OrderStatuses;
        ready_in: T.Schema.Order.OrderSchema['ready_in'];
        delivery_in: T.Schema.Order.OrderSchema['delivery_in'];
      };
      store.order.updateItem(data);
      this.sendPushNotification(t('order.receipt.wait_time_update.title', { number: o.number }), {
        body: t('order.receipt.wait_time_update.body'),
        timeout: 30000,
        requireInteraction: true,
      });
    }
    if (message.name === 'tookan_booked') {
      const data = message.data as {
        config: T.Schema.Order.OrderSchema['config'];
      };
      store.order.updateItem(data);
      this.sendPushNotification(t('order.receipt.delivery_update.title', { number: o.number }), {
        body: t('order.receipt.delivery_update.body'),
        timeout: 30000,
        requireInteraction: true,
      });
    }
    if (message.name === 'payment_status') {
      if (message.data.status === 'payment_failed') {
        store.checkout.order_error(undefined, 'payment_fail');
      }
      store.api.order_check({ _id: localStorage.getItem("initial_order_id") || '', isPostHookRequire: false }).then(
        (existingOrder) => {
          if (!existingOrder.outcome && existingOrder.order.payment.method === 'bambora_na' && existingOrder.order.payment.status === 'success') {
            // @ts-ignore
            store.checkout.order_complete_v2(existingOrder)
          }
        }
      );
      store.order.updateItem(message.data);
      store.order.update({
        paymentFormModal: '',
        showCardPaymentAuthenticationModal: false,
      });
      this.sendPushNotification(t('order.receipt.payment_status_update.title', { number: o.number }), {
        body: t('order.receipt.payment_status_update.body'),
        timeout: 30000,
        requireInteraction: true,
      });
    }
  };

  @action ablyHandleConnectionState = (stateChange: Ably.Types.ConnectionStateChange) => {
    this.ablyState = stateChange.current;
  };

  @action setTab = (tab: number) => (this.tab = tab);

  initPushNotification = () => {
    try {
      if (!Push.Permission.has() && !this.pushNotificationsInitialized) {
        Push.Permission.request(
          () => {},
          () => {}
        );
        this.pushNotificationsInitialized = true;
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  sendPushNotification = async (title: string, opts: PushNotificationParams) => {
    try {
      if (Push.Permission.has()) {
        const { store } = this.injected;
        const n = await Push.create(title, {
          vibrate: true,
          icon: '/store-notification-icon.png',
          link: store.router.s.path,
          onClick: () => {
            n.close();
            window.focus();
          },
          ...opts,
        });
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  overrideServiceName(
    serviceName: T.Schema.Restaurant.Services.RestaurantServiceTypes,
    r: T.Schema.Restaurant.RestaurantSafeSchema
  ): string {
    let serviceDisplayName: string = serviceName;
    switch (serviceName) {
      case 'pickup':
        serviceDisplayName = r.settings.services.pickup.display_name
          ? r.settings.services.pickup.display_name
          : serviceName;
        break;
      case 'delivery':
        serviceDisplayName = r.settings.services.delivery.display_name
          ? r.settings.services.delivery.display_name
          : serviceName;
        break;
      case 'dine_in':
        serviceDisplayName = r.settings.services.dine_in.display_name
          ? r.settings.services.dine_in.display_name
          : serviceName;
        break;
      case 'table_booking':
        serviceDisplayName = r.settings.services.table_booking.display_name
          ? r.settings.services.table_booking.display_name
          : serviceName;
        break;
    }
    return serviceDisplayName.toString();
  }

  parseOrderTime(value?: number) {
    let date, time;
    const { t } = this.injected;
    const datetime = t('datetimeFromTimestamp', { value });
    if (datetime) {
      date = _trim(datetime.split(',')[0]);
      time = _trim(datetime.split(',')[1]);
    }
    return { date, time };
  }

  render() {
    const { store, t } = this.injected;
    const { loading, error } = store.order.s;
    const { order_id } = store;
    const o = store.order.s.item;
    const { time: readyTime } = this.parseOrderTime(o?.ready_in?.timestamp);
    const { time: deliveryTime } = this.parseOrderTime(o?.delivery_in?.timestamp);
    const r = store.restaurant;
    const { logged_in_only } = r.settings.business;
    let active = !!order_id && _isEmpty(store.order.s.paymentFormModal) && !store.checkout.s.using_bambora_na;
    if (typeof window !== 'undefined') {
      if (active) {
        localStorage.setItem("saveActiveReceiptOrder", 'true')
      } else {
        localStorage.removeItem("saveActiveReceiptOrder")
      }
    }
    if (logged_in_only && !store.customer.isLoggedIn) {
      active = false;
    }
    let itemsTaxIndicatorCopy = [];
    if (o && o.dishes) {
      const changeableItems = toJS(o.dishes);
      itemsTaxIndicatorCopy = changeableItems.map((item: any) => {
        r.settings.region.tax.rates?.forEach(rate => {
          if (rate.tax_indicator_flag && rate.dish_tax && rate.dish_tax.indexOf(item._id) === -1) {
            item.name = item.name + rate.tax_indicator_character;
          }
        });
        return item;
      });
    }

    const serviceDisplayName = o?.config.service ? this.overrideServiceName(o?.config.service, r) : o?.config.service;

    let orderDetailFields: DetailRowListItem[] = [];
    if (o) {
      const trackingUrl = OrderUtils.getTrackingUrl(o);
      const returnTrackingUrl = OrderUtils.getReturnTrackingUrl(o);
      let displayAmountRefunded: any = 0;
      if (o.payment.refunded_amount) {
        const amountRefunded = o.payment.refunded_amount / 100;
        displayAmountRefunded = t('currency', { value: amountRefunded });
      }
      orderDetailFields = [
        {
          l: <strong>{t('order.receipt.details.delivery_text')}</strong>,
          h: !(trackingUrl && o.config.delivery_provider == 'uber' && o.status !== 'cancelled'),
          v: (
            <span className="flex-right">
              {o.config.delivery_provider === 'uber' ? (
                'via Uber'
              ) : (
                <LinkTag
                  style={{ color: '#5CA3E2', textDecoration: 'none' }}
                  className="m-l-2"
                  target="_blank"
                  href={trackingUrl}
                >
                  {t('order.receipt.details.delivery_tracking_link')}
                </LinkTag>
              )}
            </span>
          ),
        },
        {
          l: t('order.receipt.details.estimated_arrival_time'),
          h: !(trackingUrl && o.config.delivery_provider == 'uber' && o.status !== 'cancelled'),
          v: (
            <Eta>
              {t('timeFromTimestamp', { value: this.state.orderDeliveryPickupETA })}
              <span>
                <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="#313131" />
                </svg>
              </span>
              {t('timeFromTimestamp', { value: this.state.orderDeliveryETA })}
            </Eta>
          ),
        },
        {
          l: t('order.receipt.details.arrival_date'),
          h: !(trackingUrl && o.config.delivery_provider == 'uber' && o.status !== 'cancelled'),
          v: t('dateFromTimestamp', { value: this.state.orderDeliveryETA }),
        },
        {
          l: t('order.receipt.details.delivery_status'),
          h: !(trackingUrl && o.config.delivery_provider == 'uber' && o.status !== 'cancelled'),
          v: (
            <span>
              {['pending', 'pickup'].includes(o.config.uber_order_status!) ? (
                <DeliveryStatusWarning>{o.config.uber_order_status}</DeliveryStatusWarning>
              ) : ['pickup_complete', 'dropoff'].includes(o.config.uber_order_status!) ? (
                <DeliveryStatusInfo>{o.config.uber_order_status}</DeliveryStatusInfo>
              ) : (
                <DeliveryStatusSuccess>{o.config.uber_order_status}</DeliveryStatusSuccess>
              )}
            </span>
          ),
        },
        {
          l: t('order.receipt.details.destination'),
          h: o.config.delivery_provider !== 'uber',
          v: o.config.destination && o.config.destination_misc ? (o.config.destination_misc ? `${o.config.destination_misc} - ` : '') + o.config.destination: null,
        },
        {
          l: null,
          h: !(trackingUrl && o.status !== 'cancelled' && o.config.delivery_provider === 'uber'),
          v: (
            <ButtonTrackYourOrder href={trackingUrl} target="_blank">
              <span>
                <svg width="14" height="20" viewBox="0 0 14 20" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.66304 9.23661 8.29893 8.76777 8.76777C8.29893 9.23661 7.66304 9.5 7 9.5Z"
                    fill="#F5F5F5"
                  />
                </svg>
              </span>
              {t('order.receipt.details.delivery_track_your_order')}
            </ButtonTrackYourOrder>
          ),
        },
        {
          l: t('order.receipt.details.placed'),
          v: t('datetimeFromTimestamp', { value: o.created }),
        },
        {
          l: t('order.receipt.details.due'),
          v: (() => {
            if (o.config.due === 'now') {
              return t('order.receipt.details.due_asap');
            }
            const { date, time } = o.config;
            const timestamp = store.intl.momentFromFormat(`${date} ${time}`, FORMATS.moment.datetime).valueOf();
            return t('datetimeFromTimestamp', { value: timestamp });
          })(),
        },
        {
          l: t('order.receipt.details.last_updated'),
          h: true, // (o.updated === o.created || !o.updated),
          v: o.updated ? t('datetimeFromTimestamp', { value: o.updated }) : '',
        },
        {
          l: t('order.receipt.details.total'),
          v: t('currency', { value: o.bill.total }),
        },
        {
          l: t('order.receipt.details.type'),
          v: serviceDisplayName !== o.config.service ? serviceDisplayName : t(`constants.services.${o.config.service}`),
        },
        {
          l: t('order.receipt.details.table'),
          h: o.config.service !== 'dine_in',
          v: o.config.table,
        },
        {
          l: t('order.receipt.details.payment_method'),
          v: (() => {
            const m = r.settings.payments[o.payment.method];
            const { method: key, method_alias: aliasKey, method_display_name } = o.payment;
            if (method_display_name) return method_display_name;
            const hasPmAlias = hasPaymentMethodAlias(key, String(aliasKey));
            const pmDisplayName = getPaymentMethodName(key, String(aliasKey), t);

            if (m) {
              let label = m.label || t(`constants.payment.method.${o.payment.method}`);
              if (m.label_delivery && store.order_config.s.service === 'delivery') {
                label = m.label_delivery;
              }

              if (hasPmAlias) {
                label = pmDisplayName;
              }

              return label;
            }

            return pmDisplayName;
          })(),
        },
        {
          l: t('order.receipt.details.payment_status'),
          h:
            o.payment.method === 'cash' ||
            o.payment.method === 'card' ||
            PaymentMethods.indexOf(o.payment.method) === -1,
          v: (
            <span
              className={
                o.payment.status === 'success'
                  ? 'success-text'
                  : ['error', 'refunded'].indexOf(o.payment.status) !== -1
                  ? 'error-text'
                  : ''
              }
            >
              <span className="block">
                {t(
                  o.payment.status === `refunded` && o.payment.stripe_connect_refunded_status === 'partial_refund'
                    ? `Refunded (${displayAmountRefunded})`
                    : `constants.payment.status.${o.payment.status}`
                )}
              </span>
            </span>
          ),
        },
        {
          l: t('order.receipt.details.notes'),
          h: !o.notes,
          v: o.notes,
        },
        {
          l: t('order.receipt.details.name'),
          h: !o.customer.name,
          v: o.customer.name,
        },
        {
          l: t('order.receipt.details.email'),
          h: !o.customer.email,
          v: <span style={{ wordBreak: 'break-all' }}>{o.customer.email}</span>,
        },
        {
          l: t('order.receipt.details.phone'),
          h: !o.customer.phone,
          v: o.customer.phone,
        },
        {
          l: t('order.receipt.details.delivery_address'),
          h: !(o.config.service !== 'delivery' && o.config.delivery_provider != 'uber'),
          v: (o.config.destination_misc ? `${o.config.destination_misc} - ` : '') + o.config.destination,
        },
        {
          l: t('order.receipt.details.delivery_tracking'),
          h: !(trackingUrl && o.status !== 'cancelled' && o.config.delivery_provider !== 'uber'),
          v: (
            <span>
              <LinkTagSuccess target="_blank" href={trackingUrl}>
                {t('order.receipt.details.delivery_tracking_link')}
              </LinkTagSuccess>
            </span>
          ),
        },
        {
          l: t('order.receipt.details.delivery_return_tracking'),
          h: !(returnTrackingUrl && o.status === 'cancelled' && o.config.delivery_provider !== 'uber'),
          v: (
            <span>
              <LinkTagError target="_blank" href={returnTrackingUrl}>
                {t('order.receipt.details.delivery_tracking_link')}
              </LinkTagError>
            </span>
          ),
        },
        {
          l: t('order.receipt.details.store_address'),
          h: o.config.delivery_provider === 'uber',
          v: r.location.address,
        },
      ];

      let checkoutFields = _get(o, 'checkout_fields');
      if (checkoutFields) {
        let checkoutOrderFields = checkoutFields.map((field: T.Schema.CustomFieldValue): DetailRowListItem => {
          let answer = field.answer;
          if (field.type === 'checkbox') {
            answer = answer === 'yes' ? '✅' : '❌';
          }
          return {
            l: field.question.label,
            v: answer,
          };
        });

        const idx = _findIndex(
          orderDetailFields,
          (item: DetailRowListItem) => item.l === t('order.receipt.details.notes')
        );
        if (idx === -1) {
          orderDetailFields = [...orderDetailFields, ...checkoutOrderFields];
        } else {
          orderDetailFields.splice(idx, 0, ...checkoutOrderFields);
        }
      }

      if (o.config.service === 'dine_in') {
        remove(orderDetailFields, function (item) {
          return (
            !item.l ||
            item.l === t('order.receipt.details.due') ||
            item.l === t('order.receipt.details.type') ||
            item.l === t('order.receipt.details.store_address')
          );
        });
      }
    }

    return (
      <Modal
        id="order-receipt-modal"
        width={420}
        closeButton={true}
        active={active}
        isFullScreen
        close={() => store.router.push('/')}
      >
        {loading && (
          <ModalContent paddinglr={20} paddingtb={100} className="round-sm">
            <RotateLoader size={3} />
          </ModalContent>
        )}
        {!loading && error && (
          <ModalContent paddinglr={20} paddingtb={50} className="round-sm text-center">
            <p className="error-text">{t(`store.modals.receipt.errors.${error}`)}</p>
          </ModalContent>
        )}

        {!loading && !error && o && (
          <div
            onMouseEnter={e => {
              this.setState({ flashOrder: false });
            }}
          >
            <ModalContent paddinglr={20} paddingtb={25} className="flex-l-r-center no-border">
              <OrderNumber shouldFlash={this.state.flashOrder} key={this.state.latestAblyMsgId}>
                {o.config.service !== 'delivery' && readyTime && <span>{readyTime}</span>}
                {o.config.service === 'delivery' && deliveryTime && <span>{deliveryTime}</span>}
              </OrderNumber>
              <div>
                <div className="text-right">
                  <p className="small">
                    {t('order.receipt.details.order_no')} <span className="font-semi-bold">{`${o.number}`}</span>
                  </p>
                </div>

                <div className="text-right m-t-1">
                  <p className="smaller">
                    {this.ablyState === 'connected'
                      ? t(`store.modals.receipt.connection.connected`)
                      : this.ablyState === 'connecting'
                      ? t(`store.modals.receipt.connection.connecting`)
                      : t(`store.modals.receipt.connection.disconnected`)}
                    <ConnectionIndicatorCircle className="m-l-1" status={this.ablyState} />
                  </p>
                </div>
              </div>
            </ModalContent>

            <OrderTimeline status={o.status} isDelivery={o.config.service === 'delivery'} />

            <ModalTabs
              value={this.tab}
              onChange={selected => this.setTab(selected.value as number)}
              tabs={[
                { label: t(`order.receipt.tab_details`), value: 0 },
                { label: t(`order.receipt.tab_dishes`), value: 1 },
              ]}
            />

            {this.tab === 0 && (
              <ModalContent paddinglr={20} paddingtb={25}>
                <DetailRowList items={orderDetailFields} />
              </ModalContent>
            )}

            {this.tab === 1 && (
              <ModalContent paddinglr={20} paddingtb={25}>
                <OrderItems items={itemsTaxIndicatorCopy} />
                <OrderTotals
                  tax_in_prices={o.bill.tax_in_prices}
                  fees={o.bill.fees}
                  taxes={o.bill.taxes}
                  discount={o.bill.discount}
                  promo={o.promos && _isArray(o.promos) && o.promos.length > 0  ? o.promos[0] : null}
                  totalCart={o.bill.cart}
                  total={o.bill.total}
                  tip={typeof o.bill.tip === 'undefined' ? 0 : o.bill.tip}
                />
                {r.settings.region.tax.rates.map((rate, index) => {
                  {
                    return rate.tax_indicator_flag ? (
                      <p key={index} className="small" style={{ marginTop: '10px' }}>
                        {rate.tax_indicator_string}
                      </p>
                    ) : (
                      <div key={index}></div>
                    );
                  }
                })}
              </ModalContent>
            )}
          </div>
        )}
      </Modal>
    );
  }
}

export const ReceiptModal = withTranslation()(ReceiptModalClass);
