import * as React from "react";
import { observer, inject } from "mobx-react";
import { HeaderWrapper, HeaderContent } from "./components";
import { CoreUtils } from "@lib/common";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }

@inject("store") @observer
class HeaderClass extends MobxComponent<Props> {

	render() {
		// const { modal } = this.injected.store;
		const r = this.injected.store.restaurant;
		const isWebView = this.injected.store.view.s.is_web_view;
		// console.log('wew', r.website.sections.header)
		const { images, colors, text } = r.website.sections.header;
		const backgroundImage = CoreUtils.image.uc(images.background, { format: "auto" });
		const headerLogoImage = CoreUtils.image.uc(images.logo, { format: "auto" });
		const paddingTop = this.injected.store.view.s.top_nav_height;

		const image = headerLogoImage ? (
			<img
				id="header-logo-image"
				className="res-img m-b-2"
				src={headerLogoImage}
				alt={`Logo - ${r.name}`}
			/>
		) : null;

		return (
			<HeaderWrapper
				color={colors.text}
				background={colors.background}
				tint={colors.tint}
				image={backgroundImage}
				className="test2345"
				paddingTop={paddingTop}>
				<HeaderContent>
					{(image && images.logo_link && !isWebView) && (
						<a href={images.logo_link}>
							{image}
						</a>
					)}
					{(image && (!images.logo_link || isWebView)) && image}
					{text.title !== " " && <h1 className="lhp">{text.title || r.name}</h1>}
					{text.subtitle && <p className="lhp big font-semi-bold m-t-2">{text.subtitle}</p>}
				</HeaderContent>
			</HeaderWrapper>
		);
	}

}

export const Header = withTranslation()(HeaderClass);
