import * as React from "react";
import { styled, CS, Button, shadeAlternate } from "@lib/components";
import { CoreUtils } from "@lib/common";
import { observer, inject } from "mobx-react";
import { ContentCSS } from "./components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { FaShoppingBag } from "react-icons/fa";
import { MainActionsRenderer } from "../../../components/main_actions_renderer";
import MenuSearchButton from "../../../components/menu_search/MenuSearchButton";

interface Props extends WithTranslation { }

const Wrapper = styled("div")`
  padding: 10px 20px;
  height: ${({ theme }) => theme.top_nav.height}px;
`;

const Content = styled("div")`
  ${ContentCSS};
`;

const Logo = styled("div")`
  ${CS.flex.center};
  span {
    font-family: ${({ theme }) => theme.font.header};
    font-size: ${({ theme }) => theme.font.size + 4}px;
    font-weight: 600;
  }
`;

const CartButton = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
     font-size: 16px;
  }
`;

const CartButtonBase = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 10px 8px;
  color: ${({ theme }) => theme.colors.primary_text};
  border: 1px solid ${(props) => props.theme.colors.primary};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
     padding: 10px 12px;
  }
`;

const CartButtonLeft = styled(CartButtonBase)`
  color: ${(props) => props.theme.colors.primary};
  background: transparent;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 14px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
     font-size: 12px;
     padding: 10px 6px;
  }
`;

const CartButtonMiddle = styled(CartButtonBase)`
  border-left: 1px solid ${({ theme }) => shadeAlternate(0.12, theme.colors.primary)};
  background: ${({ theme }) => theme.colors.primary};
`;

const CartButtonRight = styled(CartButtonBase)`
  background: ${(props) => props.theme.colors.primary};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const OrderButton = styled("div")`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
     font-size: 16px;
  }
`;

const OrderButtonLeft = styled("div")`
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.primary_text};
  border: 1px solid ${(props) => props.theme.colors.primary};
  background: ${(props) => props.theme.colors.primary};
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
`;

const OrderButtonRight = styled("div")`
  padding: 10px 12px;
  color: ${({ theme }) => theme.colors.primary_text};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-left: 1px solid ${({ theme }) => shadeAlternate(0.12, theme.colors.primary)};
  background: ${({ theme }) => theme.colors.primary};
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;

@inject("store") @observer
class NavigationMainClass extends MobxComponent<Props> {

	render() {
		const { t, store } = this.injected;
		const { orderingDisabled } = store;
		const screenWidth = store.view.s.screen_width;
		const r = store.restaurant;
		// console.log(r._id)
		const website = r.website;
		const { images, text } = website.sections.top_nav;

		const logo = (
			<>
				{(images.logo) && (
					<img
						style={{ maxHeight: "50px" }}
						className="m-r-3"
						alt="Business logo"
						src={CoreUtils.image.uc(images.logo, { resize: "x50" })!}
					/>
				)}
				{(r.website.bottom_cart_button || screenWidth > 600 || !images.logo) && <span>{text.logo === " " ? "" : (text.logo || r.name)}</span>}
			</>
		);

		return (
			<Wrapper>
				<Content>

					<Logo>
						{images.logo_link && (
							<a href={images.logo_link} className="flex-center">
								{logo}
							</a>
						)}
						{!images.logo_link && logo}
					</Logo>

					<MenuSearchButton />

					<div id="order-button-wrapper" className={r.website.bottom_cart_button ? "hide-below-sm" : ""}>
						<MainActionsRenderer
							renderClosed={() => (
								<Button id="store-closed-button" key="closed" color="primary-disabled" className="not-allowed">
									{orderingDisabled ? t("store.global.buttons.order.not_accepting") : t("store.global.buttons.order.closed")}						
								</Button>
							)}
							renderPaused={() => (
								<Button color="primary-disabled" className="no-round flex-grow not-allowed">
									{t("store.global.buttons.order.not_accepting")}
								</Button>
							)}
							renderPartiallyOpen={(displayText, order) => (
								<Button
									id="order-button"
									key="open"
									color="primary"
									onClick={order}>
									{displayText}
								</Button>
							)}
							renderOpen={(order) => (
								<OrderButton id="order-button">
									<OrderButtonLeft onClick={order}>
										{t("store.global.buttons.order.book")}
									</OrderButtonLeft>
									<OrderButtonRight onClick={order}>
										{t("store.global.buttons.order.order")}
									</OrderButtonRight>
								</OrderButton>
							)}
							renderCart={(total, cart, restart) => (!orderingDisabled &&
								<CartButton id="cart-button">
									<CartButtonLeft onClick={restart}>
										{t("store.global.buttons.order.restart")}
									</CartButtonLeft>
									<CartButtonRight onClick={cart}>
										<FaShoppingBag className="m-r-1" />
										{total}
									</CartButtonRight>
								</CartButton>
							)}
						/>
					</div>

				</Content>
			</Wrapper>
		);
	}

}

export const NavigationMain = withTranslation()(NavigationMainClass);
