import React from 'react';
import { Modal, ModalContent, ModalTitle } from '@lib/components';
import { MenuSearch } from './MenuSearch';
import { MobxComponent } from '../../../mobx/component';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation { }

@inject("store") @observer
class MenuSearchModalClass extends MobxComponent<Props, {}> {
	handleModalClose = () => {
		const { store } = this.injected;
		store.menuSearch.update({
			showModal: false, chosen_menu_id: '', chosen_category_id: '', searchMenu: undefined, searchMenuAvailable: undefined
		});
	}

	render() {
		const { store, t } = this.injected;
		const showModal = store.menuSearch.s.showModal;

		return (
			<Modal
				width={1200}
				active={showModal}
				alignTop={true}
				id="menu-search-modal"
				close={this.handleModalClose}>
				<ModalTitle className="round-top">
					<h4>{t(`store.sections.menus.search.title`)}</h4>
				</ModalTitle>

				<ModalContent>
					<MenuSearch />
				</ModalContent>
			</Modal>
		);
	}
}

export const MenuSearchModal = withTranslation()(MenuSearchModalClass);
