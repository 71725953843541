import React from 'react';
import { styled, BlockError, InputBox } from '@lib/components';
import { MobxComponent } from '../../../../mobx/component';
import { inject, observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { logger } from '@lib/common';

interface Props extends WithTranslation {
  error: string;
  onError: (error: string) => void;
  onChange: () => void;
}

interface State {
  initError: string;
  loading: boolean;
  usingStripeConnect: string;
}

const style = {
  base: {
    fontSmoothing: 'antialiased',
  },
  invalid: {
    color: '#bd362f',
    iconColor: '#bd362f',
  },
};

const StripeInput = styled(InputBox)`
  background-color: white !important;
  color: black !important;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    width: 100%;
  }
`;

const spinner = (
  <svg
    style={{ margin: 'auto', background: 'rgb(255, 255, 255)', display: 'block', shapeRendering: 'auto' }}
    width="130px"
    height="130px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#000000"
      strokeWidth="3"
      r="23"
      strokeDasharray="108.38494654884786 38.12831551628262"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      >
        {' '}
      </animateTransform>
    </circle>
  </svg>
);

@inject('store')
@observer
class StripeCardFormClass extends MobxComponent<Props, State> {
  state = { initError: '', loading: true, usingStripeConnect: 'NOT CHECKED' };

  async componentDidMount() {
    const { onError, onChange } = this.props;
    const { t, store } = this.injected;

    const usingStripeConnect = store.checkout.getStripeConnectStatus();
    this.setState({ usingStripeConnect });

    if (usingStripeConnect !== 'CONNECT') {
      this.setState({ loading: false }, () => {
        try {
          if (!window.stripeCard) {
            window.stripeCard = window.stripeElements!.create('card', { style });
          }
          window.stripeCard.mount('#stripe-card-el');
          window.stripeCard.on('change', event => {
            if (!event) return;
            if (event && !event.error) {
              onChange();
            }
            const error = event.error ? event.error.message : '';
            onError(error || '');
          });
        } catch (e) {
          console.log({ e });
          logger.captureException(e);
          this.setState({
            initError: t('store.modals.checkout.errors.stripe_loading_fail'),
          });
        }
      });
    } else {
      this.setState({ loading: false }, () => {
        try {
          if (!window.stripeConnectCard) {
            window.stripeConnectCard = window.stripeConnectElements!.create('card', { style });
          }
          window.stripeConnectCard.mount('#stripe-connect-card-el');
          window.stripeConnectCard.on('change', event => {
            if (!event) return;
            if (event && !event.error) {
              onChange();
            }
            const error = event.error ? event.error.message : '';
            onError(error || '');
          });
        } catch (e) {
          console.log({ e });
          logger.captureException(e);
          this.setState({
            initError: t('store.modals.checkout.errors.stripe_loading_fail'),
          });
        }
      });
    }
  }

  componentWillUnmount() {
    if (window.stripeCard) {
      window.stripeCard.destroy();
      window.stripeCard = undefined;
    }

    if (window.stripeConnectCard) {
      window.stripeConnectCard.destroy();
      window.stripeConnectCard = undefined;
    }
  }

  render() {
    const { error } = this.props;
    const { loading, initError, usingStripeConnect } = this.state;

    if (initError) {
      return <BlockError text={initError} />;
    }

    if (loading) {
      return spinner;
    }

    const stripeInputId = usingStripeConnect === 'CONNECT' ? 'stripe-connect-card-el' : 'stripe-card-el';

    return (
      <>
        <StripeInput id={stripeInputId} />
        {error && <BlockError text={error} className="m-t-2" />}
      </>
    );
  }
}

export const StripeCardForm = withTranslation()(StripeCardFormClass);
