import { useState } from 'react';
import semver from 'semver';
import { useStores } from '../../../mobx/useStores';

interface VersionCheckerHook {
  fetchLatestVersions: () => Promise<boolean>;
  isVersionChanged: boolean;
}

const useVersionChecker = (): VersionCheckerHook => {
  const store = useStores();
  const [isVersionChanged, setIsVersionChanged] = useState(false);

  const fetchLatestVersions = async () => {
    try {
      const response = await store.api.getServiceLatestVersion();
      if (response.outcome === 1) return false;

      let hasVersionChanged = false;
      response.data.forEach((service: T.API.ServiceVersion) => {
        const key = `cw:${service.name}_version`;
        const newVersion = service.latestVersion;
        const storedVersion = localStorage.getItem(key) || '1.0.0';
        const isMajorOrMinorRelease = semver.diff(newVersion, storedVersion) !== null;

        if (isMajorOrMinorRelease) {
          hasVersionChanged = true;
        }

        localStorage.setItem(key, service.latestVersion);
      });

      setIsVersionChanged(hasVersionChanged);
      return hasVersionChanged;
    } catch (err) {
      return false;
    }
  };

  return {
    fetchLatestVersions,
    isVersionChanged,
  };
};

export default useVersionChecker;
