import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { Dish } from "./dish";
import { styled, shadeAlternate, Button } from "@lib/components";
import { Circle, CircleWrapper } from "./components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";

interface Props extends WithTranslation { }

const Wrapper = styled("div")`
  position: relative;
  padding: 40px 20px 100px 20px;
  background: ${({ theme }) => shadeAlternate(0.03, theme.colors.background)};
`;

const Content = styled("div")`
  position: relative;
  z-index: 1;
  max-width: ${({ theme }) => theme.content_width.lg}px;
  width: 100%;
  margin: 0 auto;
`;

export const DishGrid = styled("div") <{ enableCondensedMenu: boolean; tagsApplied: boolean; dishStyle: "list" | "list-rounded" | "card";}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  .col {
    width: 100%;
    margin-bottom: ${({ tagsApplied, dishStyle }) => (tagsApplied && dishStyle !== "card") ? 30 : 20}px;
  }
  @media (max-width: 767px) {
     max-width: 480px;
     margin: 0 auto;
	 .col {
		${({ enableCondensedMenu }) => enableCondensedMenu && `
    		margin-bottom: 0px;
  		`}
	 }
  }
  @media (min-width: 768px) and (max-width: 1023px) {
     flex-direction: row;
     .col {
        width: calc(50% - 10px);
        margin-right: 19.9px;
        &:nth-child(2n) {
          margin-right: 0;
        }
     }
  }
  @media (min-width: 1024px) {
     flex-direction: row;
     .col {
        width: calc(33% - 10px);
        margin-right: 19.8px;
        &:nth-child(3n) {
          margin-right: 0;
        }
     }
  }
  @media(max-width: 480px ) {
    .list-style #dish-grid {
        padding-left: 0;
        padding-right: 0;
    }
    .list-style #dish-grid h2 {
        font-size: 26px;
    }
    .list-style #dish-grid .col {
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;
    }
    .list-style #dish-grid .col:last-child {
        border-bottom: none;
    }
    .list-style #dish-grid div[class*=dish__DishComponent]:not(.htcybr) div[class*=dish__Content] div[class*=dish__Tags] {
        width: auto;
    }
    .list-style #dish-grid div[class*=dish__DishComponent]:not(.htcybr) {
        max-width: unset;
        width: 100%;
    }
}
`;

@inject("store") @observer
class MenusClass extends MobxComponent<Props> {

	handleLazybeforeunveil = (e: any) => {
		const bg = e.target.getAttribute('data-bg');
		if (bg) {
			e.target.style.background = `url(${bg}) no-repeat center center`;
			e.target.style.backgroundSize = 'cover';
		}
	}

	componentDidMount() {
		this.forceUpdate();

		document.addEventListener('lazybeforeunveil', this.handleLazybeforeunveil, true)
	}

	componentWillUnmount() {
		document.removeEventListener('lazybeforeunveil', this.handleLazybeforeunveil, true)
	}

	getNumColumns = () => {
		const { store } = this.injected;
		const screenWidth = store.view.s.screen_width;
		return (
			screenWidth >= 1024 ? 3 :
				screenWidth >= 768 ? 2 :
					1
		);
	}

	getPaddingContainers = (length: number) => {
		const numColumns = this.getNumColumns();
		if (length % numColumns) {
			return numColumns - (length % numColumns);
		}
		return 0;
	}

	render() {
		const { store, t } = this.injected;
		const { restaurant } = store;
		const { categories, tagsApplied } = store.dishes;
		const enable_condensed_menu = restaurant.website.sections.menus.enable_condensed_menu;
		const dishes = store.dishes.dishes;
		const ws = store.restaurant.website.sections;
		const menu = store.menu;
		const doesMenuHaveRestrictions = store.doesMenuHaveRestrictions;
		const menuAvailability = store.isMenuAvailable;
		const doesCategoryHaveRestrictions = store.doesFiltersCategoryHaveRestrictions;
		const categoryAvailability = store.isFilterCategoryAvailable;
		return (
			<Wrapper id="dish-grid" className="">

				<CircleWrapper>
					<Circle />
				</CircleWrapper>

				{!menu && (
					<Content>
						<div className="m-t-6 p-2 error-bg max400 text-center center round">
							<p className="">{t("store.sections.menus.info.no_menus")}</p>
						</div>
					</Content>
				)}

				{menu && (
					<Content>

						{menu.description && (
							<div className={cn("text-center", doesMenuHaveRestrictions ? "m-b-4" : "m-b-8")}>
								<p className="lhp">{menu.description}</p>
							</div>
						)}

						<div className="m-b-8 text-center">
							{doesMenuHaveRestrictions && (
								<Button
									size="xs"
									color="primary-inverse"
									className="m-1"
									onClick={() => store.modal.toggle("menu-restrictions")}
								>
									{menuAvailability.available && t("store.sections.menus.info.restrictions")}
									{!menuAvailability.available && t("store.sections.menus.info.not_available")}
								</Button>
							)}

							{doesCategoryHaveRestrictions && (
								<Button
									size="xs"
									color="primary-inverse"
									className="m-1"
									onClick={() => store.modal.toggle("category-restrictions")}
								>
									{categoryAvailability.available && t("store.sections.category.restrictions")}
									{!categoryAvailability.available && t("store.sections.category.not_available")}
								</Button>
							)}
						</div>


						<DishGrid
							enableCondensedMenu={enable_condensed_menu || false}
							dishStyle={ws.menus.dish_style}
							tagsApplied={tagsApplied}>
							{dishes.map((d, i) => {
								const tags = restaurant.dish_tags.filter((tag) => d.tags.indexOf(tag._id) !== -1);
								const category = categories[i];
								return (
									<Dish
										key={d._id + i + d.name}
										index={i}
										dish={d}
										category={category}
										tags={tags}
										className="col"
									/>
								);
							})}
						</DishGrid>

					</Content>
				)}

			</Wrapper>
		);
	}

}

export const Menus = withTranslation()(MenusClass);
