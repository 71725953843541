import { observable, action, computed, toJS } from 'mobx';
import {
  clearPersist,
  isSynchronized,
  persistence,
  stopPersist,
} from 'mobx-persist-store';
import { storageAdapter } from './storage/adapter';
import _sum from 'lodash/sum';
import _sumBy from 'lodash/sumBy';
import _merge from 'lodash/merge';
import _orderBy from 'lodash/orderBy';
import _groupBy from 'lodash/groupBy';
import _remove from 'lodash/remove';
import _concat from 'lodash/concat';
import _find from 'lodash/find';
import _uniqWith from 'lodash/uniqWith';
import _isEqual from 'lodash/isEqual';
import _assign from 'lodash/assign';
import autobind from 'autobind-decorator';
import { RootStore } from '../store';
import { cloneDeepSafe } from '../utils';
import { GN, logger, RestaurantUtils, PromoParser } from '@lib/common';
import { config } from '../../../config';

type OptionQuantityMultiplier =
  T.Schema.Restaurant.Menu.RestaurantOptionSetOptionToppingQuantityMultiplier;

interface SelectedOption {
  id: string;
  name: string;
  point: number;
  dishID: string;
  optionSetID: string;
  position?: T.Schema.Restaurant.Menu.RestaurantOptionSetOptionToppingPosition;
  multiplier?: OptionQuantityMultiplier;

  // Store references to these values to perform additional
  // point calculations later.
  basePoint: number;
  referenceID: string;
}

interface FreeOption {
  dishID: string;
  optionName: string;
  optionSetID: string;
  optionID: string;
}

interface ExcessPointOption extends SelectedOption {
  multiplier_price?: OptionQuantityMultiplier;
}

interface DishPointItem {
  id: string;
  dishID: string;
  dishType: T.Schema.Restaurant.Menu.RestaurantDish['type'];

  // This is used to store the list of selected options when using the point system.
  // This prevents duplicated data when the customer clicks on the option multiple times.
  // We also compute the list of free options based on this value.
  selectedOptions?: Array<SelectedOption>;

  // The list of free options.
  freeOptions?: Array<FreeOption>;

  // The list of option with excess points.
  excessPointOptions?: Array<ExcessPointOption>;
}

interface OptionPointPrices
  extends T.Schema.Restaurant.Menu.RestaurantOptionSetOptionPointPrices {}

export interface DishState {
  dish: T.Schema.Order.OrderDish | null;
  category: T.Schema.Restaurant.Menu.RestaurantCategory | null;
  errors: Array<boolean[] | boolean | undefined>;
  error: boolean;
  edit: number;
}

@persistence({
  name: 'DishStore',
  properties: ['pointCollection'],
  adapter: storageAdapter,
  reactionOptions: {
    delay: 50,
  },
})
@autobind
export class DishStore {
  @observable s: DishState;
  @observable store: RootStore;
  @observable.deep pointCollection: Array<DishPointItem>;

  constructor(store: RootStore, initialState?: DishState) {
    this.store = store;
    this.s = initialState
      ? observable(initialState)
      : observable({
          dish: null,
          category: null,
          errors: [],
          error: true,
          edit: -1,
        });
    this.pointCollection = [];
  }

  @computed get price() {
    const { dish } = this.s;
    if (!dish) return '0';
    return RestaurantUtils.dish.calculateTotal(dish).price;
  }

  @computed get base_price() {
    const { dish } = this.s;
    if (!dish) return 0;
    return dish.price; // before modifiers
  }

  @computed get tags() {
    const dish = this.s.dish;
    if (!dish) return [];
    return this.store.restaurant.dish_tags.filter(
      tag => dish.tags.indexOf(tag._id) !== -1
    );
  }

  @computed get errors() {
    if (!this.s.dish) return;
    const dish = this.s.dish;
    const errors = [];
    if (dish.type === 'combo') {
      for (const [i, choice] of dish.choices.entries()) {
        errors[i] = choice.selected
          ? RestaurantUtils.dish.optionSetValidate(choice.selected.option_sets)
          : true;
      }
    } else {
      errors.push(RestaurantUtils.dish.optionSetValidate(dish.option_sets));
    }
    return errors || [];
  }

  @action toCart = () => {
    if (!this.s.dish) return;
    const dish = toJS(this.s.dish);
    dish.price = parseFloat(this.price);
    dish.base_price = this.base_price ? this.base_price : 0; 

    let isValid = true;
    const cartItemQty = this.store.cart.s.items.reduce(
      (acc: any, item: any) => acc + item.qty,
      0
    );
    const errors = this.errors!;
    for (const err of errors) {
      if (err === true) {
        isValid = false;
        break;
      } else if (err !== false) {
        for (const e of err) {
          if (e) {
            isValid = false;
            break;
          }
        }
        if (!isValid) break;
      }
    }

    if (!isValid) {
      this.s.errors = this.errors!;
      this.s.error = true;
    } else {
      const editIndex = this.s.edit;
      if (editIndex === -1) {
        const existingDishIndex = this.store.cart.s.items.findIndex(d => (d._id === dish._id) && (d.type !== "combo"));
        if((existingDishIndex !== -1) 
          && dish.option_sets.length  == 0
          && dish.notes.trim().length == 0) {
          const existingDish = this.store.cart.s.items[existingDishIndex];
          this.store.cart.push({
            ...dish,
            qty: dish.qty + existingDish.qty,
            price: dish.price + existingDish.price
          }, existingDishIndex);
        } else this.store.cart.push(dish);
      }
      else this.store.cart.push(dish, editIndex);

      if (editIndex !== -1) this.store.modal.toggle('cart');

      fbq('track', 'AddToCart', {
        value: dish.price,
        currency: this.store.restaurant.settings.region.currency.code,
        content_name: dish.name,
        content_category: this.s.category!.name,
        content_ids: [dish._id],
        content_type: 'product',
      });

      this.s = {
        dish: null,
        category: null,
        errors: [],
        error: false,
        edit: -1,
      };

      GN.add({
        type: 'success',
        message: `<p>${this.store.intl.i18n.t(
          'store.modals.dish.added_to_cart'
        )}</p>`,
        duration: 2000,
      });
    }
    const promo = this.store.cart.s.promos.length > 0 ? this.store.cart.s.promos[0] : null;
    const cartItems = this.store.cart.s.items;
    const oldCartItems = cartItems.filter(i => !i.isFreeItems);
    const itemQty = oldCartItems.reduce((acc, item) => acc + item.qty, 0);
    if (promo) {
      if (new PromoParser(promo).getType() === 'free_item') {
        let tiers = this.store.cart.conditionTierFilter(
          promo,
          cartItemQty,
          'upper_limit'
        ).tiers;
        if (tiers && itemQty > tiers[tiers.length - 1].upper_limit) {
          this.store.cart.promoRemove();
        }
      }
    }
    // TODO: Refactor this. Consolidate to one central function to do promo validation.
    const promo_type = new PromoParser(promo).getType();
    if (promo_type === 'conventional_discount') {
      // @ts-ignore
      this.store.cart.validateConventionalPromo(promo, true);
    }
  };

  // OS AND O ID ARE USED TO SET DEFAULT OPTION
  @action set = (dish_id?: string, os_id?: string, o_id?: string) => {
    if (!dish_id) {
      const dish = this.s.dish;
      if (dish) {
        this.removeDishPointItemForUnaddedDish(dish);
      }

      this.s = {
        dish: null,
        category: null,
        errors: [],
        error: false,
        edit: -1,
      };
    } else {
      const r = this.store.restaurant;
      const filters = this.store.filters;

      let menu_id = filters.s.menu;
      let category_id = filters.s.category;
      if (this.store.menuSearch.s.showModal) {
        menu_id = this.store.menuSearch.s.chosen_menu_id;
        category_id = this.store.menuSearch.s.chosen_category_id;
      }

      const menu = r.menus.find(m => m._id === menu_id)!;
      // const categories = menu.categories.filter((c) => category_id ? c._id === category_id : true);
      const categories = menu.categories;
      for (const category of categories) {
        for (const d of category.dishes) {
          if (d._id === dish_id) {
            const dish = cloneDeepSafe(d);
            const isCombo = dish.type === 'combo';
            const option_sets = [];
            if (!isCombo) {
              for (const option_set of r.option_sets) {
                if (dish.option_sets.indexOf(option_set._id) !== -1) {
                  const os = cloneDeepSafe(option_set);
                  if (os_id && o_id) {
                    if (os._id === os_id) {
                      for (const [index, o] of os.options.entries()) {
                        os.options[index].quantity = o._id === o_id ? 1 : 0;
                      }
                    }
                  }
                  option_sets.push(os);
                }
              }
            }

            this.s = {
              dish: {
                ...dish,
                option_sets: option_sets,
                qty: 1,
                notes: '',
              },
              category: cloneDeepSafe(category),
              errors: [],
              error: false,
              edit: -1,
            };

            if (this.s.dish!.type === 'combo') {
              for (const [i, choice] of this.s.dish!.choices.entries()) {
                if (choice.dishes.length === 0) {
                  // REMOVE CHOICES WITH NO DISHES TO ITS NAME
                  this.s.dish!.choices.splice(i, 1);
                } else if (choice.dishes.length === 1) {
                  // SET DEFAULT CHOICE
                  this.setChoice(
                    i,
                    choice.dishes[0],
                    this.s.dish!.option_set_blacklist || []
                  );
                }
              }
            }

            fbq('track', 'ViewContent', {
              value: dish.price,
              currency: this.store.restaurant.settings.region.currency.code,
              content_name: dish.name,
              content_category: category.name,
              content_ids: [dish._id],
              content_type: 'product',
            });

            break;
          }
        }
      }
    }
  };

  @action setFromCart = (dish: T.Schema.Order.OrderDish, index: number) => {
    const find = RestaurantUtils.menu.findDish(this.store.restaurant, dish._id);
    if (find) {
      const category = cloneDeepSafe(find.category);
      dish.price = find.dish.price; // RESET THE PRICE
      dish.base_price = find.dish.price;
      this.s = {
        dish: dish,
        category: category,
        errors: [],
        error: false,
        edit: index,
      };

      this.refreshDishPointItemForCartItem(dish);
    } else {
      logger.captureWarning(
        `Missing item in menu setFromCart edit ${dish._id}`
      );
    }
  };

  @action setChoice = (
    index: number,
    dish_id: string,
    option_set_blacklist: string[]
  ) => {
    const r = this.store.restaurant;
    const find = RestaurantUtils.menu.findDish(r, dish_id);
    const dish = cloneDeepSafe(find!.dish);
    const option_sets = [];

    for (const os of r.option_sets) {
      if (
        dish.option_sets.indexOf(os._id) !== -1 &&
        option_set_blacklist.indexOf(os._id) === -1
      ) {
        option_sets.push(cloneDeepSafe(os));
      }
    }

    /*
		  for (const option_set_id of dish.option_sets) {
			const os = r.option_sets.find((option_set) => option_set._id === option_set_id);
			if (os && option_set_blacklist.indexOf(os._id) === -1) {
			  option_sets.push(cloneDeepSafe(os));
			}
		  }
		*/

    this.s.dish!.choices[index].selected = {
      ...dish,
      qty: 1,
      option_sets: option_sets,
      notes: '',
    };
  };

  _wrapID = (id: string | undefined): string => {
    return id || '<none>';
  };

  /**
   * Build a unique ID that'll be used to distinguish dish point items.
   * The ID format is: <original_dish_id>|<combo_choice_id>|<combo_selected_dish_id>|<counter>.
   * <combo_choice_id> and <combo_selected_dish_id> are optional and will be replaced by <none> if not available.
   * <counter> will be calculated based on the latest cart item ID.
   *
   * @param {string} originalDishID - The ID of the original dish (regular dish or combo dish).
   * @param {string} comboChoiceID - The ID of combo choice if the original dish is a combo.
   * @param {string} comboDishID - The ID of the selected dish in combo.
   * @returns {string}
   */
  constructIdForDishPointItem = (
    originalDishID: string,
    comboChoiceID?: string,
    comboDishID?: string
  ): string => {
    // If we're updating a given dish (cart item)
    // The ID should be constructed from the existing
    // cart item ID.
    const isUpdatingDish = this.s.edit !== -1;
    if (isUpdatingDish && this.s.dish) {
      return this.constructIdForDishPointItemUsingCartItemId(
        this.s.dish.cart_item_id!,
        originalDishID,
        comboChoiceID,
        comboDishID
      );
    }

    // The counter should be initialized to 1 if there's no
    // matching dish in the cart. This makes generated ID be
    // consistent when refreshing point data from cart.
    let counter = 1;
    let data = cloneDeepSafe(this.store.cart.itemsWithIDs);

    // New ID counter will be calculated based on the
    // largest ID in cart items.
    if (data.length > 0) {
      data = _orderBy(data, ['cartItemID'], ['desc']);
      const latestCartItem = data[0];
      counter = latestCartItem.cartItemID + 1;
    }

    return `${originalDishID}|${this._wrapID(comboChoiceID)}|${this._wrapID(
      comboDishID
    )}|${counter}`;
  };

  /**
   * A helper method to generate dish point item ID using
   * cart item ID. This is useful whe refreshing point data
   * from cart items.
   *
   * @param {string} cartItemID
   * @param {string} originalDishID
   * @param {string} comboChoiceID
   * @param {string} comboDishID
   * @returns {string}
   */
  constructIdForDishPointItemUsingCartItemId = (
    cartItemID: number,
    originalDishID: string,
    comboChoiceID?: string,
    comboDishID?: string
  ): string => {
    return `${originalDishID}|${this._wrapID(comboChoiceID)}|${this._wrapID(
      comboDishID
    )}|${cartItemID}`;
  };

  /**
   * Clear all dish point items.
   * Also clear data from persistent storage.
   */
  clearPointCollection = () => {
    this.pointCollection = [];
    this.clearStore();
  };

  /**
   * Store the current dish information to initialize dish point item data.
   *
   * @param {string} dishID
   * @param {T.Schema.Restaurant.Menu.RestaurantDish["type"]} dishType
   */
  @action initDishPointItem = (
    id: string,
    dishID: string,
    dishType: T.Schema.Restaurant.Menu.RestaurantDish['type']
  ) => {
    const pointCollection = cloneDeepSafe(this.pointCollection);

    const idx = pointCollection.findIndex(el => el.id === id);

    if (idx === -1) {
      pointCollection.push({ id, dishID, dishType });
    } else {
      pointCollection[idx] = _merge({}, pointCollection[idx], {
        id,
        dishID,
        dishType,
      });
    }

    this.pointCollection = pointCollection;
  };

  /**
   * Recalculate dish point item
   * when the state of a given option inside option set changed.
   *
   * @param {string} dishID
   * @param {number} point
   * @param {number} totalPoints
   * @param {string} optionSetID
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSetOption} option
   * @param {boolean | undefined} debug
   * @returns {void}
   */
  @action updateDishPointItem = (
    id: string,
    dishID: string,
    optionSets: T.Schema.Order.OrderDish['option_sets'],
    optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
    option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption,
    debug?: boolean
  ): void => {
    const position = option.position;
    const multiplier = option.quantity_multiplier;
    const point = this.getOptionPoints(optionSets, optionSet, option);

    // Get dish point data for the given dish.
    let data = this._getDishPointItem(id);
    if (!data) return;
    let [dishPointItem, pointCollection, dishPointItemIdx] = data;

    // Make copies of selected options.
    let selectedOptions = cloneDeepSafe(dishPointItem.selectedOptions) || [];

    // Calculate the base point for option (excluding the quantity multiplier).
    const basePoint = this._getOptionPointsWithoutQuantityMultiplier(
      optionSets,
      optionSet,
      option
    );

    // If position or quantity multiplier is not defined
    // we remove the the option from the list of selected options.
    if (!position || multiplier === 'none') {
      debug && console.log('\nOPTION ACTION ➜ REMOVING');

      _remove(selectedOptions, item => item.id === option._id);
    } else {
      // Update the list of selected options when the user clicks it for the first time.
      const idx = selectedOptions.findIndex(item => item.id === option._id);
      if (idx === -1) {
        debug && console.log('\nOPTION ACTION ➜ ADDING');

        selectedOptions.push({
          id: option._id,
          name: option.name,
          point,
          dishID,
          optionSetID: optionSet._id,
          position: option.position,
          multiplier: option.quantity_multiplier,
          basePoint,
          referenceID: id,
        });
      }
      // If the user clicks the option the second time but changed the position.
      // Remove the old selected option and replace it with a new one.
      else if (this._isOptionChanged(selectedOptions[idx], option, point)) {
        debug && console.log('\nOPTION ACTION ➜ UPDATING');

        selectedOptions.splice(idx, 1);
        selectedOptions.push({
          id: option._id,
          name: option.name,
          point,
          dishID,
          optionSetID: optionSet._id,
          position: option.position,
          multiplier: option.quantity_multiplier,
          basePoint,
          referenceID: id,
        });
      }
    }

    // Sort the selected options by their points in descending order.
    // Calculate the total consumed points based on that order.
    // The option with the largest point will be added to the consumed point first.
    const sortedSelectedOptions = _orderBy(selectedOptions, ['point'], ['asc']);
    pointCollection[dishPointItemIdx].selectedOptions = sortedSelectedOptions;
    this.pointCollection = pointCollection;

    this._updateDishOptionSets(id, dishID, optionSet, option);
    this._calculateFreeOptions(id, debug);

    if (debug) {
      console.log(`OPTION SET ➜ [${optionSet._id} - ${optionSet.name}]`);
      console.log(`OPTION ➜ [${option._id} - ${option.name}]`);
      console.log('SELECTED OPTIONS ➜', sortedSelectedOptions);
      console.log('OPTION POINTS ➜', point);
      console.log('OPTION BASE POINTS ➜', basePoint);
      console.log('TOTAL POINTS ➜', this.dishTotalPoints);
      console.log('POINT COLLECTION ➜', toJS(this.pointCollection));
    }
  };

  /**
   * Calculate free options for the current selected dish.
   *
   * @param {string} id
   * @param {boolean} debug
   * @returns {void}
   */
  _calculateFreeOptions = (id: string, debug?: boolean): void => {
    const rootDish = this.s.dish;
    if (!rootDish) {
      return;
    }

    if (rootDish.type !== 'combo') {
      return this.update({
        option_sets: this._calculateFreeOptionsForSingleDish(
          id,
          rootDish,
          undefined,
          debug
        ),
      });
    }

    const choices = cloneDeepSafe(rootDish.choices);
    const selectedDishes: Array<{ [key: string]: string }> = [];
    for (const choice of choices) {
      if (choice.selected !== null) {
        selectedDishes.push({
          choiceId: choice._id,
          selectedDishId: choice.selected._id,
        });
      }
    }

    const group = [];
    const pointCollection = this.pointCollection;
    for (const [, element] of selectedDishes.entries()) {
      const result = _find(pointCollection, item => {
        // Only search for dish in the same cart item.
        // The final part of point collection item is the order in the cart.
        const isInSameCartItem = id.split('|')[3] === item.id.split('|')[3];

        // Need to check for combo choice ID to handle the case selecting
        // same dish for different choices in a single combo.
        const isSameComboChoice = element.choiceId === item.id.split('|')[1];

        return (
          item.dishID === element.selectedDishId &&
          isInSameCartItem &&
          isSameComboChoice
        );
      });
      if (result) {
        group.push(toJS(result.selectedOptions) || []);
      }
    }

    const allSelectedOptions = _orderBy(
      _concat([], ...group),
      ['point'],
      ['asc']
    );
    if (debug) {
      console.log('[COMBO] SELECTED OPTIONS ➜', toJS(allSelectedOptions));
    }

    for (const [choiceIdx, choice] of choices.entries()) {
      if (choice.selected !== null) {
        const dish = cloneDeepSafe(choice.selected!);
        const pointItemId = this.constructIdForDishPointItem(
          rootDish._id,
          choice._id,
          dish._id
        );
        const optionSets = this._calculateFreeOptionsForSingleDish(
          pointItemId,
          dish,
          allSelectedOptions,
          debug
        );
        if (optionSets.length > 0) {
          choices[choiceIdx].selected!.option_sets = optionSets;
        }
      }
    }

    this.update({ choices });
  };

  /**
   * Calculate free option for a dish.
   *
   * @param {string} id - The ID of the dish point item in point collection.
   * @param {T.Schema.Order.OrderDish} dish
   * @param {Array<SelectedOption>} selectedOptions
   * @param {boolean} debug
   * @returns {Array<T.Schema.Restaurant.Menu.RestaurantOptionSet>}
   */
  _calculateFreeOptionsForSingleDish = (
    id: string,
    dish: T.Schema.Order.OrderDish,
    selectedOptions?: Array<SelectedOption>,
    debug?: boolean
  ): Array<T.Schema.Restaurant.Menu.RestaurantOptionSet> => {
    const data = this._getDishPointItem(id);
    if (!data) return [];
    const [dishPointItem] = data;
    const options = selectedOptions
      ? selectedOptions
      : dishPointItem.selectedOptions || [];
    const [freeOptions, excessPointOptions] = this._extractFreeOptions(
      id,
      options || [],
      debug
    );
    dishPointItem.freeOptions = freeOptions;
    dishPointItem.excessPointOptions = excessPointOptions;
    const optionSets = this._replaceOptionSetsFreeOptions(dish, dishPointItem);
    return optionSets;
  };

  /**
   * Assign the free options for a given option set of a given dish.
   * This is done by grouping free options by option set ID and placing
   * each group to the right option set.
   *
   * @param {T.Schema.Order.OrderDish} dish
   * @param {string} optionSetID
   * @returns {Array<T.Schema.Restaurant.Menu.RestaurantOptionSet>}
   */
  _replaceOptionSetsFreeOptions = (
    dish: T.Schema.Order.OrderDish,
    dishPointItem: DishPointItem
  ): Array<T.Schema.Restaurant.Menu.RestaurantOptionSet> => {
    let freeOptions = dishPointItem.freeOptions || [];
    let excessPointOptions = dishPointItem.excessPointOptions || [];

    const groupedFreeOptions = _groupBy(freeOptions, 'optionSetID');
    const groupedExcessPointOptions = _groupBy(
      excessPointOptions || [],
      'optionSetID'
    );

    let optionSets = cloneDeepSafe(dish.option_sets);
    for (const [i, optionSet] of optionSets.entries()) {
      if (groupedFreeOptions.hasOwnProperty(optionSet._id)) {
        const freeOptions = groupedFreeOptions[optionSet._id].map(
          option => option.optionID
        );
        optionSet.freeOptions = freeOptions;
      } else {
        optionSet.freeOptions = [];
      }

      if (groupedExcessPointOptions.hasOwnProperty(optionSet._id)) {
        const excessPointOptions = groupedExcessPointOptions[optionSet._id];
        for (const [i, option] of optionSet.options.entries()) {
          const excessPointOption = _find(
            excessPointOptions,
            item => item.id === option._id
          );
          if (excessPointOption) {
            option.quantity_multiplier_price =
              excessPointOption.multiplier_price;
          }
          optionSet.options[i] = option;
        }
      }

      optionSets[i] = optionSet;
    }

    return optionSets;
  };

  /**
   * Extract the list of free options from selected options.
   *
   * @param {Array<SelectedOption>} sortedSelectedOptions
   * @param {string} id
   * @param {boolean} debug
   * @returns {Array<SelectedOption>}
   */
  _extractFreeOptions = (
    id: string,
    sortedSelectedOptions: Array<SelectedOption>,
    debug?: boolean
  ): [Array<FreeOption>, Array<ExcessPointOption>] => {
    const totalPoints = this.dishTotalPoints || 0;

    let consumedPoints = 0;
    let freeOptions: Array<FreeOption> = [];
    let excessPointOptions: Array<ExcessPointOption> = [];
    let shouldCheckExcessPoint = true;

    /**
     * Mark a given selected option as free.
     * Also make the list of free options be unique.
     *
     * @param {SelectedOption} selectedOption
     */
    const markAsFree = (selectedOption: SelectedOption) => {
      if (id === selectedOption.referenceID) {
        freeOptions.push({
          dishID: selectedOption.dishID,
          optionName: selectedOption.name,
          optionSetID: selectedOption.optionSetID,
          optionID: selectedOption.id,
        });

        freeOptions = _uniqWith(freeOptions, _isEqual);
      }
    };

    // Iterate over the sorted list of selected options and compute free options.
    // We start by maintaining a sum for total consumed points, and adding the current selected
    // option point to that sum.
    // - If the current sum is smaller than or equal to the total points -> mark the selected option as free.
    // - Otherwise:
    //    + Calculate the fraction (the number representation of quantity multiplier) by dividing option point by option base point.
    //      This value should be 1 for single, 2 for double, 3 for triple, etc.
    //    + Iterate using the fraction and adding the base point to the total consumed points.
    //      This is essentially the process of dividing the option point into smaller chunks (base point)
    //      in order to consume points more efficiently. Adding the base point to the total excess points
    //      if the current consumed points is greater than dish total points.
    for (const selectedOption of sortedSelectedOptions) {
      let excessPoints = 0;
      const basePoint = selectedOption.basePoint;
      consumedPoints += selectedOption.point;

      if (debug) {
        console.log(
          '[FREE CHECK] CURRENT - CONSUMED - TOTAL ➜',
          selectedOption.point,
          consumedPoints,
          totalPoints
        );
      }

      if (consumedPoints <= totalPoints) {
        markAsFree(selectedOption);
      } else if (shouldCheckExcessPoint) {
        // Need to subtract the previously added point.
        consumedPoints -= selectedOption.point;

        const fraction = selectedOption.point / basePoint;
        for (let i = 0; i < fraction; i++) {
          consumedPoints += basePoint;
          if (debug) {
            console.log(
              '[EXCESS POINTS CHECK] CURRENT - CONSUMED - TOTAL ➜',
              basePoint
            );
          }
          if (consumedPoints > totalPoints) {
            excessPoints += basePoint;
            if (debug) {
              console.log('[EXCESS POINTS] CURRENT ➜', excessPoints);
            }
          }
        }
      }

      if (excessPoints > 0) {
        // Calculate the quantity multiplier that will be used to calculate option set option price.
        // The customer may choose double but we only use single price for calculating, for example.
        let multiplier = this._getQuantityMultiplierFromFraction(
          excessPoints / basePoint
        );

        if (debug) {
          console.log('[EXCESS POINTS] TOTAL ➜', excessPoints);
          console.log('[EXCESS POINTS] MULTIPLIER ➜', multiplier);
        }

        if (id === selectedOption.referenceID) {
          excessPointOptions.push(
            _assign(cloneDeepSafe(selectedOption), {
              multiplier_price: multiplier,
            })
          );
        }

        shouldCheckExcessPoint = false;
      }
    }

    if (debug) {
      console.log('TOTAL CONSUMED POINTS ➜', consumedPoints);
    }

    return [freeOptions, excessPointOptions];
  };

  /**
   * Calculate point for a given option set option without quantity multiplier.
   *
   * @param {T.Schema.Order.OrderDish["option_sets"]} optionSets
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSet} optionSet
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSetOption} option
   * @returns {number}
   */
  _getOptionPointsWithoutQuantityMultiplier = (
    optionSets: T.Schema.Order.OrderDish['option_sets'],
    optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
    option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption
  ): number => {
    const pricingData = RestaurantUtils.dish.getOptionSetOptionPricingData(
      optionSets,
      optionSet,
      option
    ) as OptionPointPrices;

    let points = 0;
    if (option.position === 'whole') {
      points = pricingData.whole.points;
    } else if (option.position === 'left' || option.position === 'right') {
      points = pricingData.half.points;
    }

    return points;
  };

  /**
   * Convert option quantity multiplier to number format.
   *
   * @param {OptionQuantityMultiplier | undefined} multiplier
   * @returns {number}
   */
  _getPointFraction = (multiplier?: OptionQuantityMultiplier): number => {
    switch (multiplier) {
      case 'single':
        return 1;
      case 'double':
        return 2;
      default:
        return 1;
    }
  };

  /**
   * Convert a fraction number to string representation of option quantity multiplier.
   *
   * @param {number} fraction
   * @returns {OptionQuantityMultiplier}
   */
  _getQuantityMultiplierFromFraction = (
    fraction: number
  ): OptionQuantityMultiplier => {
    switch (fraction) {
      case 1:
        return 'single';
      case 2:
        return 'double';
      default:
        return 'none';
    }
  };

  /**
   * Calculate point for a given option set option.
   *
   * @param {T.Schema.Order.OrderDish["option_sets"]} optionSets
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSet} optionSet
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSetOption} option
   * @returns {number}
   */
  getOptionPoints = (
    optionSets: T.Schema.Order.OrderDish['option_sets'],
    optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
    option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption
  ): number => {
    const points = this._getOptionPointsWithoutQuantityMultiplier(
      optionSets,
      optionSet,
      option
    );

    return points * this._getPointFraction(option.quantity_multiplier);
  };

  /**
   * Refresh point data for a given dish from cart.
   * This is useful when editing a given cart item.
   *
   * @param {T.Schema.Order.OrderDish} dish
   * @return {void}
   */
  refreshDishPointItemForCartItem = (dish: T.Schema.Order.OrderDish): void => {
    if (dish.type === 'combo') {
      for (const choice of dish.choices) {
        const selectedDish = choice.selected;
        if (selectedDish === null) {
          continue;
        }
        let optionSets = selectedDish.option_sets;
        if (!(optionSets && optionSets.length > 0)) {
          continue;
        }
        for (const optionSet of optionSets) {
          for (const option of optionSet.options) {
            this.updateDishPointItem(
              this.constructIdForDishPointItemUsingCartItemId(
                dish.cart_item_id as number,
                dish._id,
                choice._id,
                selectedDish._id
              ),
              selectedDish._id,
              optionSets,
              optionSet,
              option,
              !config.isProduction
            );
          }
        }
      }
      return;
    }

    let optionSets = dish.option_sets;
    if (!(optionSets && optionSets.length > 0)) {
      return;
    }
    for (const optionSet of optionSets) {
      for (const option of optionSet.options) {
        this.updateDishPointItem(
          this.constructIdForDishPointItemUsingCartItemId(
            dish.cart_item_id as number,
            dish._id
          ),
          dish._id,
          optionSets,
          optionSet,
          option,
          !config.isProduction
        );
      }
    }
  };

  /**
   * This method is used to remove redundant dish point item from point collection
   * for removed cart item. For example, customer views the cart, and remove items.
   *
   * @param {T.Schema.Order.OrderDish} dish
   * @returns {void}
   */
  removeDishPointItemForCartItem = (dish: T.Schema.Order.OrderDish): void => {
    const pointCollection = cloneDeepSafe(this.pointCollection);

    if (dish.type === 'combo') {
      for (const choice of dish.choices) {
        const selectedDish = choice.selected;
        if (selectedDish !== null) {
          const id = this.constructIdForDishPointItemUsingCartItemId(
            dish.cart_item_id as number,
            dish._id,
            choice._id,
            selectedDish._id
          );
          _remove(pointCollection, item => item.id === id);
        }
      }
    } else {
      const id = this.constructIdForDishPointItemUsingCartItemId(
        dish.cart_item_id as number,
        dish._id
      );
      _remove(pointCollection, item => item.id === id);
    }

    this.pointCollection = pointCollection;
  };

  /**
   * This method is used to remove redundant dish point item from point collection
   * for unadded dish. For example, customer views the dish, to some actions, but
   * doesn't add it to the cart.
   *
   * @param {T.Schema.Order.OrderDish} dish
   * @returns {void}
   */
  removeDishPointItemForUnaddedDish = (
    dish: T.Schema.Order.OrderDish
  ): void => {
    const pointCollection = cloneDeepSafe(this.pointCollection);

    if (dish.type === 'combo') {
      for (const choice of dish.choices) {
        const selectedDish = choice.selected;
        if (selectedDish !== null) {
          const id = this.constructIdForDishPointItem(
            dish._id,
            choice._id,
            selectedDish._id
          );
          _remove(pointCollection, item => item.id === id);
        }
      }
    } else {
      const id = this.constructIdForDishPointItem(dish._id);
      _remove(pointCollection, item => item.id === id);
    }

    this.pointCollection = pointCollection;
  };

  /**
   * Get the total points for dish.
   *
   * For combo dish, this is the sum of total points of all selected dishes inside combo
   * or the total points from combo dish level.
   */
  @computed get dishTotalPoints() {
    const dish = this.s.dish;
    if (!dish) {
      return undefined;
    }

    if (dish.type !== 'combo') {
      return dish.total_points;
    }

    if (dish.compute_combo_point_from_dishes) {
      return _sum(
        dish.choices
          .filter(choice => choice.selected !== null)
          .map(choice => choice.selected!.total_points || 0)
      );
    }

    return dish.total_points || 0;
  }

  /**
   * Calculate the total points consumed by a given dish
   * (or multiple dishes if we're dealing with combo).
   */
  @computed get pointConsumed() {
    let total = 0;
    const pointCollection = cloneDeepSafe(this.pointCollection);
    if (pointCollection.length === 0) {
      return 0;
    }

    for (const el of pointCollection) {
      total += this._computeDishTotalPoint(el);
    }

    return total;
  }

  /**
   * Compute total consumed points for a given dish.
   *
   * @param {DishPointItem} data
   * @returns {number}
   */
  _computeDishTotalPoint(data: DishPointItem): number {
    let total = 0;
    const options = data.selectedOptions;
    if (options && options.length > 0) {
      total = _sumBy(options, option => option.point);
    }

    return total;
  }

  /**
   * Find dish point item in point collection by ID.
   *
   * @param {string} id
   * @returns {DishPointItem|null}
   */
  _getDishPointItem = (
    id: string
  ): [DishPointItem, Array<DishPointItem>, number] | null => {
    const pointCollection = cloneDeepSafe(this.pointCollection);
    if (!pointCollection) {
      return null;
    }

    const idx = pointCollection.findIndex(element => element.id === id);
    if (idx === -1) {
      return null;
    }

    return [cloneDeepSafe(pointCollection[idx]), pointCollection, idx];
  };

  /**
   * Verify that the state of a given option set option changed.
   *
   * @param {SelectedOption} selectedOption
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSetOption} option
   * @param {number} newOptionPoint
   * @returns {boolean}
   */
  _isOptionChanged = (
    selectedOption: SelectedOption,
    option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption,
    newOptionPoint: number
  ): boolean => {
    return (
      selectedOption.point !== newOptionPoint ||
      selectedOption.position !== option.position ||
      selectedOption.multiplier !== option.quantity_multiplier
    );
  };

  /**
   * Update option sets for the current selected dish.
   *
   * @param {string} id - The dish point item ID.
   * @param {string} dishID
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSet} optionSet
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSetOption} option
   * @returns {void}
   */
  _updateDishOptionSets = (
    id: string,
    dishID: string,
    optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
    option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption
  ): void => {
    const rootDish = this.s.dish;
    if (!rootDish) {
      return;
    }

    if (rootDish.type !== 'combo') {
      return this.update({
        option_sets: this._assignDishOptionSets(rootDish, optionSet, option),
      });
    }

    // The second segment in dish point item ID will be combo choice ID (if available)
    const choiceID = id.split('|')[1];
    const choices = cloneDeepSafe(rootDish.choices);
    const choiceIdx = choices.findIndex(
      choice =>
        choice._id === choiceID &&
        choice.selected !== null &&
        choice.selected._id === dishID
    );
    if (choiceIdx !== -1) {
      const dish = cloneDeepSafe(choices[choiceIdx].selected!);
      const optionSets = this._assignDishOptionSets(dish, optionSet, option);
      this.updateChoice(choiceIdx, { option_sets: optionSets });
    }
  };

  /**
   * A helper method to replace a specific option inside
   * an option set of a given dish.
   *
   * @param {T.Schema.Order.OrderDish} dish
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSet} optionSet
   * @param {T.Schema.Restaurant.Menu.RestaurantOptionSetOption} option
   * @returns {Array<T.Schema.Restaurant.Menu.RestaurantOptionSetOption>}
   */
  _assignDishOptionSets = (
    dish: T.Schema.Order.OrderDish,
    optionSet: T.Schema.Restaurant.Menu.RestaurantOptionSet,
    option: T.Schema.Restaurant.Menu.RestaurantOptionSetOption
  ): Array<T.Schema.Restaurant.Menu.RestaurantOptionSet> => {
    let optionSets = cloneDeepSafe(dish.option_sets);
    const optionSetIdx = optionSets.findIndex(
      item => item._id === optionSet._id
    );
    if (optionSetIdx === -1) {
      return [];
    }

    const optionIdx = optionSet.options.findIndex(
      item => item._id === option._id
    );
    if (optionIdx !== -1) {
      optionSet.options[optionIdx] = option;
    }

    optionSets[optionSetIdx] = optionSet;

    return optionSets;
  };

  @action update = (data: Partial<T.Schema.Order.OrderDish>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Schema.Order.OrderDish];
        if (value !== undefined && this.s.dish) {
          // @ts-ignore
          this.s.dish[key as keyof T.Schema.Order.OrderDish] = value;
        }
      }
    }
  };

  @action updateChoice = (
    index: number,
    data: Partial<T.Schema.Order.OrderDish>
  ) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Schema.Order.OrderDish];
        if (value !== undefined && this.s.dish) {
          // @ts-ignore
          this.s.dish.choices[index].selected![
            key as keyof T.Schema.Order.OrderDish
          ] = value;
        }
      }
    }
  };

  @action clearStore = () => {
    clearPersist(this);
  };

  @action stopPersist = () => {
    stopPersist(this);
  };

  @computed get isSynchronized() {
    return isSynchronized(this);
  }
}
