import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal, ModalContent, Button } from "@lib/components";

interface Props {
  active: boolean;
  onClose: () => void;
  onProceed: () => void;
}

export const LocationConfirmModal: FC<Props> = ({ active, onClose, onProceed }) => {
  const { t } = useTranslation();

  return (
    <Modal
      id="modal-order-restart"
      width="xs"
      closeButton={true}
      active={active}
      close={onClose}>
      <ModalContent>
        <p className="lhp">{t("store.modals.order.config.address.location_permission")}</p>
      </ModalContent>
      <ModalContent className="text-right" paddingtb={15}>
        <Button size="xs" className="m-r-2" onClick={onClose}>
          <p>{t("store.modals.warnings.location_permission.cancel")}</p>
        </Button>
        <Button size="xs" color="primary" onClick={() => {
          onProceed();
          onClose();
        }}>
          <p>{t("store.modals.warnings.location_permission.proceed")}</p>
        </Button>
      </ModalContent>
    </Modal>
  );
}
