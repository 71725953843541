import * as React from "react";
import { styled, css, CS } from "@lib/components";

interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
	background: string;
	color: string;
	image?: string;
	tint: string;
	paddingTop: number;
}

export const HeaderWrapper = styled("header") <HeaderProps>`
  ${CS.flex.center};
  width: 100%;
  min-height: 500px;
  padding: ${({ paddingTop }) => paddingTop + 20}px 20px 30px 20px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  
  ${({ image, tint }) => (image && !tint) && css`
    background: url(${image}) no-repeat center center;
    background-size: cover;
  `};
  
  ${({ image, tint }) => (image && tint) && css`
    background: linear-gradient(${tint}, ${tint}), url(${image}) no-repeat center center;
    background-size: cover;
  `};
  
  p.big {
    font-size: 22px;
  }
`;

export const HeaderContent = styled("div")`
  text-align: center;
  z-index: 1;
`;
