import * as React from "react";
import styled from "styled-components";
import { FallbackProps } from "react-error-boundary";
import { Button } from "@lib/components";
import { config } from "../../../../../config";

const ErrorContainer = styled.div`
  margin: 1rem;
  font-size: 0.9rem;
  line-height: 1.2;

  & > button {
    margin-top: 0.5rem;
  }
`;

const ErrorMessage = styled.p`
  color: rgb(248, 148, 6);
  margin-left: 0.5rem;
`;

export function OptionSetErrorFallback(props: FallbackProps) {
	return (
		<ErrorContainer>
			<ErrorMessage>{props.error.message}</ErrorMessage>
			{/* {!config.isProduction && (<pre>{props.error.message}</pre>)} */}
			{/* <Button size="xxs" color="primary" onClick={props.resetErrorBoundary}>Reload choice</Button> */}
		</ErrorContainer>
	)
}
