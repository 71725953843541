import { observable, action } from "mobx";
import { RootStore } from "../store";
import { AvailabilityCheck } from "../../ui/modals/menu-restrictions";

export interface MenuSearchState {
	query: string;
	showModal: boolean;

	// This field is used to store the latest menu ID
	// of the dish that the user clicked inside the search results.
	chosen_menu_id: string;

	// This field is used to store the latest category ID
	// of the dish that the user clicked inside the search results.
	chosen_category_id: string;

	// menu of searched iteam
	searchMenu?: T.Schema.Restaurant.Menu.RestaurantMenu | undefined;
	searchMenuAvailable?: AvailabilityCheck | undefined;
}

export class MenuSearchStore {
	@observable s: MenuSearchState;
	store: RootStore;

	constructor(store: RootStore, initialState?: MenuSearchState) {
		this.store = store;
		this.s = {
			query: '',
			showModal: false,
			chosen_menu_id: '',
			chosen_category_id: '',
			...(initialState || {}),
		};
	}

	@action update = (data: Partial<MenuSearchState>) => {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const value = data[key as keyof MenuSearchState];
				if (value !== undefined) {
					// @ts-ignore
					this.s[key as keyof MenuSearchState] = value;
				}
			}
		}
	}
}
