import * as React from "react";
import { observer, inject } from "mobx-react";
import { styled, withTheme } from "@lib/components";
import { RotateLoader, Input as InputBase, Textarea, Button } from "@lib/components";
import { logger } from "@lib/common";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }
interface State {
	name: string;
	email: string;
	phone: string;
	message: string;
	loading: boolean;
	response_type: "error" | "success" | null;
	response_message: string;
}

const Wrapper = styled("div")`
  max-width: ${({ theme }) => theme.content_width.md}px;
  width: 100%;
  margin: -260px auto 80px auto;
  color: ${({ theme }) => theme.box.text};
`;

const Content = styled("div")`
  background: ${({ theme }) => theme.box.background};
  padding: 40px;
`;

const Input = styled(InputBase)`
  height: 50px;
`;

@inject("store") @observer
class ContactFormClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			phone: "",
			message: "",
			loading: false,
			response_type: null,
			response_message: "",
		};
	}

	submit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		this.setState({ loading: true, response_message: "", response_type: null });
		const { store } = this.injected;
		try {
			const response = await store.api.contactForm({
				name: this.state.name,
				email: this.state.email,
				phone: this.state.phone,
				message: this.state.message,
			});
			if (response.outcome) {
				this.setState({
					loading: false,
					response_type: "error",
					response_message: response.message,
				});
			}
			else {
				this.setState({
					name: "",
					email: "",
					phone: "",
					message: "",
					loading: false,
					response_type: "success",
					response_message: "Your message has been successfully sent",
				});
			}
		}
		catch (e) {
			logger.captureException(e);
			this.setState({
				loading: false,
				response_type: "error",
				response_message: "Something went wrong, try again or at a later time",
			});
		}
	}

	change = <T extends keyof State>(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		const update = { [name]: value };
		this.setState(update as { [P in T]: State[P] });
	}

	render() {
		if (!this.state) return null;
		const { t, theme } = this.injected;
		const { email, name, phone, message, loading, response_message, response_type } = this.state;
		return (
			<Wrapper>
				<div className="text-center m-b-8">
					<h1>{t("store.sections.contact.title")}</h1>
				</div>
				<Content>
					<form onSubmit={this.submit}>
						<div className="grid-3 md sm-gap">
							<Input
								type="text"
								className="col"
								placeholder={t("store.sections.contact.name")}
								name="name"
								value={name}
								onChange={this.change}
								required={true}
							/>
							<Input
								type="email"
								className="col"
								placeholder={t("store.sections.contact.email")}
								name="email"
								value={email}
								onChange={this.change}
								required={true}
							/>
							<Input
								type="text"
								className="col"
								placeholder={t("store.sections.contact.phone")}
								name="phone"
								value={phone}
								onChange={this.change}
							/>
						</div>
						<Textarea
							className="m-t-4 minh150"
							placeholder={t("store.sections.contact.message")}
							required={true}
							name="message"
							value={message}
							onChange={this.change}
						/>

						{response_type && (
							<div className="text-center m-t-4">
								<p className={response_type === "error" ? "error-text" : "success-text"}>{response_message}</p>
							</div>
						)}

						<div className="text-center m-t-4">
							<Button type="submit" color="primary" className="min150">
								{loading && <RotateLoader size={2} color={theme.colors.primary_text} />}
								{!loading && t("store.sections.contact.send")}
							</Button>
						</div>
					</form>
				</Content>
			</Wrapper>
		);
	}

}

// @ts-ignore
export const ContactForm = withTheme(withTranslation()(ContactFormClass));
