import React from "react";

interface Props {
	checkout_public_key: string
}
interface State { }

class CheckoutForm extends React.Component<Props, State> {
	componentDidMount = () => {
		window.Frames.init(this.props.checkout_public_key);

		window.Frames.addEventHandler(window.Frames.Events.CARD_VALIDATION_CHANGED, function (event: any) {
			window.checkout_token = '';
		});
	};

	render() {
		return (
			<div style={{ backgroundColor: "white" }}>
				<div className="one-liner">
					<div className="card-frame" style={{ height: "40px", marginTop: "20px" }} />
				</div>
				<p className="success-payment-message" />
			</div>
		);
	}
}

export default CheckoutForm;
