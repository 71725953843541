import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalTitle } from '@lib/components'
import { CheckoutFormModalTitleProps } from './types';

export function CheckoutFormModalTitle(props: CheckoutFormModalTitleProps) {
  const { t } = useTranslation();
  const { useQrFlow, useDeepLinkFlow, methodName } = props;

  if (useQrFlow) {
    return (
      <ModalTitle className="round-top" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <h4>{methodName} QR</h4>
        <p className="small m-t-1">
          {t('store.modals.checkout.payment.qr_modal_help', { app: methodName })}
        </p>
      </ModalTitle>
    );
  }

  if (useDeepLinkFlow) {
    return (
      <ModalTitle className="round-top">
        <p className="small">
          {t('store.modals.checkout.payment.switch_to_app_help', { app: methodName })}
        </p>
      </ModalTitle>
    );
  }

  return (
    <ModalTitle className="round-top">
      <h4>{methodName}</h4>
    </ModalTitle>
  );
}
