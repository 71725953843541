import * as React from "react";
import { observer, inject } from "mobx-react";
import {
	FaFacebookSquare,
	FaInstagram,
	FaTwitterSquare,
	FaYoutubeSquare,
	FaPinterestSquare,
} from "react-icons/fa";
import { ContactForm } from "./contact";
import { CoreUtils } from "@lib/common";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../../mobx/component";
import { Wrapper, Content, SocialIcons, Divider } from "./components";
import styled from "styled-components";
import { TiktokIcon } from "@lib/components";

interface Props extends WithTranslation {}

const LogoStyle = styled.img`
	width: 45px;
	height: auto;
`;
@inject("store")
@observer
class FooterClass extends MobxComponent<Props> {
	togglePoliciesAndAgreementsModal = ( modalType: T.Schema.Restaurant.PoliciesAndAgreements) => {
		this.injected.store.modal.toggle(modalType);
	};

	render() {
		const { t } = this.injected;
		const { reseller } = this.injected.store;
		const r = this.injected.store.restaurant;
		const { images, colors } = r.website.sections.footer;
		const isWebView = this.injected.store.view.s.is_web_view;
		const contactEnabled = !!r.website.contact_form_email;

		const termsActive = !!r.settings.terms;
		const privacyActive = !!r.settings.privacy;
		const cookieActive = !!r.settings.cookie?.content;

		const social_links = r.website.social_links || {};
		const paymentLogos = r.website.sections.footer.payment_logos || [];
		const oneSocialLinkActive =
			!!social_links.facebook ||
			!!social_links.instagram ||
			!!social_links.twitter ||
			!!social_links.youtube ||
			!!social_links.pinterest ||
			!!social_links.tiktok;

		const restaurantHidePoweredBy =
			r.website.sections.footer.hide_powered_by;
		let powered_by_style = {};
		if (reseller.stores.powered_by_color) {
			powered_by_style = {
				color: `${reseller.stores.powered_by_color}`,
			};
		}

		let powered_by_disabled_resellers:string[] = [];
		let powered_by_disabled_restaurants = [
			"tcmexicankitchen",
			"renwicktakeaway",
			"chaiyorichmond",
			"ormistoncafe",
			"benandmaxxi",
			"lemongrassblenheim",
			"miracleasian",
			"samkocafe-nelson",
			"bamboogarden",
			"veganushi",
			"masaladosa",
		];

		let poweredByActive = !(
			powered_by_disabled_restaurants.indexOf(r.subdomain) > -1 ||
			powered_by_disabled_resellers.indexOf(r.reseller_id) > -1
		);

		if (restaurantHidePoweredBy === true) {
			poweredByActive = false;
		}

		const showDivider = oneSocialLinkActive || paymentLogos.length > 0;

		return (
			<Wrapper
				color={colors.text}
				background={colors.background}
				tint={colors.tint}
				image={CoreUtils.image.uc(images.background, {})}
				contactEnabled={contactEnabled}
			>
				{contactEnabled && <ContactForm />}

				<Content>
					{!isWebView && (
						<>
							<SocialIcons style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
								{oneSocialLinkActive && (
									<>
										{social_links.facebook && (
											<a
												href={social_links.facebook}
												target="_blank"
											>
												<FaFacebookSquare />
											</a>
										)}
										{social_links.instagram && (
											<a
												href={social_links.instagram}
												target="_blank"
											>
												<FaInstagram />
											</a>
										)}
										{social_links.twitter && (
											<a
												href={social_links.twitter}
												target="_blank"
											>
												<FaTwitterSquare />
											</a>
										)}
										{social_links.youtube && (
											<a
												href={social_links.youtube}
												target="_blank"
											>
												<FaYoutubeSquare />
											</a>
										)}
										{social_links.tiktok && (
											<a
												href={social_links.tiktok}
												target="_blank"
												style={{marginTop: -2}}
											>
												<TiktokIcon />
											</a>
										)}
										{social_links.pinterest && (
											<a
												href={social_links.pinterest}
												target="_blank"
											>
												<FaPinterestSquare />
											</a>
										)}
									</>
								)}

								{paymentLogos.length > 0 &&
									paymentLogos.map((item, index) => (
										<LogoStyle
											key={index}
											src={`/payment-logos/${item}.png`}
										></LogoStyle>
									))}
							</SocialIcons>

							{showDivider && <Divider />}

							<div id="powered-by-attribution">
								<p className="lhp-lg">
									{poweredByActive && (
										<span>
											{t(
												"store.sections.footer.powered_by"
											)}{" "}
											<a
												id="powered-by-attribution-link"
												style={powered_by_style}
												href={
													reseller.stores
														.powered_by_link
												}
												target="_blank"
												className="underline"
											>
												{
													reseller.stores
														.powered_by_name
												}
											</a>
										</span>
									)}


									{poweredByActive && termsActive && " | "}
									{termsActive && (
										<a
											className="underline"
											onClick={() => {this.togglePoliciesAndAgreementsModal("terms")}}
											style={{whiteSpace: "nowrap"}}
										>
											{t(
												"store.sections.footer.terms_and_conditions"
											)}
										</a>
									)}

									{(poweredByActive || termsActive) && privacyActive && " | "}
									{privacyActive && (
										<a
											className="underline"
											onClick={() => this.togglePoliciesAndAgreementsModal("privacy")}
											style={{whiteSpace: "nowrap"}}
										>
											{t(
												"store.sections.footer.privacy"
											)}
										</a>
									)}

									{(poweredByActive || termsActive || privacyActive) && cookieActive && " | "}
									{cookieActive && (
										<a
											className="underline"
											onClick={() => this.togglePoliciesAndAgreementsModal("cookie")}
											style={{whiteSpace: "nowrap"}}
										>
											{t(
												"store.sections.footer.cookie"
											)}
										</a>
									)}
								</p>
								<p style={{fontSize: '0.75rem', marginTop: '0.5rem'}}>This site is protected by Google reCAPTCHA.</p>
							</div>
						</>
					)}
				</Content>
			</Wrapper>
		);
	}
}

export const Footer = withTranslation()(FooterClass);
