import React, { FC, Fragment } from 'react';
import { RootStore } from '../../../mobx/store';

type SubscriptionContextValue = {
  plan: string;
  isFreePlan: boolean;
}

export const SubscriptionContext = React.createContext<SubscriptionContextValue>({ plan: '', isFreePlan: false });

type Props = {
  store: RootStore,
};

export const SubscriptionContextProvider: FC<Props> = ({ children, store }) => {
  const { subscription } = store.restaurant;
  const plan = subscription?.plan || '';
  const isFreePlan = plan === 'v4-free';
  
  return (
    <SubscriptionContext.Provider value={{ plan, isFreePlan }}>
      <Fragment>{children}</Fragment>
    </SubscriptionContext.Provider>
  );
};
