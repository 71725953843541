import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import darken from "polished/lib/color/darken";
import { BlockError, OrderItems, OrderTotals, Button, styled, Tag } from "@lib/components";

const Item = styled("div")`
  
`;

const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid ${(props) => props.theme.box.border};
  background: ${(props) => darken(0, props.theme.box.background)};
`;

const Content = styled("div") <{ active: boolean }>`
  padding: 20px;
  display: ${({ active }) => active ? "block" : "none"};
  border-bottom: 1px solid ${(props) => props.theme.box.border};
  background: ${(props) => darken(0.0, props.theme.box.background)};
`;

interface Props extends WithTranslation {
	i: number;
	o: T.Schema.Order.OrderSchema;
	active: boolean;
	toggleActive: () => void;
}
interface State {
	invalid: string;
}

@inject("store") @observer
class OrderHistoryItemClass extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			invalid: this.injected.store.order_history.orderInvalidCheck(props.o),
		};
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (this.props.o._id !== prevProps.o._id) {
			this.setState({ invalid: this.injected.store.order_history.orderInvalidCheck(this.props.o) });
		}
	}

	render() {
		if (!this.state) return null;
		const { store, t } = this.injected;
		const { o, active, toggleActive, i } = this.props;
		const { invalid } = this.state;
		return (
			<Item>
				<Header onClick={toggleActive} className="">
					<div className="flex-line centered">
						{active ? <FaChevronUp /> : <FaChevronDown />}
						<Tag className="m-lr-2">#{o.number}</Tag>
						<p className="smaller">{t("dateFromTimestamp", { value: o.created })}</p>
					</div>
					<div className="flex-line centered">
						{!invalid && (
							<Button
								size="xxs"
								round={true}
								color={"primary-inverse"}
								onClick={(e) => store.order_history.clickOrder(e, i)}
								className="p-lr-2 m-r-1">
								Order
							</Button>
						)}
						<Button
							size="xxs"
							round={true}
							color="primary-inverse"
							onClick={(e) => store.order_history.clickReceipt(e, i)}
							className="p-lr-2">
							Receipt
						</Button>
					</div>
				</Header>
				<Content active={active}>
					{!!invalid && (
						<BlockError className="m-b-3" text={t("store.modals.order.history.invalid")} />
					)}
					<OrderItems items={o.dishes} />
					<OrderTotals
						tax_in_prices={o.bill.tax_in_prices}
						fees={o.bill.fees}
						taxes={o.bill.taxes}
						discount={o.bill.discount}
						promo={o.promos.length > 0 ? o.promos[0] : null}
						totalCart={o.bill.cart}
						total={o.bill.total}
						tip={typeof o.bill.tip === "undefined" ? 0 : o.bill.tip}
					/>
				</Content>
			</Item>
		);
	}

}

export const OrderHistoryItem = withTranslation()(OrderHistoryItemClass);
