import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Modal,
  ModalTitle,
  ModalContent,
} from "@lib/components";
import { Option } from './address.google';
import { GoogleAddressPicker, Location as PickerLocation } from "./marker.google";

const LocationPreview = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
`;

const ActionsWrapper = styled.div`
  display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 10px;
`;

const LocationButton = styled(Button)`
	margin: 0 5px;
  width: 140px;
  font-weight: normal;
  color: rgba(50,50,50,1);
  border: 1px solid #ccc;
`;

const AddressWrapper = styled.div``;

type Props = {
  active: boolean;
  onClose: () => void;
  handleAddressSelect: (location: Option) => void;
  pickerDefaultLocation: PickerLocation;
  handleLocationPermission: () => void;
};

export const GoogleMapPickerModal: FC<Props> = ({
  active,
  onClose,
  handleAddressSelect,
  pickerDefaultLocation,
  handleLocationPermission,
}) => {
  const { t } = useTranslation();
  const [location, setLocation] = React.useState<PickerLocation>();

  const handleLocationUpdated = (location: PickerLocation) => {
    if (location.geocoderResult) {
      setLocation(location);
    }
  }

  const handleGooglePickerUpdated = () => {
    if (location?.geocoderResult) {
      handleAddressSelect({ ...location.geocoderResult, label: location.geocoderResult.formatted_address });
    }
    onClose();
  }

  return (
    <Modal
      width={720}
      closeButton
      active={active}
      close={onClose}
      isFullScreen
      id="google-maps-location-picker-modal"
    >
      <ModalTitle paddinglr={25} paddingtb={20}>
        <div>
          <h4>{t("store.modals.order.config.address.pick_location")}</h4>
          <p className="small m-t-1">{t("store.modals.order.config.address.pick_location_help_text")}</p>
        </div>
      </ModalTitle>
      <ModalContent>
        <GoogleAddressPicker
          onAddressUpdated={handleLocationUpdated}
          defaultLocation={pickerDefaultLocation}
          handleLocationPermission={handleLocationPermission}
        />
        <AddressWrapper>
          <LocationPreview>{location?.geocoderResult?.formatted_address || pickerDefaultLocation.address}</LocationPreview>
          <ActionsWrapper>
            <LocationButton
              size="xs"
              color="white"
              onClick={onClose}>
              {t("store.modals.maps.cancel")}
            </LocationButton>

            <LocationButton
              size="xs"
              color="white"
              onClick={handleGooglePickerUpdated}>
              {t("store.modals.maps.use_location")}
            </LocationButton>
          </ActionsWrapper>
        </AddressWrapper>
      </ModalContent>
    </Modal>
  );
}
