import * as React from 'react';
import { styled, shadeAlternate } from '@lib/components';
import { observer, inject } from 'mobx-react';
import {
  FaChevronLeft,
  FaGlobe,
  FaUser,
  FaPowerOff,
  FaLock,
} from 'react-icons/fa';
import Select from 'react-select';
import { ContentCSS } from './components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../../../mobx/component';
import { Untrusive } from '@lib/common';
import { formatNumWithComma } from '../../../../mobx/utils';

interface Props extends WithTranslation {}

export const Wrapper = styled('div')`
  background: ${({ theme }) =>
    shadeAlternate(0.05, theme.top_nav.background_color)};
  padding: 0 20px 0 10px;
  height: 40px;
`;

export const Content = styled('div')`
  ${ContentCSS};
  p {
    font-size: ${({ theme }) => theme.font.size - 2}px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: ${({ theme }) => theme.font.size}px;
    p {
      font-size: ${({ theme }) => theme.font.size}px;
    }
  }
`;

interface ProfileImageProps {
  referrerPolicy: string;
}

export const UserThumbnail = styled.img.attrs((props: ProfileImageProps) => ({
  referrerPolicy: props.referrerPolicy,
}))<ProfileImageProps>`
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`;

const SelectInput = styled(Select)`
  width: 95px;
  font-size: ${({ theme }) => theme.font.size}px;
  background-color: transparent;
  color: ${({ theme }) => theme.top_nav.text_color};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: center right;
  boxshadow: none !important;
  background-repeat: no-repeat;
  &:focus {
    border: none !important;
  }
  &::-ms-expand {
    display: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: ${({ theme }) => theme.font.size - 2}px;
  }
  z-index: 1000;
`;

const LableOption = styled('div')`
  display: flex;
  align-items: center;
`;

const LabelText = styled('p')`
  color: ${({ theme }) => theme.top_nav.text_color};
`;

@inject('store')
@observer
class NavigationTopClass extends MobxComponent<Props> {
  logout = () => {
    const { store } = this.injected;
    if (store.cart.s.items.length > 0) {
      store.modal.show('logout-warning');
    } else {
      store.customer.logout();
    }
  };

  render() {
    const { t, store } = this.injected;
    const { modal } = store;
    const walletlyPoints =
      store.customer.walletly_points >= 0
        ? formatNumWithComma(store.customer.walletly_points)
        : '';
    const customer = store.customer.s.item;
    const showBackButton =
      store.view.s.is_web_view && !!store.view.s.reseller_portal_url;

    // TODO: Improve this. This is going against styled components pattern.
    // Forced to do it this way because {theme} is only available in styled components and not in its customStyles.
    const topnav_background_color =
      store.restaurant.website.sections.top_nav.colors.background;
    const customStyles = {
      control: (base: any, state: any) => ({
        ...base,
        boxShadow: 'none',
        '&:hover': { borderColor: 'red' },
        border: '0px solid lightgray',
        backgroundColor: 'transparent',
        cursor: 'pointer',
      }),
      menu: (base: any) => ({
        ...base,
        borderRadius: 0,
        marginTop: 0,
        backgroundColor: shadeAlternate(0.05, topnav_background_color),
      }),
      menuList: (base: any) => ({
        ...base,
        padding: 0,
      }),
      option: (styles: any, state: any) => ({
        ...styles,
        backgroundColor: state.isSelected
          ? topnav_background_color
          : shadeAlternate(0.05, topnav_background_color),
      }),
    };

    const default_language_selected = {
      label: (
        <LableOption>
          {/* <span className="flag flag-uk"></span> */}
          <LabelText>{t('short_names.en')}</LabelText>
        </LableOption>
      ),
      value: 'en',
    };
    const breakpoint = store.view.s.breakpoint;
    return (
      <Wrapper id="topNavBar">
        <Content>
          {showBackButton && (
            <a onClick={() => store.modal.toggle('reseller-portal-back')}>
              <p>
                <FaChevronLeft className="m-r-1" /> Back
              </p>
            </a>
          )}

          {!showBackButton && (
            <div className="flex-line centered">
              <SelectInput
                styles={customStyles}
                defaultValue={default_language_selected}
                onChange={(e: any) => {
                  Untrusive.start();
                  store.intl
                    .changeLanguage(e.value)
                    .then(() => setTimeout(() => Untrusive.stop(), 500))
                    .catch(() => setTimeout(() => Untrusive.stop(), 500));
                }}
                // Follow: https://github.com/SlavkoPekaric/Country-Flags-Responsive-CSS-Sprite
                options={[
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-uk"></span> */}
                        <LabelText>{t('short_names.en')}</LabelText>
                      </LableOption>
                    ),
                    value: 'en',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-ae"></span> */}
                        <LabelText>{t('short_names.ar')}</LabelText>
                      </LableOption>
                    ),
                    value: 'ar',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-cn"></span> */}
                        <LabelText>{t('short_names.bg')}</LabelText>
                      </LableOption>
                    ),
                    value: 'bg',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-dk"></span> */}
                        <LabelText>{t('short_names.da')}</LabelText>
                      </LableOption>
                    ),
                    value: 'da',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-de"></span> */}
                        <LabelText>{t('short_names.de')}</LabelText>
                      </LableOption>
                    ),
                    value: 'de',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-gr"></span> */}
                        <LabelText>{t('short_names.el')}</LabelText>
                      </LableOption>
                    ),
                    value: 'el',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-es"></span> */}
                        <LabelText>{t('short_names.es')}</LabelText>
                      </LableOption>
                    ),
                    value: 'es',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-fr"></span> */}
                        <LabelText>{t('short_names.fr')}</LabelText>
                      </LableOption>
                    ),
                    value: 'fr',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-fi"></span> */}
                        <LabelText>{t('short_names.fi')}</LabelText>
                      </LableOption>
                    ),
                    value: 'fi',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-id"></span> */}
                        <LabelText>{t('short_names.id')}</LabelText>
                      </LableOption>
                    ),
                    value: 'id',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-it"></span> */}
                        <LabelText>{t('short_names.it')}</LabelText>
                      </LableOption>
                    ),
                    value: 'it',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-jp"></span> */}
                        <LabelText>{t('short_names.ja')}</LabelText>
                      </LableOption>
                    ),
                    value: 'ja',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-km"></span> */}
                        <LabelText>{t('short_names.km')}</LabelText>
                      </LableOption>
                    ),
                    value: 'km',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-no"></span> */}
                        <LabelText>{t('short_names.nb')}</LabelText>
                      </LableOption>
                    ),
                    value: 'nb',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-pa"></span> */}
                        <LabelText>{t('short_names.pa')}</LabelText>
                      </LableOption>
                    ),
                    value: 'pa',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-pt"></span> */}
                        <LabelText>{t('short_names.pt')}</LabelText>
                      </LableOption>
                    ),
                    value: 'pt',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-pt"></span> */}
                        <LabelText>{t('short_names.sv')}</LabelText>
                      </LableOption>
                    ),
                    value: 'sv',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-sr"></span> */}
                        <LabelText>{t('short_names.sr')}</LabelText>
                      </LableOption>
                    ),
                    value: 'sr',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-th"></span> */}
                        <LabelText>{t('short_names.th')}</LabelText>
                      </LableOption>
                    ),
                    value: 'th',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-vn"></span> */}
                        <LabelText>{t('short_names.vi')}</LabelText>
                      </LableOption>
                    ),
                    value: 'vi',
                  },
                  {
                    label: (
                      <LableOption>
                        {/* <span className="flag flag-cn"></span> */}
                        <LabelText>{t('short_names.zh')}</LabelText>
                      </LableOption>
                    ),
                    value: 'zh',
                  },
                ]}
                isSearchable={false}
              />
            </div>
          )}

          <div className="flex-line">
            {customer && (
              <div
                className="flex-line centered cursor"
                onClick={() => modal.show('customer')}
              >
                {customer.avatar && <UserThumbnail src={customer.avatar} referrerPolicy="no-referrer" />}
                {!customer.avatar && <FaUser className="m-r-1" />}
                <p>{store.customer.display_name}</p>
              </div>
            )}

            {customer && walletlyPoints && (
              <div
                className="cursor flex-line centered m-l-2"
                onClick={() => modal.show('customer')}
              >
                <img
                  width="20"
                  height="20"
                  src="/walletly-logo.png"
                  alt="walletly-logo"
                />
                <p className="m-l-1">{walletlyPoints}</p>
              </div>
            )}

            {!customer && ['xl', 'md'].includes(breakpoint) && (
              <p className="m-r-4 flex-center">
                <FaUser className="m-r-1" />
                {t('store.sections.nav_top.guest')}
              </p>
            )}
            {/*
						{customer && (
							<p className="cursor flex-center" onClick={this.logout}>
								<FaPowerOff className="m-r-1" />
								{t("store.sections.nav_top.logout")}
							</p>
						)} */}

            {!customer && (
              <p
                className="cursor flex-center"
                onClick={() => modal.show('auth')}
              >
                <FaLock className="m-r-1" />
                {t('store.sections.nav_top.login')}
                {/* {disable_signup
									? t("store.sections.nav_top.login")
									: t("store.sections.nav_top.login_signup")} */}
              </p>
            )}
          </div>
        </Content>
      </Wrapper>
    );
  }
}

export const NavigationTop = withTranslation()(NavigationTopClass);
