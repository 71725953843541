import React from 'react';
import { observer } from 'mobx-react';
import { Modal, ModalTitle, ModalContent, Button } from '@lib/components';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../mobx/useStores';

export const AppRefreshModal = observer(() => {
  const store = useStores();
  const { t } = useTranslation();

  const handleClose = () => {
    store.modal.toggle('app-refresh');
  };

  const handleRefresh = () => {
    store.modal.hide('app-refresh');
    window.location.reload();
  };

  return (
    <Modal
      id="modal-app-refresh"
      width={420}
      closeButton={true}
      preventClose
      active={store.modal.isVisible('app-refresh')}
      close={handleClose}
    >
      <ModalTitle paddinglr={25} paddingtb={20} className="round-top">
        <div>
          <h4 className="lhp">{t('store.modals.app_refresh.title')}</h4>
          <p className="lhp small">{t('store.modals.app_refresh.description')}</p>
        </div>
      </ModalTitle>

      <ModalContent>
        <Button full={true} color="primary" onClick={handleRefresh}>
          {t('store.modals.app_refresh.refresh')}
        </Button>
      </ModalContent>
    </Modal>
  );
});
