import React, { useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import _kebabCase from 'lodash/kebabCase';
import _capitalize from 'lodash/capitalize';

import { PaymentMethodAliases, PaymentImages } from '@lib/common';
import { useStores } from '../../../mobx/useStores';
import { Modal, ConfirmationModal } from '@lib/components';
import { DefaultFlow, QrFlow, DeepLinkFlow, CheckoutFormProps, CheckoutFormModalTitle } from './flows';
import { config } from '../../../../config';
import styled from 'styled-components';

const LogoWrapper = styled.div`
  height: 100px;
  margin-top: 1rem;
  & > img {
    height: 6rem;
    width: 100%;
    object-fit: scale-down;
    object-position: center;
  }
`;

function CheckoutFormModalPlain(props: CheckoutFormProps) {
  const store = useStores();
  const [confirmationModalVisible, showConfirmationModal] = useState(false);

  const {
    paymentMethod,
    useQrFlow,
    qrImageUrl,
    useDeepLinkFlow,
    deepLink,
    isFullScreen,
    adhocCloseAction,
    preventClosingModal,
    orderId,
  } = props;

  const confirmClosingPaymentForm = () => {
    if(paymentMethod.name === 'bambora_na'){
      store.order.update({ paymentFormModal: '' });
      store.router.push('/');
      store.checkout.resetPayments();
      store.sessionRemoveCart();
      store.sessionRemoveOrder();
      store.checkout.s.loading = false;
      store.modal.show('checkout');
    }else{
      showConfirmationModal(true);
    }
    
  };

  const closeConfirmationModal = () => {
    showConfirmationModal(false);
  };
  const handleCloseReceiptModal = () => {
    const getActive = localStorage.getItem("saveActiveReceiptOrder");
    if(!getActive){
      confirmClosingPaymentForm()
    }
  }

  const closePaymentForm = async (isCancel = false) => {
    if (isCancel) {
      const result = await store.api.order_update_status({
        _id: orderId,
        status: 'cancelled',
      });
      if (result.outcome === 1) {
        store.order.update({
          error: result.message,
        });
      }
    }
    store.order.update({ paymentFormModal: '' });
    closeConfirmationModal();
  };

  const closePaymentFormWithCustomAction = () => {
    if (adhocCloseAction && typeof adhocCloseAction === 'function') {
      adhocCloseAction();
    }

    if (!preventClosingModal) {
      closePaymentForm();
    }
  };

  const paymentMethodName = useMemo(() => {
    const defaultName = paymentMethod.label || _capitalize(paymentMethod.name);
    if (!paymentMethod.alias) {
      return defaultName;
    }

    const methodAlias = (PaymentMethodAliases[paymentMethod.name] || []).find(
      alias => alias.key === paymentMethod.alias
    );
    if (!methodAlias) {
      return defaultName;
    }

    return methodAlias.label;
  }, [paymentMethod]);

  const paymentMethodImage = useMemo(() => {
    const images = PaymentImages[paymentMethod.name as keyof typeof PaymentImages];
    if (Array.isArray(images)) {
      const baseUrl = config.isTest ? 'http://localhost:3000/payment-logos' : '/payment-logos';
      return `${baseUrl}${images[0]}`;
    }
    return null;
  }, [paymentMethod]);
  return (
    <>
      <Modal
        width={520}
        closeButton
        preventClose
        isFullScreen={isFullScreen}
        close={confirmClosingPaymentForm}
        id={`${_kebabCase(paymentMethod.name)}-checkout-modal`}
        active={store.order.s.paymentFormModal === paymentMethod.name}
      >
        <CheckoutFormModalTitle {...props} methodName={paymentMethodName === 'Worldline' ? 'Verification Needed' : paymentMethodName} />

        {paymentMethodImage && (
          <LogoWrapper>
            <img src={paymentMethodImage} />
          </LogoWrapper>
        )}

        {useQrFlow && <QrFlow qrUrl={String(qrImageUrl)} methodName={paymentMethodName} />}
        {useDeepLinkFlow && <DeepLinkFlow deepLink={String(deepLink)} methodName={paymentMethodName} />}
        {!(useQrFlow || useDeepLinkFlow) && (
          <DefaultFlow {...props} methodName={paymentMethodName} closePaymentForm={closePaymentFormWithCustomAction} handleCloseReceiptModal={handleCloseReceiptModal}/>
        )}
      </Modal>

      <ConfirmationModal
        width={420}
        id={`${_kebabCase(paymentMethodName)}-confirmation-checkout-modal`}
        visible={confirmationModalVisible}
        header="Payment Cancellation"
        description="If you cancel the current payment process, you need to create a new order and do the payment again."
        question="Please confirmation your action for this order."
        cancelButtonText="Cancel Payment"
        confirmButtonText="Continue To Payment"
        confirmButtonType="primary"
        cancel={() => closePaymentForm(true)}
        handleConfirm={closeConfirmationModal}
      />
    </>
  );
}

export const CheckoutFormModal = observer(CheckoutFormModalPlain);
