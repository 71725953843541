import * as React from "react";
import { observer, inject } from "mobx-react";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";

interface Props extends WithTranslation { }
interface State { }

@inject("store") @observer
class OrderConfigNumberOfPeopleClass extends MobxComponent<Props, State> {

	render() {
		const { t } = this.injected;
		const oc = this.injected.store.order_config;
		return (
			<div className="m-t-6 text-center">
				<Input
					type="number"
					className="max300 text-center"
					value={oc.s.number_of_people === 0 ? '' : oc.s.number_of_people}
					placeholder={t("store.modals.booking.form.number_of_guests")}
					onChange={(e) => {
						oc.setNumberOfPeople(parseInt(e.target.value, 10));
					}}
				/>
			</div>
		);
	}

}

export const OrderConfigNumberOfPeople = withTranslation()(OrderConfigNumberOfPeopleClass);
