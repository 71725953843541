import { StorageAdapter } from "mobx-persist-store";

export const storageAdapter = new StorageAdapter({
	read: async (name) => {
		try {
			if (typeof window !== 'undefined') {
				const data = window.localStorage.getItem(name);
				return data ? JSON.parse(data) : undefined;
			}
		} catch (e) {
			//
		}
	},
	write: async (name, content) => {
		try {
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(name, JSON.stringify(content));
			}
		} catch (e) {
			//
		}
	},
})
