import React from "react";
import { inject, observer } from "mobx-react";
import { Field, Form, Formik, FormikProps } from "formik";
import Flexsearch, { Index, CreateOptions } from "flexsearch";
import _isEmpty from "lodash/isEmpty";
import _find from "lodash/find";
import { Input, styled } from "@lib/components";
import { MobxComponent } from "../../../mobx/component";
import { DishGrid } from "../../views/sections/item-layout/components";
import { Dish } from "../../views/sections/item";
import { Dish as DishV1 } from "../../views/menuTemplateV1/menus/dish/index";
import { WithTranslation, withTranslation } from "react-i18next";
import { CJKTokenizer } from "@lib/common";

interface Props extends WithTranslation {}

interface State {
	query: string;
	results?: Array<T.Schema.Restaurant.Menu.RestaurantDish>;
}

function* iterate(data: Array<T.Schema.Restaurant.Menu.RestaurantDish>) {
	const values = [...data];
	for (const val of values) {
		yield val;
	}
}

function doAdvancedEncoding(str: string) {
	return Flexsearch.encode("advanced", str);
}

function doFulltokenization(str: string): string[] {
	if (_isEmpty(str)) {
		return [];
	}

	let tokens: string[] = [];
	const words = str.split(/\W+/);
	for (let i = 0; i < words.length; i++) {
		const value = words[i];
		if (value) {
			const length = value.length;
			for (let x = 0; x < length; x++) {
				for (let y = length; y > x; y--) {
					const token = value.substring(x, y);
					tokens.push(token);
				}
			}
		}
	}

	return tokens;
}

const Content = styled("div")`
	position: relative;
	z-index: 1;
	max-width: ${({ theme }) => theme.content_width.lg}px;
	width: 100%;
`;

const ResultsWrapper = styled("div")`
	margin: 2rem 0;
	padding: 2rem 0;
	max-height: calc(90vh - 200px);
	overflow-y: auto;
	& .dish-item {
		box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.1);
		&: hover {
			box-shadow: 0px 4px 10px -1px rgba(0, 0, 0, 0.25);
		}
	}
`;

const NoResults = styled("div")`
	margin: 1rem 0;
	max-height: calc(90vh - 200px);
	font-size: 0.9rem;
`;

@inject("store")
@observer
class MenuSearchClass extends MobxComponent<Props, State> {
	index: Index<T.Schema.Restaurant.Menu.RestaurantDish>;
	dishes: Array<T.Schema.Restaurant.Menu.RestaurantDish>;

	constructor(props: Props) {
		super(props);

		const { store } = this.injected;
		const { restaurant } = store;

		this.state = {
			query: store.menuSearch.s.query,
		};

		let indexOptions = {
			threshold: 0,
			async: false,
			worker: false,
			cache: true,
			doc: {
				id: "_id",
				field: [
					"name",
					"description",
					"subtitle",
					"tagList",
					"ingredientList",
				],
			},
			stemmer: {
				hawa: "haw",
			},
		} as CreateOptions;

		/**
		 * For tokenization, we'll tokenize the given string
		 * using both normal Latin-based tokenizer and CJK tokenizer.
		 *
		 * @param str {string}
		 */
		indexOptions.tokenize = function (str: string): string[] {
			const tokens = doFulltokenization(doAdvancedEncoding(str));
			const cjkTokens = Object.keys(CJKTokenizer.tokenize(str));

			return tokens.concat(cjkTokens);
		};

		const index: Index<T.Schema.Restaurant.Menu.RestaurantDish> =
			Flexsearch.create(indexOptions);
		this.index = index;

		const distTags = restaurant.dish_tags;
		let { dishes } = this.injected.store.searchableDishes;

		dishes = dishes.map((dish) => {
			const tagIDs = dish.tags;

			if (tagIDs.length === 0) {
				// @ts-ignore
				dish.tagList = "";
			} else {
				// @ts-ignore
				dish.tagList = tagIDs
					.map((id) => {
						const tag = _find(distTags, (tag) => tag._id === id);
						return tag?.name;
					})
					.join(" ");
			}

			const ingredients = dish.ingredients;
			if (ingredients.length === 0) {
				// @ts-ignore
				dish.ingredientList = "";
			} else {
				// @ts-ignore
				dish.ingredientList = ingredients
					.map((ingredient) => ingredient.name)
					.join(" ");
			}

			return dish;
		});

		this.dishes = dishes;

		const dishesIterator = iterate(this.dishes);
		for (const item of dishesIterator) {
			this.index.add(item);
		}
	}

	handleQueryChanged = async (e: any) => {
		const query = e.target.value;
		const { store } = this.injected;
		const results = await this.index.search(query);
		this.setState({
			query: query,
			results: results,
		});

		store.menuSearch.update({ query });
	};

	async componentDidMount() {
		const query = this.state.query;
		if (!_isEmpty(query)) {
			const results = await this.index.search(query);
			this.setState({
				results: results,
			});
		}
	}

	render() {
		const { store, t } = this.injected;
		const { restaurant } = store;
		const dishes = this.state.results || null;
		const ws = store.restaurant.website.sections;
		const { categories } = store.searchableDishes;

		return (
			<Content>
				<Formik
					initialValues={{ query: "" }}
					onSubmit={(values, { setSubmitting }) => {
						setSubmitting(false);
					}}
				>
					{(props: FormikProps<any>) => (
						<Form>
							<Field
								name="query"
								render={({ field }: any) => (
									<Input
										type="text"
										{...field}
										autoSave="false"
										autoCorrect="false"
										style={{ backgroundColor: "white", color: "black" }}
										value={this.state.query}
										placeholder={t(
											`store.sections.menus.search.placeholder`
										)}
										onChange={this.handleQueryChanged}
									/>
								)}
							/>
						</Form>
					)}
				</Formik>

				{dishes && dishes.length > 0 && (
					<ResultsWrapper>
						<DishGrid
							dishStyle={ws.menus.dish_style}
							tagsApplied={true}
						>
							{dishes.map((dish, i) => {
								const tags = restaurant.dish_tags.filter(
									(tag) => dish.tags.indexOf(tag._id) !== -1
								);
								const category = categories.find(
									(category) =>
										category._id === dish.category_id
								)!;

								if (
									store.restaurant.website.sections
										.menu_nav_bar?.menu_template === "v2"
								) {
									return (
										<Dish
											key={dish._id}
											index={i}
											dish={dish}
											category={category}
											tags={tags}
											className="col dish-item"
										/>
									);
								}

								return (
									<DishV1
										key={dish._id}
										index={i}
										dish={dish}
										category={category}
										tags={tags}
										className="col dish-item"
									/>
								);
							})}
						</DishGrid>
					</ResultsWrapper>
				)}

				{!(dishes && dishes.length > 0) &&
					this.state.query.length > 0 && (
						<NoResults>
							{t(`store.sections.menus.search.no_result`)}
						</NoResults>
					)}
			</Content>
		);
	}
}

export const MenuSearch = withTranslation()(MenuSearchClass);
