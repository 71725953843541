import * as React from "react";
import { styled, shadeAlternate } from "@lib/components";

export const ImageWrapper = styled("div")`
  position: relative;
  height: 240px; 
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  overflow: hidden;
`;

export const Image = styled("div") <{ url: string }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: url(${({ url }) => url}) no-repeat center center;
  background-size: cover;
  height: 240px; 
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

export const ImageCategory = styled("div")`
  position: absolute;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.box.background};
  color: ${({ theme }) => theme.box.text};
  padding: 4px 8px;
  border-top-left-radius: 5px;
`;

export const OptionListWrapper = styled("div")`
  > div {
    border-bottom: 1px dashed ${({ theme }) => shadeAlternate(0.15, theme.box.background)};
    padding: 5px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
`;

export const OptionListItem = styled("div") <{ cursor?: string }>`
  cursor: ${({ cursor }) => cursor || "pointer"};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`;
