import React from 'react';
import { useTranslation } from 'react-i18next';
import { MobXProviderContext, observer } from 'mobx-react';
import { Button, Modal, ModalContent } from '@lib/components';

export const FreeItemPromoUsageConfirmation = observer(() => {
  const { store } = React.useContext(MobXProviderContext);
  const { modal } = store;
  const { t } = useTranslation();

  return (
    <Modal
      id="freeItemConfirm"
      width={420}
      closeButton={true}
      active={store.modal.isVisible('freeItemConfirm')}
      close={() => store.modal.toggle('freeItemConfirm')}
      alignTop={true}
    >
      <div className="p-4">
        <h4 className="font-16">{t('store.modals.free_items_promo.title')}</h4>
        <ModalContent />
        <ModalContent>
          <p className="lhp">{t('store.modals.free_items_promo.usage_confirmation.question')}</p>
        </ModalContent>
        <ModalContent className="text-right" paddingtb={15}>
          <Button className="m-r-2" onClick={() => modal.show('checkout')}>
            <p>{t('store.modals.free_items_promo.usage_confirmation.cancellation')}</p>
          </Button>
          <Button color="primary" onClick={() => modal.show('selectFreeItems')}>
            <p>{t('store.modals.free_items_promo.usage_confirmation.acceptance')}</p>
          </Button>
        </ModalContent>
      </div>
    </Modal>
  );
});
