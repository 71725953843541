import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { DividedIntoCategoriesLayout } from "./divided-into-categories";
import { OneGridLayout } from "./one-grid-layout";
import { MenusExperimental } from "../../menuTemplateV1/menus/indexExperimental";
import { Menus } from "../../menuTemplateV1/menus";

interface Props { }

@inject("store") @observer
export class ItemLayout extends MobxComponent<Props> {
  handleLazyBeforeUnveil = (e: any) => {
    const bg = e.target.getAttribute("data-bg");
    if (bg) {
      e.target.style.background = `url(${bg}) no-repeat center center`;
      e.target.style.backgroundSize = "cover";
    }
  };

  componentDidMount() {
    this.forceUpdate();

    document.addEventListener(
      "lazybeforeunveil",
      this.handleLazyBeforeUnveil,
      true
    );
  }

  componentWillUnmount() {
    document.removeEventListener(
      "lazybeforeunveil",
      this.handleLazyBeforeUnveil,
      true
    );
  }

  render() {
    const { store } = this.injected;
	const website = store.restaurant.website;

	const menuTemplate = website.sections.menu_nav_bar?.menu_template || "v1";

	const legacyDividedIntoCategoriesFlag = website.experimental_ui_1;

	if (menuTemplate === "v1") {
		return legacyDividedIntoCategoriesFlag ? (
			<MenusExperimental />
		) : (
			<Menus />
		);
	}

	if (menuTemplate === "v2") {
		const itemLayoutStyle = store.view.itemLayoutStyle;

		if(itemLayoutStyle === "divided-into-categories") {
			return <DividedIntoCategoriesLayout />;
		}

		return <OneGridLayout />;

	}

  }
}