import * as React from "react";
import { inject, observer } from "mobx-react";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../../mobx/component";
import { Input, FormGroup, RotateLoader, Button, Checkbox } from "@lib/components";

interface Props extends WithTranslation { }
interface State { }

@inject("store") @observer
class AuthRegisterFormClass extends MobxComponent<Props, State> {

	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = e.target;
		const update = { [name]: value };
		// @ts-ignore
		this.injected.store.customer.updateRegister(update);
	}

	render() {
		const { t, store } = this.injected;
		const { termsActive } = store;
		const { login, password, accept_terms, confirmation_code, step, error, submitting } = store.customer.form.register;
		if (step === 0) {
			return (
				<form key="1" onSubmit={store.customer.register_commence}>
					<FormGroup no_border={true} className="m-b-2">
						<Input
							type="text"
							name="login"
							placeholder={t("store.modals.auth.login_placeholder")}
							required={true}
							value={login}
							onChange={this.onChange}
						/>
					</FormGroup>
					<FormGroup no_border={true} className="m-b-4">
						<Input
							type="password"
							name="password"
							placeholder={t("store.modals.auth.password_placeholder")}
							required={true}
							value={password}
							onChange={this.onChange}
						/>
					</FormGroup>
					{termsActive && (
						<FormGroup no_border={true} className="m-b-4 flex-center">
							<Checkbox
								id="accept-terms-register"
								name="accept_terms"
								checked={accept_terms}
								onChange={(e) => store.customer.updateRegister({ accept_terms: e.target.checked })}
								label={
									<p className="inline-block small">
										{t("store.modals.auth.accept_terms")} - <a className="link" onClick={() => store.modal.show("terms")}>{t("store.modals.auth.accept_terms_view")}</a>
									</p>
								} />
						</FormGroup>
					)}
					{error && <FormGroup no_border={true} className="m-b-2" error={t(`store.modals.auth.${error}`)} />}
					<FormGroup no_border={true}>
						<Button full={true} color="primary" disabled={submitting}>
							{!submitting && t("store.modals.auth.register_commence")}
							{submitting && <RotateLoader color="white" size={2} />}
						</Button>
					</FormGroup>
				</form>
			);
		}

		return (
			<form key="2" onSubmit={store.customer.register_complete}>
				<FormGroup no_border={true} className="m-b-3">
					<Input
						type="text"
						className="text-center"
						name="confirmation_code"
						placeholder={t("store.modals.auth.confirmation_code_placeholder")}
						value={confirmation_code}
						onChange={this.onChange}
						required={true}
					/>
				</FormGroup>
				<FormGroup no_border={true} className="m-b-3 text-center">
					<p className="small lhp">{store.customer.form.register.resent ? t("store.modals.auth.register_confirmation_resent") : t("store.modals.auth.register_confirmation_sent")}</p>
					<div className="flex-line flex-center-h m-t-2">
						<p className="small" onClick={store.customer.register_restart}><a className="link m-r-2">{t("store.modals.auth.register_restart")}</a></p>
						{!store.customer.form.register.resent && <p className="small" onClick={store.customer.register_resend}><a className="link m-r-2">{t("store.modals.auth.register_resend_code")}</a></p>}
					</div>
				</FormGroup>
				{error && <FormGroup no_border={true} className="m-b-3" error={t(`store.modals.auth.${error}`)} />}
				<FormGroup no_border={true}>
					<Button full={true} color="primary" disabled={submitting}>
						{!submitting && t("store.modals.auth.register_commence")}
						{submitting && <RotateLoader color="white" size={2} />}
					</Button>
				</FormGroup>
			</form>
		);

	}

}

export const AuthRegisterForm = withTranslation()(AuthRegisterFormClass);
