import React, { ChangeEvent } from 'react';
import { styled, Input } from '@lib/components';
import { inject, observer } from 'mobx-react';
import { MobxComponent } from '../../../../mobx/component';
import _isEmpty from 'lodash/isEmpty';
import Payment from 'payment';
import { CheckoutState } from '../../../../mobx/state/checkout';

interface Props {}
interface State {
  error: string;
}

const NumberInputNoSpinButton = styled(Input)`
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

type PaymentField = keyof CheckoutState;

@inject('store')
@observer
class BamboraNaFormClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  onChangeField(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldName: PaymentField) {
    const { store } = this.injected;
    store.checkout.update({ [fieldName]: e.target.value });

    this.validateCard();
  }

  onChangeName = (e: ChangeEvent<HTMLInputElement>) => this.onChangeField(e, 'cardholder_name');
  onChangeAddress = (e: ChangeEvent<HTMLInputElement>) => this.onChangeField(e, 'billing_address_street');
  onChangeCity = (e: ChangeEvent<HTMLInputElement>) => this.onChangeField(e, 'billing_address_city');
  onChangePostalCode = (e: ChangeEvent<HTMLInputElement>) => this.onChangeField(e, 'postal_code');
  onChangeCvv = (e: ChangeEvent<HTMLInputElement>) => this.onChangeField(e, 'card_cvv');
  onChangeCardNumber = (e: ChangeEvent<HTMLInputElement>) => this.onChangeField(e, 'card_number');

  onChangeExpiry = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { store } = this.injected;
    const next = e.target.value;
    const current = store.checkout.s.card_expiry;

    if (next.length > 5) {
      return;
    }
    if (current.length === 3 && next.length === 2) {
      store.checkout.update({ card_expiry: next });
    } else if (current.length === 2 && next.length === 3) {
      store.checkout.update({ card_expiry: current + '/' + next[2] });
    } else if (current.length === 1 && next.length === 2) {
      store.checkout.update({ card_expiry: next + '/' });
    } else {
      store.checkout.update({ card_expiry: next });
    }

    this.validateCard();
  };

  formatCreditCardNumber = (value: string) => {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = value.replace(/\D+/g, '');
    let nextValue;

    switch (issuer) {
      case 'amex':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 15)}`;
        break;
      case 'dinersclub':
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
          12,
          19
        )}`;
        break;
    }

    return nextValue.trim();
  };

  validateCard = () => {
    const { store } = this.injected;
    const cardNumber = store.checkout.s.card_number;
    const cardExpiry = store.checkout.s.card_expiry;
    const cardCvv = store.checkout.s.card_cvv;

    if (_isEmpty(cardNumber) || !Payment.fns.validateCardNumber(cardNumber)) {
      this.setState({ error: 'Missing card number or card number is invalid' });
      return false;
    }

    if (_isEmpty(cardExpiry) || !Payment.fns.validateCardExpiry(cardExpiry)) {
      this.setState({ error: 'Missing card expiry or card expiry is invalid' });
      return false;
    }

    if (_isEmpty(cardCvv) || !Payment.fns.validateCardCVC(cardCvv)) {
      this.setState({ error: 'Missing card CVV or card CVV is invalid' });
      return false;
    }

    this.setState({ error: ' ' });

    return true;
  };

  render() {
    const { store } = this.injected;
    const {
      cardholder_name,
      billing_address_street,
      billing_address_city,
      postal_code,
      card_number,
      card_expiry,
      card_cvv,
    } = store.checkout.s;

    const { restaurant } = store;
    const { avs: isAvsEnabled, avs_only_postal_code: isAvsOnlyPostalCodeEnabled } =
      restaurant.settings.payments.bambora_na || {};

    return (
      <>
        <div id="paymentForm" style={{ marginTop: '2rem' }}>
          <Input
            type="text"
            name="card_number"
            className="col"
            inputMode="numeric"
            placeholder={'Card Number'}
            onChange={this.onChangeCardNumber}
            value={card_number}
            style={{ marginBottom: '1rem' }}
            required={true}
            autoComplete="cc-number"
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '.25rem',
              marginBottom: '1rem',
            }}
          >
            <Input
              type="text"
              name="card_expiry"
              className="col"
              pattern="([0-9]{2})\/([0-9]{2})"
              inputMode="numeric"
              placeholder={'Expiry (MM/YY)'}
              onChange={this.onChangeExpiry}
              value={card_expiry}
              required={true}
              autoComplete="cc-exp"
            />
            <NumberInputNoSpinButton
              type="text"
              name="card_cvv"
              className="col"
              placeholder={'CVV'}
              inputMode="numeric"
              onChange={this.onChangeCvv}
              value={card_cvv}
              required={true}
              autoComplete="cc-csc"
            />
          </div>
          {isAvsEnabled && (
            <>
              {!isAvsOnlyPostalCodeEnabled && (
                <>
                  <Input
                    type="text"
                    name="name"
                    className="col"
                    inputMode="text"
                    placeholder={"Cardholder's Name"}
                    onChange={this.onChangeName}
                    value={cardholder_name}
                    style={{ marginBottom: '1rem' }}
                    required={true}
                    autoComplete="name"
                    maxLength={64}
                  />
                  <Input
                    type="text"
                    name="address"
                    className="col"
                    inputMode="text"
                    placeholder={'Street Address'}
                    onChange={this.onChangeAddress}
                    value={billing_address_street}
                    style={{ marginBottom: '1rem' }}
                    required={true}
                    autoComplete="street-address"
                    maxLength={64}
                  />
                  <Input
                    type="text"
                    name="city"
                    className="col"
                    inputMode="text"
                    placeholder={'City'}
                    onChange={this.onChangeCity}
                    value={billing_address_city}
                    style={{ marginBottom: '1rem' }}
                    required={true}
                    autoComplete="address-level2 city"
                    maxLength={32}
                  />
                </>
              )}
              <Input
                type="text"
                name="postal_code"
                className="col"
                inputMode="text"
                placeholder={'Postal Code'}
                onChange={this.onChangePostalCode}
                value={postal_code}
                style={{ marginBottom: '1rem' }}
                required={true}
                autoComplete="postal-code"
              />
            </>
          )}
        </div>

        <p style={{ textAlign: 'center', marginTop: '1em', color: 'red' }}>{this.state.error}</p>
      </>
    );
  }
}

export const BamboraNaForm = BamboraNaFormClass;
