import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Modal, ModalContent, Button } from '@lib/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { MobxComponent } from '../../mobx/component';

interface Props extends WithTranslation {}

@inject('store')
@observer
class OrderRestartModalClass extends MobxComponent<Props> {
  render() {
    const { store, t } = this.injected;
    const { modal, order_config } = store;
    return (
      <Modal
        id="modal-order-restart"
        width="sm"
        closeButton={true}
        active={modal.isVisible('order-restart')}
        close={() => modal.hide('order-restart')}
      >
        <ModalContent>
          <p className="error-text small m-b-1">{t('store.modals.warnings.order_restart.warning')}</p>
          <h4>{t('store.modals.warnings.order_restart.title')}</h4>
        </ModalContent>
        <ModalContent>
          <p className="lhp">{t('store.modals.warnings.order_restart.body')}</p>
        </ModalContent>
        <ModalContent className="text-right" paddingtb={15}>
          <Button className="m-r-2" onClick={() => modal.hide('order-restart')}>
            <p>{t('store.modals.warnings.order_restart.cancel')}</p>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              modal.hide('order-restart');
              modal.show('order-config');
              store.checkout.resetPayments();
              store.order.generateOrderId(true);
              if (store.checkout.delivery_provider === 'uber') {
                order_config.update({
                  uber_quotation_id: '',
                });
              }
            }}
          >
            <p>{t('store.modals.warnings.order_restart.proceed')}</p>
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}

export const OrderRestartModal = withTranslation()(OrderRestartModalClass);
