import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { Input, RotateLoader } from "@lib/components";
import { OrderUtils } from "@lib/common";

interface Props extends WithTranslation { }
interface State {
	address: string;
	error: string;
	loading: boolean;
}

@inject("store") @observer
class OrderConfigAddressCustomClass extends MobxComponent<Props, State> {

	timeout: any;

	constructor(props: Props) {
		super(props);
		this.state = {
			address: "",
			error: "",
			loading: false,
		};
	}

	componentDidMount(): void {
		const oc = this.injected.store.order_config.s;
		if (oc.destination) {
			this.setState({ address: oc.destination });
		}
	}

	handleAddressChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const t = this.injected.t;
		const r = this.injected.store.restaurant;
		const oc = this.injected.store.order_config;
		const v = e.target.value;
		this.setState({ address: v, loading: true, error: "" });
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {

			const block_addresses = r.settings.services.delivery.block_addresses;
			const blocked = OrderUtils.checkBlockedAddresses(v, block_addresses);

			if (blocked) {
				oc.setService("delivery");
				this.setState({ loading: false, error: t("store.modals.order.config.address.not_delivering") });
			}
			else {
				this.injected.store.order_config.setDestination({
					destination: v,
					destination_misc: "",
					lat: -1,
					lng: -1,
					distance: -1,
					driving_time: -1,
					zone: "",
				});
				this.setState({ loading: false });
			}

		}, 1000);
	}

	render() {

		if (!this.state)
			return null;

		const { t } = this.injected;
		const { address, loading, error } = this.state;

		return (
			<div className="m-t-6">

				<div className="flex-line centered max400 width100 center">
					<Input
						className="text-center"
						type="text"
						value={address}
						placeholder={t("store.modals.order.config.address_custom.input_placeholder")}
						onChange={this.handleAddressChange}
					/>
					{loading && <RotateLoader size={2} className="m-l-2" />}
				</div>

				{error && (
					<p className="m-t-4 text-center error-text lhp small">{error}</p>
				)}

			</div>
		);
	}

}

export const OrderConfigAddressCustom = withTranslation()(OrderConfigAddressCustomClass);
