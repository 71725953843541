import React from 'react';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { CheckoutFormModal } from './checkout-form';
import { useStores } from '../../../mobx/useStores';
import { InputField, PaymentMethod } from './flows';
import { getCheckoutFormCommonFields } from './utils';
import { useTranslation } from 'react-i18next';

function PaymongoFormModalPlain() {
  const store = useStores();
  const { t } = useTranslation();

  const order = store.order.s.item;
  const paymongoData = order?.payment?.paymongo;

  if (!order || !paymongoData) return null;

  const paymentMethod: PaymentMethod = {
    label: 'PayMongo',
    name: order.payment.method,
    urlEndpoint: paymongoData.checkoutUrl,
  };

  const inputFields: InputField[] = [...getCheckoutFormCommonFields(order, t)];

  return (
    <CheckoutFormModal
      inputFields={inputFields}
      paymentMethod={paymentMethod}
      orderId={order._id}
      isFullScreen={isMobile}
      openNewWindow
    />
  );
}

export const PaymongoFormModal = observer(PaymongoFormModalPlain);
