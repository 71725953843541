import store from 'store';

export const SessionStorage = {
  clear() {
    store.each((value, key) => {
      if (key.indexOf('saved-session-') !== -1) {
        store.remove(key);
      }
    });
  },

  set(ref: string, data: string) {
    const key = `saved-session-${ref}`;
    store.set(key, data);
  },

  get(ref: string) {
    const key = `saved-session-${ref}`;
    const data = store.get(key);
    store.remove(key);
    if (data) return JSON.parse(data);
    return null;
  },

  remove(ref: string) {
    const key = `saved-session-${ref}`;
    store.remove(key);
  },

  list() {
    console.log('LIST SESSIONS');
    store.each((value, key) => {
      console.log(`key = ${key} --- value = ${value}`);
    });
  },
};
