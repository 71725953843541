import * as React from "react";
import { observer, inject } from "mobx-react";
import { styled, withTheme, TabSelect } from "@lib/components";
import { action } from "mobx";
import { MobxComponent } from "../../../../mobx/component";
import { withTranslation, WithTranslation } from "react-i18next";
import { TabSelect as NewTabSelect } from "../../../components/tab-select";

const Tabs = styled("div")`
	flex-grow: 1;
`;

@inject("store")
@observer
class CategoryNavBarClass extends MobxComponent<WithTranslation> {
	componentDidMount() {
		const { store } = this.injected;
		const { images } = store.restaurant.website.sections.header;

		if (
			store.view.itemLayoutStyle === "divided-into-categories" &&
			store.filters.s.category
		) {
			store.view.scrollToCategory(store.filters.s.category);
		}
	}

	@action onCategoryChange = (selected: { label: string; value: string }) => {
		const { store } = this.injected;

		if (store.view.itemLayoutStyle === "one-grid") {
			this.injected.store.filters.s.menu = this.injected.store.menu!._id;
			this.injected.store.filters.s.category = selected.value;
			store.view.scrollToMenu();
		} else {
			store.view.scrollToCategory(selected.value);
		}
	};

	render() {
		const { store } = this.injected;

		const theme = this.injected.theme;
		const { view, filters, restaurant, menu } = this.injected.store;

		const sections = restaurant.website.sections;

		if (!menu) return null;

		const hide_category_bar = sections.menu_nav_bar?.hide_category_bar;

		if (hide_category_bar) return null;

		const hideAllCategory =
			view.itemLayoutStyle === "divided-into-categories" ||
			sections.menus.dish_hide_all_category;

		let categoryOptions = menu.categories.map((c) => ({
			value: c._id,
			label: c.display_name || c.name,
		}));

		if (!hideAllCategory && categoryOptions.length) {
			const allOption = { label: "All", value: "" };
			categoryOptions.unshift(allOption);
		}

		const highlightSelectedStyle =
			sections.menu_nav_bar?.slider_highlight_selected_style;

		return (
			<Tabs>
				{store.view.itemLayoutStyle === "divided-into-categories" && (
					<NewTabSelect
						id="menu-tab-select"
						background={theme.top_nav.background_color}
						screenWidth={view.s.screen_width}
						value={filters.s.category}
						values={categoryOptions}
						onChange={this.onCategoryChange}
						highlightSelectedStyle={highlightSelectedStyle}
					/>
				)}

				{store.view.itemLayoutStyle === "one-grid" && (
					<TabSelect
						id="menu-tab-select"
						background={theme.top_nav.background_color}
						screenWidth={view.s.screen_width}
						value={filters.s.category}
						values={categoryOptions}
						onChange={this.onCategoryChange}
					/>
				)}
			</Tabs>
		);
	}
}

// @ts-ignore
export const CategoryNavBar = withTheme(withTranslation()(CategoryNavBarClass));
