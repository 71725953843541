import React from 'react';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { PaymentMethod } from './flows/types';
import { CheckoutFormModal } from './checkout-form';
import { useStores } from '../../../mobx/useStores';

function SwishFormModalPlain() {
  const store = useStores();

  const order = store.order.s.item;
  const swishData = order?.payment?.swish;

  if (!order || !swishData) return null;

  const paymentMethod: PaymentMethod = {
    name: order.payment.method,
  };

  const svgBlob = new Blob([swishData.qr], { type: 'image/svg+xml' });
  const svgUrl = URL.createObjectURL(svgBlob);

  return (
    <CheckoutFormModal
      useQrFlow={!isMobile}
      qrImageUrl={svgUrl}
      useDeepLinkFlow={isMobile}
      deepLink={swishData.deepLink}
      paymentMethod={paymentMethod}
      orderId={order._id}
      isFullScreen={isMobile}
    />
  );
}

export const SwishFormModal = observer(SwishFormModalPlain);
