import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, Button } from "@lib/components";
import { withTranslation, WithTranslation } from "react-i18next";
import { MobxComponent } from "../../mobx/component";

interface Props extends WithTranslation { }

@inject("store") @observer
class LogoutWarningModalClass extends MobxComponent<Props> {

	render() {
		const { store, t } = this.injected;
		const { modal } = store;
		return (
			<Modal
				id="modal-logout-warning"
				width="sm"
				closeButton={true}
				active={modal.isVisible("logout-warning")}
				close={() => modal.hide("logout-warning")}>

				<ModalContent>
					<p className="error-text small m-b-1">{t("store.modals.warnings.logout.pretitle")}</p>
					<h4>{t("store.modals.warnings.logout.title")}</h4>
				</ModalContent>

				<ModalContent>
					<p className="lhp">{t("store.modals.warnings.logout.body")}</p>
				</ModalContent>

				<ModalContent className="text-right" paddingtb={15}>
					<Button className="m-r-2" onClick={() => modal.hide("logout-warning")}>
						<p>{t("store.modals.warnings.logout.cancel")}</p>
					</Button>
					<Button color="primary" onClick={() => {
						modal.hide("logout-warning");
						store.customer.logout();
					}}>
						<p>{t("store.modals.warnings.logout.proceed")}</p>
					</Button>
				</ModalContent>

			</Modal>
		);
	}

}

export const LogoutWarningModal = withTranslation()(LogoutWarningModalClass);
