import * as React from "react";
import { Provider as ProviderComponent } from "mobx-react";
import { RootStore } from "../mobx/store";
import { App } from "./app";
import { ThemeProvider } from "@lib/components";
import { I18nextProvider } from "react-i18next";
import { SubscriptionContextProvider } from "./shared/contexts/subscription";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { config } from "../../config";

interface Props {
  store: RootStore;
  theme: T.DefaultTheme;
}

export default class Application extends React.Component<Props> {
  render() {
    const { store, theme } = this.props;
    const google_client_id = store.restaurant.settings.accounts.google.api_client_id || 
        (['cloudwaitress', 'cloudwaitress-test'].includes(store.reseller._id) && config.google_api_client_id) || '';
    return (
      <GoogleOAuthProvider 
        clientId={google_client_id}>
        <ProviderComponent store={store}>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={store.intl.i18n}>
              <SubscriptionContextProvider store={store}>
                <App />
              </SubscriptionContextProvider>
            </I18nextProvider>
          </ThemeProvider>
        </ProviderComponent>
      </GoogleOAuthProvider>
    );
  }
}
